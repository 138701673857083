import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../services/auth';
import { Sun, User as UserIcon } from 'react-feather';
import { useTheme } from '../../contexts/ThemeContext';
import { Switch } from './../../components/ui/Switch';
import { useAuth } from '../../components/AuthProvider';

function ProfileDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { theme, toggleTheme } = useTheme();
  const { user, loading } = useAuth();

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      await auth.logout();
      setIsOpen(false);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleThemeToggle = () => {
    toggleTheme();
    setIsOpen(false);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center w-10 h-10">
        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900 dark:border-white"></div>
      </div>
    );
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center w-10 h-10 text-sm font-medium text-gray-700 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-gray-300 dark:hover:text-gray-100"
      >
        <UserIcon size={24} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-10 dark:bg-gray-800">
          {user ? (
            <>
              <Link 
                to="/profile" 
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700" 
                onClick={() => setIsOpen(false)}
              >
                Profile
              </Link>
              <Link 
                to="/products" 
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700" 
                onClick={() => setIsOpen(false)}
              >
                My Products
              </Link>

              {/* Support Links */}
              <Link 
                to="/user/support/new" 
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700" 
                onClick={() => setIsOpen(false)}
              >
                Create Support Ticket
              </Link>
              <Link 
                to="/user/support" 
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700" 
                onClick={() => setIsOpen(false)}
              >
                My Tickets
              </Link>

              {/* Admin-only section */}
              {user.role === 'admin' && (
                <Link 
                  to="/admin/support" 
                  className="block px-4 py-2 text-sm text-blue-600 hover:bg-gray-100 dark:text-blue-400 dark:hover:bg-gray-700" 
                  onClick={() => setIsOpen(false)}
                >
                  Support Dashboard
                </Link>
              )}

              <div className="flex items-center px-4 py-2">
                <Sun size={24} className="text-gray-700 dark:text-gray-300 mr-2" />
                <Switch
                  checked={theme === 'light'}
                  onChange={handleThemeToggle}
                />
              </div>
              <button 
                onClick={handleLogout} 
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
              >
                Logout
              </button>
            </>
          ) : (
            <Link 
              to="/login" 
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700" 
              onClick={() => setIsOpen(false)}
            >
              Login
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default ProfileDropdown;
import React, { useState } from 'react';
import { Modal } from '../../../ui/Modal';
import { Button } from '../../../ui/Button';
import { Textarea } from '../../../ui/Textarea';
import DOMPurify from 'dompurify';

interface SwitchRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  productId: string;
}

const MAX_REQUEST_LENGTH = 4000;

const SwitchRequestModal: React.FC<SwitchRequestModalProps> = ({ isOpen, onClose, productId }) => {
  const [request, setRequest] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log(`Switch request for product ${productId}:`, request);
    // await api.sendSwitchRequest(productId, request);
    onClose();
  };

  const handleRequestChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const sanitizedContent = DOMPurify.sanitize(e.target.value);
    setRequest(sanitizedContent.slice(0, MAX_REQUEST_LENGTH));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-4 sm:p-6 bg-white dark:bg-gray-800 max-w-[90vw] sm:max-w-lg w-full rounded-lg shadow-lg">
        <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-gray-800 dark:text-white">
          Request Course Switch
        </h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4">
          Please explain why you need to switch courses. Our support team will review your request.
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <Textarea
            value={request}
            onChange={handleRequestChange}
            placeholder="Enter your switch request reason here..."
            className="w-full bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
            rows={4}
            aria-label="Switch request input"
            maxLength={MAX_REQUEST_LENGTH}
          />
          <p className="text-sm text-gray-500">
            {request.length}/{MAX_REQUEST_LENGTH} characters
          </p>
          <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
            <Button 
              type="button" 
              onClick={onClose} 
              variant="outline"
              className="w-full sm:w-auto bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 hover:text-gray-900 dark:hover:text-white"
            >
              Cancel
            </Button>
            <Button 
              type="submit"
              className="w-full sm:w-auto bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white"
            >
              Submit Request
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SwitchRequestModal;
import React, { useState } from 'react';
import { Modal } from '../../../components/ui/Modal';
import { Button } from '../../../components/ui/Button';

interface EndTestletProps {
  isOpen: boolean;
  onClose: () => void;
  onEndTestlet: () => Promise<void>;
  isLastQuestion: boolean;
  questionsAnswered: number;
  totalQuestions: number;
  isViewingExplanation: boolean;  // New prop
}

const EndTestlet: React.FC<EndTestletProps> = ({ 
  isOpen, 
  onClose, 
  onEndTestlet, 
  isLastQuestion, 
  questionsAnswered, 
  totalQuestions,
  isViewingExplanation  // New prop
}) => {
  const [isEnding, setIsEnding] = useState(false);

  const handleEndTestlet = async () => {
    setIsEnding(true);
    try {
      await onEndTestlet();
    } finally {
      setIsEnding(false);
    }
  };

  // Calculate the actual number of answered questions
  const actualAnsweredQuestions = isViewingExplanation ? questionsAnswered : Math.max(0, questionsAnswered - 1);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full mx-auto">
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              {isLastQuestion ? 'End of Testlet' : 'Warning'}
            </h2>
            <p className="mb-4 text-gray-700 dark:text-gray-300">
              You have answered {actualAnsweredQuestions} out of {totalQuestions} questions.
            </p>
            <p className="mb-6 text-gray-700 dark:text-gray-300">
              {isLastQuestion
                ? 'Would you like to finish the quiz or continue reviewing your answers?'
                : 'You have not completed all questions in this testlet. Are you sure you want to end it?'}
            </p>
            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
              <Button 
                onClick={onClose} 
                variant="outline" 
                className="w-full sm:w-auto dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
              >
                {isLastQuestion ? 'Continue Review' : 'Cancel'}
              </Button>
              <Button 
                onClick={handleEndTestlet} 
                variant={isLastQuestion ? "primary" : "destructive"} 
                className="w-full sm:w-auto relative"
                disabled={isEnding}
              >
                {isEnding && (
                  <span className="absolute inset-0 flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                )}
                <span className={isEnding ? 'invisible' : ''}>
                  {isLastQuestion ? 'Finish Quiz' : 'End Testlet'}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EndTestlet;
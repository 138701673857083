import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../../services/auth';
import { api } from '../../services/api'; // Add this import
import { getBrowserInstanceId } from '../../utils/browserInstance'; // Add this import

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    try {
      const { data } = await auth.login(email, password);
      api.clearAllCache(); // Clear all cache on successful login
      
      // Create or update user instance
      const browserInstanceId = await getBrowserInstanceId();
      await api.updateUserInstance(browserInstanceId);
      
      navigate('/');
    } catch (err) {
      setError((err as Error).message);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4 text-light-text dark:text-dark-text">Login</h2>
      {error && <p className="text-red-500 dark:text-red-400 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block mb-1 text-light-text dark:text-dark-text">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
          />
        </div>
        <div>
          <label htmlFor="password" className="block mb-1 text-light-text dark:text-dark-text">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
          />
        </div>
        <div className="flex justify-between items-center">
          <button type="submit" className="bg-light-primary dark:bg-dark-primary text-white py-2 px-4 rounded hover:bg-light-accent dark:hover:bg-dark-accent">
            Login
          </button>
          <Link to="/forgot-password" className="text-light-primary dark:text-dark-accent hover:underline">
            Forgot Password?
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Login;
import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, QuizHistory } from '../../services/api';
import { auth } from '../../services/auth';
import LoadingScreen from './common/LoadingScreen';
import { useQuizHistory } from '../../hooks/useQuizHistory';
import QuizHistoryTable from './QuizHistoryTable';
import Pagination from './common/Pagination';

interface AnalyticsProps {
  courseId: string;
  userId: string;
}

function Analytics({ courseId, userId }: AnalyticsProps) {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const quizzesPerPage = 20;
  const initialFetchDone = useRef(false);
  const [totalTime, setTotalTime] = useState(0);

  const {
    data: quizHistory,
    isLoading: quizHistoryLoading,
    error: quizHistoryError,
    refetchQuizHistory
  } = useQuizHistory(currentUser?.id, courseId, 'all', false, false);

  useEffect(() => {
    async function fetchUser() {
      const user = await auth.getCurrentUser();
      setCurrentUser(user);
    }
    fetchUser();
  }, []);

  const manualRefetch = useCallback(async () => {
    if (!isRefetching && currentUser) {
      setIsRefetching(true);
      try {
        // Delete empty quizzes before refetching
        await api.deleteEmptyQuizzes(currentUser.id, courseId);
        await refetchQuizHistory();
      } catch (error) {
        console.error('Error during refetch:', error);
      } finally {
        setIsRefetching(false);
      }
    }
  }, [refetchQuizHistory, isRefetching, currentUser, courseId]);

  useEffect(() => {
    if (currentUser && !initialFetchDone.current) {
      initialFetchDone.current = true;
      manualRefetch();
    }
  }, [currentUser, manualRefetch]);

  const handleViewQuizResults = async (quizId: string) => {
    try {
      const quizHistoryData = await api.getQuizHistoryById(quizId);
      if (!quizHistoryData) throw new Error('Quiz history not found');
  
      navigate(`/course/${courseId}/quiz`, { 
        state: { 
          viewQuizId: quizId,
          quizData: quizHistoryData,
          from: 'analytics',
          showSummary: true,
          quizCompleted: true
        } 
      });
    } catch (err) {
      console.error('Failed to fetch quiz history:', err);
      alert('Failed to load quiz results. Please try again.');
    }
  };

  const handleResumeQuizFromHistory = useCallback((quizToResume: QuizHistory) => {
    navigate(`/course/${courseId}/quiz`, { 
      state: { 
        resumeQuizId: quizToResume.id,
        quizData: quizToResume
      } 
    });
  }, [courseId, navigate]);


  const handleEndQuizSession = useCallback(async (quizId: string) => {
    try {
      await api.endQuizSession(quizId, totalTime);
      await refetchQuizHistory();
      alert('Quiz session ended successfully.');
    } catch (err) {
      console.error('Failed to end quiz session:', err);
      alert('Failed to end quiz session. Please try again.');
    }
  }, [refetchQuizHistory]);

  const renderQuizHistory = () => {
    if (quizHistoryLoading || isRefetching) {
      return <LoadingScreen message="Loading quiz history..." subMessage="Please wait while we fetch your quiz data." />;
    }
    if (quizHistoryError) return <div>Error loading quiz history: {(quizHistoryError as Error).message || 'Unknown error'}</div>;
    if (!quizHistory || quizHistory.length === 0) return <div>No quiz history available.</div>;

    const indexOfLastQuiz = currentPage * quizzesPerPage;
    const indexOfFirstQuiz = indexOfLastQuiz - quizzesPerPage;
    const currentQuizzes = quizHistory.slice(indexOfFirstQuiz, indexOfLastQuiz);

    return (
      <div className="mt-8">

        <div className="overflow-x-auto">
          <QuizHistoryTable 
            quizzes={currentQuizzes} 
            onViewResults={handleViewQuizResults}
            onResumeQuiz={handleResumeQuizFromHistory}
            onEndQuiz={handleEndQuizSession}
          />
        </div>
        <Pagination
          currentPage={currentPage}
          totalItems={quizHistory.length}
          itemsPerPage={quizzesPerPage}
          onPageChange={setCurrentPage}
        />
      </div>
    );
  };

  return (
    <div className="space-y-6 lg:px-8">
      <div className="flex flex-col sm:flex-row justify-between items-center">
        <h2 className="text-2xl font-bold text-light-text dark:text-dark-text">
          Your Progress Analytics
        </h2>
        <button
          onClick={manualRefetch}
          className="mt-4 sm:mt-0 px-4 py-2 bg-light-primary dark:bg-dark-primary text-white rounded hover:bg-light-accent dark:hover:bg-dark-accent"
        >
          Refresh Quiz History
        </button>
      </div>
      {renderQuizHistory()}
    </div>
  );
  
}

export default Analytics;
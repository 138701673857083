import React, { useState, useContext, useEffect } from 'react';
import { ModalContext } from '../../App'; // Adjust the import path as necessary
import useKeyPress from '../../hooks/useKeyPress';// Adjust the import path as necessary
import DOMPurify from 'dompurify';

const MAX_CONFIRM_LENGTH = 10; // Adjust this as needed

interface QuizResetCategoryPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  categoryCount: number;
}

const QuizResetCategoryPopup: React.FC<QuizResetCategoryPopupProps> = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  categoryCount 
}) => {
  const [confirmText, setConfirmText] = useState('');
  const { setIsModalOpen } = useContext(ModalContext);

  useEffect(() => {
    setIsModalOpen(isOpen);
    return () => setIsModalOpen(false);
  }, [isOpen, setIsModalOpen]);

  const sanitizeInput = (input: string) => {
    return DOMPurify.sanitize(input);
  };

  const handleConfirm = () => {
    if (sanitizeInput(confirmText).toLowerCase() === 'reset') {
      onConfirm();
      setConfirmText('');
      setIsModalOpen(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedInput = sanitizeInput(e.target.value).slice(0, MAX_CONFIRM_LENGTH);
    setConfirmText(sanitizedInput);
  };

  const handleClose = () => {
    onClose();
    setIsModalOpen(false);
  };

  useKeyPress('Enter', handleConfirm, () => isOpen && confirmText.toLowerCase() === 'reset');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-gray-200">Reset Quiz Categories</h2>
        <p className="mb-4 text-gray-600 dark:text-gray-400">
          Are you sure you want to reset {categoryCount} selected quiz {categoryCount === 1 ? 'category' : 'categories'}? 
          This action will mark all questions in these categories as unanswered BUT will retain your confidence levels.
        </p>
        <p className="mb-2 text-gray-600 dark:text-gray-400">
          Enter RESET in the textbox below to confirm:
        </p>
        <input
          type="text"
          value={confirmText}
          onChange={handleInputChange}
          className="w-full p-2 mb-4 border rounded text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-700"
          placeholder="Type RESET to confirm"
          maxLength={MAX_CONFIRM_LENGTH}
        />
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
            <button
              onClick={handleConfirm}
              disabled={sanitizeInput(confirmText).toLowerCase() !== 'reset'}
              className={`px-4 py-2 rounded ${
                sanitizeInput(confirmText).toLowerCase() === 'reset'
                  ? 'bg-red-500 text-white hover:bg-red-600'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizResetCategoryPopup;
import React, { useState, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { api, StudyTaskDetails } from '../../../services/api';
import { Check, AlertTriangle } from 'lucide-react';
import DOMPurify from 'dompurify';

interface MockExamRetakeTaskProps {
  taskDetails: StudyTaskDetails;
  userId: string;
  courseId: string;
  itemId: string;
  onTaskComplete: () => void;
}

interface KeslerTask {
  id: string;
  title: string;
  description: string;
  completed: boolean;
  actionType: 'big-box-mock' | 'kesler-mock';
  buttonText: string;
}

const MockExamRetakeTask: React.FC<MockExamRetakeTaskProps> = ({ taskDetails, userId, courseId, itemId, onTaskComplete }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isMainTaskCompleted, setIsMainTaskCompleted] = useState<boolean>(false);
  const [keslerTasks, setKeslerTasks] = useState<KeslerTask[]>([]);

  const getTaskAction = useCallback((actionType: KeslerTask['actionType']) => {
    const mockExamUrl = `https://app.keslercpareview.com/course/${courseId}/mock-exam`;
    return () => window.open(mockExamUrl, '_blank');
  }, [courseId]);

  useEffect(() => {
    if (taskDetails) {
      setIsMainTaskCompleted(taskDetails.is_completed);
  
      const storedTasks = localStorage.getItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`);
      if (storedTasks) {
        const parsedTasks: KeslerTask[] = JSON.parse(storedTasks);
        const updatedTasks = parsedTasks.map(task => ({
          ...task,
          completed: taskDetails.is_completed ? true : task.completed,
        }));
        setKeslerTasks(updatedTasks);
        localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(updatedTasks));
      } else {
        const newTasks: KeslerTask[] = [
          { 
            id: '1', 
            title: 'Take Big Box CPA Review Course Mock Exam (Retake)', 
            description: 'Complete a full mock exam from your Big Box CPA Review Course, focusing on your previously identified weak areas',
            completed: taskDetails.is_completed, 
            actionType: 'big-box-mock',
            buttonText: 'Start Big Box Mock'
          },
          { 
            id: '2', 
            title: 'Take Kesler CPA Review Mock Exam (Retake)', 
            description: 'Complete a full mock exam from Kesler CPA Review, paying special attention to topics you struggled with in your previous attempt',
            completed: taskDetails.is_completed,
            actionType: 'kesler-mock',
            buttonText: 'Start Kesler Mock'
          },
        ];
        setKeslerTasks(newTasks);
        localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(newTasks));
      }
    }
  }, [taskDetails, itemId]);

  const toggleTaskCompletion = async (taskId: string) => {
    const updatedTasks = keslerTasks.map(task =>
      task.id === taskId ? { ...task, completed: !task.completed } : task
    );
    setKeslerTasks(updatedTasks);
    localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(updatedTasks));
  
    const allCompleted = updatedTasks.every(task => task.completed);
    if (allCompleted && !isMainTaskCompleted) {
      await handleMarkComplete();
    } else if (!allCompleted && isMainTaskCompleted) {
      await handleUncompleteTask();
    }
  };
  
  const handleMarkComplete = async () => {
    try {
      await api.markStudyTaskComplete(itemId);
      setIsMainTaskCompleted(true);
      const updatedTasks = keslerTasks.map(task => ({ ...task, completed: true }));
      setKeslerTasks(updatedTasks);
      localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(updatedTasks));
      
      await queryClient.invalidateQueries(['studyPlan', userId, courseId]);
      onTaskComplete();
    } catch (error) {
      console.error('Error marking task as complete:', error);
    }
  };
  
  const handleUncompleteTask = async () => {
    try {
      await api.unmarkStudyTaskComplete(itemId);
      setIsMainTaskCompleted(false);
      
      await queryClient.invalidateQueries(['studyPlan', userId, courseId]);
      onTaskComplete();
    } catch (error) {
      console.error('Error unmarking task as complete:', error);
    }
  };

  const formatContentWithLineBreaks = (content: string) => {
    return content.replace(/;(?=<\/span><span class="font-bold">)/g, ';</span><br/><span class="font-bold">');
  };

  const renderTaskContent = (taskDetails: StudyTaskDetails) => {
    return (
      <>
        <div className="text-center px-6 pb-6 bg-transparent">
          <h2 
            className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-gray-100"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(taskDetails.task_name) }}
          />
        </div>
        <div className="mb-8">
          <div className="mb-6 p-6 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm">
            <h3 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">Mock Exam Retake Tasks</h3>
            <div 
              className="text-gray-700 dark:text-gray-300 p-4 bg-white dark:bg-gray-800 rounded-md border-l-4 border-primary-orange"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatContentWithLineBreaks(taskDetails.task_name)) }}
            />
          </div>
          <div className="mb-6 p-4 bg-yellow-100 dark:bg-yellow-800 rounded-lg">
            <h4 className="flex items-center text-lg font-semibold mb-2 text-yellow-800 dark:text-yellow-200">
              <AlertTriangle className="mr-2" size={24} />
              RETAKE PRO TIP
            </h4>
            <p className="text-yellow-800 dark:text-yellow-200">
              For this retake, focus on improving your weak areas identified in your previous attempt. Pay special attention to time management and question types you struggled with before. Remember, progress is more important than the score itself. Use this mock exam as a tool to refine your strategy and boost your confidence for the real exam.
            </p>
          </div>
          <div className="w-full mb-4 overflow-hidden">
            <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 overflow-hidden">
              <table className="w-full">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">To-Do Items</th>
                    <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Take Exam</th>
                    <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Mark Complete</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {keslerTasks.map((task) => (
                    <tr key={task.id}>
                      <td className="py-4 px-4">
                        <p className={`${task.completed ? 'line-through text-gray-400 dark:text-gray-500' : 'text-gray-700 dark:text-gray-300'}`}>
                          {task.description}
                        </p>
                      </td>
                      <td className="py-4 px-4 text-center">
                        <button
                          onClick={getTaskAction(task.actionType)}
                          className="bg-primary-orange hover:bg-primary-orange-hover text-white py-2 px-4 rounded-md transition duration-300 text-sm"
                        >
                          {task.buttonText}
                        </button>
                      </td>
                      <td className="py-4 px-4 text-center">
                        <div className="flex justify-center">
                          <button
                            onClick={() => toggleTaskCompletion(task.id)}
                            className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300 border-2 ${
                              task.completed
                                ? 'bg-primary-blue border-primary-blue text-white'
                                : 'bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-500 hover:border-primary-blue dark:hover:border-primary-blue'
                            }`}
                            title={task.completed ? "Mark as incomplete" : "Mark as complete"}
                          >
                            {task.completed && <Check size={16} className="mx-auto" />}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="bg-transparent">
      {renderTaskContent(taskDetails)}

      <div className="mt-8">
        <button
          onClick={isMainTaskCompleted ? handleUncompleteTask : handleMarkComplete}
          className={`w-full ${
            isMainTaskCompleted
              ? 'bg-gray-400 hover:bg-gray-500'
              : 'bg-primary-blue hover:bg-primary-blue-hover'
          } text-white py-3 px-6 rounded-md transition duration-300`}
        >
          {isMainTaskCompleted ? 'Mark Task as Incomplete' : 'Mark Task as Complete'}
        </button>
      </div>
    </div>
  );
};

export default MockExamRetakeTask;
import React from 'react';
import { Check } from 'lucide-react';

interface CheckboxProps {
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  onCheckedChange,
  disabled = false,
  className = '',
  onClick
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && onCheckedChange) {
      onCheckedChange(e.target.checked);
    }
  };

  return (
    <div 
      className={`relative inline-flex ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} ${className}`}
      onClick={onClick}
    >
      <input
        type="checkbox"
        className="sr-only"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <div
        className={`
          w-5 h-5 rounded border transition-all duration-200 flex items-center justify-center
          ${checked 
            ? 'bg-blue-500 border-blue-500 dark:bg-blue-600 dark:border-blue-600' 
            : 'bg-white border-gray-300 dark:bg-gray-800 dark:border-gray-600'
          }
          ${!disabled && !checked && 'hover:border-blue-500 dark:hover:border-blue-400'}
        `}
      >
        {checked && (
          <Check 
            className="h-3.5 w-3.5 text-white stroke-[3]" 
          />
        )}
      </div>
    </div>
  );
};

export default Checkbox;
import React from 'react';

interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'destructive' | 'warning' | 'success';
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ className = '', variant = 'default', ...props }, ref) => {
    const variants = {
      default: 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-200',
      destructive: 'bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-300',
      warning: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-300',
      success: 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300'
    };

    return (
      <div
        ref={ref}
        role="alert"
        className={`
          relative w-full rounded-lg border p-4
          ${variants[variant]}
          ${className}
        `}
        {...props}
      />
    );
  }
);

Alert.displayName = 'Alert';

interface AlertTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {}

export const AlertTitle = React.forwardRef<HTMLHeadingElement, AlertTitleProps>(
  ({ className = '', ...props }, ref) => {
    return (
      <h5
        ref={ref}
        className={`mb-1 font-medium leading-none tracking-tight ${className}`}
        {...props}
      />
    );
  }
);

AlertTitle.displayName = 'AlertTitle';

interface AlertDescriptionProps extends React.HTMLAttributes<HTMLParagraphElement> {}

export const AlertDescription = React.forwardRef<HTMLParagraphElement, AlertDescriptionProps>(
  ({ className = '', ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={`mt-2 text-sm [&_p]:leading-relaxed ${className}`}
        {...props}
      />
    );
  }
);

AlertDescription.displayName = 'AlertDescription';
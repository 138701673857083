import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { useQuery, UseQueryResult, QueryClient, QueryClientProvider } from 'react-query';
import { useAuth } from '../components/AuthProvider';
import { UserCourseAccess, UserCoursePurchase, User } from '../services/api';
import { userDataService } from '../services/userDataService';

interface UserCourseAccessContextType {
  userCourseAccesses: UserCourseAccess[];
  userCoursePurchases: UserCoursePurchase[];
  userData: User | null;
  hasActivePurchase: boolean;
  canSwitchToPaidCourse: boolean;
  isLoading: boolean;
  error: unknown;
  refetch: () => Promise<void>;
}

const UserCourseAccessContext = createContext<UserCourseAccessContextType | undefined>(undefined);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

export const UserCourseAccessProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();

  const queryKey = ['userCourseData', user?.id];

  // Update the useQuery section
  const { data, isLoading, error, refetch }: UseQueryResult<{
    user: User | null;
    userCoursePurchases: UserCoursePurchase[];
    userCourseAccesses: UserCourseAccess[];
  }, unknown> = useQuery(
    queryKey,
    async () => {
      if (!user) throw new Error('No authenticated user');
      
      const userData = await userDataService.getAllUserData(user.id);
      return { 
        user: userData.user, 
        userCoursePurchases: userData.coursePurchases,
        userCourseAccesses: userData.courseAccesses
      };
    },
    {
      enabled: !!user,
      refetchInterval: 5 * 60 * 1000,
    }
  );

  const safeRefetch = useCallback(async () => {
    if (user) {
      await refetch();
    }
  }, [user, refetch]);

  const contextValue = useMemo(() => {
    const hasActivePurchase = data?.userCoursePurchases.some(purchase => 
      (purchase.tier_id === 'c99793af-5529-446b-be12-cc56709509e6' || 
       purchase.tier_id === '0b992474-3a65-41b5-ad90-cc88662cc594') &&
      purchase.is_active && 
      (purchase.subscription_status === 'active' || purchase.subscription_status === null)
    ) ?? false;

    const canSwitchToPaidCourse = !!data?.user && 
      typeof data.user.switches_available === 'number' && 
      data.user.switches_available > 0;

    return {
      userCourseAccesses: data?.userCourseAccesses ?? [],
      userCoursePurchases: data?.userCoursePurchases ?? [],
      userData: data?.user ?? null,
      hasActivePurchase,
      canSwitchToPaidCourse,
      isLoading,
      error,
      refetch: safeRefetch,
    };
  }, [data, isLoading, error, safeRefetch]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserCourseAccessContext.Provider value={contextValue}>
        {children}
      </UserCourseAccessContext.Provider>
    </QueryClientProvider>
  );
};

export const useUserCourseAccess = () => {
  const context = useContext(UserCourseAccessContext);
  if (context === undefined) {
    throw new Error('useUserCourseAccess must be used within a UserCourseAccessProvider');
  }
  return context;
};
// Format date to a readable string
export const formatDate = (date: string | number | Date): string => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

// Calculate percentage
export const calculatePercentage = (value: number, total: number): number => {
  return Math.round((value / total) * 100);
};

// Truncate long text
export const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + '...';
};

// Generate a random color (for charts or UI elements)
export const getRandomColor = (): string => {
  return '#' + Math.floor(Math.random()*16777215).toString(16);
};

// Capitalize the first letter of a string
export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
import React, { useState, useCallback } from 'react';

interface FormattedNumberInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

const FormattedNumberInput: React.FC<FormattedNumberInputProps> = ({
  value,
  onChange,
  placeholder,
  className,
  disabled
}) => {
  const [displayValue, setDisplayValue] = useState(formatNumber(value));

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/[^\d.-]/g, '');
    setDisplayValue(formatNumber(rawValue));
    onChange(rawValue); // Pass the raw value to the parent component
  }, [onChange]);

  const handleBlur = useCallback(() => {
    setDisplayValue(formatNumber(value));
  }, [value]);

  return (
    <input
      type="text"
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
    />
  );
};

function formatNumber(value: string): string {
  if (!value) return '';
  
  const parts = value.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
  // Only add decimal part if it exists in the original value
  if (parts.length > 1) {
    return parts.join('.');
  } else {
    return parts[0];
  }
}

export default FormattedNumberInput;
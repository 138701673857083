import React from 'react';
import { StudyTaskDetails } from '../../../services/api';
import LearnTask from './LearnTask';
import LoopTask from './LoopTask';
import FunnelTask from './FunnelTask';
import RetakeFunnelTask from './RetakeFunnelTask';
import MockExamTask from './MockExamTask';
import MockExamRetakeTask from './MockExamRetakeTask';

interface TaskTypeRendererProps {
  taskDetails: StudyTaskDetails;
  userId: string;
  courseId: string;
  itemId: string;
  onTaskComplete: () => void;
  nextTaskId: string | null;
  prevTaskId: string | null;
  onNavigateNext: () => void;
  onNavigatePrev: () => void;
}

const TaskTypeRenderer: React.FC<TaskTypeRendererProps> = ({
  taskDetails,
  userId,
  courseId,
  itemId,
  onTaskComplete,
  nextTaskId,
  prevTaskId,
  onNavigateNext,
  onNavigatePrev
}) => {
  console.log('TaskTypeRenderer - Received taskDetails:', taskDetails);
  console.log('TaskTypeRenderer - topic_type:', taskDetails.topic_type);

  const commonProps = {
    taskDetails,
    userId,
    courseId,
    itemId,
    onTaskComplete,
    nextTaskId,
    prevTaskId,
    onNavigateNext,
    onNavigatePrev
  };

  let renderedComponent;

  switch (taskDetails.topic_type) {
    case 'learn':
      console.log('Rendering LearnTask');
      renderedComponent = <LearnTask {...commonProps} />;
      break;
    case 'loop':
      console.log('Rendering LoopTask');
      renderedComponent = <LoopTask {...commonProps} />;
      break;
    case 'funnel':
      console.log('Rendering FunnelTask');
      renderedComponent = <FunnelTask {...commonProps} />;
      break;
    case 'retake_funnel':
      console.log('Rendering RetakeFunnelTask');
      renderedComponent = <RetakeFunnelTask {...commonProps} />;
      break;
    case 'mock_exam':
      console.log('Rendering MockExamTask');
      renderedComponent = <MockExamTask {...commonProps} />;
      break;
    case 'mock_exam_retake':
      console.log('Rendering MockExamRetakeTask');
      renderedComponent = <MockExamRetakeTask {...commonProps} />;
      break;
    default:
      console.log('Unknown task type:', taskDetails.topic_type);
      renderedComponent = <div>Unknown task type: {taskDetails.topic_type}</div>;
  }

  console.log('TaskTypeRenderer - Rendered component:', renderedComponent.type.name);

  return renderedComponent;
};

export default TaskTypeRenderer;
import React, { useState, useEffect, useCallback } from 'react';
import { api } from '../../services/api';
import { auth } from '../../services/auth';

interface MockExamProps {
  courseId: string;
  userId: string;
}

interface ExamSection {
  id: string;
  title: string;
  timeLimit: number;
  questions: ExamQuestion[];
}

interface ExamQuestion {
  id: string;
  text: string;
  options: ExamQuestionOption[];
}

interface ExamQuestionOption {
  id: string;
  text: string;
}

interface Exam {
  id: string;
  title: string;
  sections: ExamSection[];
}

interface UserAnswers {
  [key: string]: string;
}

function MockExam({ courseId, userId }: MockExamProps) {
  const [exam, setExam] = useState<Exam | null>(null);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState<UserAnswers>({});
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [examCompleted, setExamCompleted] = useState(false);
  const [onBreak, setOnBreak] = useState(false);
  const [breakTimeRemaining, setBreakTimeRemaining] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchExam = useCallback(async () => {
    try {
      const user = await auth.getCurrentUser();
      if (!user) throw new Error('User not authenticated');
      
      const data = await api.getMockExam(courseId);
     // setExam(data);
     // setTimeRemaining(data.sections[0].timeLimit * 60);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch mock exam. Please try again later.');
      setLoading(false);
    }
  }, [courseId, userId]);

  useEffect(() => {
    fetchExam();
  }, [fetchExam]);

  const handleSectionComplete = useCallback(() => {
    if (exam && currentSection < exam.sections.length - 1) {
      setCurrentSection(prevSection => prevSection + 1);
      setCurrentQuestion(0);
      setOnBreak(true);
      setBreakTimeRemaining(10 * 60); // 10 minutes break
    } else {
      setExamCompleted(true);
    }
  }, [currentSection, exam]);

  const handleBreakComplete = useCallback(() => {
    if (exam) {
      setOnBreak(false);
      setTimeRemaining(exam.sections[currentSection].timeLimit * 60);
    }
  }, [currentSection, exam]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (exam && !examCompleted) {
      if (onBreak) {
        timer = setInterval(() => {
          setBreakTimeRemaining(prevTime => {
            if (prevTime !== null && prevTime <= 1) {
              clearInterval(timer);
              handleBreakComplete();
              return 0;
            }
            return prevTime !== null ? prevTime - 1 : null;
          });
        }, 1000);
      } else {
        timer = setInterval(() => {
          setTimeRemaining(prevTime => {
            if (prevTime !== null && prevTime <= 1) {
              clearInterval(timer);
              handleSectionComplete();
              return 0;
            }
            return prevTime !== null ? prevTime - 1 : null;
          });
        }, 1000);
      }
    }
    return () => clearInterval(timer);
  }, [exam, examCompleted, onBreak, handleBreakComplete, handleSectionComplete]);

  function handleAnswer(questionId: string, answerId: string) {
    setUserAnswers(prevAnswers => ({
      ...prevAnswers,
      [`${currentSection}-${questionId}`]: answerId
    }));
  }

  function formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  if (loading) return <div>Loading mock exam...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!exam) return <div>No exam data available.</div>;

  if (examCompleted) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold">Mock Exam Completed</h2>
        <p>Thank you for completing the mock exam. Your results will be available soon.</p>
        {/* Here you would typically show a summary of the exam, including score if available */}
      </div>
    );
  }

  if (onBreak && breakTimeRemaining !== null) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold">Break Time</h2>
        <p>Time remaining: {formatTime(breakTimeRemaining)}</p>
        <button
          onClick={handleBreakComplete}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          End Break
        </button>
      </div>
    );
  }

  const currentSectionData = exam.sections[currentSection];
  const currentQuestionData = currentSectionData.questions[currentQuestion];

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Mock Exam: {exam.title}</h2>
      <div className="flex justify-between items-center">
        <p>Section {currentSection + 1} of {exam.sections.length}</p>
        <p>Time remaining: {timeRemaining !== null ? formatTime(timeRemaining) : 'N/A'}</p>
      </div>
      <div className="bg-white p-4 rounded shadow">
        <h3 className="text-xl font-bold mb-4">Question {currentQuestion + 1} of {currentSectionData.questions.length}</h3>
        <p className="mb-4">{currentQuestionData.text}</p>
        <div className="space-y-2">
          {currentQuestionData.options.map((option) => (
            <button
              key={option.id}
              onClick={() => handleAnswer(currentQuestionData.id, option.id)}
              className={`w-full text-left p-2 rounded ${
                userAnswers[`${currentSection}-${currentQuestionData.id}`] === option.id
                  ? 'bg-blue-100'
                  : 'hover:bg-gray-100'
              }`}
            >
              {option.text}
            </button>
          ))}
        </div>
      </div>
      <div className="flex justify-between">
        <button
          onClick={() => setCurrentQuestion(prev => Math.max(0, prev - 1))}
          disabled={currentQuestion === 0}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={() => {
            if (currentQuestion < currentSectionData.questions.length - 1) {
              setCurrentQuestion(prev => prev + 1);
            } else {
              handleSectionComplete();
            }
          }}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          {currentQuestion < currentSectionData.questions.length - 1 ? 'Next' : 'Finish Section'}
        </button>
      </div>
    </div>
  );
}

export default MockExam;
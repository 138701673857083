import React, { useState, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { api, StudyTaskDetails } from '../../../services/api';
import { Check } from 'lucide-react';
import DOMPurify from 'dompurify';

interface LearnTaskProps {
  taskDetails: StudyTaskDetails;
  userId: string;
  courseId: string;
  itemId: string;
  onTaskComplete: () => void;
}

interface KeslerTask {
  id: string;
  title: string;
  description: string;
  completed: boolean;
  actionType: 'lesson' | 'flashcards' | 'quiz';
  buttonText: string;
}

const LearnTask: React.FC<LearnTaskProps> = ({ taskDetails, userId, courseId, itemId, onTaskComplete }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isMainTaskCompleted, setIsMainTaskCompleted] = useState<boolean>(false);
  const [keslerTasks, setKeslerTasks] = useState<KeslerTask[]>([]);

  const createTasks = useCallback((isCompleted: boolean): KeslerTask[] => {
    return [
      { 
        id: '1', 
        title: 'Review Kesler Lesson', 
        description: 'Review a textbook/lecture covering the topic above, and leave notes in the corresponding Kesler Lesson recording all of your ah-ha moments',
        completed: isCompleted, 
        actionType: 'lesson',
        buttonText: 'Lesson'
      },
      { 
        id: '2', 
        title: 'Practice KeslerCards', 
        description: 'Take some time to review the flashcards for this topic before answering practice questions',
        completed: isCompleted,
        actionType: 'flashcards',
        buttonText: 'Flashcards'
      },
      { 
        id: '3', 
        title: 'Complete KeslerQs', 
        description: "Complete a sample of 10-15 KeslerQs on this topic and leave notes on questions you don't understand for later review",
        completed: isCompleted,
        actionType: 'quiz',
        buttonText: 'Quiz'
      },
    ];
  }, []);

  const getTaskAction = useCallback((actionType: KeslerTask['actionType'], taskDetails: StudyTaskDetails) => {
    switch (actionType) {
      case 'lesson':
        return () => {
          navigate(`/course/${courseId}/lessons/${taskDetails?.lesson_id}`, { state: { item_id: itemId } });
        };
      case 'flashcards':
        return () => {
          if (taskDetails) {
            const encodedCategory = encodeURIComponent(`${taskDetails.parent_flashcard_category_name} - ${taskDetails.sub_flashcard_category_name}`);
            const encodedReturnUrl = encodeURIComponent(`/course/${courseId}/study-plan/${itemId}`);
            const url = `/course/${courseId}/kesler-cards?category=${encodedCategory}&returnUrl=${encodedReturnUrl}`;
            navigate(url);
          }
        };
      case 'quiz':
        return () => {
          navigate(`/course/${courseId}/quiz?category=${taskDetails?.question_category_id}&count=10`);
        };
      default:
        return () => {};
    }
  }, [courseId, itemId, navigate]);

  useEffect(() => {
    if (taskDetails) {
      setIsMainTaskCompleted(taskDetails.is_completed);

      const storedTasks = localStorage.getItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`);
      if (storedTasks) {
        const parsedTasks: KeslerTask[] = JSON.parse(storedTasks);
        const updatedTasks = parsedTasks.map(task => ({
          ...task,
          completed: taskDetails.is_completed ? true : task.completed,
        }));
        setKeslerTasks(updatedTasks);
        localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(updatedTasks));
      } else {
        const newTasks = createTasks(taskDetails.is_completed);
        setKeslerTasks(newTasks);
        localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(newTasks));
      }
    }
  }, [taskDetails, itemId, createTasks]);

  const toggleTaskCompletion = async (taskId: string) => {
    const updatedTasks = keslerTasks.map(task =>
      task.id === taskId ? { ...task, completed: !task.completed } : task
    );
    setKeslerTasks(updatedTasks);
    localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(updatedTasks));
  
    const allCompleted = updatedTasks.every(task => task.completed);
    if (allCompleted && !isMainTaskCompleted) {
      await handleMarkComplete();
    } else if (!allCompleted && isMainTaskCompleted) {
      await handleUncompleteTask();
    }
  };
  
  const handleMarkComplete = async () => {
    try {
      await api.markStudyTaskComplete(itemId);
      setIsMainTaskCompleted(true);
      const updatedTasks = keslerTasks.map(task => ({ ...task, completed: true }));
      setKeslerTasks(updatedTasks);
      localStorage.setItem(`keslerTasks_${itemId}_${taskDetails.topic_type}`, JSON.stringify(updatedTasks));
      
      await queryClient.invalidateQueries(['studyPlan', userId, courseId]);
      onTaskComplete();
    } catch (error) {
      console.error('Error marking task as complete:', error);
    }
  };
  
  const handleUncompleteTask = async () => {
    try {
      await api.unmarkStudyTaskComplete(itemId);
      setIsMainTaskCompleted(false);
      
      await queryClient.invalidateQueries(['studyPlan', userId, courseId]);
      onTaskComplete();
    } catch (error) {
      console.error('Error unmarking task as complete:', error);
    }
  };

  const formatContentWithLineBreaks = (content: string) => {
    return content.replace(/;(?=<\/span><span class="font-bold">)/g, ';</span><br/><span class="font-bold">');
  };

  const renderTaskContent = (taskDetails: StudyTaskDetails) => {
    const isKeslerPrimary = taskDetails.primary_course_name === 'Kesler CPA Review';
    
    const nonKeslerCourseName = isKeslerPrimary ? taskDetails.secondary_course_display_name : taskDetails.primary_course_display_name;
    const nonKeslerCourseAlias = isKeslerPrimary ? taskDetails.secondary_course_alias : taskDetails.primary_course_alias;
    
    const topicTitle = isKeslerPrimary 
      ? (taskDetails.secondary_topic_title || taskDetails.blueprint_topic_title)
      : taskDetails.primary_topic_title;
    
    const keslerTopicTitle = isKeslerPrimary ? taskDetails.primary_topic_title : taskDetails.secondary_topic_title;
  
    const displayCourseName = (name: string | null, alias: string | null) => {
      if (name && alias) {
        return `${name} (${alias})`;
      }
      return name || 'N/A';
    };
  
    return (
      <>
        <div className="text-center px-6 pb-6 bg-transparent">
          <h2 
            className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-gray-100"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(taskDetails.task_name) }}
          />
        </div>
        {nonKeslerCourseName && (
          <div className="mb-8 p-6 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm">
            <h3 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">
              {displayCourseName(nonKeslerCourseName, nonKeslerCourseAlias)} Tasks
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Review a textbook and/or video lectures that cover this topic:
            </p>
            <div 
              className="font-medium text-gray-800 dark:text-gray-200 p-4 bg-white dark:bg-gray-800 rounded-md border-l-4 border-primary-blue"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatContentWithLineBreaks(topicTitle || 'N/A')) }}
            />
          </div>
        )}
  
        <div className="mb-8">
          <div className="mb-6 p-6 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 shadow-sm">
            <h3 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">Kesler Tasks</h3>
            <div 
              className="text-gray-700 dark:text-gray-300 p-4 bg-white dark:bg-gray-800 rounded-md border-l-4 border-primary-orange"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatContentWithLineBreaks(keslerTopicTitle || '')) }}
            />
          </div>
          <div className="w-full mb-4 overflow-hidden">
            <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 overflow-hidden">
              <table className="w-full">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">To-Do Items</th>
                    <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Visit Task</th>
                    <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Mark Complete</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {keslerTasks.map((task) => (
                    <tr key={task.id}>
                      <td className="py-4 px-4">
                        <p className={`${task.completed ? 'line-through text-gray-400 dark:text-gray-500' : 'text-gray-700 dark:text-gray-300'}`}>
                          {task.description}
                        </p>
                      </td>
                      <td className="py-4 px-4 text-center">
                        <button
                          onClick={() => {
                            if (task.actionType) {
                              getTaskAction(task.actionType, taskDetails)();
                            }
                          }}
                          className="bg-primary-orange hover:bg-primary-orange-hover text-white py-2 px-4 rounded-md transition duration-300 text-sm"
                        >
                          {task.buttonText}
                        </button>
                      </td>
                      <td className="py-4 px-4 text-center">
                        <div className="flex justify-center">
                          <button
                            onClick={() => toggleTaskCompletion(task.id)}
                            className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300 border-2 ${
                              task.completed
                                ? 'bg-primary-blue border-primary-blue text-white'
                                : 'bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-500 hover:border-primary-blue dark:hover:border-primary-blue'
                            }`}
                            title={task.completed ? "Mark as incomplete" : "Mark as complete"}
                          >
                            {task.completed && <Check size={16} className="mx-auto" />}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  return (
    <div className="bg-transparent">
      {renderTaskContent(taskDetails)}

      <div className="mt-8">
        <button
          onClick={isMainTaskCompleted ? handleUncompleteTask : handleMarkComplete}
          className={`w-full ${
            isMainTaskCompleted
              ? 'bg-gray-400 hover:bg-gray-500'
              : 'bg-primary-blue hover:bg-primary-blue-hover'
          } text-white py-3 px-6 rounded-md transition duration-300`}
        >
          {isMainTaskCompleted ? 'Mark Task as Incomplete' : 'Mark Task as Complete'}
        </button>
      </div>
    </div>
  );
};

export default LearnTask;
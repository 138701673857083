import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ProfileDropdown from './ProfileDropdown';
import BottomNavigation from './mobile/BottomNavigation';
import CourseDropdown from './CourseDropdown';
import { useAuth } from '../AuthProvider';
import { useTheme } from '../../contexts/ThemeContext';
import TextSelectionPopup from '../course/PiracyProtection/TextSelectionPopup';
import Sidebar from '../../pages/Sidebar';
import { Menu } from 'react-feather';
import Footer from './Footer';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  const location = useLocation();
  const { courseId } = useParams<{ courseId?: string }>();
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCoursePage, setIsCoursePage] = useState(false);
  const [currentCourseId, setCurrentCourseId] = useState<string | undefined>(undefined);

  const isSupportDashboard = location.pathname === '/admin/support';
  const isAdminRoute = location.pathname.startsWith('/admin');

  // Custom handler for text selection events
  const handleTextSelection = (event: MouseEvent) => {
    // Check if we're in an admin route
    if (!isAdminRoute) return true;

    // Allow selection in question content areas
    const target = event.target as HTMLElement;
    const isQuestionContent = target.closest('[data-question-content="true"]');
    if (isQuestionContent) return true;

    // Prevent selection in other admin areas
    return false;
  };

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const isCourse = pathParts[1] === 'course' && pathParts[2];
    setIsCoursePage(!!isCourse);
    setCurrentCourseId(isCourse ? pathParts[2] : undefined);
  }, [location]);

  useEffect(() => {
    if (isAdminRoute) {
      // Add CSS to prevent text selection except in question content
      const style = document.createElement('style');
      style.innerHTML = `
        .admin-content {
          user-select: none;
          -webkit-user-select: none;
        }
        [data-question-content="true"] {
          user-select: text;
          -webkit-user-select: text;
        }
      `;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [isAdminRoute]);

  return (
    <div className={`min-h-screen flex flex-col ${isDarkMode ? 'dark bg-gray-900' : 'bg-gray-100'}`}>
      <header className="bg-white shadow-sm dark:bg-gray-800 sticky top-0 z-10 h-16">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 h-full">
          <div className="flex justify-between items-center h-full">
            <div className="flex items-center h-full">
              <button
                onClick={() => setIsMenuOpen(true)}
                className="hamburger-btn mr-2 custom-lg:hidden text-gray-600 hover:text-gray-800 focus:outline-none dark:text-gray-300 dark:hover:text-gray-100 w-10 h-10 flex items-center justify-center"
              >
                <Menu size={24} />
              </button>
              {isCoursePage && (
                <div className="hidden md:flex items-center h-full">
                  <CourseDropdown currentCourseId={currentCourseId} />
                </div>
              )}
            </div>
            <Link 
              to="/products" 
              className="absolute left-1/2 transform -translate-x-1/2 h-full flex items-center"
            >
              <img
                src={`${process.env.PUBLIC_URL}/logo/kesler-course-logo-blue-orange.png`}
                alt="Kesler CPA Review"
                className="h-8 w-auto dark:hidden"
              />
              <img
                src={`${process.env.PUBLIC_URL}/logo/kesler-course-logo-white.png`}
                alt="Kesler CPA Review"
                className="h-8 w-auto hidden dark:block"
              />
            </Link>
            <div className="flex items-center h-full">
              <div className="h-full flex items-center">
                <ProfileDropdown />
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="flex flex-1">
        <div className="hidden custom-lg:block">
          <Sidebar
            courseId={currentCourseId}
            user={user}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        </div>
        
        <main className={`flex-1 flex flex-col ${isCoursePage ? 'custom-lg:ml-[0px]' : ''}`}>
          {isSupportDashboard ? (
            <div className="flex-1 flex flex-col overflow-hidden">
              {children}
            </div>
          ) : (
            <>
              <div className="flex-1 max-w-7xl w-full mx-auto px-2 sm:px-4 lg:px-6 py-4">
                {children}
              </div>
              <Footer />
            </>
          )}
        </main>
      </div>

      {isMenuOpen && (
        <div className="custom-lg:hidden">
          <Sidebar
            courseId={currentCourseId}
            user={user}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        </div>
      )}
      <BottomNavigation currentPath={location.pathname} isInsideCourse={isCoursePage} />
      {/* Only render TextSelectionPopup if not in admin route and we have required props */}
      {!isAdminRoute && user && currentCourseId && (
        <TextSelectionPopup courseId={currentCourseId} />
      )}
    </div>
  );
}

export default Layout;
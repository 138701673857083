import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, SearchResult } from '../../services/api';
import { Search as SearchIcon, ChevronDown, ChevronUp, ExternalLink } from 'lucide-react';
import DOMPurify from 'dompurify';

interface SearchProps {
  courseId: string;
}

const RESULTS_PER_PAGE = 15;

const Search: React.FC<SearchProps> = ({ courseId }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedResults, setExpandedResults] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleSearch = async () => {
    if (!query.trim()) return;
  
    setIsLoading(true);
    setError(null);
  
    try {
      const searchResults = await api.performContentSearch(courseId, query);
      console.log('Search results:', searchResults);
      setResults(searchResults);
      setCurrentPage(1);
      setActiveFilters([]);
    } catch (err) {
      console.error('Search error:', err);
      setError('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleFilterToggle = (filter: string) => {
    setActiveFilters(prev =>
      prev.includes(filter)
        ? prev.filter(f => f !== filter)
        : [...prev, filter]
    );
    setCurrentPage(1);
  };

  const toggleResultExpansion = (resultId: string) => {
    setExpandedResults(prev => 
      prev.includes(resultId) 
        ? prev.filter(id => id !== resultId)
        : [...prev, resultId]
    );
  };

  const navigateToResult = (result: SearchResult, expanded: boolean = false) => {
    switch (result.type) {
      case 'lesson':
        navigate(`/course/${courseId}/lessons/${result.id}`);
        break;
      case 'simulation':
        navigate(`/course/${courseId}/simulations/${result.id}`);
        break;
      case 'question':
        if (expanded) {
          navigate(`/course/${courseId}/quiz?categoryId=${result.category_id}&questionId=${result.id}`);
        } else {
          toggleResultExpansion(result.id);
        }
        break;
      case 'flashcard':
      case 'custom_flashcard':
        if (expanded) {
          navigate(`/course/${courseId}/kesler-cards?categoryId=${result.category_id}&flashcardId=${result.id}`);
        } else {
          toggleResultExpansion(result.id);
        }
        break;
      case 'note':
        if (expanded) {
          navigate(`/course/${courseId}/focus-notes?noteId=${result.id}`);
        } else {
          toggleResultExpansion(result.id);
        }
        break;
    }
  };

  const filteredResults = useMemo(() => {
    return results.filter(result => 
      activeFilters.length === 0 || activeFilters.includes(result.type)
    );
  }, [results, activeFilters]);

  const pageCount = Math.ceil(filteredResults.length / RESULTS_PER_PAGE);
  const paginatedResults = filteredResults.slice(
    (currentPage - 1) * RESULTS_PER_PAGE,
    currentPage * RESULTS_PER_PAGE
  );

  const availableFilters = useMemo(() => {
    const filters = new Set(results.map(result => result.type));
    return Array.from(filters);
  }, [results]);

  const filterLabels: { [key: string]: string } = {
    'lesson': 'Lessons',
    'question': 'Questions',
    'simulation': 'Simulations',
    'flashcard': 'Flashcards',
    'custom_flashcard': 'Flashcards',
    'note': 'Notes'
  };


  const renderExpandedContent = (result: SearchResult) => {
    const renderNavigationButton = () => (
      <button
        onClick={(e) => {
          e.stopPropagation();
          navigateToResult(result, true);
        }}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 flex items-center"
      >
        <ExternalLink size={16} className="mr-2" />
        {result.type === 'question' && 'Go to Question'}
        {(result.type === 'flashcard' || result.type === 'custom_flashcard') && 'Go to Flashcard'}
        {result.type === 'note' && 'Go to Note'}
      </button>
    );
  
    const contentClasses = "mt-4 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md border border-gray-200 dark:border-gray-700";
  
    switch (result.type) {
      case 'question':
        return (
          <div className={`${contentClasses} quiz-content`}>
            <h5 className="font-semibold text-lg mb-3 text-gray-800 dark:text-gray-200">Question:</h5>
            <div 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result.fullContent?.question || '') }} 
            />
            <h5 className="font-semibold text-lg mt-6 mb-3 text-gray-800 dark:text-gray-200">Answer:</h5>
            <div 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result.fullContent?.answer || '') }} 
            />
            {renderNavigationButton()}
          </div>
        );
      case 'flashcard':
      case 'custom_flashcard':
        return (
          <div className={contentClasses}>
            <h5 className="font-semibold text-lg mb-3 text-gray-800 dark:text-gray-200">Front:</h5>
            <p 
              className="mb-4 text-gray-700 dark:text-gray-300" 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result.fullContent?.front || '') }} 
            />
            <h5 className="font-semibold text-lg mt-6 mb-3 text-gray-800 dark:text-gray-200">Back:</h5>
            <p 
              className="text-gray-700 dark:text-gray-300" 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result.fullContent?.back || '') }} 
            />
            {renderNavigationButton()}
          </div>
        );
      case 'note':
        return (
          <div className={contentClasses}>
            <div 
              className="text-gray-700 dark:text-gray-300" 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result.fullContent?.content || '') }} 
            />
            {renderNavigationButton()}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center mb-8 text-gray-800 dark:text-white">Search Kesler Content</h2>
      
      <div className="flex items-center mb-6">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Enter your search query..."
          className="flex-grow p-3 border border-gray-300 rounded-l-lg dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleSearch}
          className="p-3 bg-blue-500 text-white rounded-r-lg hover:bg-blue-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
          disabled={isLoading}
        >
          {isLoading ? 'Searching...' : <SearchIcon size={24} />}
        </button>
      </div>

      {error && <p className="text-red-500 mb-4 text-center">{error}</p>}

      {results.length > 0 && (
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-3 text-gray-700 dark:text-gray-300">Filters:</h3>
          <div className="flex flex-wrap gap-2">
            {availableFilters.map((filter) => (
              <button
                key={filter}
                onClick={() => handleFilterToggle(filter)}
                className={`px-4 py-2 rounded-full transition duration-300 ease-in-out ${
                  activeFilters.includes(filter)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700 dark:bg-gray-600 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-500'
                }`}
              >
                {filterLabels[filter]}
              </button>
            ))}
          </div>
        </div>
      )}

{paginatedResults.length > 0 ? (
        <>
          <ul className="space-y-4">
            {paginatedResults.map((result) => (
              <li
                key={result.id}
                className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg transition duration-300 ease-in-out"
              >
                <div 
                  className="cursor-pointer flex justify-between items-center"
                  onClick={() => navigateToResult(result)}
                >
                  <div>
                    <h4 className="font-semibold mb-2 text-lg text-gray-800 dark:text-white"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result.title) }}
                    />
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-2"
                       dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result.preview) }}
                    />
                    <span className="inline-block px-2 py-1 text-xs font-semibold text-blue-500 bg-blue-100 dark:bg-blue-900 rounded">{filterLabels[result.type]}</span>
                  </div>
                  {['question', 'flashcard', 'custom_flashcard', 'note'].includes(result.type) && (
                    expandedResults.includes(result.id) ? <ChevronUp size={20} /> : <ChevronDown size={20} />
                  )}
                </div>
                {expandedResults.includes(result.id) && renderExpandedContent(result)}
              </li>
            ))}
          </ul>
          {pageCount > 1 && (
            <div className="flex justify-center mt-6 space-x-2">
              {Array.from({ length: pageCount }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  className={`px-3 py-1 rounded ${
                    currentPage === page
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  {page}
                </button>
              ))}
            </div>
          )}
        </>
      ) : query && !isLoading ? (
        <p className="text-center text-gray-600 dark:text-gray-400">No results found.</p>
      ) : null}
    </div>
  );
};

export default Search;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { api, Note, LessonCategory } from '../../services/api';
import DOMPurify from 'dompurify';
import useKeyPress from '../../hooks/useKeyPress';

interface LessonNotesProps {
  courseId: string;
  userId: string;
  lessonId: string;
  lessonName: string;
  lessonCategory: LessonCategory;
  unsavedNote?: string;
  onNoteChange: (content: string) => void;
  onNoteSave: (content: string) => void;
}

const MAX_NOTE_LENGTH = 2000;

const LessonNotes: React.FC<LessonNotesProps> = React.memo(({ 
  courseId, 
  userId, 
  lessonId, 
  onNoteSave
}) => {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [localNoteContent, setLocalNoteContent] = useState('');
  const [error, setError] = useState<string | null>(null);

  const { data: note, isLoading } = useQuery<Note | null>(
    ['lessonNote', userId, courseId, lessonId],
    () => api.getNoteForLesson(userId, courseId, lessonId),
    { 
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      onError: (err: any) => {
        console.error('Error fetching note:', err);
        setError(`Failed to fetch note: ${err.message || 'Unknown error'}`);
      }
    }
  );

  useEffect(() => {
    if (note) {
      setLocalNoteContent(note.content || '');
    } else {
      setLocalNoteContent('');
    }
  }, [note]);

  const updateNoteMutation = useMutation(
    (content: string) => api.updateNote(
      note?.id || null,
      { content: DOMPurify.sanitize(content) },
      userId,
      courseId,
      null,
      lessonId,
      null
    ),
    {
      onSuccess: (updatedNote) => {
        queryClient.setQueryData(['lessonNote', userId, courseId, lessonId], updatedNote);
        setIsEditing(false);
        setError(null);
        onNoteSave(updatedNote.content);
      },
      onError: (err: any) => {
        console.error('Failed to save note:', err);
        setError(`Failed to save note: ${err.message || 'Unknown error'}`);
      }
    }
  );

  const deleteNoteMutation = useMutation(
    () => api.deleteNote(note?.id || ''),
    {
      onSuccess: () => {
        queryClient.setQueryData(['lessonNote', userId, courseId, lessonId], null);
        setLocalNoteContent('');
        setError(null);
      },
      onError: (err: any) => {
        console.error('Failed to delete note:', err);
        setError(`Failed to delete note: ${err.message || 'Unknown error'}`);
      }
    }
  );

  const handleDeleteNote = useCallback(() => {
    if (note?.id) {
      deleteNoteMutation.mutate();
    } else {
      setLocalNoteContent('');
    }
  }, [deleteNoteMutation, note]);

  const handleSaveNote = useCallback(() => {
    updateNoteMutation.mutate(localNoteContent);
    setIsEditing(false);
  }, [updateNoteMutation, localNoteContent]);

  // Simplified handleEnterPress function
  const handleEnterPress = useCallback((event?: KeyboardEvent) => {
    if (event && !event.shiftKey) {
      handleSaveNote();
    }
  }, [handleSaveNote]);

  // Use the useKeyPress hook
  useKeyPress('Enter', handleEnterPress, () => isEditing);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
    setLocalNoteContent(DOMPurify.sanitize(note?.content || ''));
  }, [note]);

  const handleNoteChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const sanitizedContent = DOMPurify.sanitize(e.target.value);
    setLocalNoteContent(sanitizedContent.slice(0, MAX_NOTE_LENGTH));
  }, []);

  useEffect(() => {
    if (note) {
      setLocalNoteContent(DOMPurify.sanitize(note.content || ''));
    } else {
      setLocalNoteContent('');
    }
  }, [note]);

  if (isLoading) {
    return <div>Loading notes...</div>;
  }

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">Lesson Notes</h3>
      {error && <p className="text-red-500 mb-2">{error}</p>}
      {isEditing ? (
        <>
          <textarea
            value={localNoteContent}
            onChange={handleNoteChange}
            className="w-full p-2 mb-2 border rounded bg-white dark:bg-gray-800 text-gray-800 dark:text-white"
            rows={5}
            maxLength={MAX_NOTE_LENGTH}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevent default to avoid newline
              }
            }}
          />
          <p className="text-sm text-gray-500 mb-2">
            {localNoteContent.length}/{MAX_NOTE_LENGTH} characters
          </p>
          <div className="flex justify-end space-x-2">
            <button
              onClick={handleSaveNote}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
            >
              Save
            </button>
            <button
              onClick={handleCancelEdit}
              className="bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-white px-4 py-2 rounded hover:bg-gray-400 dark:hover:bg-gray-600 transition-colors duration-200"
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="text-gray-800 dark:text-white mb-4">
            {note?.content ? (
              <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(note.content) }} />
            ) : (
              'No notes yet. Click "Add Note" to create one.'
            )}
          </p>
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setIsEditing(true)}
              className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition-colors duration-200"
            >
              {note ? 'Edit' : 'Add Note'}
            </button>
            {note && (
              <button
                onClick={handleDeleteNote}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200"
              >
                Clear
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
});

export default LessonNotes;
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../AuthProvider';
import { api, MentorModule, ContentBlock } from '../../services/api';
import { CheckCircle, ChevronDown, ChevronUp, Download, Book } from 'lucide-react';
import DOMPurify from 'dompurify';
import LoadingScreen from './common/LoadingScreen';

const MAX_NOTE_LENGTH = 2000;

const Mentor: React.FC = () => {
  const { user } = useAuth();
  const [modules, setModules] = useState<MentorModule[]>([]);
  const [expandedModules, setExpandedModules] = useState<Set<string>>(new Set());
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingNoteId, setEditingNoteId] = useState<string | null>(null);
  const [currentModule, setCurrentModule] = useState<string | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showRecommendation, setShowRecommendation] = useState(false);
  const mainContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;
      try {
        const modulesData = await api.getMentorModules();
        setModules(modulesData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching mentor data:', err);
        setError('Failed to load mentor data. Please try again.');
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const handleModuleCompletion = async (moduleId: string, isCompleted: boolean) => {
    if (!user) return;

    try {
      await api.updateMentorModuleProgress(moduleId, isCompleted);
      setModules(prev => 
        prev.map(m => m.id === moduleId ? { ...m, is_completed: isCompleted } : m)
      );
      if (isCompleted) {
        setShowRecommendation(true);
      } else {
        setShowRecommendation(false);
      }
    } catch (err) {
      console.error('Error updating module completion:', err);
      setError('Failed to update module completion. Please try again.');
    }
  };


  const sanitizeInput = (input: string) => {
    return DOMPurify.sanitize(input);
  };
  
  const fetchNoteForModule = useCallback(async (moduleId: string) => {
    if (!user) return;
    try {
      const module = modules.find(m => m.id === moduleId);
      if (!module) return;

      const note = await api.getNoteForMentor(user.id, module.course_id, moduleId);
      setModules(prev =>
        prev.map(m => m.id === moduleId ? { ...m, notes: note?.content || '' } : m)
      );
    } catch (err) {
      console.error('Error fetching note for module:', err);
      setError('Failed to load note for this module. Please try again.');
    }
  }, [user, modules]);

  const toggleModule = useCallback((moduleId: string) => {
    setExpandedModules(prev => {
      const newSet = new Set(prev);
      if (newSet.has(moduleId)) {
        newSet.delete(moduleId);
      } else {
        newSet.add(moduleId);
        fetchNoteForModule(moduleId);
      }
      return newSet;
    });
    setCurrentModule(moduleId);
    setIsMobileMenuOpen(false);
    setShowRecommendation(false);

    // Scroll to the top of the main content area
    if (mainContentRef.current) {
      mainContentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [fetchNoteForModule]);

  const handleSaveNote = async (moduleId: string, content: string) => {
    if (!user) return;

    try {
      const module = modules.find(m => m.id === moduleId);
      if (!module) {
        throw new Error('Module not found');
      }

      const sanitizedContent = sanitizeInput(content).slice(0, MAX_NOTE_LENGTH);
      const existingNote = await api.getNoteForMentor(user.id, module.course_id, moduleId);
      const updatedNote = await api.updateNote(
        existingNote?.id || null,
        { content: sanitizedContent },
        user.id,
        module.course_id,
        null,
        null,
        moduleId
      );

      setModules(prev =>
        prev.map(m => m.id === moduleId ? { ...m, notes: updatedNote.content } : m)
      );
      setEditingNoteId(null);
    } catch (err) {
      console.error('Failed to save note:', err);
      setError('Failed to save note. Please try again.');
    }
  };


  const handleClearNote = async (moduleId: string) => {
    if (!user) return;

    try {
      const module = modules.find(m => m.id === moduleId);
      if (!module) {
        throw new Error('Module not found');
      }

      const existingNote = await api.getNoteForMentor(user.id, module.course_id, moduleId);
      if (existingNote) {
        await api.deleteNote(existingNote.id);
      }

      setModules(prev => 
        prev.map(m => m.id === moduleId ? { ...m, notes: '' } : m)
      );
    } catch (err) {
      console.error('Error clearing notes:', err);
      setError('Failed to clear notes. Please try again.');
    }
  };

  const renderCompletionCheckbox = (module: MentorModule) => (
    <button
      onClick={() => handleModuleCompletion(module.id, !module.is_completed)}
      className={`flex items-center justify-center p-3 rounded-md transition-colors duration-200 w-full ${
        module.is_completed
          ? 'bg-primary-blue hover:bg-primary-blue-hover text-white'
          : 'bg-primary-orange hover:bg-primary-orange-hover text-white'
      }`}
      title={module.is_completed ? "Mark as incomplete" : "Mark as complete"}
    >
      <CheckCircle size={20} className="mr-2" />
      <span className="text-sm font-medium">
        {module.is_completed ? "Completed" : "Mark Complete"}
      </span>
    </button>
  );

  const renderNextModuleRecommendation = () => {
    const nextIncompleteModule = modules.find(m => !m.is_completed);
    return (
      <div className="mt-8 bg-blue-50  dark:bg-gray-600 p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">Recommended Next Module</h3>
        {nextIncompleteModule ? (
          <>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Continue your learning journey with the next uncompleted module:
            </p>
            <button
              onClick={() => toggleModule(nextIncompleteModule.id)}
              className="bg-primary-blue text-white px-4 py-2 rounded hover:bg-primary-blue-hover transition-colors duration-200"
            >
              Start {nextIncompleteModule.title}
            </button>
          </>
        ) : (
          <p className="text-gray-600 dark:text-gray-400">
            Congratulations! You've completed all modules. Keep reviewing to reinforce your knowledge.
          </p>
        )}
      </div>
    );
  };

  const renderContentBlock = (block: ContentBlock, courseId: string, videoIndex: number, totalVideos: number) => {
    switch (block.type) {
      case 'text':
        return <div className="prose dark:prose-invert max-w-none mb-6" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(block.content) }} />;
      case 'video':
        return (
          <div className="mb-8 w-full">
            <h4 className="text-lg font-semibold mb-3">
              {totalVideos > 1 ? `[${videoIndex} of ${totalVideos}] ` : ''}{block.content}
            </h4>
            <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
              <iframe
                src={`https://player.vimeo.com/video/${block.videoId}?h=00000000`}
                className="absolute top-0 left-0 w-full h-full"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title={block.content}
              ></iframe>
            </div>
          </div>
        );
      case 'download':
        if (block.downloadUrl) {
          const downloadUrl = block.downloadUrl.replace('{{courseId}}', courseId);
          return (
            <a 
              href={downloadUrl} 
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Download size={18} className="mr-2" />
              {block.buttonText || 'Download'}
            </a>
          );
        }
        return null;
      case 'html':
        return <div className="prose dark:prose-invert max-w-none mb-6" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(block.content) }} />;
      default:
        return null;
    }
  };

  if (isLoading) {
    return <LoadingScreen message="Loading your Mentorship Modules..." subMessage="This may take a few moments" />;
  }

  if (error) return <div className="text-red-500 text-center py-10">{error}</div>;

  const completedModules = modules.filter(m => m.is_completed).length;
  const totalModules = modules.length;
  const progressPercentage = (completedModules / totalModules) * 100;

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100 dark:bg-gray-900 overflow-hidden">
      {/* Mobile Menu Dropdown */}
      <div className="md:hidden w-full bg-white dark:bg-gray-800 shadow-md mb-8">
        <button
          className="w-full p-4 flex justify-between items-center text-lg font-semibold"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          Course Modules
          {isMobileMenuOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
        </button>
        {isMobileMenuOpen && (
          <div className="p-4 space-y-2">
            {modules.map((module) => (
              <button
                key={module.id}
                onClick={() => toggleModule(module.id)}
                className={`w-full text-left p-2 text-sm rounded-md transition-colors duration-200 ${
                  currentModule === module.id
                    ? 'bg-primary-blue text-white'
                    : 'hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                {module.title}
                {module.is_completed && (
                  <CheckCircle size={16} className="inline-block ml-2 text-primary-blue" />
                )}
              </button>
            ))}
          </div>
        )}
      </div>
  
      {/* Desktop Sidebar */}
      <div className="hidden md:block md:w-80 bg-white dark:bg-gray-800 shadow-md overflow-hidden rounded-lg h-full">
        <div className="h-full flex flex-col">
        <h2 className="text-xl font-bold p-4 text-gray-800 dark:text-gray-200">Course Modules</h2>
          <div className="flex-1 overflow-y-auto">
            <div className="p-2 space-y-1">
              {modules.map((module) => (
                <button
                  key={module.id}
                  onClick={() => toggleModule(module.id)}
                  className={`w-full text-left p-3 text-sm rounded-md transition-colors duration-200 flex items-center border-l-4 ${
                    currentModule === module.id
                      ? 'bg-blue-50 dark:bg-blue-900 border-primary-blue text-primary-blue dark:text-gray-200'
                      : module.is_completed
                      ? 'bg-green-50 dark:bg-green-900 border-green-500 text-green-700 dark:text-green-300'
                      : 'bg-gray-50 dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 hover:border-primary-blue hover:text-primary-blue dark:hover:text-primary-blue'
                  }`}
                >
                  <span className="flex-grow pr-2">{module.title}</span>
                  {module.is_completed && (
                    <CheckCircle size={16} className="text-green-500 flex-shrink-0" />
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div ref={mainContentRef} className="flex-1 overflow-y-auto">
        <div className="max-w-4xl mx-auto px-6">
          {/* Course Overview */}
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md mb-8">
            <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">CPA Exam Preparation</h1>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Master the skills and knowledge needed to pass the CPA exam with our comprehensive mentorship program.
            </p>
            <div className="flex items-center space-x-4 mb-4">
              <div className="flex items-center">
                <Book size={20} className="mr-2 text-primary-blue" />
                <span>{totalModules} Modules</span>
              </div>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-3 dark:bg-gray-700">
              <div 
                className="bg-primary-blue h-3 rounded-full transition-all duration-500 ease-in-out" 
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              {completedModules} of {totalModules} modules completed
            </p>
          </div>

          {/* Module Content */}
          {currentModule && modules.find(m => m.id === currentModule) && (
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
                {modules.find(m => m.id === currentModule)?.title}
              </h2>
              <div className="space-y-6">
                {modules.find(m => m.id === currentModule)?.content.blocks.map((block, index) => {
                  const totalVideos = modules.find(m => m.id === currentModule)?.content.blocks.filter(b => b.type === 'video').length || 0;
                  const videoIndex = modules.find(m => m.id === currentModule)?.content.blocks.filter((b, i) => b.type === 'video' && i <= index).length || 0;
                  return (
                    <div key={index}>
                      {renderContentBlock(block, modules.find(m => m.id === currentModule)?.course_id || '', videoIndex, totalVideos)}
                    </div>
                  );
                })}
              </div>

              {/* Notes Section */}
              <div className="mt-8 bg-gray-50 dark:bg-gray-700 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Module Notes</h3>
                {editingNoteId === currentModule ? (
                  <>
                    <textarea
                      className="w-full p-3 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      rows={4}
                      value={modules.find(m => m.id === currentModule)?.notes || ''}
                      onChange={(e) => {
                        const sanitizedContent = sanitizeInput(e.target.value).slice(0, MAX_NOTE_LENGTH);
                        setModules(prev =>
                          prev.map(m => m.id === currentModule ? { ...m, notes: sanitizedContent } : m)
                        );
                      }}
                      placeholder="Add your notes here..."
                      maxLength={MAX_NOTE_LENGTH}
                    ></textarea>
                    <p className="text-sm text-gray-500 mt-1 mb-2">
                      {(modules.find(m => m.id === currentModule)?.notes || '').length}/{MAX_NOTE_LENGTH} characters
                    </p>
                    <div className="mt-4 space-x-2">
                      <button
                        onClick={() => handleSaveNote(currentModule, modules.find(m => m.id === currentModule)?.notes || '')}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setEditingNoteId(null)}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors duration-200"
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-gray-700 dark:text-gray-300 mb-4">
                      {modules.find(m => m.id === currentModule)?.notes 
                        ? <span dangerouslySetInnerHTML={{ __html: sanitizeInput(modules.find(m => m.id === currentModule)?.notes || '') }} />
                        : 'No notes yet.'}
                    </p>
                    <div className="space-x-2">
                      <button
                        onClick={() => setEditingNoteId(currentModule)}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
                      >
                        {modules.find(m => m.id === currentModule)?.notes ? 'Edit' : 'Add'} Note
                      </button>
                      {modules.find(m => m.id === currentModule)?.notes && (
                        <button
                          onClick={() => handleClearNote(currentModule)}
                          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200"
                        >
                          Clear Note
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>

              {/* Module Completion */}
              <div className="mt-8">
                {renderCompletionCheckbox(modules.find(m => m.id === currentModule)!)}
              </div>

              {/* Next Module Recommendation */}
              {showRecommendation && renderNextModuleRecommendation()}
            </div>
          )}

          {/* Display course overview when no module is selected */}
          {!currentModule && (
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md mt-8">
              <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">Welcome to Your CPA Exam Preparation</h3>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                Select a module from the sidebar to begin your study session. 
                Each module contains valuable content to help you master the CPA exam material.
              </p>
              {renderNextModuleRecommendation()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Mentor;
// src/components/ui/Separator.tsx

import React from "react";

interface SeparatorProps extends React.HTMLAttributes<HTMLDivElement> {
  orientation?: 'horizontal' | 'vertical';
}

const Separator = React.forwardRef<HTMLDivElement, SeparatorProps>(
  ({ className = '', orientation = 'horizontal', ...props }, ref) => {
    return (
      <div
        ref={ref}
        role="separator"
        className={`
          bg-gray-200 dark:bg-gray-700
          ${orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]'}
          ${className}
        `}
        {...props}
      />
    );
  }
);

Separator.displayName = 'Separator';

export { Separator };
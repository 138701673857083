import { v4 as uuidv4 } from 'uuid';
import { api } from '../services/api';
import { auth } from '../services/auth';

export async function getBrowserInstanceId(): Promise<string> {
  let instanceId = localStorage.getItem('browserInstanceId');
  if (!instanceId) {
    instanceId = uuidv4();
    localStorage.setItem('browserInstanceId', instanceId);
    
    // Only create user instance if user is logged in
    const currentUser = await auth.getCurrentUser();
    if (currentUser) {
      await api.createUserInstance(instanceId);
    }
  }
  return instanceId;
}
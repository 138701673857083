import { supabase } from './supabase'
import { auth } from './auth'; // Import the auth service

// Existing user types
export type UserRole = 'user' | 'admin' | 'moderator';

export interface User {
  id: string;
  email: string;
  full_name: string | null;
  created_at: string | null;
  last_sign_in_at: string | null;
  role: UserRole;
  payment_id: string | null;
  updated_at: string | null;
  switches_available: number;
  last_switch_date: string | null;
  last_switch_replenish_date: string | null;
}

// New support user types
export interface SupportUser {
  id: string;
  email: string;
  full_name: string;
  created_at: string;
  last_activity: string;
  auth_user_id: string | null;
  metadata: Record<string, unknown>;
  email_domain: string;
}

export interface UserInstance {
  id: string;
  user_id: string;
  browser_instance_id: string;
  device_info: string;
  created_at: string;
  last_active: string;
}

export interface Product {
  id: string;
  title: string;  // Changed from title to name
  description: string;
  created_at: string;
  type: string;
  is_addon: boolean;
  is_global: boolean;
  updated_at: string;
}

export interface UserCourseAccess {
  id: string;
  user_id: string;
  course_id: string;
  start_date: string | null;
  end_date: string | null;
  tier_id: string;
  updated_at: string;
}

export type SubscriptionStatus = 
  | 'active'
  | 'active_partial_refund'
  | 'cancelled'
  | 'cancelled_partial_refund'
  | 'failed'
  | 'refunded'
  | 'completed'
  | 'paused'
  | 'pending'
  | 'trial'
  | 'expired';
  
  export interface UserCoursePurchase {
    // Primary key
    id: string;
    
    // Foreign keys
    user_id: string;
    tier_id: string;
    
    // ThriveCart identifiers
    thrivecart_product_id: string;
    thrivecart_order_id: string | null;
    thrivecart_subscription_id: string | null;
    
    // Dates
    purchase_date: string;
    last_payment_date: string | null;
    next_payment_date: string | null;
    refund_date: string | null;
    cancellation_date: string | null;
    pause_date: string | null;
    resume_date: string | null;
    last_failed_payment_date: string | null;
    created_at: string;
    updated_at: string;
    
    // Status
    is_active: boolean;
    subscription_status: SubscriptionStatus;
    subscription_health: string | null;  // Generated column
    
    // Payment details
    payment_processor: string | null;
    payment_amount: number | null;
    payment_currency: string | null;
    payment_frequency: string | null;
    payment_plan_id: string | null;
    payment_plan_name: string | null;
    
    // Payment tracking
    total_payments_expected: number | null;
    payments_made: number | null;
    failed_payment_count: number | null;
    
    // Customer info
    customer_identifier: string | null;
    purchase_ip: string | null;
    purchase_country: string | null;
    cancellation_reason: string | null;
    
    // Order additions
    has_bump: boolean;
    has_upsell: boolean;
    bump_ids: string[] | null;
    upsell_ids: string[] | null;
    bump_total: number | null;
    upsell_total: number | null;
    
    // Additional data
    purchase_map: string[] | null;
    event_type: string | null;
    raw_data: Record<string, any> | null;  // jsonb type
  }

export interface Tier {
  id: string;
  name: string;
  description: string | null;
  is_global: boolean;
  created_at: string;
  updated_at: string;
}

export interface UserAccessState {
  product: Product;
  currentTier: string;
  isActivatable: boolean;
  hasFullAccess: boolean;
  hasMentorAccess: boolean;
}

export const COURSE_IDS = {
  ISC_2024: '2ced0142-2bd9-423e-a541-9ac6bfed88ef',
  REG_2024: '2d55838e-52b1-46d3-8bdf-8d691e28e596',
  FAR_2024: '2e7dd01a-dffa-481d-9359-17594c903eb0',
  BAR_2024: '7b008bd2-4b5d-4876-82d5-c9f1e05895e0',
  AUD_2024: 'e780a7b3-dcd1-4740-ba54-014e594326a4',
  TCP_2024: 'ffbf1eef-d731-4100-9bc7-8dda62a586d3',
  MENTORSHIP: 'a6a5eaf3-63bc-4e9a-92ac-64153a1f8395'
} as const;

export const TIER_IDS = {
  STUDY_MATERIALS_ONLY: '0b992474-3a65-41b5-ad90-cc88662cc594',
  MENTOR_ONLY: '25f83b83-0a8a-4599-b387-c8870d79b214',
  FREE_TRIAL: '337eeb06-1040-43d5-ad6d-5316e058aec9',
  FULL_ACCESS: 'c99793af-5529-446b-be12-cc56709509e6'
} as const;

// Cache key constants
export const CACHE_KEYS = {
  USER_DATA: (userId: string) => `userData_${userId}`,
  USER_ACCESS: (userId: string) => `userAccess_${userId}`,
  PRODUCTS: 'products',
  USER_COURSE_ACCESS: (userId: string, courseId: string) => `userCourseAccess_${userId}_${courseId}`,
  USER_COURSE_PURCHASES: (userId: string) => `userCoursePurchases_${userId}`
} as const;

// Cache expiry durations
export const CACHE_DURATION = {
  USER_DATA: 2 * 60 * 1000, // 2 minutes
  PRODUCTS: 30 * 60 * 1000, // 30 minutes
  USER_ACCESS: 2 * 60 * 1000 // 2 minutes
} as const;

//QUIZ

export interface QuestionCategory {
  id: string;
  course_id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
}

export interface Question {
  id: string;
  course_id: string;
  question_content: string; // question and 4 multiple guesses
  answer_content: string; //answer explanation
  question_content_file: string | null;
  answer_content_file: string | null;
  exam_id: string | null;
  exam_name: string | null;
  exam_section_name: string | null;
  exam_section_time_allowed: number | null;
  question_category_id: string;
  question_category_name: string;
  sub_question_category_name: string;
  question_type: string;
  choices_count: number;
  correct_answer: string;
  related_lessons: string[];
  question_number: number;
  answers_count: number;
  elo_rank: number;
  correct_answers_count: number;
  incorrect_answers_count: number;
  related_section_ids: string[];
  related_section_names: string[];
  external_id: string | null;
  tags: string[];
  answer_container_type: string;
  avg_confidence: number;
  not_confident_count: number;
  guessing_count: number;
  confident_count: number;
  note: Note | null;
  confidenceLevel: string | null;
  confidenceHistory: Array<{ level: string | null; timestamp: string }>; // Changed to allow null levels
  last_updated: string;
  deleted_at: string | null;
  free_trial_content: boolean;
}


export interface ConfidenceLevel {
  question_id: string;
  current_confidence_level: string | null;
  confidence_history: Array<{ level: string; timestamp: string }>;
}

// Update the QuizSummary interface
export interface QuizSummary {
  parentCategory: string;
  subCategory: string;
  questionId: string;
  question: string;
  romanList: string | null;
  options: string[];
  tableHTML: string;
  isTableQuestion: boolean;
  userAnswer: string;
  correctAnswer: string;
  answerContent: string;
  isCorrect: boolean;
  note: Note | null;
  confidenceLevel: string;
  timeSpent: number;  // Add this line
}

export interface FilterCounts {
  all: number;
  correct: number;
  incorrect: number;
  confident: number;
  maybe: number;
  guessing: number;
}

export interface UserAnswer {
  [questionId: string]: string;
}

export interface QuizStats {
  totalQuestions: number;
  unansweredQuestions: number;
  incorrectQuestions: number;
  confidenceLevels: {
    guessing: number;
    maybe: number;
    confident: number;
  };
}

export interface QuizQuestionData {
  answered: boolean;
  isCorrect: boolean;
  timeSpent: number;
  questionId: string;
  userAnswer: string;
  confidenceLevel: 'guessing' | 'maybe' | 'confident' | null;  // Allow null
}

export interface QuizHistory {
  id: string;
  start_time: string;
  end_time: string | null;
  total_questions: number;
  correct_answers: number;
  incorrect_answers: number;
  percentage_correct: number;
  completed: boolean;
  questions_data: QuizQuestionData[];
  total_time: number;
  avg_time_per_question: number;
}

//END QUIZ

//FLASHCARDS

export interface FlashcardCategory {
  id: string;
  course_id: string;
  parent_flashcard_category_name: string;
  sub_flashcard_category_name: string;
  sequence_number: number; // Add this line
}

export interface Flashcard {
  id: string;
  course_id: string;
  side_1_content: string;
  side_2_content: string;
  side_1_content_file: string | null;
  side_2_content_file: string | null;
  flash_card_category_id: string;
  flash_card_category_name: string;
  sub_flash_card_category_name: string;
  related_sections: string[];
  avg_confidence: number;
  not_confident_count: number;
  guessing_count: number;
  confident_count: number;
  last_updated: string;
  deleted_at: string | null;
  free_trial_content: boolean;
}

export interface CustomFlashcard {
  id: string;
  user_id: string;
  course_id: string;
  front_content: string;
  back_content: string;
  confidence_level: 'guessing' | 'maybe' | 'confident' | null;
  created_at: string;
  updated_at: string;
  tags: string[];
  color: string;
  card_identifier: string;
  last_reviewed: string;
  browser_instance_id: string;
  version: number;
  synced: number;
  local_id: string; // Add this line
}

export interface UserFlashcardProgress {
  id: string;
  user_id: string;
  course_id: string;
  flashcard_id: string;
  confidence_level: 'guessing' | 'maybe' | 'confident' | null;
  last_reviewed: string;
  browser_instance_id: string; // Add this for device tracking
  version: number; // Add this for optimistic locking
  synced: number; // Add this line
}

//LESSONS

export interface LessonCategory {
  id: string;
  course_id: string;
  name: string;
  parent_lesson_category_id: string | null;
  category_number: number; // Add this line
}

export interface Lesson {
  id: string;
  course_id: string;
  name: string;
  parent_section_id: string | null;
  lesson_category_id: string | null;
  lesson_category_name: string | null;
  sub_lesson_category_name: string | null;
  content: string | null;
  sequence_number: number | null; // Add this line
  last_updated: string;
  deleted_at: string | null;
  free_trial_content: boolean;
}

export interface UserLessonResponse {
  id: string;
  user_id: string;
  lesson_id: string;
  course_id: string;
  is_completed: boolean;
  confidence_level: string | null;
  last_interaction_at: string;
}

interface Exam {
  id: string;
  course_id: string;
  name: string;
  full_length: number;
  introduction: string;
}

interface ExamSection {
  id: string;
  course_id: string;
  exam_id: string;
  name: string;
  time_allowed: number;
  introduction: string;
  question_ids: string[];
}

interface StudyTask {
  id: string;
  course_id: string;
  name: string;
  parent_study_task_name: string | null;
  task_item_type: string;
  task_item_category_id: string | null;
  task_item_ids: string[];
}

interface StudyTaskGroup {
  id: string;
  course_id: string;
  name: string;
  study_task_count: number;
}

export interface SimulationCategory {
  id: string;
  course_id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
}

export interface Simulation {
  id: string;
  course_id: string;
  html: string;
  question_content: SimulationQuestionContent;
  answer_content: string;
  question_type: 'paragraph' | 'table' | 'standalone_dropdown' | 'mixed_table' | null;
  correct_answer: {
    [questionId: string]: {
      [cellRef: string]: string;
    };
  };
  question_content_file: string | null;
  answer_content_file: string | null;
  exam_id: string | null;
  exam_name: string | null;
  exam_section_name: string | null;
  exam_section_time_allowed: number | null;
  question_category_id: string | null;
  question_category_name: string | null;
  sub_question_category_name: string | null;
  choices_count: number | null;
  related_lessons: string[] | null;
  question_number: number | null;
  answers_count: number;
  elo_rank: number | null;
  correct_answers_count: number;
  incorrect_answers_count: number;
  related_section_ids: string[] | null;
  related_section_names: string[] | null;
  external_id: string | null;
  tags: string[] | null;
  answer_container_type: string | null;
  last_updated: string;
  deleted_at: string | null;
  free_trial_content: boolean;
}


export interface SimulationQuestionContent {
  type: 'mixed_table';
  questions: (TBSq | HTMLContent)[];
  tableHeaders: string[];
  introduction: string;
}

// TBSq (Task-Based Simulation Question) interface
// This interface defines the structure for various types of simulation questions
export interface TBSq {
  id: string;
  type: 'paragraph' | 'table' | 'standalone_dropdown' | 'mixed_table';
  cells: Array<{
    ref: string;
    type: 'text' | 'dropdown' | 'input';
    value: string;
    options?: string[];
  }>;
  content?: {
    text?: string;
    dropdowns?: Array<{
      id: string;
      options: string[];
      correctAnswer: string;
    }>;
    table?: {
      headers: string[];
      rows: Array<{
        id: string;
        cells: Array<{
          ref: string;
          type: 'text' | 'dropdown' | 'input';
          value: string;
          options?: string[];
        }>;
      }>;
    };
  };
  additionalInfo?: {
    title?: string;
    description?: string;
  };
}


export interface HTMLContent {
  type: 'html';
  content: string;
}

export interface AnswerFeedback {
  isCorrect: boolean;
  userAnswer: any;
  correctAnswer: any;
}

export interface FeedbackItem {
  questionId: string;
  userAnswer: Record<string, any>;
  correctAnswer: Record<string, any>;
  score: number;
  maxScore: number;
  scorePercentage: number;
  explanation: string;
  answerFeedback: Record<string, AnswerFeedback>;
}

export interface UserTBSResponse {
  id?: string;
  user_id: string;
  simulation_id: string;
  answers: Record<string, any>;
  score: number;
  completed: boolean;
  created_at: string;
  updated_at: string;
  feedback: FeedbackItem[];
  confidence_level: 'guessing' | 'maybe' | 'confident' | null;
  question_results: Array<{
    questionId: string;
    score: number;
    maxScore: number;
    isCorrect: boolean;
  }>;
}

// UPDATE: Add lesson_name, lesson_category, and lesson_subcategory to the Note interface
export interface Note {
  id: string;
  course_id: string;
  user_id: string;
  lesson_id: string | null;
  question_id: string | null;
  simulation_id: string | null;
  module_id: string | null; // Add this line
  content: string;
  created_at: string;
  updated_at: string;
  question_category?: string;
  sub_question_category?: string;
  lesson_name?: string; // ADD: Lesson name
  lesson_category?: string; // ADD: Lesson category
  lesson_subcategory?: string; // ADD: Lesson subcategory
  module_title?: string; // Add this line
}

interface UserActivity {
  id: string;
  user_id: string;
  course_id: string;
  activity_type: string;
  activity_id: string;
  result: string | null;
  created_at: string;
}

interface UserQuestionResponse {
  id: string;
  user_id: string;
  question_id: string;
  course_id: string;
  selected_answer: string;
  is_correct: boolean;
  confidence_level: 'guessing' | 'maybe' | 'confident';
  response_time: number;
  created_at: string;
}

// Additional interfaces for the new methods
interface UserProgress {
  user_id: string;
  course_id: string;
  progress: any; // This should be more specifically defined based on your app's needs
}

interface UserAnalytics {
  categoryPerformance: Array<{ name: string; score: number }>;
  recentQuizzes: Array<{ date: string; name: string; score: number }>;
  totalStudyTime: number;
  averageDailyStudyTime: number;
}

export interface Category {
  id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
  total_questions: number;
  unanswered_questions: number;
  correct_percentage: number;
}

export interface CategoryStats {
  category_id: string;
  total_questions: number;
  unanswered_questions: number;
  correct_percentage: number;
}

export interface LocationState {
  resumeQuizId?: string;
  quizData?: QuizHistory;
  viewQuizId?: string;
  from?: string;
}

interface StudyPlan {
  // Define the structure of your study plan here
  tasks: Array<{ name: string; duration: number; type: string }>;
  // ... other relevant fields
}

interface PerformanceReview {
  strengths: string[];
  weaknesses: string[];
  recommendedTopics: string[];
  overallScore: number;
  // ... other relevant fields
}



//STUDY PLANNER INTERFACES

// Updated and new interfaces
export interface SPCourse {
  cpa_course_id: string;
  course_id: string;
  name: string;
  display_name: string;
  is_blueprint_based: boolean;
  outline_name: string;
}

export interface SPTopic {
  topic_id: string;
  cpa_course_id: string;
  course_provider: string;
  title: string;
  sequence_number: number;
  is_blueprint: boolean;
  estimated_minutes: number | null;
  retake_minutes: number | null;
  topic_type: 'learn' | 'loop' | 'funnel' | 'retake_funnel' | 'mock_exam' | 'mock_exam_retake' | null;
}

export interface SPTopicMapping {
  mapping_id: string;
  course_id: string;
  blueprint_topic_id: string;
  kesler_topic_id: string | null;
  becker_topic_id: string | null;
  gleim_topic_id: string | null;
  question_category_id: string | null;
  flashcard_category_id: string | null;
  task_name: string;
  lesson_id: string | null;
  blueprint_topic: SPTopic;
  primary_topic: SPTopic;
  secondary_topic?: SPTopic;
}

export interface SPStudyType {
  id: string;
  user_id: string;
  course_id: string;
  study_type: 'brand_new' | 'retake';
  created_at: string;
}

export interface SPCourseMix {
  mix_id: string;
  user_id: string;
  course_id: string;
  primary_cpa_course_id: string;
  secondary_cpa_course_id: string | null;
  alias: string | null;  // Add this line
}

export interface SPExamDate {
  exam_date_id: string;
  user_id: string;
  course_id: string;
  exam_date: string;
  created_at: string;
  updated_at: string;
}

export interface SPStudyDays {
  study_days_id: string;
  user_id: string;
  course_id: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface SPUserStudyPlan {
  plan_id: string;
  user_id: string;
  course_id: string;
  course_mix_id: string;
  study_type_id: string;
  current_schedule_id: string | null;
  created_at: string;
  updated_at: string;
  recommended_weekly_hours: '15' | '20' | '25'| '30'; // Add this line
}

export interface SPStudySchedule {
  schedule_id: string;
  plan_id: string;
  exam_date_id: string;
  start_date: string;
  end_date: string;
  is_current: boolean;
  created_at: string;
  updated_at: string;
}

export interface SPStudyPlanItem {
  item_id: string;
  plan_id: string;
  mapping_id: string;
  is_completed: boolean;
  sequence_number: number;
  task_name: string;
  blueprint_topic_title: string;
  blueprint_topic_sequence: number;
  primary_topic_title: string;
  primary_topic_sequence: number;
  secondary_topic_title: string | null;
  secondary_topic_sequence: number | null;
  primary_provider: string;
  secondary_provider: string | null;
  primary_cpa_course_id: string;
  secondary_cpa_course_id: string | null;
  planned_date: string | null;
  estimated_minutes: number | null;
  primary_sequence: number;
  is_primary_blueprint: boolean;  // Add this line
  is_secondary_blueprint: boolean;  // Add this line
  primary_alias: string | null;
  secondary_alias: string | null;
  topic_type: string | null;
}

export interface SPStudyPlanItemSchedule {
  item_schedule_id: string;
  item_id: string;
  schedule_id: string;
  planned_date: string;
  estimated_minutes: number;
  is_completed: boolean;
  completion_date: string | null;
}

export interface TopicInfo {
  sequence_number: number;
  course_provider: string;
}

export interface TopicMappingResult {
  mapping_id: string;
  task_name: string;
  blueprint_topics: TopicInfo[];
  primary_topics: TopicInfo[];
  secondary_topics: TopicInfo[];
}

export interface ConfidenceData {
  total: number;
  confident: number;
}

export interface ConfidenceTrackerData {
  mentorship: {
    total: number;
    completed: number;
  };
  questions: {
    total: number;
    confident: number;
  };
  flashcards: {
    total: number;
    confident: number;
  };
  lessons: {
    total: number;
    confident: number;
  };
  simulations: {
    total: number;
    confident: number;
  };
}

interface ScheduledDateItem {
  item_id: string;
  planned_date: string;
  week_number: number;
  estimated_minutes: number;
  primary_sequence: number;
  is_primary_blueprint: boolean;
  is_secondary_blueprint: boolean;
}

export interface StudyTaskDetails {
  task_name: string;
  plan_id: string;  // Add this line
  description: string;
  lesson_id: string | null;
  lesson_name: string | null;
  lesson_category_name: string | null;
  sub_lesson_category_name: string | null;
  question_category_id: string | null;
  parent_question_category_name: string | null;
  sub_question_category_name: string | null;
  flashcard_category_id: string | null;
  parent_flashcard_category_name: string | null;
  sub_flashcard_category_name: string | null;
  primary_course_name: string;
  primary_course_display_name: string;
  primary_course_alias: string | null;
  secondary_course_name: string | null;
  secondary_course_display_name: string | null;
  secondary_course_alias: string | null;
  primary_topic_title: string;
  secondary_topic_title: string | null;
  blueprint_topic_title: string;
  is_completed: boolean;
  topic_type: string | null;
}


// MENTOR INTERFACES

export interface MentorModule {
  id: string;
  course_id: string;  // Add this line
  title: string;
  content: {
    description: string;
    blocks: ContentBlock[];
  };
  sequence_number: number;
  is_completed: boolean;
  notes: string;
}

export interface ContentBlock {
  type: 'text' | 'video' | 'download' | 'html';
  content: string;
  videoId?: string;
  downloadUrl?: string;
  buttonText?: string;
}

export interface UserMentorProgress {
  id: string;
  user_id: string;
  module_id: string;
  is_completed: boolean;
  notes: string | null;
}


//Search bar interface

export interface SearchResult {
  id: string;
  type: 'lesson' | 'question' | 'simulation' | 'flashcard' | 'custom_flashcard' | 'note';
  title: string;
  preview: string;
  category_id?: string;
  fullContent?: {
    question?: string;
    answer?: string;
    front?: string;
    back?: string;
    content?: string;
  };
}


// CUSTOMER SUPPORT INTERFACES

// Support service interface
export interface SupportService {
  getOrCreateSupportUser(email: string, name?: string): Promise<SupportUser>;
  createTicket(
    email: string,
    subject: string,
    body: string,
    priority?: Ticket['priority']
  ): Promise<TicketCreationResult>;
}

export interface Ticket {
  id: string;
  short_id: number;
  support_user_id: string;
  admin_id: string | null;
  status: 'new' | 'open' | 'pending' | 'closed';
  subject: string;
  body: string;
  priority: 'low' | 'medium' | 'high' | 'urgent';
  created_at: string;
  updated_at: string;
  last_activity_at: string;
  closed_at: string | null;
  tags: string[];
  content_id: string | null;
  has_unread?: boolean;
  attachments: Array<{
    name: string;
    url: string;
    type: string;
  }>;
  user: SupportUser;
  
  // Email-related fields
  source: 'email' | 'web' | 'api';
  original_email_id: string | null;
  original_from_email: string | null;
  original_subject: string | null;
  email_thread_id: string | null;
  support_email_alias: string | null;
  is_spam: boolean;
  spam_score: number | null;
  thread_topic: string | null;
  list_id: string | null;

  // Joined fields
  admin?: {
    email: string;
    full_name: string;
  };
  _count?: {
    messages: number;
  };
}

export interface Message {
  id: string;
  ticket_id: string;
  support_user_id: string;
  content: string;
  is_internal: boolean;
  created_at: string;
  is_read: boolean;
  attachments: Array<{
    name: string;
    url: string;
    type: string;
  }>;
  
  // Email sending status
  email_sent: boolean;
  email_sent_at: string | null;
  sendgrid_message_id: string | null;
  
  // Email delivery tracking
  email_delivered: boolean;
  email_delivered_at: string | null;
  
  // Email open tracking
  email_opened: boolean;
  email_opened_at: string | null;
  email_open_count: number;
  
  // Email click tracking
  email_clicked: boolean;
  email_clicked_at: string | null;
  email_click_count: number;
  
  // Email error tracking
  email_error: boolean;
  email_error_reason: string | null;
  email_bounce_category: string | null;
  
  // Email metadata
  email_source: Record<string, unknown> | null;
  from_email: string | null;
  to_emails: string[] | null;
  email_subject: string | null;
  email_timestamp: string | null;
  email_spam_score: number | null;
  message_type: 'outbound' | 'inbound' | 'internal' | 'system';
  email_message_id: string | null;
  email_in_reply_to: string | null;
  email_references: string[] | null;
  user: SupportUser;  // Changed from {email: string, full_name: string} to SupportUser
  admin?: {
    email: string;
    full_name: string | null;
  };
}

// Helper types for email-related data
export interface EmailSource {
  headers: Record<string, string>;
  dkim: string;
  spf: string;
  spam_report: string;
}

export interface EmailAttachment {
  name: string;
  url: string;
  type: string;
}

export interface EmailTemplate {
  id: string;
  name: string;
  subject: string;
  content: string;
  variables: string[];
  created_by: string | null;
  created_at: string;
  updated_at: string;
  is_active: boolean;
  category: string | null;
  description: string | null;
}

// Additional interface for creating new tickets
export interface NewTicketData {
  user_id: string;
  subject: string;
  body: string;
  priority: Ticket['priority'];
  status: Ticket['status'];
}

// Additional interface for ticket updates
export interface TicketUpdate extends Partial<Pick<Ticket, 
  'status' | 
  'priority' | 
  'admin_id' | 
  'tags' | 
  'body' |
  'original_email_id' |
  'email_thread_id' |
  'thread_topic' |
  'list_id' |
  'attachments' // Add attachments to the list of updateable fields
>> {
  updated_at?: string;
  closed_at?: string | null;
  last_activity_at?: string;
}

interface TicketCreationResult {
  ticket: Ticket;
  message: Message | null;
}

//END INTERFACES

// Helper function for error handling
const handleApiError = (error: any): never => {
  console.error('API call failed:', error);
  if (error instanceof Error) {
    if (error.message.includes('network')) {
      throw new Error('Network error: Please check your internet connection');
    }
    if (error.message.includes('timeout')) {
      throw new Error('Request timeout: The server is taking too long to respond');
    }
  }
  throw error;
};

// Helper functions at the top level
const sanitizeContent = (content: string): string => {
  // Already has HTML formatting? Return as-is
  if (/<[a-z][\s\S]*>/i.test(content)) {
    return content;
  }
  
  // Convert newlines and add styling
  return `
    <div style="font-family: Arial, sans-serif; color: #333333; line-height: 1.6; padding: 20px;">
      ${content.replace(/\n/g, '<br>')}
      <br><br>
      <div style="color: #666666; font-size: 12px; border-top: 1px solid #eeeeee; margin-top: 20px; padding-top: 20px;">
        <p>Kesler CPA Review Support Team</p>
        <p>Need help? Reply to this email or visit our <a href="https://app.keslercpareview.com/user/support/tickets" style="color: #0066cc; text-decoration: underline;">Support Center</a></p>
      </div>
    </div>
  `;
};

const formatSubject = (subject: string, shortId: string): string => {
  // Remove any existing ticket reference patterns
  const cleanSubject = subject
    .replace(/^Re:\s*/, '')                // Remove leading "Re: "
    .replace(/\[#[^\]]+\]\s*/, '')        // Remove [#123456]
    .replace(/\[Ticket #[^\]]+\]\s*/, '')  // Remove [Ticket #...]
    .trim();                               // Clean up whitespace
  
  return `Re: [#${shortId}] ${cleanSubject}`;
};

// API methods
export const api = {

  // STALE DATA CHECK BETWEEN SUPABASE AND IDB
  async getLastUpdatedTimestamp(table: 'flashcards' | 'flashcard_categories', courseId: string): Promise<string> {
    const { data, error } = await supabase
      .from(table)
      .select('last_updated')
      .eq('course_id', courseId)
      .order('last_updated', { ascending: false })
      .limit(1)
      .single();

    if (error) throw error;
    return data?.last_updated || '1970-01-01T00:00:00Z';
  },




  // User methods
  async getUser(userId: string): Promise<User | null> {
    const { data, error } = await supabase
      .from('users')
      .select('*')
      .eq('id', userId)
      .single();
    
    if (error) {
      if (error.code === 'PGRST116') {
        console.warn(`No user found with ID: ${userId}`);
        return null;
      }
      console.error('Error fetching user:', error);
      throw error;
    }
    
    return data as User;
  },

  async getUserCoursePurchases(userId: string): Promise<UserCoursePurchase[]> {
    const { data, error } = await supabase
      .from('user_course_purchases')
      .select('*')
      .eq('user_id', userId);

    if (error) {
      console.error('Error fetching user course purchases:', error);
      throw error;
    }

    return data || [];
  },

  async getAllUserData(userId: string): Promise<{
    user: User | null;
    userCoursePurchases: UserCoursePurchase[];
    courseAccesses: UserCourseAccess[];
    mentorshipAccess: UserCourseAccess | null;
  }> {
    const cacheKey = `userData_${userId}`;
    const cachedData = localStorage.getItem(cacheKey);
  
    if (cachedData) {
      const parsed = JSON.parse(cachedData);
      if (parsed.expiry > Date.now()) {
        return parsed.data;
      }
    }
  
    const [
      { data: userData, error: userError },
      { data: purchasesData, error: purchasesError },
      { data: accessData, error: accessError }
    ] = await Promise.all([
      supabase.from('users').select('*').eq('id', userId).single(),
      supabase.from('user_course_purchases').select('*').eq('user_id', userId),
      supabase.from('user_course_access').select('*').eq('user_id', userId)
    ]);
  
    if (userError) throw userError;
    if (purchasesError) throw purchasesError;
    if (accessError) throw accessError;
  
    const result = {
      user: userData,
      userCoursePurchases: purchasesData || [],
      courseAccesses: accessData || [],
      mentorshipAccess: accessData?.find(access => 
        access.course_id === 'a6a5eaf3-63bc-4e9a-92ac-64153a1f8395'
      ) || null
    };
  
    localStorage.setItem(cacheKey, JSON.stringify({
      data: result,
      expiry: Date.now() + (5 * 60 * 1000) // 5 minutes
    }));
  
    return result;
  },
  
  async createUserInstance(browserInstanceId: string): Promise<void> {
    const user = await auth.getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const deviceInfo = navigator.userAgent;

    const { error } = await supabase
      .from('user_instances')
      .insert({
        user_id: user.id,
        browser_instance_id: browserInstanceId,
        device_info: deviceInfo
      });

    if (error) throw error;
  },

  async updateUserInstance(browserInstanceId: string): Promise<void> {
    const user = await auth.getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const { error } = await supabase
      .from('user_instances')
      .upsert({
        user_id: user.id,
        browser_instance_id: browserInstanceId,
        last_active: new Date().toISOString()
      }, {
        onConflict: 'user_id,browser_instance_id'
      });

    if (error) throw error;
  },

  async updateUser(userId: string, updates: Partial<User>): Promise<User> {
    const { data, error } = await supabase
      .from('users')
      .update(updates)
      .eq('id', userId)
      .single();
    if (error) throw error;
    return data;
  },

  // Product methods
  async getProducts(): Promise<Product[]> {
    console.log('API: getProducts called');
    
    const cacheKey = 'cachedProducts';
    const cachedData = localStorage.getItem(cacheKey);
  
    if (cachedData) {
      const { data, expiry } = JSON.parse(cachedData);
      if (expiry > Date.now()) {
        console.log(`API: getProducts returned ${data.length} product(s) from cache`);
        return data as Product[];
      }
    }
  
    const { data, error } = await supabase
      .from('products')
      .select('*');
    
    if (error) {
      console.error('Error fetching products:', error);
      throw error;
    }
    
    // Cache the data for 1 hour
    localStorage.setItem(cacheKey, JSON.stringify({
      data,
      expiry: Date.now() + 3600000 // 1 hour in milliseconds
    }));
  
    console.log(`API: getProducts returned ${data.length} product(s) from API`);
    return data as Product[];
  },

  async getCourse(courseId: string): Promise<Product | null> {
    console.log(`API: getCourse called for courseId: ${courseId}`);
    
    const { data, error } = await supabase
      .from('products')
      .select('*')
      .eq('id', courseId)
      .single();
    
    if (error) {
      if (error.code === 'PGRST116') {
        console.warn(`No course found with ID: ${courseId}`);
        return null;
      }
      console.error('Error fetching course:', error);
      throw error;
    }
    
    console.log(`API: getCourse returned data for courseId: ${courseId}`);
    return data as Product;
  },


  // User Course Access methods
  // Updated User Course Access method with caching
  async getUserCourseAccess(userId: string, courseId?: string): Promise<UserCourseAccess | UserCourseAccess[] | null> {
    if (courseId) {
      // Fetch single course access
      const cacheKey = `userCourseAccess_${userId}_${courseId}`;
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        return JSON.parse(cachedData);
      }

      const { data, error } = await supabase
        .from('user_course_access')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .single();

      if (error) {
        console.error('Error fetching user course access:', error);
        return null;
      }

      if (data) {
        localStorage.setItem(cacheKey, JSON.stringify(data));
      }

      return data;
    } else {
      // Fetch all course accesses for the user
      const cacheKey = `userCourseAccesses_${userId}`;
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        return JSON.parse(cachedData);
      }

      const { data, error } = await supabase
        .from('user_course_access')
        .select('*')
        .eq('user_id', userId);

      if (error) {
        console.error('Error fetching user course accesses:', error);
        return null;
      }

      if (data) {
        localStorage.setItem(cacheKey, JSON.stringify(data));
      }

      return data;
    }
  },

  //PRODUCT ACTIVATION

  async handleCourseSwitch(
    userId: string,
    currentCourseId: string | null,
    newCourseId: string,
    useSwitch: boolean
  ): Promise<{ success: boolean; message: string; switchUsed: boolean }> {
    try {
      const { data, error } = await supabase.rpc('access_switch_cpa_course', {
        input_user_id: userId,
        input_current_course_id: currentCourseId,
        input_new_course_id: newCourseId,
        input_use_switch: useSwitch
      });
  
      if (error) throw error;
  
      // Clear cache after switch
      this.clearUserCache(userId);
  
      return {
        success: true,
        message: 'Course switched successfully',
        switchUsed: data
      };
    } catch (error) {
      console.error('Error switching course:', error);
      return {
        success: false,
        message: error instanceof Error ? error.message : 'Failed to switch course',
        switchUsed: false
      };
    }
  },
  
  async getDefaultTier(userId: string): Promise<string> {
    const { data, error } = await supabase.rpc('access_get_default_tier', {
      user_id: userId
    });
  
    if (error) throw error;
    return data;
  },
  
  async verifyActivePurchase(userId: string): Promise<boolean> {
    const { data, error } = await supabase.rpc('access_verify_active_purchase', {
      user_id: userId
    });
  
    if (error) throw error;
    return data;
  },



  // CACHING OF PRODUCT TO LOCAL STORAGE AND CLEARING IT
  // New method to clear user-specific cache
  clearUserCache(userId: string) {
    localStorage.removeItem(`userCourseAccess_${userId}`);
    localStorage.removeItem(`userCourseAccess_${userId}_expiry`);
    
    // Clear or update cachedProducts if they contain user-specific data
    const cachedProducts = localStorage.getItem('cachedProducts');
    if (cachedProducts) {
      // If you need to clear user-specific data from products, you can do it here
      // For example, if products contain user-specific fields:
      // const products = JSON.parse(cachedProducts);
      // Object.keys(products).forEach(key => {
      //   delete products[key].userSpecificField;
      // });
      // localStorage.setItem('cachedProducts', JSON.stringify(products));
      
      // Or if you want to clear all product cache:
      localStorage.removeItem('cachedProducts');
    }
  },

  // New method to clear all cache
  clearAllCache() {
    localStorage.removeItem('cachedProducts');
    // Clear all user-specific caches
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith('userCourseAccess_')) {
        localStorage.removeItem(key);
      }
    });
  },

//QUIZ START

  // Question Category methods
  async getQuestionCategories(courseId: string): Promise<QuestionCategory[]> {
    const { data, error } = await supabase
      .from('question_categories')
      .select('*')
      .eq('course_id', courseId);
    if (error) throw error;
    return data;
  },

  // Question methods
  async getQuestionsForCategories(courseId: string, categoryIds: string[], userId: string): Promise<Question[]> {
    try {
      console.log('Fetching questions for categories:', categoryIds);
  
      const { data: answeredQuestions, error: answeredError } = await supabase
        .from('user_question_responses')
        .select('question_id')
        .eq('user_id', userId);
  
      if (answeredError) throw answeredError;
  
      const answeredQuestionIds = answeredQuestions.map(q => q.question_id);
  
      // Fetch all question categories
      const { data: allCategories, error: categoriesError } = await supabase
        .from('question_categories')
        .select('*')
        .eq('course_id', courseId);
  
      if (categoriesError) throw categoriesError;
  
      // Filter out parent categories and get all sub-category IDs
      const subCategoryIds = categoryIds.reduce((acc: string[], categoryId) => {
        const category = allCategories.find(c => c.id === categoryId || c.parent_question_category_name === categoryId);
        if (category) {
          if (category.sub_question_category_name === 'All') {
            // If it's a parent category, add all its sub-categories
            acc.push(...allCategories
              .filter(c => c.parent_question_category_name === category.parent_question_category_name && c.id !== category.id)
              .map(c => c.id)
            );
          } else {
            // If it's already a sub-category, just add its ID
            acc.push(category.id);
          }
        }
        return acc;
      }, []);
  
      console.log('Fetching questions for sub-categories:', subCategoryIds);
  
      let query = supabase
        .from('questions')
        .select(`
          *,
          notes:notes(*),
          user_confidence_levels!inner(confidence_history)
        `)
        .eq('course_id', courseId)
        .in('question_category_id', subCategoryIds)
        .eq('user_confidence_levels.user_id', userId)
        .eq('user_confidence_levels.course_id', courseId);
  
      if (answeredQuestionIds.length > 0) {
        query = query.not('id', 'in', `(${answeredQuestionIds.join(',')})`);
      }
  
      const { data, error } = await query;
  
      if (error) throw error;
  
      console.log('Fetched questions:', data.length);
  
      return data.map(question => ({
        ...question,
        note: question.notes.length > 0 ? question.notes[0] : null,
        confidenceHistory: question.user_confidence_levels[0]?.confidence_history || []
      }));
    } catch (error) {
      console.error('Error fetching questions:', error);
      throw error;
    }
  },

  // Save Q responses
  async saveQuestionResponse(data: {
    user_id: string;
    question_id: string;
    course_id: string;
    selected_answer: string;
    is_correct: boolean;
    confidence_level: string;
    response_time: number;
  }): Promise<void> {
    try {
      const { data: existingData, error: selectError } = await supabase
        .from('user_question_responses')
        .select()
        .eq('user_id', data.user_id)
        .eq('question_id', data.question_id)
        .maybeSingle();
  
      if (selectError) {
        console.error('Error checking for existing response:', selectError);
        throw selectError;
      }

      if (existingData) {
        const { error: updateError } = await supabase
          .from('user_question_responses')
          .update(data)
          .eq('user_id', data.user_id)
          .eq('question_id', data.question_id);
  
        if (updateError) {
          console.error('Error updating question response:', updateError);
          throw updateError;
        }
      } else {
        const { error: insertError } = await supabase
          .from('user_question_responses')
          .insert(data);
  
        if (insertError) {
          console.error('Error inserting question response:', insertError);
          throw insertError;
        }
      }
    } catch (error) {
      console.error('Error in saveQuestionResponse:', error);
      throw error;
    }
  },

  async getCategoryStats(userId: string, courseId: string, categoryIds: string[]): Promise<CategoryStats[]> {
    console.log('Fetching category stats...', { userId, courseId, categoryIds });
    try {
      const { data, error } = await supabase.rpc('get_bulk_category_stats', { 
        p_user_id: userId, 
        p_course_id: courseId, 
        p_category_ids: categoryIds 
      });
      if (error) {
        console.error('Error fetching category stats:', error);
        throw error;
      }
      console.log('Received category stats:', data);
      return Array.isArray(data) ? data : [data];
    } catch (err) {
      console.error('Exception in getCategoryStats:', err);
      throw err;
    }
  },

  async getNoteForQuestion(userId: string, courseId: string, questionId: string): Promise<Note | null> {
    try {
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .eq('question_id', questionId)
        .maybeSingle();
  
      if (error) {
        console.error('Error fetching note:', error);
        return null;
      }
  
      return data;
    } catch (error) {
      console.error('Error fetching note:', error);
      return null;
    }
  },

  async createQuizHistory(userId: string, courseId: string, questionIds: string[]): Promise<string> {
    const { data, error } = await supabase
      .from('quiz_history')
      .insert({
        user_id: userId,
        course_id: courseId,
        total_questions: questionIds.length,
        correct_answers: 0,
        incorrect_answers: 0,
        percentage_correct: 0,
        questions_data: questionIds.map(id => ({ questionId: id, answered: false, timeSpent: 0 })),
        completed: false,
        total_time: 0,
        avg_time_per_question: 0
      })
      .select('id')
      .single();
  
    if (error) throw error;
    return data.id;
  },

  async updateQuizHistory(quizHistoryId: string, questionId: string, userAnswer: string, isCorrect: boolean, confidenceLevel: string, timeSpent: number): Promise<void> {
    const { data: quizHistory, error: fetchError } = await supabase
      .from('quiz_history')
      .select('*')
      .eq('id', quizHistoryId)
      .single();
  
    if (fetchError) throw fetchError;
  
    const updatedQuestionsData = quizHistory.questions_data.map((q: QuizQuestionData) => 
      q.questionId === questionId ? { ...q, answered: true, userAnswer, isCorrect, confidenceLevel, timeSpent } : q
    );
  
    const correctAnswers = updatedQuestionsData.filter((q: QuizQuestionData) => q.isCorrect).length;
    const totalAnswered = updatedQuestionsData.filter((q: QuizQuestionData) => q.answered).length;
    const completed = totalAnswered === quizHistory.total_questions;
    const percentageCorrect = (correctAnswers / totalAnswered) * 100;
    const totalTime = updatedQuestionsData.reduce((sum: number, q: QuizQuestionData) => sum + (q.timeSpent || 0), 0);
    const avgTimePerQuestion = totalAnswered > 0 ? totalTime / totalAnswered : 0;
  
    const { error: updateError } = await supabase
      .from('quiz_history')
      .update({
        questions_data: updatedQuestionsData,
        correct_answers: correctAnswers,
        incorrect_answers: totalAnswered - correctAnswers,
        percentage_correct: percentageCorrect,
        completed: completed,
        end_time: completed ? new Date().toISOString() : null,
        total_time: totalTime,
        avg_time_per_question: avgTimePerQuestion,
      })
      .eq('id', quizHistoryId);
  
    if (updateError) throw updateError;
  },
  
  async getQuizHistory(userId: string, courseId: string): Promise<QuizHistory[]> {
    if (!userId) {
      console.warn('getQuizHistory called without a valid userId');
      return [];
    }
    return this.getFilteredQuizHistory(userId, courseId, 'all');
  },

  async getQuizHistoryForQuestion(userId: string, courseId: string, questionId: string): Promise<any | null> {
    const { data, error } = await supabase
      .rpc('get_quiz_history_for_question', {
        p_user_id: userId,
        p_course_id: courseId,
        p_question_id: questionId
      });

    if (error) {
      console.error('Error fetching quiz history for question:', error);
      throw error;
    }

    return data && data.length > 0 ? data[0] : null;
  },
  
  async getIncompleteQuiz(userId: string, courseId: string): Promise<any | null> {
    console.log('API: Fetching incomplete quiz...', { userId, courseId });
    try {
      const { data, error } = await supabase
        .from('quiz_history')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .eq('completed', false)
        .order('start_time', { ascending: false })
        .limit(1);
  
      if (error) {
        console.error('API: Error fetching incomplete quiz:', error);
        throw error;
      }
      console.log('API: Incomplete quiz fetched:', data);
      return data.length > 0 ? data[0] : null;
    } catch (err) {
      console.error('API: Error in getIncompleteQuiz:', err);
      throw err;
    }
  },

  async getQuestionById(questionId: string): Promise<Question> {
    const { data, error } = await supabase
      .from('questions')
      .select('*')
      .eq('id', questionId)
      .single();

    if (error) throw error;
    return data;
  },
  
  async getQuestionCategory(categoryId: string): Promise<QuestionCategory | null> {
    const { data, error } = await supabase
      .from('question_categories')
      .select('*')
      .eq('id', categoryId)
      .single();

    if (error) {
      if (error.code === 'PGRST116') {
        return null; // Category not found
      }
      throw error;
    }
    return data;
  },

  async getQuestionsById(questionIds: string[], userId: string, courseId: string): Promise<(Question & { note: Note | null })[]> {
    console.log('Fetching questions by ID:', { questionIds, userId, courseId });
    try {
      if (!questionIds || questionIds.length === 0) {
        throw new Error('No question IDs provided');
      }
  
      let query = supabase
        .from('questions')
        .select(`
          *,
          question_categories (
            parent_question_category_name,
            sub_question_category_name
          ),
          notes (
            id,
            content,
            created_at,
            updated_at
          )
        `)
        .in('id', questionIds);
    
      // Only add user and course filters if they are non-empty
      if (userId && userId.trim() !== '') {
        query = query.eq('notes.user_id', userId);
      }
      if (courseId && courseId.trim() !== '') {
        query = query.eq('notes.course_id', courseId);
      }
    
      const { data, error } = await query;
    
      if (error) {
        console.error('Supabase query error:', error);
        throw error;
      }
    
      if (!data || data.length === 0) {
        console.warn('No questions found for the given IDs');
        return [];
      }
    
      console.log(`Found ${data.length} questions`);
    
      return data.map(q => ({
        ...q,
        question_category_name: q.question_categories?.parent_question_category_name || '',
        sub_question_category_name: q.question_categories?.sub_question_category_name || '',
        note: q.notes && q.notes.length > 0 ? q.notes[0] : null
      }));
    } catch (error) {
      console.error('Error in getQuestionsById:', error);
      throw error;
    }
  },

  async getQuizHistoryById(quizId: string): Promise<any> {
    const { data, error } = await supabase
      .from('quiz_history')
      .select('*')
      .eq('id', quizId)
      .single();
  
    if (error) throw error;
    return data;
  },

  async updateConfidenceLevel(userId: string, courseId: string, questionId: string, confidenceLevel: string): Promise<void> {
    try {
      const timestamp = new Date().toISOString();
  
      // Perform the upsert
      const { error } = await supabase
        .from('user_confidence_levels')
        .upsert({
          user_id: userId,
          course_id: courseId,
          question_id: questionId,
          confidence_history: `
            coalesce(confidence_history, '[]'::jsonb) || 
            jsonb_build_array(jsonb_build_object('level', '${confidenceLevel}', 'timestamp', '${timestamp}'))
          `,
          updated_at: timestamp
        }, {
          onConflict: 'user_id,question_id'
        });
  
      if (error) {
        console.error('Error updating confidence level:', error);
        throw error;
      }
    } catch (error) {
      console.error('Error in updateConfidenceLevel:', error);
      throw error;
    }
  },

  async getConfidenceLevels(userId: string, courseId: string, questionIds: string[]): Promise<ConfidenceLevel[]> {
    try {
      console.log('Fetching confidence levels for questions:', questionIds);
      if (!questionIds || questionIds.length === 0) {
        console.warn('No question IDs provided for fetching confidence levels');
        return [];
      }
      const { data, error } = await supabase
        .from('user_confidence_levels')
        .select('question_id, confidence_level, confidence_history')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .in('question_id', questionIds);
  
      if (error) throw error;
  
      console.log('Fetched confidence levels:', data);
  
      return data.map(item => ({
        question_id: item.question_id,
        current_confidence_level: item.confidence_level || null,
        confidence_history: item.confidence_history || []
      }));
    } catch (error) {
      console.error('Error fetching confidence levels:', error);
      return [];
    }
  },
  
  async getActiveQuiz(userId: string, courseId: string): Promise<any | null> {
    if (!userId) {
      console.warn('getActiveQuiz called without a valid userId');
      return null;
    }
    console.log('Fetching active quiz for user:', userId, 'course:', courseId);
    try {
      const { data, error } = await supabase
        .from('quiz_history')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .eq('completed', false)
        .order('start_time', { ascending: false })
        .limit(1);
  
      if (error) {
        console.error('Error fetching active quiz:', error);
        throw error;
      }
  
      console.log('Active quiz data:', data);
      return data.length > 0 ? data[0] : null;
    } catch (error) {
      console.error('Caught error in getActiveQuiz:', error);
      return null;
    }
  },
  
  async endQuizSession(quizId: string, totalTime: number): Promise<void> {
    try {
      // Fetch quiz data
      const { data: quizData, error: fetchError } = await supabase
        .from('quiz_history')
        .select('*')
        .eq('id', quizId)
        .single();
  
      if (fetchError) throw fetchError;
  
      // Process quiz data
      const answeredQuestions = quizData.questions_data.filter((q: any) => q.answered);
      const totalAnswered = answeredQuestions.length;
      const correctAnswers = answeredQuestions.filter((q: any) => q.isCorrect).length;
      const percentageCorrect = totalAnswered > 0 ? (correctAnswers / totalAnswered) * 100 : 0;
  
      // Update quiz history
      const { error: updateError } = await supabase
        .from('quiz_history')
        .update({
          completed: true,
          end_time: new Date().toISOString(),
          total_questions: totalAnswered,
          correct_answers: correctAnswers,
          incorrect_answers: totalAnswered - correctAnswers,
          percentage_correct: percentageCorrect,
          questions_data: answeredQuestions,
          total_time: totalTime
        })
        .eq('id', quizId);
  
      if (updateError) throw updateError;
  
      // Call the Supabase function to calculate average time per question
      const { data: avgTimeData, error: avgTimeError } = await supabase.rpc('calculate_avg_time_per_question', { quiz_id: quizId });
  
      if (avgTimeError) {
        console.error('Error calculating average time:', avgTimeError);
        // Don't throw here, continue with the function
      } else if (avgTimeData !== null) {
        // Update quiz history with average time
        const { error: avgTimeUpdateError } = await supabase
          .from('quiz_history')
          .update({ avg_time_per_question: avgTimeData })
          .eq('id', quizId);
  
        if (avgTimeUpdateError) {
          console.error('Error updating average time:', avgTimeUpdateError);
          // Don't throw here, continue with the function
        }
      }
  
    } catch (error) {
      console.error('Error ending quiz session:', error);
      throw error;
    }
  },
  
  async getAvailableQuestionCounts(
    courseId: string,
    categoryIds: string[],
    keslerQFilter: string[],
    userId: string
  ): Promise<{[key: string]: number}> {
    console.log('API: Getting available question counts:', { courseId, categoryIds, keslerQFilter, userId });
    try {
      const { data, error } = await supabase.rpc('get_available_question_counts', {
        p_course_id: courseId,
        p_category_ids: categoryIds,
        p_kesler_q_filter: keslerQFilter,
        p_user_id: userId
      });
  
      if (error) throw error;
      console.log('API: Received available question counts:', data);
      return data;
    } catch (error) {
      console.error('API: Error fetching available question counts:', error);
      throw error;
    }
  },

  async getQuestionsForCustomQuiz(
    courseId: string,
    categoryIds: string[],
    keslerQFilter: string[],
    questionCount: number,
    userId: string
  ): Promise<Question[]> {
    console.log('Fetching custom quiz questions:', { courseId, categoryIds, keslerQFilter, questionCount, userId });
    try {
      const { data, error } = await supabase.rpc('get_questions_for_custom_quiz', {
        p_user_id: userId,
        p_course_id: courseId,
        p_category_ids: categoryIds,
        p_kesler_q_filter: keslerQFilter,
        p_question_count: questionCount
      });
  
      if (error) throw error;
      
      console.log('Raw data from get_questions_for_custom_quiz:', data);
  
      if (!data || data.length === 0) {
        console.warn('No questions returned from custom quiz query');
        return [];
      }
  
      // Map the returned data to the Question type
      const questionsWithConfidence: Question[] = data.map((item: any) => ({
        id: item.id,
        course_id: item.course_id,
        question_content: item.question_content,
        answer_content: item.answer_content,
        question_content_file: item.question_content_file || null,
        answer_content_file: item.answer_content_file || null,
        exam_id: item.exam_id || null,
        exam_name: item.exam_name || null,
        exam_section_name: item.exam_section_name || null,
        exam_section_time_allowed: item.exam_section_time_allowed || null,
        question_category_id: item.question_category_id,
        question_category_name: item.question_category_name,
        sub_question_category_name: item.sub_question_category_name,
        question_type: item.question_type || '',
        choices_count: item.choices_count || 0,
        correct_answer: item.correct_answer,
        related_lessons: item.related_lessons || [],
        question_number: item.question_number || 0,
        answers_count: item.answers_count || 0,
        elo_rank: item.elo_rank || 0,
        correct_answers_count: item.correct_answers_count || 0,
        incorrect_answers_count: item.incorrect_answers_count || 0,
        related_section_ids: item.related_section_ids || [],
        related_section_names: item.related_section_names || [],
        external_id: item.external_id || null,
        tags: item.tags || [],
        answer_container_type: item.answer_container_type || '',
        avg_confidence: item.avg_confidence || 0,
        not_confident_count: item.not_confident_count || 0,
        guessing_count: item.guessing_count || 0,
        confident_count: item.confident_count || 0,
        note: item.note || null,
        confidenceLevel: item.confidence_level,
        confidenceHistory: item.confidence_history || []
      }));
  
      console.log('Questions with confidence:', questionsWithConfidence);
  
      return questionsWithConfidence;
    } catch (error) {
      console.error('Error in getQuestionsForCustomQuiz:', error);
      throw error;
    }
  },
  
  async resetSelectedQuestions(
    userId: string,
    courseId: string,
    questionIds: string[]
  ): Promise<void> {
    try {
      console.log('Resetting selected questions:', { userId, courseId, questionIds });
      const { error } = await supabase.rpc('reset_selected_questions', {
        p_user_id: userId,
        p_course_id: courseId,
        p_question_ids: questionIds
      });
      if (error) throw error;
      console.log('Successfully reset selected questions');
    } catch (error) {
      console.error('Error resetting selected questions:', error);
      throw error;
    }
  },

  async updateQuestionConfidenceLevel(userId: string, courseId: string, questionId: string, confidenceLevel: string): Promise<void> {
    const { error } = await supabase.rpc('update_question_confidence_level', {
      p_user_id: userId,
      p_course_id: courseId,
      p_question_id: questionId,
      p_confidence_level: confidenceLevel
    });
    if (error) throw error;
  },

  async resetQuestionConfidenceLevel(userId: string, questionId: string): Promise<void> {
    const { error } = await supabase.rpc('reset_question_confidence_level', {
      p_user_id: userId,
      p_question_id: questionId
    });
    if (error) throw error;
  },

  
  //Filtering quiz history
  async getFilteredQuizHistory(userId: string, courseId: string, filter: string): Promise<QuizHistory[]> {
    if (!userId) {
      console.warn('getFilteredQuizHistory called without a valid userId');
      return [];
    }
    console.log('API: Fetching filtered quiz history...', { userId, courseId, filter });
    try {
      const { data, error } = await supabase.rpc('filter_quiz_history', {
        p_user_id: userId,
        p_course_id: courseId,
        p_filter: filter.toLowerCase()
      });
  
      if (error) throw error;
  
      return data;
    } catch (err) {
      console.error('API: Error fetching filtered quiz history:', err);
      throw err;
    }
  },


  async deleteEmptyQuizzes(userId: string, courseId: string): Promise<void> {
    const { error } = await supabase.rpc('delete_empty_quizzes', {
      p_user_id: userId,
      p_course_id: courseId
    });
    if (error) throw error;
  },

  //END QUIZ

  // API.TS SUPABASE FLASHCARD FUNCTIONS

    // Flashcard Category methods
    // SYNC WITH IDB DIRECTLY FROM KESLERCARDS
    async getFlashcardCategories(courseId: string): Promise<FlashcardCategory[]> {
      try {
        const { data, error } = await supabase
          .from('flashcard_categories')
          .select('*')
          .eq('course_id', courseId);
        if (error) throw error;
        if (!data) throw new Error('No data returned from API');
        return data;
      } catch (error) {
        return handleApiError(error);
      }
    },

    // Flashcard methods
    // SYNC WITH IDB DIRECTLY FROM KESLERCARDS
    async getFlashcards(courseId: string): Promise<Flashcard[]> {
      try {
        const { data, error } = await supabase
          .from('flashcards')
          .select('*')
          .eq('course_id', courseId);
        if (error) throw error;
        if (!data) throw new Error('No data returned from API');
        return data;
      } catch (error) {
        return handleApiError(error);
      }
    },

    //IDB OFFLINE
    // User Flashcard Progress methods
    async updateUserFlashcardProgress(progress: Omit<UserFlashcardProgress, 'id'>): Promise<UserFlashcardProgress> {
      console.log('Updating user flashcard progress:', progress);
    
      // Perform the upsert operation
      const { error: upsertError } = await supabase
        .from('user_flashcard_progress')
        .upsert(progress, {
          onConflict: 'user_id,course_id,flashcard_id'
        });
    
      if (upsertError) {
        console.error('Error updating user flashcard progress:', upsertError);
        throw upsertError;
      }
    
      // Fetch the updated record
      const { data, error: fetchError } = await supabase
        .from('user_flashcard_progress')
        .select()
        .match({
          user_id: progress.user_id,
          course_id: progress.course_id,
          flashcard_id: progress.flashcard_id
        })
        .single();
    
      if (fetchError) {
        console.error('Error fetching updated user flashcard progress:', fetchError);
        throw fetchError;
      }
    
      if (!data) {
        console.error('No data returned after upsert operation');
        throw new Error('Failed to update user flashcard progress');
      }
    
      console.log('User flashcard progress updated successfully:', data);
      return data;
    },
  
    // IDB OFFLINE
    async getUserFlashcardProgress(userId: string, courseId: string): Promise<UserFlashcardProgress[]> {
      try {
        const { data, error } = await supabase
          .from('user_flashcard_progress')
          .select('*')
          .eq('user_id', userId)
          .eq('course_id', courseId);
        if (error) throw error;
        if (!data) throw new Error('No data returned from API');
        return data;
      } catch (error) {
        return handleApiError(error);
      }
    },

    //CUSTOM FLASHCARDS
    // IDB OFFLINE
    async getCustomFlashcards(
      userId: string, 
      courseId: string, 
      filters?: { 
        tags?: string[], 
        confidenceLevel?: string,
        lastReviewedBefore?: Date
      }
    ): Promise<CustomFlashcard[]> {
      try {
        let query = supabase
          .from('custom_flashcards')
          .select('*')
          .eq('user_id', userId)
          .eq('course_id', courseId)
          .order('last_reviewed', { ascending: true });
  
        if (filters?.tags && filters.tags.length > 0) {
          query = query.contains('tags', filters.tags);
        }
  
        if (filters?.confidenceLevel) {
          query = query.eq('confidence_level', filters.confidenceLevel);
        }
  
        if (filters?.lastReviewedBefore) {
          query = query.lt('last_reviewed', filters.lastReviewedBefore.toISOString());
        }
  
        const { data, error } = await query;
  
        if (error) throw error;
        if (!data) throw new Error('No data returned from API');
        return data;
      } catch (error) {
        console.error('getCustomFlashcards failed:', error);
        if (error instanceof Error) {
          if (error.message.includes('network')) {
            throw new Error('Network error: Please check your internet connection');
          }
          if (error.message.includes('timeout')) {
            throw new Error('Request timeout: The server is taking too long to respond');
          }
        }
        throw error;
      }
    },

    // IDB OFFLINE
        // Modify the createCustomFlashcard function to handle new tags
    async createCustomFlashcard(flashcard: Omit<CustomFlashcard, 'id' | 'created_at' | 'updated_at' | 'last_reviewed'>): Promise<CustomFlashcard> {
      const newFlashcard = {
        ...flashcard,
        last_reviewed: new Date().toISOString()
      };

      const { data, error } = await supabase
        .from('custom_flashcards')
        .insert(newFlashcard)
        .select()
        .single();

      if (error) throw error;

      // After creating the flashcard, update the user's tag list if there are new tags
      if (flashcard.tags && flashcard.tags.length > 0) {
        const existingTags = await this.getAllUserTags(flashcard.user_id, flashcard.course_id);
        const newTags = flashcard.tags.filter(tag => !existingTags.includes(tag));

        if (newTags.length > 0) {
          // Here you might want to update a separate 'user_tags' table or similar
          // For now, we'll just log that new tags were created
          console.log('New tags created:', newTags);
        }
      }

      return data;
    },

    //IDB OFFLINE
    async updateCustomFlashcard(id: string, updates: Partial<CustomFlashcard>): Promise<CustomFlashcard> {
      console.log('Updating custom flashcard:', { id, updates });
    
      // First, check if the flashcard exists
      const { data: existingCard, error: fetchError } = await supabase
        .from('custom_flashcards')
        .select('*')
        .eq('id', id)
        .single();
    
      if (fetchError) {
        console.error('Error fetching existing custom flashcard:', fetchError);
        throw fetchError;
      }
    
      if (!existingCard) {
        console.error('No existing custom flashcard found with the given id');
        throw new Error('Custom flashcard not found');
      }
    
      // Use the user_id from the existing card if it's not provided in the updates
      const userIdForUpdate = updates.user_id || existingCard.user_id;
    
      // If the card exists, proceed with the update
      const { data, error } = await supabase
        .from('custom_flashcards')
        .update(updates)
        .eq('id', id)
        .eq('user_id', userIdForUpdate)
        .select();
    
      if (error) {
        console.error('Error updating custom flashcard:', error);
        throw error;
      }
    
      if (!data || data.length === 0) {
        console.error('No data returned after update operation');
        throw new Error('Failed to update custom flashcard');
      }
    
      console.log('Custom flashcard updated successfully:', data[0]);
      return data[0];
    },

    // IDB OFFLINE
    async deleteCustomFlashcard(flashcardId: string): Promise<void> {
      const { error } = await supabase
        .from('custom_flashcards')
        .delete()
        .eq('id', flashcardId);

      if (error) throw error;
    },

    //TAG CACHE FUNCTION
    // Add this new function to get all user tags
    async getAllUserTags(userId: string, courseId: string): Promise<string[]> {
      const { data, error } = await supabase
        .from('custom_flashcards')
        .select('tags')
        .eq('user_id', userId)
        .eq('course_id', courseId);

      if (error) throw error;

      // Flatten the array of tag arrays and remove duplicates
      const allTags = Array.from(new Set(data.flatMap(item => item.tags)));
      return allTags;
    },

    // SPACED REPETITION CHECK
    // IDB OFFLINE
    async getUserStudyStats(userId: string, courseId: string): Promise<{ cardsCompleted: number }> {
      try {
        // Get the count of unique flashcards the user has interacted with
        const { count: premadeCount, error: premadeError } = await supabase
          .from('user_flashcard_progress')
          .select('flashcard_id', { count: 'exact', head: true })
          .eq('user_id', userId)
          .eq('course_id', courseId);
  
        if (premadeError) throw premadeError;
  
        // Get the count of custom flashcards the user has created
        const { count: customCount, error: customError } = await supabase
          .from('custom_flashcards')
          .select('id', { count: 'exact', head: true })
          .eq('user_id', userId)
          .eq('course_id', courseId);
  
        if (customError) throw customError;
  
        if (premadeCount === null || customCount === null) {
          throw new Error('Failed to retrieve count from API');
        }
  
        const cardsCompleted = (premadeCount || 0) + (customCount || 0);
  
        return { cardsCompleted };
      } catch (error) {
        console.error('getUserStudyStats failed:', error);
        if (error instanceof Error) {
          if (error.message.includes('network')) {
            throw new Error('Network error: Please check your internet connection');
          }
          if (error.message.includes('timeout')) {
            throw new Error('Request timeout: The server is taking too long to respond');
          }
        }
        throw error;
      }
    },

// END FLASHCARD FUNCTIONS

//START lesson functions

  // Lesson Category methods
  async getLessonCategories(courseId: string): Promise<LessonCategory[]> {
    const { data, error } = await supabase
      .from('lesson_categories')
      .select('*')
      .eq('course_id', courseId)
      .order('category_number', { ascending: true }); // Add this line
    if (error) throw error;
    return data;
  },

  async getLessons(courseId: string, userId: string): Promise<(Lesson & { is_completed: boolean; confidence_level: string | null })[]> {
    const { data: userCourseAccess } = await supabase
      .from('user_course_access')
      .select('tier_id')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .single();
  
    const freeTrialTierId = '337eeb06-1040-43d5-ad6d-5316e058aec9';
    const mentorOnlyTierId = '25f83b83-0a8a-4599-b387-c8870d79b214';
    const isLimitedTier = userCourseAccess?.tier_id === freeTrialTierId || userCourseAccess?.tier_id === mentorOnlyTierId;
  
    const { data, error } = await supabase
      .from('lessons')
      .select(`
        *,
        notes:notes(*),
        user_lesson_responses!left(is_completed, confidence_level),
        lesson_categories!inner(category_number)
      `)
      .eq('course_id', courseId)
      .eq('user_lesson_responses.user_id', userId)
      .order('lesson_categories(category_number)', { ascending: true })
      .order('sequence_number', { ascending: true });
  
    if (error) throw error;
  
    return data.map(lesson => ({
      ...lesson,
      note: lesson.notes[0] || null,
      is_completed: lesson.user_lesson_responses[0]?.is_completed || false,
      confidence_level: lesson.user_lesson_responses[0]?.confidence_level || null,
      content: isLimitedTier && !lesson.free_trial_content ? null : lesson.content
    }));
  },

  // Update lesson status
// Update the function name to plural to indicate it returns multiple responses
async getUserLessonResponse(userId: string, courseId: string): Promise<UserLessonResponse[]> {
  // Use courseId instead of lessonId to fetch all responses for the course
  const { data, error } = await supabase
    .from('user_lesson_responses')
    .select('*')
    .eq('user_id', userId)
    .eq('course_id', courseId);

  if (error) {
    console.error('Error fetching user lesson responses:', error);
    throw error;
  }

  // Return an empty array if no data is found
  return data || [];
},

  async updateLessonStatus(userId: string, courseId: string, lessonId: string, isCompleted: boolean, confidenceLevel: string | null): Promise<void> {
    const { error } = await supabase
      .from('user_lesson_responses')
      .upsert({
        user_id: userId,
        course_id: courseId,
        lesson_id: lessonId,
        is_completed: isCompleted,
        confidence_level: confidenceLevel,
        last_interaction_at: new Date().toISOString(),
      }, {
        onConflict: 'user_id,lesson_id'
      });

    if (error) {
      console.error('Error updating lesson status:', error);
      throw error;
    }
  },

  async getNoteForLesson(userId: string, courseId: string, lessonId: string): Promise<Note | null> {
    try {
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .eq('lesson_id', lessonId)
        .order('created_at', { ascending: false })
        .limit(1);
  
      if (error) throw error;
  
      // Return the first note if it exists, otherwise return null
      return data.length > 0 ? data[0] : null;
    } catch (error) {
      console.error('Error fetching note for lesson:', error);
      throw error;
    }
  },

  //end lesson functions

  // Exam methods
  async getExams(courseId: string): Promise<Exam[]> {
    const { data, error } = await supabase
      .from('exams')
      .select('*')
      .eq('course_id', courseId);
    if (error) throw error;
    return data;
  },

  // Exam Section methods
  async getExamSections(examId: string): Promise<ExamSection[]> {
    const { data, error } = await supabase
      .from('exam_sections')
      .select('*')
      .eq('exam_id', examId);
    if (error) throw error;
    return data;
  },

  // Study Task methods
  async getStudyTasks(courseId: string): Promise<StudyTask[]> {
    const { data, error } = await supabase
      .from('study_tasks')
      .select('*')
      .eq('course_id', courseId);
    if (error) throw error;
    return data;
  },

  // Study Task Group methods
  async getStudyTaskGroups(courseId: string): Promise<StudyTaskGroup[]> {
    const { data, error } = await supabase
      .from('study_task_groups')
      .select('*')
      .eq('course_id', courseId);
    if (error) throw error;
    return data;
  },

//TBS SIMULATIONS START

 // Simulation Category methods (unchanged)
 async getSimulationCategories(courseId: string): Promise<SimulationCategory[]> {
  const { data, error } = await supabase
    .from('simulation_categories')
    .select('*')
    .eq('course_id', courseId);
  if (error) throw error;
  return data;
},

// Updated Simulation methods
async getSimulations(courseId: string): Promise<Simulation[]> {
  const { data, error } = await supabase
    .from('simulations')
    .select('*')  // Select all fields to match the Simulation interface
    .eq('course_id', courseId);
  if (error) throw error;
  return data;
},

async getSimulation(simulationId: string): Promise<Simulation> {
  const { data, error } = await supabase
    .from('simulations')
    .select('*')
    .eq('id', simulationId)
    .single();
  if (error) throw error;
  return data;
},

  // ADDED: New method to reset simulations
  async resetSimulations(userId: string, courseId: string): Promise<void> {
    try {
      const { error } = await supabase.rpc('reset_simulations', { p_user_id: userId, p_course_id: courseId });
      if (error) throw error;
    } catch (error) {
      console.error('Error resetting simulations:', error);
      throw error;
    }
  },

  // Get user's response for a specific simulation
  async getUserTBSResponse(userId: string, simulationId: string): Promise<UserTBSResponse | null> {
    try {
      const { data, error } = await supabase
        .from('user_tbs_responses')
        .select('*')
        .eq('user_id', userId)
        .eq('simulation_id', simulationId)
        .maybeSingle();
  
      if (error) {
        console.error('Error fetching user TBS response:', error);
        return null;
      }
  
      if (!data) {
        console.log('No existing TBS response found for this user and simulation.');
        return null;
      }
  
      return {
        ...data,
        answers: data.answers || {},
        feedback: data.feedback || [],
        question_results: data.question_results || []
      };
    } catch (error) {
      console.error('Error in getUserTBSResponse:', error);
      return null;
    }
  },

  // Helper function to format numbers with commas
  formatNumberWithCommas(value: string | number): string {
    const num = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(num)) return value.toString();
    if (Math.abs(num) >= 1000) {
      return num.toLocaleString('en-US', { maximumFractionDigits: 2 });
    }
    return num.toString();
  },

  // Helper function to recursively format numbers in an object
  formatObjectNumbers(obj: Record<string, any>): Record<string, any> {
    const formatted: Record<string, any> = {};
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object' && value !== null) {
        formatted[key] = this.formatObjectNumbers(value);
      } else if (typeof value === 'number' || (typeof value === 'string' && !isNaN(Number(value)))) {
        formatted[key] = this.formatNumberWithCommas(value);
      } else {
        formatted[key] = value;
      }
    }
    return formatted;
  },

  // This function will be called when the user submits their answers
  async submitUserTBSResponse(userId: string, simulationId: string, answers: Record<string, any>, confidenceLevel: 'guessing' | 'maybe' | 'confident'): Promise<UserTBSResponse | null> {
    try {
      // Format the answers with commas for numbers
      const formattedAnswers = this.formatObjectNumbers(answers);
      console.log('Submitting user TBS response:', { userId, simulationId, formattedAnswers });
  
      // Fetch the simulation to get the correct answers
      const { data: simulation, error: simulationError } = await supabase
        .from('simulations')
        .select('correct_answer')
        .eq('id', simulationId)
        .single();
  
      if (simulationError) {
        console.error('Error fetching simulation:', simulationError);
        return null;
      }
  
      console.log('Raw simulation data:', simulation);
      console.log('Correct answers:', simulation.correct_answer);
  
      const { data: gradingResult, error: gradingError } = await supabase.rpc('grade_tbs', {
        p_user_id: userId,
        p_simulation_id: simulationId,
        p_user_answers: formattedAnswers,
        p_correct_answers: simulation.correct_answer
      });
  
      if (gradingError) {
        console.error('Error grading TBS simulation:', gradingError);
        return null;
      }

    console.log('Graded TBS response:', gradingResult);

    if (gradingResult && Array.isArray(gradingResult) && gradingResult.length > 0) {
      const [result] = gradingResult;
      
      const feedbackArray = result.feedback_array || result.feedback || [];
      
      console.log('Feedback array:', feedbackArray);

      const processedFeedback = feedbackArray
        .filter((item: FeedbackItem) => item.questionId !== null && item.userAnswer !== null)
        .map((item: FeedbackItem) => {
          console.log('Processing feedback item:', item);
          
          const answerFeedback: Record<string, AnswerFeedback> = {};
          let itemScore = 0;
          const maxScore = Object.keys(item.userAnswer || {}).length; // Dynamic maxScore

          Object.entries(item.userAnswer || {}).forEach(([key, userValue]) => {
            const correctValue = item.correctAnswer[key];
            const isCorrect = String(userValue).toLowerCase() === String(correctValue).toLowerCase();
            
            answerFeedback[key] = {
              isCorrect: isCorrect,
              userAnswer: userValue,
              correctAnswer: correctValue
            };

            if (isCorrect) {
              itemScore += 1;
            }
          });
  
        // Calculate the score percentage for this item
        const scorePercentage = maxScore > 0 ? (itemScore / maxScore) * 100 : 0;

        // Determine the explanation based on the score
        let explanation;
        if (itemScore === maxScore) {
          explanation = 'Correct! All parts are correct.';
        } else if (itemScore === 0) {
          explanation = 'Incorrect. Please review all parts.';
        } else {
          explanation = 'Partially correct. Please review the incorrect parts.';
        }

        return {
          ...item,
          score: itemScore,
          maxScore: maxScore,
          scorePercentage: scorePercentage,
          explanation: explanation,
          answerFeedback
        };
      });

      // Calculate the overall score with explicit type annotations
      const overallScore = processedFeedback.reduce((sum: number, item: FeedbackItem) => sum + item.scorePercentage, 0) / processedFeedback.length;

      const userTBSResponse: Omit<UserTBSResponse, 'id'> = {
        user_id: userId,
        simulation_id: simulationId,
        answers: formattedAnswers,
        score: overallScore,
        completed: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        feedback: processedFeedback,
        confidence_level: confidenceLevel,        
        question_results: processedFeedback.map((item: FeedbackItem) => ({
          questionId: item.questionId,
          score: item.score,
          maxScore: item.maxScore,
          isCorrect: item.score === item.maxScore
        }))
      };

      console.log('Prepared userTBSResponse:', userTBSResponse);

      const { data: savedResponse, error: saveError } = await supabase
        .from('user_tbs_responses')
        .upsert(userTBSResponse, {
          onConflict: 'user_id,simulation_id'
        })
        .select()
        .single();

      if (saveError) {
        console.error('Error saving graded TBS response:', saveError);
        return null;
      }

      console.log('Saved graded TBS response:', savedResponse);
      return savedResponse;
    }

    console.error('Invalid data structure returned from grade_tbs:', gradingResult);
    return null;
  } catch (error) {
    console.error('Error in submitUserTBSResponse:', error);
    return null;
  }
},


  // Grade TBS simulation
  async gradeSimulation(userId: string, simulationId: string, userAnswers: Record<string, any>): Promise<{
    score: number;
    feedback: any[];
    questionResults: any[];
  } | null> {
    try {
      console.log('Grading simulation with params:', { userId, simulationId, userAnswers });
      
      const { data, error } = await supabase.rpc('grade_tbs', {
        p_simulation_id: simulationId,
        p_user_answers: userAnswers,
        p_user_id: userId
      });
  
      if (error) {
        console.error('Error grading simulation:', error);
        if (error.code === 'PGRST203') {
          console.error('Multiple grade_tbs functions detected. Please remove one from the database.');
        }
        return null;
      }
  
      console.log('Raw data from grade_tbs:', data);
  
      if (data && Array.isArray(data) && data.length > 0) {
        const [result] = data;
        return {
          score: result.score,
          feedback: result.feedback_array,
          questionResults: result.results_array
        };
      }
  
      console.error('Invalid data structure returned from grade_tbs:', data);
      return null;
    } catch (error) {
      console.error('Error in gradeSimulation:', error);
      return null;
    }
  },

  async saveSimulationGradingResults(userId: string, simulationId: string, results: any): Promise<void> {
    const answersToSave = results.userAnswers || {};
    
    const { error } = await supabase
      .from('user_tbs_responses')
      .upsert({
        user_id: userId,
        simulation_id: simulationId,
        answers: answersToSave,
        score: results.score,
        feedback: results.feedback,
        question_results: results.questionResults,
        completed: true,
        updated_at: new Date().toISOString()
      }, {
        onConflict: 'user_id,simulation_id'
      });

    if (error) {
      console.error('Error saving simulation results:', error);
      throw error;
    }
  },

  async getSimulationGradingResults(userId: string, simulationId: string): Promise<any | null> {
    const { data, error } = await supabase
      .from('user_tbs_responses')
      .select('*')
      .eq('user_id', userId)
      .eq('simulation_id', simulationId)
      .single();

    if (error) {
      console.error('Error fetching simulation grading results:', error);
      return null;
    }

    if (data) {
      return {
        score: data.score,
        feedback: data.feedback,
        questionResults: data.question_results,
        userAnswers: data.answers
      };
    }

    return null;
  },

  // Note methods
  async getNotes(
    userId: string,
    courseId: string,
    noteType: string | null = null,
    page: number = 1,
    limit: number = 25
  ): Promise<{ notes: Note[], total: number }> {
    try {
      let query = supabase
        .from('notes')
        .select(`
          *,
          lessons:lessons (
            name,
            lesson_categories:lesson_categories (
              name,
              parent_lesson_category_id
            )
          ),
          questions:questions (
            question_category_name,
            sub_question_category_name
          ),
          mentor_modules:mentor_modules (
            title
          )
        `, { count: 'exact' })
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .order('created_at', { ascending: false })
        .range((page - 1) * limit, page * limit - 1);
  
      if (noteType) {
        switch (noteType) {
          case 'Quiz':
            query = query.not('question_id', 'is', null);
            break;
          case 'Lesson':
            query = query.not('lesson_id', 'is', null);
            break;
          case 'Simulation':
            query = query.not('simulation_id', 'is', null);
            break;
          case 'Mentor':
            query = query.not('module_id', 'is', null);
            break;
          case 'General':
            query = query.is('question_id', null).is('lesson_id', null).is('simulation_id', null).is('module_id', null);
            break;
        }
      }
  
      const { data, error, count } = await query;
  
      if (error) throw error;
  
      const processedNotes = data.map(note => ({
        ...note,
        lesson_name: note.lessons?.name,
        lesson_category: note.lessons?.lesson_categories?.name,
        lesson_subcategory: note.lessons?.lesson_categories?.parent_lesson_category_id ? note.lessons?.lesson_categories?.name : null,
        question_category: note.questions?.question_category_name,
        sub_question_category: note.questions?.sub_question_category_name,
        module_title: note.mentor_modules?.title
      }));
  
      return {
        notes: processedNotes,
        total: count || 0
      };
    } catch (error) {
      console.error('Error fetching notes:', error);
      throw error;
    }
  },

  async createNote(note: Omit<Note, 'id' | 'created_at' | 'updated_at'>): Promise<Note> {
    console.log('createNote function called with:', note);
    try {
      const { data, error } = await supabase
        .from('notes')
        .insert(note)
        .select()
        .single();
      
      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }
      
      if (!data) {
        console.error('No data returned from Supabase');
        throw new Error('Note creation failed: No data returned');
      }
      
      console.log('Note created successfully:', data);
      return data;
    } catch (error) {
      console.error('Error in createNote:', error);
      throw error;
    }
  },

  // Note methods
  async updateNote(
    noteId: string | null, 
    updates: Partial<Note>, 
    userId: string, 
    courseId: string, 
    questionId: string | null, 
    lessonId: string | null,
    moduleId: string | null
  ): Promise<Note> {
    console.log('updateNote called with:', { noteId, updates, userId, courseId, questionId, lessonId, moduleId });
    
    try {
      const noteData = {
        user_id: userId,
        course_id: courseId,
        question_id: questionId,
        lesson_id: lessonId,
        module_id: moduleId,
        ...updates
      };
  
      // Remove undefined or null fields
      Object.keys(noteData).forEach(key => {
        if (noteData[key as keyof Note] === undefined || noteData[key as keyof Note] === null) {
          delete noteData[key as keyof Note];
        }
      });
  
      // Use upsert operation
      const { data, error } = await supabase
        .from('notes')
        .upsert(noteData, { 
          onConflict: 'user_id,course_id,lesson_id',
          ignoreDuplicates: false
        })
        .select()
        .single();
      
      if (error) {
        console.error('Error upserting note:', error);
        throw error;
      }
      
      if (!data) {
        console.error('No data returned when upserting note');
        throw new Error('No data returned when upserting note');
      }
      
      console.log('Note upserted successfully:', data);
      return data;
    } catch (error) {
      console.error('Error in updateNote:', error);
      throw error;
    }
  },

  async deleteNote(noteId: string): Promise<void> {
    const { error } = await supabase
      .from('notes')
      .delete()
      .eq('id', noteId);
    if (error) throw error;
  },

  // User Activity methods
  async logUserActivity(activity: Omit<UserActivity, 'id' | 'created_at'>): Promise<void> {
    const { error } = await supabase
      .from('user_activity')
      .insert(activity);
    if (error) throw error;
  },

  // Mock Exam methods
  async getMockExam(courseId: string): Promise<Exam & { sections: (ExamSection & { questions: Question[] })[] }> {
    const { data, error } = await supabase
      .from('exams')
      .select(`
        *,
        sections:exam_sections (
          *,
          questions:questions (*)
        )
      `)
      .eq('course_id', courseId)
      .eq('full_length', true)
      .single();
    if (error) throw error;
    return data;
  },

  // User Progress methods
  async getUserProgress(userId: string, courseId: string): Promise<UserProgress> {
    const { data, error } = await supabase
      .from('user_progress')
      .select('*')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .single();
    if (error) throw error;
    return data;
  },

  async updateUserProgress(userId: string, courseId: string, progress: Partial<UserProgress>): Promise<void> {
    const { error } = await supabase
      .from('user_progress')
      .upsert({ user_id: userId, course_id: courseId, ...progress });
    if (error) throw error;
  },

  // Analytics methods
  async getUserAnalytics(userId: string, courseId: string): Promise<UserAnalytics> {
    // This is a placeholder. In a real application, you might need to aggregate data from multiple tables.
    const { data, error } = await supabase.rpc('get_user_analytics', { user_id: userId, course_id: courseId });
    if (error) throw error;
    return data;
  },

  async resetCategory(userId: string, courseId: string, categoryId: string): Promise<void> {
    console.log('Resetting category:', categoryId);
  
    const { data: questionIds, error: fetchError } = await supabase
      .from('questions')
      .select('id')
      .eq('question_category_id', categoryId);
  
    if (fetchError) {
      console.error('Error fetching questions:', fetchError);
      throw fetchError;
    }
  
    if (!questionIds || questionIds.length === 0) {
      console.log('No questions found for category:', categoryId);
      return; // No questions to reset
    }
  
    console.log(`Found ${questionIds.length} questions to reset`);
  
    // Delete all user responses for the category
    const { error: deleteError } = await supabase
      .from('user_question_responses')
      .delete()
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .in('question_id', questionIds.map(q => q.id));
  
    if (deleteError) {
      console.error('Error deleting user responses:', deleteError);
      throw deleteError;
    }
  
    console.log('Successfully reset category:', categoryId);
  },

  // Search method
  async searchContent(courseId: string, query: string): Promise<Array<Question | Flashcard | Lesson | Simulation>> {
    const { data, error } = await supabase.rpc('search_course_content', { course_id: courseId, search_query: query });
    if (error) throw error;
    return data;
  },

  // User Course Progress
  async getUserCourseProgress(userId: string, courseId: string): Promise<{
    completedLessons: number;
    totalLessons: number;
    quizScore: number;
    flashcardProgress: number;
  }> {
    const { data, error } = await supabase.rpc('get_user_course_progress', { user_id: userId, course_id: courseId });
    if (error) throw error;
    return data;
  },

  // Leaderboard
  async getCourseLeaderboard(courseId: string, limit: number = 10): Promise<Array<{ user_id: string; score: number }>> {
    const { data, error } = await supabase.rpc('get_course_leaderboard', { course_id: courseId, leaderboard_limit: limit });
    if (error) throw error;
    return data;
  },

  // Study Plan
  async generateStudyPlan(userId: string, courseId: string): Promise<StudyPlan> {
    const { data, error } = await supabase.rpc('generate_study_plan', { user_id: userId, course_id: courseId });
    if (error) throw error;
    return data;
  },

  // Performance Review
  async getPerformanceReview(userId: string, courseId: string): Promise<PerformanceReview> {
    const { data, error } = await supabase.rpc('get_performance_review', { user_id: userId, course_id: courseId });
    if (error) throw error;
    return data;
  },
  




// STUDY PLANNER FUNCTIONS

async getCPACourses(courseId: string): Promise<SPCourse[]> {
  const { data, error } = await supabase
    .from('sp_courses')
    .select('*')
    .eq('course_id', courseId);
  if (error) throw error;
  return data;
},

  async getTopics(CPACourseId: string): Promise<SPTopic[]> {
    const { data, error } = await supabase
      .from('sp_topics')
      .select('*')
      .eq('cpa_course_id', CPACourseId)
      .order('sequence_number');
    if (error) throw error;
    return data;
  },

  async getTopicMappings(mappingIds: string[]): Promise<SPTopicMapping[]> {
    const { data, error } = await supabase
      .from('sp_topicmappings')
      .select('*')
      .in('mapping_id', mappingIds);
    if (error) throw error;
    return data;
  },

  async createStudyType(userId: string, courseId: string, studyType: 'brand_new' | 'retake'): Promise<SPStudyType> {
    const { data, error } = await supabase
      .from('sp_studytype')
      .upsert({ 
        user_id: userId, 
        course_id: courseId, 
        study_type: studyType 
      }, { 
        onConflict: 'user_id,course_id' 
      })
      .select()
      .single();
  
    if (error) throw error;
    if (!data) throw new Error('Failed to create or update study type');
    return data;
  },

  async createCourseMix(
    userId: string, 
    courseId: string, 
    primaryCPACourseId: string | null,
    secondaryCPACourseId: string | null,
    alias: string | null
  ): Promise<SPCourseMix> {
    const { data, error } = await supabase
      .from('sp_course_mix')
      .upsert(
        {
          user_id: userId,
          course_id: courseId,
          primary_cpa_course_id: primaryCPACourseId,
          secondary_cpa_course_id: secondaryCPACourseId,
          alias: alias
        },
        {
          onConflict: 'user_id,course_id',
          ignoreDuplicates: false
        }
      )
      .select()
      .single();
  
    if (error) {
      console.error('Error in createCourseMix:', error);
      throw error;
    }
  
    if (!data) {
      throw new Error('Failed to create or update course mix');
    }
  
    return data as SPCourseMix;
  },

  async getCourseMix(userId: string, courseId: string): Promise<SPCourseMix | null> {
    const { data, error } = await supabase
      .from('sp_course_mix')
      .select('*')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .single();
  
    if (error) {
      if (error.code === 'PGRST116') {
        // No matching row found
        return null;
      }
      console.error('Error in getCourseMix:', error);
      throw error;
    }
  
    return data as SPCourseMix;
  },

    // Replace the calculateExamDate function with this new function
    async calculateStudyPlanAndExamDate(
      userId: string,
      courseId: string,
      startDate: string,
      studyType: 'brand_new' | 'retake',
      weeklyHours: '15' | '20' | '25' | '30',
      courseMixId: string,
      studyTypeId: string
    ): Promise<{ calculatedExamDate: string; totalStudyHours: number; totalWeeks: number }> {
      try {
        console.log('Calling Supabase function with:', {
          userId,
          courseId,
          startDate,
          studyType,
          weeklyHours,
          courseMixId,
          studyTypeId
        });
    
        // Remove studyDays parameter from the RPC call
        const { data, error } = await supabase.rpc('calculate_study_plan_and_schedule', {
          p_user_id: userId,
          p_course_id: courseId,
          p_start_date: startDate,
          p_study_type: studyType,
          p_weekly_hours: weeklyHours,
          p_course_mix_id: courseMixId,
          p_study_type_id: studyTypeId
        });
    
        if (error) {
          console.error('Supabase function error:', error);
          throw error;
        }
    
        console.log('Supabase function result:', data);
    
        return {
          calculatedExamDate: data.calculated_exam_date,
          totalStudyHours: data.total_study_hours,
          totalWeeks: data.total_weeks
        };
      } catch (error) {
        console.error('Error in calculateStudyPlanAndExamDate:', error);
        throw error;
      }
    },

  // Update the setExamDate function
  async setExamDate(
    userId: string, 
    courseId: string, 
    startDate: string, 
    examDate: string
  ): Promise<string> {
    try {
      const { data, error } = await supabase
        .from('sp_examdates')
        .upsert({ 
          user_id: userId, 
          course_id: courseId, 
          exam_date: examDate,
          start_date: startDate
        }, {
          onConflict: 'user_id,course_id'
        })
        .select()
        .single();
      if (error) throw error;
      if (!data) throw new Error('Failed to set exam date');
      return data.exam_date_id;
    } catch (error) {
      console.error('Error in setExamDate:', error);
      throw error;
    }
  },

  async setStudyDays(userId: string, courseId: string, studyDays: Omit<SPStudyDays, 'study_days_id' | 'user_id' | 'course_id'>): Promise<SPStudyDays> {
    const { data, error } = await supabase
      .from('sp_studydays')
      .upsert({ user_id: userId, course_id: courseId, ...studyDays })
      .single();
    if (error) throw error;
    return data;
  },

  // Update the createUserStudyPlan function
  async createUserStudyPlan(
    userId: string, 
    courseId: string, 
    courseMixId: string, 
    studyTypeId: string, 
    recommendedWeeklyHours: '15' | '20' | '25' | '30'
  ): Promise<SPUserStudyPlan> {
    const { data, error } = await supabase
      .from('sp_userstudyplans')
      .upsert({
        user_id: userId,
        course_id: courseId,
        course_mix_id: courseMixId,
        study_type_id: studyTypeId,
        recommended_weekly_hours: recommendedWeeklyHours,
      }, {
        onConflict: 'user_id,course_id'
      })
      .select()
      .single();

    if (error) throw error;
    if (!data) throw new Error('Failed to create user study plan');
    return data;
  },

  async getUserStudyPlan(userId: string, courseId: string): Promise<SPUserStudyPlan | null> {
    try {
      console.log('Fetching user study plan:', { userId, courseId });
  
      const { data, error } = await supabase
        .from('sp_userstudyplans')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .single();
  
      if (error) {
        if (error.code === 'PGRST116') {
          // No matching row found
          console.log('No study plan found for user and course:', { userId, courseId });
          return null;
        }
        console.error('Error in getUserStudyPlan:', error);
        throw error;
      }
  
      if (!data) {
        console.log('No study plan data returned for user and course:', { userId, courseId });
        return null;
      }
  
      console.log('Study plan fetched successfully:', data);
      return data as SPUserStudyPlan;
    } catch (error) {
      console.error('Unexpected error in getUserStudyPlan:', error);
      return null;
    }
  },

  async createStudySchedule(planId: string, examDateId: string, startDate: string, endDate: string): Promise<void> {
    const { error } = await supabase
      .from('sp_studyschedule')
      .insert({
        plan_id: planId,
        exam_date_id: examDateId,
        start_date: startDate,
        end_date: endDate,
        is_current: true
      });

    if (error) throw error;
  },

  async getStudySchedule(planId: string): Promise<SPStudySchedule | null> {
    const { data, error } = await supabase
      .from('sp_studyschedule')
      .select('*')
      .eq('plan_id', planId)
      .eq('is_current', true)
      .single();

    if (error) {
      console.error('Error fetching study schedule:', error);
      return null;
    }

    return data;
  },

  async createStudyPlanItems(planId: string, courseId: string, userId: string): Promise<void> {
    try { 
      console.log('Creating study plan items for plan:', planId, 'course:', courseId, 'and user:', userId);
      
      const { data, error } = await supabase.rpc('insert_study_plan_items', {
        p_plan_id: planId,
        p_course_id: courseId,
        p_user_id: userId
      });
  
      if (error) {
        console.error('Error inserting study plan items:', error);
        throw error;
      }
  
      // Check if any items were inserted
      const { count, error: countError } = await supabase
        .from('sp_studyplanitems')
        .select('*', { count: 'exact', head: true })
        .eq('plan_id', planId);
  
      if (countError) {
        console.error('Error counting study plan items:', countError);
      } else {
        console.log(`Number of study plan items: ${count}`);
      }
  
      console.log('Study plan items process completed');
    } catch (error) {
      console.error('Error in createStudyPlanItems:', error);
      throw error;
    }
  },

  
  async getOrderedTopicMappings(
    courseId: string, 
    primaryCPACourseId: string, 
    secondaryCPACourseId: string | null,
    isRetake: boolean,
    isPrimaryBlueprintBased: boolean
  ): Promise<SPTopicMapping[]> {
    console.log('Starting getOrderedTopicMappings with params:', { courseId, primaryCPACourseId, secondaryCPACourseId, isRetake, isPrimaryBlueprintBased });
  
    const getPrimaryTopicColumn = (id: string) => {
      if (id.includes('kesler')) return 'kesler_topic_id';
      if (id.includes('becker')) return 'becker_topic_id';
      if (id.includes('gleim')) return 'gleim_topic_id';
      return 'blueprint_topic_id';
    };
  
    const primaryTopicColumn = getPrimaryTopicColumn(primaryCPACourseId);
    const secondaryTopicColumn = secondaryCPACourseId ? getPrimaryTopicColumn(secondaryCPACourseId) : null;
  
    console.log('Constructed topic columns:', { primaryTopicColumn, secondaryTopicColumn });
  
    let query = supabase
      .from('sp_topicmappings')
      .select(`
        *,
        blueprint_topic:sp_topics!fk_blueprint_topic_id (
          topic_id, cpa_course_id, course_provider, title, sequence_number, is_blueprint, estimated_minutes
        ),
        primary_topic:sp_topics!fk_${primaryTopicColumn} (
          topic_id, cpa_course_id, course_provider, title, sequence_number, is_blueprint, estimated_minutes
        )
        ${secondaryTopicColumn ? `, secondary_topic:sp_topics!fk_${secondaryTopicColumn} (
          topic_id, cpa_course_id, course_provider, title, sequence_number, is_blueprint, estimated_minutes
        )` : ''}
      `)
      .eq('course_id', courseId);
  
    console.log('Constructed query:', query.toString());
  
    const { data, error } = await query;
  
    if (error) {
      console.error('Error in getOrderedTopicMappings query:', error);
      throw error;
    }
  
    console.log('Raw query result:', data);

    // Type guard function
    function isValidTopicMapping(item: any): item is SPTopicMapping {
      return (
        item &&
        typeof item.mapping_id === 'string' &&
        typeof item.course_id === 'string' &&
        typeof item.blueprint_topic_id === 'string' &&
        (item.kesler_topic_id === null || typeof item.kesler_topic_id === 'string') &&
        (item.becker_topic_id === null || typeof item.becker_topic_id === 'string') &&
        (item.gleim_topic_id === null || typeof item.gleim_topic_id === 'string') &&
        (item.question_category_id === null || typeof item.question_category_id === 'string') &&
        (item.flashcard_category_id === null || typeof item.flashcard_category_id === 'string') &&
        typeof item.task_name === 'string' &&
        (item.lesson_id === null || typeof item.lesson_id === 'string') &&
        isValidTopic(item.blueprint_topic) &&
        isValidTopic(item.primary_topic) &&
        (item.secondary_topic === undefined || isValidTopic(item.secondary_topic))
      );
    }

    // Type guard for SPTopic
    function isValidTopic(topic: any): topic is SPTopic {
      return (
        topic &&
        typeof topic.topic_id === 'string' &&
        typeof topic.cpa_course_id === 'string' &&
        typeof topic.course_provider === 'string' &&
        typeof topic.title === 'string' &&
        typeof topic.sequence_number === 'number' &&
        typeof topic.is_blueprint === 'boolean' &&
        typeof topic.estimated_minutes === 'number'
      );
    }

    // Filter out invalid mappings and sort
    const validMappings = (data as any[]).filter(isValidTopicMapping);
    console.log('Valid mappings count:', validMappings.length);
  
    const sortedMappings = validMappings.sort((a, b) => {
      const aSeq = isRetake || isPrimaryBlueprintBased
        ? a.blueprint_topic.sequence_number
        : a.primary_topic.sequence_number;
      const bSeq = isRetake || isPrimaryBlueprintBased
        ? b.blueprint_topic.sequence_number
        : b.primary_topic.sequence_number;
      return aSeq - bSeq;
    });
  
    console.log('Sorted mappings (first 3):', sortedMappings.slice(0, 3));
  
    return sortedMappings;
  },
  
  mergeTopics(blueprintTopics: any[], primaryTopics: any[], secondaryTopics: any[]): TopicMappingResult[] {
    const mergedMap = new Map<string, TopicMappingResult>();
  
    const processTopics = (topics: any[], topicType: 'blueprint_topics' | 'primary_topics' | 'secondary_topics') => {
      topics.forEach(topic => {
        const existingTopic = mergedMap.get(topic.mapping_id);
        if (existingTopic) {
          existingTopic[topicType] = Array.isArray(topic.sp_topics) ? topic.sp_topics : [topic.sp_topics];
        } else {
          mergedMap.set(topic.mapping_id, {
            mapping_id: topic.mapping_id,
            task_name: topic.task_name,
            blueprint_topics: [],
            primary_topics: [],
            secondary_topics: [],
            [topicType]: Array.isArray(topic.sp_topics) ? topic.sp_topics : [topic.sp_topics]
          });
        }
      });
    };
  
    processTopics(blueprintTopics, 'blueprint_topics');
    processTopics(primaryTopics, 'primary_topics');
    processTopics(secondaryTopics, 'secondary_topics');
  
    return Array.from(mergedMap.values());
  },
  
  orderTopics(topics: TopicMappingResult[]): TopicMappingResult[] {
    return topics.sort((a, b) => {
      const aSeq = a.blueprint_topics[0]?.sequence_number ?? 
                   a.primary_topics[0]?.sequence_number ?? 
                   a.secondary_topics[0]?.sequence_number ?? 0;
      const bSeq = b.blueprint_topics[0]?.sequence_number ?? 
                   b.primary_topics[0]?.sequence_number ?? 
                   b.secondary_topics[0]?.sequence_number ?? 0;
      return aSeq - bSeq;
    });
  },

  async getStudyPlanItems(planId: string): Promise<SPStudyPlanItem[]> {
    try {
      const { data: items, error } = await supabase
        .from('sp_studyplanitems')
        .select(`
          *,
          sp_topicmappings!inner (
            task_name,
            blueprint_topic:sp_topics!fk_blueprint_topic_id (
              topic_id, title, sequence_number, topic_type
            ),
            kesler_topic:sp_topics!fk_kesler_topic_id (
              topic_id, title, sequence_number, topic_type
            ),
            becker_topic:sp_topics!fk_becker_topic_id (
              topic_id, title, sequence_number, topic_type
            ),
            gleim_topic:sp_topics!fk_gleim_topic_id (
              topic_id, title, sequence_number, topic_type
            )
          ),
          sp_userstudyplans!inner (
            user_id,
            course_id,
            course_mix:sp_course_mix!inner (
              primary_cpa_course_id,
              secondary_cpa_course_id,
              alias,
              primary_course:sp_courses!sp_course_mix_primary_cpa_course_id_fkey (
                is_blueprint_based
              ),
              secondary_course:sp_courses!sp_course_mix_secondary_cpa_course_id_fkey (
                is_blueprint_based
              )
            )
          )
        `)
        .eq('plan_id', planId)
        .order('sequence_number', { ascending: true });
  
      if (error) throw error;
  
      console.log('Fetched study plan items:', items);
  
      if (items.length === 0) {
        throw new Error('No study plan items found');
      }
  
      const userId = items[0].sp_userstudyplans.user_id;
      const courseId = items[0].sp_userstudyplans.course_id;
  
      // Fetch start date
      const { data: examDate, error: examDateError } = await supabase
        .from('sp_examdates')
        .select('start_date')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .single();
  
      if (examDateError) throw examDateError;
  
      if (!examDate || !examDate.start_date) {
        throw new Error('No start date found for the study plan');
      }
  
      const startDate = new Date(examDate.start_date);
      startDate.setHours(0, 0, 0, 0);
  
      // Fetch scheduled dates
      const { data: scheduledDates, error: scheduleError } = await supabase
        .rpc('fetch_study_plan_schedule', { p_plan_id: planId });
  
      if (scheduleError) throw scheduleError;
  
      // Create a map of item_id to scheduled date and week number
      const dateMap = new Map(
        (scheduledDates as ScheduledDateItem[]).map((item) => [
          item.item_id,
          { 
            planned_date: item.planned_date, 
            week_number: item.week_number, 
            estimated_minutes: item.estimated_minutes,
            primary_sequence: item.primary_sequence,
            is_primary_blueprint: item.is_primary_blueprint,
            is_secondary_blueprint: item.is_secondary_blueprint
          }
        ])
      );
  
      const today = new Date();
      today.setHours(0, 0, 0, 0);
  
      // Fetch course information for primary and secondary courses
      const courseMix = items[0].sp_userstudyplans.course_mix;
      const courseIds = [courseMix.primary_cpa_course_id];
      if (courseMix.secondary_cpa_course_id) {
        courseIds.push(courseMix.secondary_cpa_course_id);
      }
  
      // Filter out any null values from courseIds
      const validCourseIds = courseIds.filter(id => id !== null);
  
      const { data: courses, error: coursesError } = await supabase
        .from('sp_courses')
        .select('cpa_course_id, name')
        .in('cpa_course_id', validCourseIds);
  
      if (coursesError) throw coursesError;
  
      const courseProviders: { [key: string]: { name: string; isBlueprint: boolean } } = (courses as SPCourse[]).reduce((acc, course) => {
        acc[course.cpa_course_id] = {
          name: this.getCourseProvider(course.name),
          isBlueprint: course.is_blueprint_based
        };
        return acc;
      }, {} as { [key: string]: { name: string; isBlueprint: boolean } });
  
      return items.map((item: any) => {
        const courseMix = item.sp_userstudyplans.course_mix;
        const topicMappings = item.sp_topicmappings;
  
        const isPrimaryBlueprint = courseMix.primary_course.is_blueprint_based;
        const isSecondaryBlueprint = courseMix.secondary_course?.is_blueprint_based || false;
  
        const primaryProvider = courseProviders[courseMix.primary_cpa_course_id];
        const secondaryProvider = courseMix.secondary_cpa_course_id ? courseProviders[courseMix.secondary_cpa_course_id] : null;
  
        const primaryTopic = topicMappings[`${primaryProvider.name}_topic`] || topicMappings.blueprint_topic;
        const secondaryTopic = secondaryProvider ? topicMappings[`${secondaryProvider.name}_topic`] : null;
  
        const scheduledItem = dateMap.get(item.item_id);
        let formattedDate: string | null = null;
  
        if (scheduledItem) {
          const dateObj = new Date(scheduledItem.planned_date);
          if (!isNaN(dateObj.getTime())) {
            dateObj.setHours(0, 0, 0, 0);
            if (dateObj.getTime() === today.getTime()) {
              formattedDate = 'Today';
            } else {
              formattedDate = `Week ${scheduledItem.week_number}`;
            }
          }
        }
  
        return {
          item_id: item.item_id,
          plan_id: item.plan_id,
          mapping_id: item.mapping_id,
          is_completed: item.is_completed,
          sequence_number: item.sequence_number,
          task_name: item.task_name || topicMappings.task_name,
          blueprint_topic_title: topicMappings.blueprint_topic?.title || 'N/A',
          blueprint_topic_sequence: topicMappings.blueprint_topic?.sequence_number || 0,
          primary_topic_title: primaryTopic?.title || 'N/A',
          primary_topic_sequence: primaryTopic?.sequence_number || 0,
          secondary_topic_title: secondaryTopic?.title || null,
          secondary_topic_sequence: secondaryTopic?.sequence_number || null,
          primary_provider: primaryProvider.name,
          secondary_provider: secondaryProvider ? secondaryProvider.name : null,
          primary_cpa_course_id: courseMix.primary_cpa_course_id,
          secondary_cpa_course_id: courseMix.secondary_cpa_course_id || null,
          planned_date: formattedDate,
          estimated_minutes: scheduledItem ? scheduledItem.estimated_minutes : null,
          primary_sequence: scheduledItem ? scheduledItem.primary_sequence : primaryTopic?.sequence_number || 0,
          is_primary_blueprint: isPrimaryBlueprint,
          is_secondary_blueprint: isSecondaryBlueprint,
          primary_alias: isPrimaryBlueprint ? courseMix.alias : null,
          secondary_alias: isSecondaryBlueprint ? courseMix.alias : null,
          topic_type: primaryTopic?.topic_type || topicMappings.blueprint_topic?.topic_type || null,
        };
      });
    } catch (error) {
      console.error('Error in getStudyPlanItems:', error);
      throw error;
    }
  },
  
  // Helper function to determine course provider
  getCourseProvider(courseName: string): string {
    const lowerCaseName = courseName.toLowerCase();
    if (lowerCaseName.includes('kesler')) return 'kesler';
    if (lowerCaseName.includes('becker')) return 'becker';
    if (lowerCaseName.includes('gleim')) return 'gleim';
    return 'blueprint';
  },

  async updateRecommendedWeeklyHours(userId: string, courseId: string, hours: '15' | '20' | '25'): Promise<void> {
    const { error } = await supabase
      .from('sp_userstudyplans')
      .update({ recommended_weekly_hours: hours })
      .eq('user_id', userId)
      .eq('course_id', courseId);
  
    if (error) throw error;
  },

  async getExamDate(userId: string, courseId: string): Promise<{ exam_date: string, start_date: string } | null> {
    const { data, error } = await supabase
      .from('sp_examdates')
      .select('exam_date, start_date')
      .eq('user_id', userId)
      .eq('course_id', courseId)
      .single();
  
    if (error) {
      console.error('Error fetching exam date:', error);
      return null;
    }
  
    return data;
  },
  
  async getStudyPlanItemSchedule(scheduleId: string): Promise<SPStudyPlanItemSchedule[]> {
    const { data, error } = await supabase
      .from('sp_studyplanitemschedule')
      .select(`
        *,
        sp_studyplanitems!inner (
          sp_topicmappings!inner (
            task_name,
            sp_topics!inner (title)
          )
        )
      `)
      .eq('schedule_id', scheduleId)
      .order('planned_date');
    
    if (error) throw error;
    return data.map(item => ({
      ...item,
      task_name: item.sp_studyplanitems.sp_topicmappings.task_name,
      topic_title: item.sp_studyplanitems.sp_topicmappings.sp_topics.title
    }));
  },

  // Update the getConfidenceTrackerData method
  async getConfidenceTrackerData(userId: string, courseId: string): Promise<ConfidenceTrackerData> {
    const { data, error } = await supabase.rpc('get_confidence_tracker_data', {
      p_user_id: userId,
      p_course_id: courseId
    });

    if (error) throw error;
    return data;
  },

  // Add a new method to get study tasks data
  async getStudyTasksProgress(planId: string): Promise<{ completed: number; total: number }> {
    const { data, error } = await supabase
      .from('sp_studyplanitems')
      .select('is_completed')
      .eq('plan_id', planId);
  
    if (error) {
      console.error('Error fetching study tasks progress:', error);
      throw error;
    }
  
    const total = data.length;
    const completed = data.filter(item => item.is_completed).length;
  
    return { completed, total };
  },

  async updateStudyPlanItemCompletion(itemId: string, isCompleted: boolean, completionDate: string | null): Promise<void> {
    const { error } = await supabase
      .from('sp_studyplanitemschedule')
      .update({ is_completed: isCompleted, completion_date: completionDate })
      .eq('item_id', itemId);
    if (error) throw error;
  },
  async getStudyTaskDetails(userId: string, courseId: string, itemId: string): Promise<StudyTaskDetails> {
    console.log('API: getStudyTaskDetails called with:', { userId, courseId, itemId });
    try {
      console.log('API: Making RPC call to get_study_task_details');
      const { data, error } = await supabase.rpc('get_study_task_details', {
        p_user_id: userId,
        p_course_id: courseId,
        p_item_id: itemId
      });
  
      console.log('API: RPC call completed. Result:', { data, error });
  
      if (error) {
        console.error('API: Supabase RPC error:', error);
        throw error;
      }
  
      if (!data || data.length === 0) {
        console.warn('API: No task details returned from RPC call');
        throw new Error('No task details found');
      }
  
      const taskDetails = data[0];
      console.log('API: Returning task details:', taskDetails);
      return taskDetails;
    } catch (error) {
      console.error('API: Error in getStudyTaskDetails:', error);
      throw error;
    }
  },

  // Add these new functions to your api object
async getStudyType(userId: string, courseId: string): Promise<SPStudyType> {
  const { data, error } = await supabase
    .from('sp_studytype')
    .select('*')
    .eq('user_id', userId)
    .eq('course_id', courseId)
    .single();

  if (error) throw error;
  if (!data) throw new Error('Study type not found');
  return data;
},

async getNextIncompleteTask(planId: string): Promise<SPStudyPlanItem | null> {
  try {
    console.log('Fetching next incomplete task for plan:', planId);
    
    const { data, error } = await supabase
      .from('sp_studyplanitems')
      .select(`
        *,
        sp_topicmappings!inner (
          task_name
        )
      `)
      .eq('plan_id', planId)
      .eq('is_completed', false)
      .order('sequence_number', { ascending: true })
      .limit(1)
      .single();

    if (error) {
      if (error.code === 'PGRST116') {
        console.log('No incomplete tasks found for plan:', planId);
        return null;
      }
      console.error('Error fetching next incomplete task:', error);
      throw error;
    }

    if (!data) {
      console.log('No data returned for next incomplete task');
      return null;
    }

    const nextTask: SPStudyPlanItem = {
      item_id: data.item_id,
      plan_id: data.plan_id,
      mapping_id: data.mapping_id,
      is_completed: data.is_completed,
      sequence_number: data.sequence_number,
      task_name: data.sp_topicmappings.task_name,
      primary_topic_title: data.primary_topic_title || '',
      secondary_topic_title: data.secondary_topic_title || null,
      blueprint_topic_title: data.blueprint_topic_title || '',
      primary_sequence: data.sequence_number,
      blueprint_topic_sequence: 0, // Set to 0 as it's not in the table
      primary_topic_sequence: data.sequence_number, // Use sequence_number for primary_topic_sequence
      secondary_topic_sequence: null, // Set to null as it's not in the table
      primary_provider: '', // Set to empty string as it's not in the table
      secondary_provider: null, // Set to null as it's not in the table
      primary_cpa_course_id: '', // Set to empty string as it's not in the table
      secondary_cpa_course_id: null, // Set to null as it's not in the table
      planned_date: null, // Set to null as it's not in the table
      estimated_minutes: null, // Set to null as it's not in the table
      is_primary_blueprint: false, // Set to false as it's not in the table
      is_secondary_blueprint: false, // Set to false as it's not in the table
      primary_alias: null, // Set to null as it's not in the table
      secondary_alias: null, // Set to null as it's not in the table
      topic_type: null,
    };

    console.log('Next incomplete task found:', nextTask);
    return nextTask;
  } catch (error) {
    console.error('Unexpected error in getNextIncompleteTask:', error);
    throw error;
  }
},

async markStudyTaskComplete(itemId: string): Promise<SPStudyPlanItem> {
  console.log('Calling markStudyTaskComplete with itemId:', itemId);
  try {
    console.log('Making RPC call to mark_study_task_complete');
    const { error } = await supabase.rpc('mark_study_task_complete', {
      p_item_id: itemId,
      p_is_complete: true
    });

    if (error) {
      console.error('Supabase RPC error:', error);
      throw new Error(`Failed to mark task as complete: ${error.message}`);
    }

    console.log('Task marked as complete successfully');
    
    // Fetch the updated study task item
    return await this.getStudyTaskItem(itemId);
  } catch (error) {
    console.error('Error in markStudyTaskComplete:', error);
    throw error;
  }
},

async unmarkStudyTaskComplete(itemId: string): Promise<SPStudyPlanItem> {
  console.log('Calling unmarkStudyTaskComplete with itemId:', itemId);
  try {
    console.log('Making RPC call to mark_study_task_complete with is_complete set to false');
    const { error } = await supabase.rpc('mark_study_task_complete', {
      p_item_id: itemId,
      p_is_complete: false
    });

    if (error) {
      console.error('Supabase RPC error:', error);
      throw new Error(`Failed to unmark task as complete: ${error.message}`);
    }

    console.log('Task unmarked as complete successfully');
    
    // Fetch the updated study task item
    return await this.getStudyTaskItem(itemId);
  } catch (error) {
    console.error('Error in unmarkStudyTaskComplete:', error);
    throw error;
  }
},

async getStudyTaskItem(itemId: string): Promise<SPStudyPlanItem> {
  console.log('Fetching study task item:', itemId);
  const { data, error } = await supabase
    .from('sp_studyplanitems')
    .select(`
      *,
      sp_studyplanitemschedule (
        planned_date,
        estimated_minutes,
        is_completed,
        completion_date
      )
    `)
    .eq('item_id', itemId)
    .single();

  if (error) {
    console.error('Error fetching study task item:', error);
    throw error;
  }
  
  if (!data) {
    throw new Error('Study task item not found');
  }
  
  console.log('Study task item fetched:', data);
  return {
    ...data,
    planned_date: data.sp_studyplanitemschedule?.planned_date,
    estimated_minutes: data.sp_studyplanitemschedule?.estimated_minutes,
    is_completed: data.sp_studyplanitemschedule?.is_completed,
    completion_date: data.sp_studyplanitemschedule?.completion_date
  } as SPStudyPlanItem;
},

  async resetStudyPlan(userId: string, courseId: string): Promise<void> {
    try {
      const { error } = await supabase.rpc('reset_user_study_plan', {
        p_user_id: userId,
        p_course_id: courseId
      });

      if (error) {
        console.error('Error resetting study plan:', error);
        throw error;
      }

      console.log('Study plan reset successfully');
    } catch (error) {
      console.error('Error in resetStudyPlan:', error);
      throw error;
    }
  },

// MENTORSHIP FUNCTIONS

async getMentorModules(): Promise<MentorModule[]> {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('No authenticated user');

  const { data: userProducts, error: productsError } = await supabase
    .from('user_course_access')
    .select('course_id')
    .eq('user_id', user.id);

  if (productsError) throw productsError;

  const courseIds = userProducts.map(p => p.course_id);

  const { data, error } = await supabase
    .from('mentor_modules')
    .select(`
      *,
      user_mentor_progress!left(is_completed, notes)
    `)
    .eq('user_mentor_progress.user_id', user.id)
    .order('sequence_number');

  if (error) throw error;

  return data.map((module: any) => {
    let parsedContent;
    try {
      parsedContent = typeof module.content === 'string' ? JSON.parse(module.content) : module.content;
    } catch (e) {
      console.error('Error parsing module content:', e);
      parsedContent = { description: 'Error loading content', blocks: [] };
    }

    return {
      ...module,
      content: parsedContent,
      is_completed: module.user_mentor_progress[0]?.is_completed || false,
      notes: module.user_mentor_progress[0]?.notes || ''
    };
  });
},

  async updateMentorModuleProgress(moduleId: string, isCompleted: boolean): Promise<void> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    const { error } = await supabase
      .from('user_mentor_progress')
      .upsert({ 
        user_id: user.id,
        module_id: moduleId,
        is_completed: isCompleted
      }, {
        onConflict: 'user_id, module_id'
      });

    if (error) throw error;
  },

  async updateMentorModuleNotes(moduleId: string, notes: string): Promise<void> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    const { error } = await supabase
      .from('user_mentor_progress')
      .upsert({ 
        user_id: user.id,
        module_id: moduleId,
        notes: notes
      }, {
        onConflict: 'user_id, module_id'
      });

    if (error) throw error;
  },

  async getNoteForMentor(userId: string, courseId: string, moduleId: string): Promise<Note | null> {
    console.log('Getting note for mentor module:', { userId, courseId, moduleId });
    try {
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', courseId)
        .eq('module_id', moduleId)
        .maybeSingle();
  
      if (error) {
        console.error('Error fetching note for mentor module:', error);
        throw error;
      }
  
      if (data) {
        console.log('Note fetched successfully:', data);
        return data;
      } else {
        console.log('No note found for this mentor module');
        return null;
      }
    } catch (error) {
      console.error('Error in getNoteForMentor:', error);
      throw error;
    }
  },

  // Add this method to the api object
async getMentorshipProgress(userId: string, courseId: string): Promise<ConfidenceTrackerData> {
  const { data, error } = await supabase.rpc('get_confidence_tracker_data', {
    p_user_id: userId,
    p_course_id: courseId
  });

  if (error) throw error;
  return data;
},

//SEARCH FUNCTIONALITY

// Add this function to the api object
async performContentSearch(courseId: string, query: string, filters: string[] = []): Promise<SearchResult[]> {
  try {
    const { data, error } = await supabase.rpc('search_course_content', {
      p_course_id: courseId,
      p_query: query,
      p_filters: filters
    });

    console.log('Raw search results:', data);

    if (error) {
      console.error('Supabase RPC error:', error);
      throw error;
    }

    if (!data || !Array.isArray(data)) {
      console.error('Unexpected data structure:', data);
      return [];
    }

    return data.map(item => ({
      id: item.id,
      type: item.type,
      title: item.title,
      preview: item.preview,
      category_id: item.category_id,
      fullContent: item.full_content
    }));
  } catch (error) {
    console.error('Error searching content:', error);
    throw error;
  }
},



// CUSTOMER SUPPORT TICKETING SYSTEM



  async getOrCreateSupportUser(email: string, name?: string): Promise<SupportUser> {
    try {
      const { data: existing, error: fetchError } = await supabase
        .from('support_users')
        .select('*')
        .eq('email', email)
        .single();

      if (!fetchError && existing) {
        // Check if we really need to update
        const needsUpdate = (
          // Only update if name is provided AND current name is missing or different
          (name && (!existing.full_name || existing.full_name === 'Future CPA')) ||
          // Or if somehow the full_name got set to the auth_user_id
          (existing.auth_user_id && existing.full_name === existing.auth_user_id)
        );

        if (needsUpdate) {
          const { data: updated, error: updateError } = await supabase
            .from('support_users')
            .update({ 
              full_name: name || existing.full_name || 'Future CPA',
              last_activity: new Date().toISOString()
            })
            .eq('id', existing.id)
            .select()
            .single();

          if (updateError) throw updateError;
          return updated;
        }

        // No update needed
        return existing;
      }

      // For new records
      const { data: created, error: createError } = await supabase
        .from('support_users')
        .insert({
          email,
          full_name: name || 'Future CPA',
          last_activity: new Date().toISOString(),
          metadata: {}
        })
        .select()
        .single();

      if (createError) throw createError;
      return created;
    } catch (error) {
      console.error('Error in getOrCreateSupportUser:', error);
      throw error;
    }
  },

  async getOrCreateStaffSupportUser(authUserId: string): Promise<SupportUser> {
    try {
      // First check if user exists in public.users and has proper role
      const { data: publicUser, error: userError } = await supabase
        .from('users')
        .select('id, email, full_name, role')
        .eq('id', authUserId)
        .single();

      if (userError || !publicUser) {
        throw new Error('User not found in public.users');
      }

      if (!['admin', 'moderator'].includes(publicUser.role)) {
        throw new Error('User does not have support staff permissions');
      }

      // Try to get existing support user by email
      const { data: existingSupportUser, error: supportError } = await supabase
        .from('support_users')
        .select('*')
        .eq('email', publicUser.email)
        .single();

      if (!supportError && existingSupportUser) {
        // Update auth_user_id if it's not set
        if (!existingSupportUser.auth_user_id) {
          const { data: updated, error: updateError } = await supabase
            .from('support_users')
            .update({ 
              auth_user_id: authUserId,
              full_name: publicUser.full_name || publicUser.email,
              last_activity: new Date().toISOString(),
              metadata: {
                ...existingSupportUser.metadata,
                role: publicUser.role,
                is_staff: true
              }
            })
            .eq('id', existingSupportUser.id)
            .select()
            .single();

          if (updateError) throw updateError;
          return updated;
        }
        return existingSupportUser;
      }

      // Create new support user for staff member
      const { data: created, error: createError } = await supabase
        .from('support_users')
        .insert({
          email: publicUser.email,
          full_name: publicUser.full_name || publicUser.email,
          auth_user_id: authUserId,
          email_domain: publicUser.email.split('@')[1],
          last_activity: new Date().toISOString(),
          metadata: {
            role: publicUser.role,
            is_staff: true,
            created_from: 'admin_panel'
          }
        })
        .select()
        .single();

      if (createError) throw createError;
      return created;
    } catch (error) {
      console.error('Error in getOrCreateStaffSupportUser:', error);
      throw error instanceof Error 
        ? error 
        : new Error('Unknown error occurred while getting/creating staff support user');
    }
  },
  
   // Updated ticket functions
   async getTickets(
    filter: 'all' | 'mine' | 'urgent' = 'all',
    userId?: string,
    searchQuery?: string,
    contentId?: string
  ): Promise<Ticket[]> {
    try {
      let query = supabase
        .from('support_tickets')
        .select(`
          *,
          user:support_users!support_tickets_support_user_id_fkey(
            id,
            email,
            full_name,
            auth_user_id,
            metadata,
            email_domain,
            created_at,
            last_activity
          ),
          admin:users!support_tickets_admin_id_fkey(
            email,
            full_name
          ),
          messages:support_messages!support_messages_ticket_id_fkey(count)
        `)
        .order('last_activity_at', { ascending: false });

      if (filter === 'mine' && userId) {
        query = query.eq('admin_id', userId);
      } else if (filter === 'urgent') {
        query = query.eq('priority', 'urgent');
      }

      if (contentId) {
        query = query.eq('content_id', contentId);
      }

      if (searchQuery) {
        query = query.or(`
          subject.ilike.%${searchQuery}%,
          body.ilike.%${searchQuery}%,
          support_users.email.ilike.%${searchQuery}%,
          support_users.full_name.ilike.%${searchQuery}%
        `);
      }

      const { data, error } = await query;
      if (error) throw error;

      return data || [];
    } catch (error) {
      console.error('Error fetching tickets:', error);
      throw error;
    }
  },

  async createTicket(
    userId: string,
    subject: string,
    body: string,
    initialMessage: string,
    priority: Ticket['priority'] = 'medium',
    contentId?: string,
    source: 'web' | 'email' | 'api' = 'web',
    attachments: Array<{ name: string; url: string; type: string }> = []
  ): Promise<TicketCreationResult> {
    try {
      // 1. First get or create support user from auth user
      const { data: userData, error: userError } = await supabase
        .from('support_users')
        .select('id, email, full_name, auth_user_id')
        .eq('auth_user_id', userId)
        .single();

      if (userError) throw userError;

      // 2. Simple check for any purchase record
      let ticketPriority = priority;
      if (userData.auth_user_id) {
        const { count } = await supabase
          .from('user_course_purchases')
          .select('id', { count: 'exact', head: true })
          .eq('user_id', userData.auth_user_id);

        if (count && count > 0) {
          ticketPriority = 'urgent';
        }
      }
  
      // 2. Generate message IDs for email threading
      const messageId = crypto.randomUUID();
      const emailMessageId = `<${messageId}@keslercpareview.com>`;
      
      // 3. Create the ticket with clean subject
      const cleanSubject = subject
        .replace(/^Re:\s*/, '')
        .replace(/\[#[^\]]+\]\s*/, '')
        .replace(/\[Ticket #[^\]]+\]\s*/, '')
        .trim();
  
        const { data: ticketData, error: ticketError } = await supabase
        .from('support_tickets')
        .insert({
          support_user_id: userData.id,
          subject: cleanSubject,
          body,
          status: 'new',
          priority: ticketPriority,
          content_id: contentId,
          source,
          has_unread: true,
          attachments,
          original_email_id: emailMessageId,
          email_thread_id: emailMessageId,
          thread_topic: cleanSubject,
          list_id: `ticket-${messageId}.support.keslercpareview.com`,
          original_from_email: userData.email,
          original_subject: cleanSubject
        })
        .select()
        .single();
  
      if (ticketError) throw ticketError;
  
      const ticket = {
        ...ticketData,
        user: userData,
        admin: null
      };
  
      let initialMessageRecord = null;
  
      // 4. Create initial message if provided
      if (initialMessage && initialMessage !== body) {
        const formattedSubject = `[#${ticketData.short_id}] ${cleanSubject}`;
  
        const { data: message, error: messageError } = await supabase
          .from('support_messages')
          .insert({
            ticket_id: ticket.id,
            support_user_id: userData.id, // Use support_user_id
            content: initialMessage,
            is_internal: false,
            message_type: source === 'email' ? 'inbound' : 'internal',
            attachments,
            email_message_id: emailMessageId,
            email_in_reply_to: null,
            email_references: [],
            from_email: userData.email,
            to_emails: ['support@keslercpareview.com'],
            email_subject: formattedSubject,
            email_timestamp: new Date().toISOString()
          })
          .select()
          .single();
  
        if (messageError) throw messageError;
        initialMessageRecord = {
          ...message,
          user: userData
        };
      }
  
      // 5. Add system message for web tickets
      if (source === 'web') {
        const { error: systemMessageError } = await supabase
          .from('support_messages')
          .insert({
            ticket_id: ticket.id,
            support_user_id: userData.id, // Use support_user_id
            content: `Ticket #${ticketData.short_id} created via web interface`,
            is_internal: true,
            message_type: 'system',
            email_message_id: emailMessageId,
            email_in_reply_to: null,
            email_references: [],
            from_email: 'system@keslercpareview.com',
            to_emails: null,
            email_subject: `[#${ticketData.short_id}] ${cleanSubject}`,
            email_timestamp: new Date().toISOString()
          });
  
        if (systemMessageError) throw systemMessageError;
      }
  
      return {
        ticket,
        message: initialMessageRecord
      };
  
    } catch (error) {
      console.error('Error creating ticket:', error);
      throw error;
    }
  },

  // Add method to generate email message ID (utility function)
  generateEmailMessageId(prefix: string = ''): {
    messageId: string;
    emailMessageId: string;
  } {
    const uuid = crypto.randomUUID();
    const messageId = prefix ? `${prefix}-${uuid}` : uuid;
    const emailMessageId = `<${messageId}@keslercpareview.com>`;
    return { messageId, emailMessageId };
  },

  // Helper method to get proper email thread references
  getEmailThreadReferences(ticket: Ticket): string[] {
    const references: string[] = [];
    
    if (ticket.email_thread_id) {
      references.push(ticket.email_thread_id);
    }
    
    if (ticket.original_email_id && ticket.original_email_id !== ticket.email_thread_id) {
      references.push(ticket.original_email_id);
    }
    
    return references;
  },
  
  // Update existing ticket
  async updateTicket(
    ticketId: string,
    updates: TicketUpdate
  ): Promise<void> {
    const { error } = await supabase
      .from('support_tickets')
      .update({
        ...updates,
        updated_at: new Date().toISOString(),
        last_activity_at: new Date().toISOString(),
        ...(updates.status === 'closed' ? { closed_at: new Date().toISOString() } : {})
      })
      .eq('id', ticketId);

    if (error) throw error;
  },
  
  async getTicketById(ticketId: string): Promise<Ticket | null> {
    try {
      // First get the ticket
      const { data: ticketData, error: ticketError } = await supabase
        .from('support_tickets')
        .select('*')
        .eq('id', ticketId)
        .single();
  
      if (ticketError) throw ticketError;
      if (!ticketData) return null;
  
      // Then get the support user and admin data separately
      const { data: supportUser, error: supportUserError } = await supabase
        .from('support_users')
        .select('id, email, full_name, auth_user_id, metadata')
        .eq('id', ticketData.support_user_id)
        .single();
  
      if (supportUserError) {
        console.error('Error fetching support user:', supportUserError);
      }
  
      // Get admin data if exists
      let adminData;
      if (ticketData.admin_id) {
        const { data: adminUser, error: adminError } = await supabase
          .from('users')
          .select('id, email, full_name')
          .eq('id', ticketData.admin_id)
          .single();
  
        if (adminError) {
          console.error('Error fetching admin user:', adminError);
        } else {
          adminData = adminUser;
        }
      }
  
      // Build the complete ticket object
      const ticket = {
        ...ticketData,
        user: supportUser || { 
          id: ticketData.support_user_id,
          email: 'unknown',
          full_name: 'Unknown User',
          auth_user_id: null,
          metadata: {}
        },
        admin: adminData,
        _count: {
          messages: 0 // We'll update this if needed
        }
      };
  
      // Optionally get message count
      const { count: messageCount } = await supabase
        .from('support_messages')
        .select('*', { count: 'exact', head: true })
        .eq('ticket_id', ticketId);
  
      if (messageCount !== null) {
        ticket._count.messages = messageCount;
      }
  
      return ticket;
    } catch (error) {
      console.error('Error fetching ticket:', error);
      return null;
    }
  },

  async markMessageAsRead(messageId: string): Promise<void> {
    const { error } = await supabase
      .from('support_messages')
      .update({ is_read: true })
      .eq('id', messageId);

    if (error) throw error;
  },
  
  async markTicketMessagesAsRead(ticketId: string, supportUserId: string): Promise<void> {
    const { error } = await supabase
      .from('support_messages')
      .update({ is_read: true })
      .eq('ticket_id', ticketId)
      .neq('support_user_id', supportUserId);

    if (error) throw error;
  },

  // Get ticket messages
  async getTicketMessages(ticketId: string): Promise<Message[]> {
    const { data, error } = await supabase
      .from('support_messages')
      .select(`
        *,
        user:support_users!support_messages_support_user_id_fkey(
          id,
          email,
          full_name,
          auth_user_id,
          metadata,
          email_domain,
          created_at,
          last_activity
        )
      `)
      .eq('ticket_id', ticketId)
      .order('created_at', { ascending: true });

    if (error) throw error;
    return data || [];
  },

  // Add ticket message
  async addTicketMessage(
    ticketId: string,
    userId: string,
    content: string,
    isInternal: boolean = false,
    attachments: Array<{ name: string; url: string; type: string }> = []
  ): Promise<Message> {
    // First get or ensure support user exists
    const { data: supportUser } = await supabase
      .from('support_users')
      .select('id')
      .eq('auth_user_id', userId)
      .single();

    if (!supportUser) {
      throw new Error('Support user not found');
    }

    const { data, error } = await supabase
      .from('support_messages')
      .insert({
        ticket_id: ticketId,
        support_user_id: supportUser.id,
        content,
        is_internal: isInternal,
        attachments
      })
      .select(`
        *,
        user:support_users!support_messages_support_user_id_fkey(
          id,
          email,
          full_name,
          auth_user_id,
          metadata
        )
      `)
      .single();

    if (error) throw error;

    // Update ticket's last activity timestamp
    await this.updateTicket(ticketId, {
      last_activity_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    });

    return data;
  },

  async uploadTicketAttachment(
    ticketId: string,
    file: File
  ): Promise<{ url: string; path: string }> {
    const path = `support-attachments/${ticketId}/${Date.now()}-${file.name}`;
    
    const { error: uploadError } = await supabase.storage
      .from('support-attachments')
      .upload(path, file);

    if (uploadError) throw uploadError;

    const { data: { publicUrl } } = supabase.storage
      .from('support-attachments')
      .getPublicUrl(path);

    return { url: publicUrl, path };
  },

  async getEmailTemplates(): Promise<EmailTemplate[]> {
    const { data, error } = await supabase
      .from('email_templates')
      .select('*')
      .eq('is_active', true)
      .order('name');

    if (error) throw error;
    return data || [];
  },

  async createEmailTemplate(template: Omit<EmailTemplate, 'id' | 'created_at' | 'updated_at'>): Promise<EmailTemplate> {
    const { data, error } = await supabase
      .from('email_templates')
      .insert(template)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  async deleteTicketAttachment(path: string): Promise<void> {
    const { error } = await supabase.storage
      .from('support-attachments')
      .remove([path]);

    if (error) throw error;
  },
  
  async sendTicketResponse(
    ticketId: string,
    userId: string,
    content: string,
    isInternal: boolean,
    attachments: File[] = [],
    subject?: string
  ): Promise<Message> {
    try {
      console.log('Starting sendTicketResponse:', { ticketId, userId, isInternal });
  
      if (!userId) {
        throw new Error('user_id is required for creating messages');
      }
  
      // 1. First get the support user
      console.log('Looking up support user:', userId);
      const { data: supportUser, error: supportError } = await supabase
        .from('support_users')
        .select('*')
        .eq('id', userId) // First try by direct ID
        .single() as { data: SupportUser | null, error: any };
  
      if (supportError || !supportUser) {
        console.error('Support user not found:', supportError);
        throw new Error('User not found in support system');
      }
  
      // 2. If this support user has an auth_user_id, verify admin status
      if (!isInternal && supportUser.auth_user_id) {
        console.log('Verifying admin status for auth_user_id:', supportUser.auth_user_id);
        const { data: publicUser, error: userError } = await supabase
          .from('users')
          .select('id, role')
          .eq('id', supportUser.auth_user_id)
          .single();
  
        console.log('Public user query result:', { publicUser, error: userError });
  
        if (userError || !publicUser) {
          console.error('Public user not found:', userError);
          throw new Error('Admin user not found');
        }
  
        if (!['admin', 'moderator'].includes(publicUser.role)) {
          throw new Error('Only admin users can send external messages');
        }
      } else if (!isInternal && !supportUser.auth_user_id) {
        // If no auth_user_id (customer) trying to send non-internal message
        throw new Error('Customers can only send internal messages');
      }
  
      // 3. Get Session Token
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError || !sessionData.session?.access_token) {
        throw new Error('Authentication required: No valid session found');
      }
      const accessToken = sessionData.session.access_token;
  
      // 4. Get Ticket Info
      interface TicketWithSupportUser {
        subject: string;
        support_user_id: string;
        original_email_id: string | null;
        email_thread_id: string | null;
        short_id: number;
        support_users: SupportUser;
      }
  
      const { data: ticketData, error: ticketError } = await supabase
        .from('support_tickets')
        .select(`
          subject,
          support_user_id,
          original_email_id,
          email_thread_id,
          short_id,
          support_users!inner (
            id,
            email,
            full_name,
            created_at,
            last_activity,
            auth_user_id,
            metadata,
            email_domain
          )
        `)
        .eq('id', ticketId)
        .single() as { data: TicketWithSupportUser | null; error: any };
  
      if (ticketError || !ticketData) {
        throw new Error(ticketError?.message || 'Ticket not found');
      }
  
      // 5. Generate message ID
      const messageId = crypto.randomUUID();
  
      // 6. Process Attachments
      const uploadedAttachments = await Promise.all(
        attachments.map(async (file) => {
          try {
            const result = await this.uploadTicketAttachment(ticketId, file);
            return {
              name: file.name,
              url: result.url,
              type: file.type || 'application/octet-stream'
            };
          } catch (error) {
            console.error(`Failed to upload attachment ${file.name}:`, error);
            return null;
          }
        })
      ).then(results => results.filter((result): result is NonNullable<typeof result> => result !== null));
  
      // 7. Create Message Record
      const { data: message, error: messageError } = await supabase
        .from('support_messages')
        .insert({
          ticket_id: ticketId,
          support_user_id: supportUser.id,
          content,
          is_internal: isInternal,
          attachments: uploadedAttachments,
          message_type: isInternal ? 'internal' : 'outbound',
          email_sent: !isInternal,
          email_sent_at: !isInternal ? new Date().toISOString() : null,
          sendgrid_message_id: !isInternal ? messageId : null,
          email_message_id: !isInternal ? `<${messageId}@keslercpareview.com>` : null,
          email_in_reply_to: !isInternal ? ticketData.original_email_id : null,
          email_references: !isInternal && ticketData.email_thread_id ? [ticketData.email_thread_id] : [],
          from_email: !isInternal ? 'support@keslercpareview.com' : null,
          to_emails: !isInternal ? [ticketData.support_users.email] : null,
          email_subject: !isInternal ? formatSubject(subject || ticketData.subject, ticketData.short_id.toString()) : null
        })
        .select()
        .single() as { data: Message | null; error: any };
  
      if (messageError || !message) {
        throw messageError || new Error('Failed to create message');
      }
  
      // 8. Send Email via SendGrid (if not internal)
      if (!isInternal) {
        const sendGridPayload = {
          ticketId,
          messageId,
          to: ticketData.support_users.email,
          subject: formatSubject(subject || ticketData.subject, ticketData.short_id.toString()),
          content: sanitizeContent(content),
          attachments: uploadedAttachments,
          recipientName: ticketData.support_users.full_name,
          fromName: `${supportUser.full_name}`,
          fromEmail: 'support@keslercpareview.com'
        };
  
        const response = await fetch(
          'https://jvidoqtewkukebhpabuv.supabase.co/functions/v1/sendgrid',
          {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(sendGridPayload)
          }
        );
  
        const responseData = await response.json();
  
        if (!responseData.success) {
          throw new Error(responseData.details || 'Failed to send email');
        }
  
        if (responseData.warning) {
          console.warn('SendGrid warning:', responseData.warning);
        }
      }
  
      // 9. Update support user activity
      await supabase
        .from('support_users')
        .update({ last_activity: new Date().toISOString() })
        .eq('id', supportUser.id);
  
      return {
        ...message,
        user: supportUser
      };
  
    } catch (error) {
      console.error('Error in sendTicketResponse:', error);
      throw error;
    }
  },  

  // Function to get tickets dashboard statistics
  async getTicketStats(): Promise<{
    open: number;
    pending: number;
    closed: number;
    urgent: number;
    unassigned: number;
  }> {
    const { data, error } = await supabase.rpc('get_ticket_stats');
    
    if (error) throw error;
    return data || {
      open: 0,
      pending: 0,
      closed: 0,
      urgent: 0,
      unassigned: 0
    };
  }



};
// src/components/admin/support/EmailComposer.tsx

import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../../../services/supabase';
import { useAuth } from '../../../components/AuthProvider';
import { api, EmailTemplate, SupportUser } from '../../../services/api';
import { 
  AlertCircle,
  Loader2, 
  Paperclip,
  Save,
  Send,
  LayoutTemplate,
  X,
  Eye,
  EyeOff,
  Search,
  UserPlus,
  Check,
} from 'lucide-react';
import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';
import { Textarea } from '../../ui/Textarea';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue 
} from '../../ui/Select';
import { Card } from '../../ui/Card';
import { Alert, 
  AlertDescription, 
  AlertTitle } from '../../ui/Alert';

interface EmailComposerProps {
  ticketId: string;
  recipientEmail?: string;
  onTicketCreated: () => void;
  onRecipientChange?: (email: string) => void;
  onNameChange?: (name: string) => void;
  requireUserConfirmation?: boolean;
}

interface AttachmentError {
  fileName: string;
  error: string;
}

interface SearchState {
  status: 'idle' | 'not_found' | 'found' | 'selected' | 'creating' | 'error';
  user: SupportUser | null;
  error: string | null;
}

const EmailComposer: React.FC<EmailComposerProps> = ({
  ticketId,
  recipientEmail: initialRecipientEmail = '',
  onTicketCreated,
  onRecipientChange,
  onNameChange,
  requireUserConfirmation = true
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [variables, setVariables] = useState<Record<string, string>>({});
  const [savingTemplate, setSavingTemplate] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [previewMode, setPreviewMode] = useState(false);
  const [attachmentErrors, setAttachmentErrors] = useState<AttachmentError[]>([]);
  const [uploading, setUploading] = useState(false);
  const [userLookupResult, setUserLookupResult] = useState<SupportUser | null>(null);
  const [userLookupError, setUserLookupError] = useState<string | null>(null);
  const [recipientEmail, setRecipientEmail] = useState(initialRecipientEmail);
  const [lookupLoading, setLookupLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [searchState, setSearchState] = useState<SearchState>({
    status: 'idle',
    user: null,
    error: null
  });
  const [canSend, setCanSend] = useState(false);

  // Effect to check if email can be sent
  useEffect(() => {
    const canSendEmail = 
      searchState.status === 'selected' && 
      !!subject.trim() && 
      !!content.trim();
    
    setCanSend(canSendEmail);
  }, [searchState.status, subject, content]);

  // Handle recipient email changes
  const handleRecipientChange = (email: string) => {
    setRecipientEmail(email);
    onRecipientChange?.(email);
    // Reset search state when email changes
    setSearchState({
      status: 'idle',
      user: null,
      error: null
    });
  };

  // Add clear user function
  const clearSelectedUser = () => {
    setRecipientEmail('');
    setSearchState({
      status: 'idle',
      user: null,
      error: null
    });
    onRecipientChange?.('');
    onNameChange?.('');
  };

  // Updated lookupUser function
  const lookupUser = async () => {
    if (!recipientEmail) {
      setSearchState({
        status: 'error',
        user: null,
        error: 'Please enter an email address'
      });
      return;
    }

    setLookupLoading(true);
    try {
      const { data: existingUser, error: fetchError } = await supabase
        .from('support_users')
        .select('*')
        .eq('email', recipientEmail)
        .single();

      if (fetchError) {
        if (fetchError.code === 'PGRST116') { // No rows returned
          setSearchState({
            status: 'not_found',
            user: null,
            error: null
          });
        } else {
          throw fetchError;
        }
      } else {
        setSearchState({
          status: 'found',
          user: existingUser,
          error: null
        });
      }
    } catch (error) {
      console.error('Error looking up user:', error);
      setSearchState({
        status: 'error',
        user: null,
        error: 'Error searching for user'
      });
    } finally {
      setLookupLoading(false);
    }
  };

  // New function to create support user
  const createSupportUser = async () => {
    setSearchState(prev => ({ ...prev, status: 'creating' }));
    try {
      const supportUser = await api.getOrCreateSupportUser(recipientEmail);
      setSearchState({
        status: 'selected',
        user: supportUser,
        error: null
      });
      onNameChange?.(supportUser.full_name || '');
    } catch (error) {
      console.error('Error creating support user:', error);
      setSearchState({
        status: 'error',
        user: null,
        error: 'Failed to create support user'
      });
    }
  };

  // New function to select existing user
  const selectUser = () => {
    if (searchState.user) {
      setSearchState(prev => ({
        ...prev,
        status: 'selected'
      }));
      onNameChange?.(searchState.user.full_name || '');
    }
  };
  
  const loadTemplates = async () => {
    try {
      const { data, error } = await supabase
        .from('email_templates')
        .select('*')
        .order('name');
        
      if (error) throw error;
      setTemplates(data || []);
    } catch (error) {
      console.error('Error loading email templates:', error);
    }
  };
  
  const handleTemplateSelect = async (templateId: string) => {
    const template = templates.find(t => t.id === templateId);
    if (!template) return;

    setSelectedTemplate(template);
    setSubject(template.subject);
    setContent(template.content);
    
    // Initialize variables
    const initialVariables: Record<string, string> = {};
    template.variables.forEach(variable => {
      initialVariables[variable] = '';
    });
    setVariables(initialVariables);
  };

  const handleVariableChange = (variable: string, value: string) => {
    setVariables(prev => ({
      ...prev,
      [variable]: value
    }));
  };

  const replaceVariables = (text: string) => {
    let result = text;
    Object.entries(variables).forEach(([key, value]) => {
      result = result.replace(new RegExp(`{{${key}}}`, 'g'), value || `{{${key}}}`);
    });
    // Add default variables
    result = result
      .replace(/{{senderName}}/g, user?.full_name || 'Support Team')
      .replace(/{{ticketId}}/g, ticketId);
    return result;
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    
    setUploading(true);
    const newErrors: AttachmentError[] = [];
    const validFiles: File[] = [];

    for (const file of Array.from(event.target.files)) {
      try {
        // Check file type
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif'];
        if (!allowedTypes.includes(file.type)) {
          newErrors.push({
            fileName: file.name,
            error: `Only PDF and images are permitted`
          });
          continue;
        }

        // Check file size (10MB limit)
        if (file.size > 10 * 1024 * 1024) {
          newErrors.push({
            fileName: file.name,
            error: `File exceeds 10MB limit`
          });
          continue;
        }

        validFiles.push(file);
      } catch (error) {
        newErrors.push({
          fileName: file.name,
          error: 'Failed to process file'
        });
      }
    }

    // Add valid files to attachments
    if (validFiles.length) {
      setAttachments(prev => [...prev, ...validFiles]);
    }

    // Set errors if any
    if (newErrors.length) {
      setAttachmentErrors(newErrors);
    }

    setUploading(false);
    if (event.target.value) {
      event.target.value = '';
    }
  };

  const removeError = (fileName: string) => {
    setAttachmentErrors(errors => errors.filter(e => e.fileName !== fileName));
  };

  const removeAttachment = (index: number) => {
    setAttachments(attachments.filter((_, i) => i !== index));
    setAttachmentErrors(errors => errors.filter(e => e.fileName !== attachments[index].name));
  };


  const saveAsTemplate = async () => {
    if (!subject || !content) return;
    
    setSavingTemplate(true);
    try {
      // Extract variables from content using regex
      const variableRegex = /{{([^}]+)}}/g;
      const matches = content.match(variableRegex) || [];
      const extractedVariables = [...new Set(matches.map(m => m.slice(2, -2)))];

      const { error } = await supabase
        .from('email_templates')
        .insert({
          name: `Template - ${new Date().toLocaleDateString()}`,
          subject,
          content,
          variables: extractedVariables,
          created_by: user?.id
        });

      if (error) throw error;
      await loadTemplates();
    } catch (error) {
      console.error('Error saving template:', error);
      setError('Failed to save template');
    } finally {
      setSavingTemplate(false);
    }
  };

// Updated sendEmail function with proper support_user handling
const sendEmail = async () => {
  if (!subject || !content) {
    setError('Subject and content are required');
    return;
  }

  if (!recipientEmail) {
    setError('Recipient email is required');
    return;
  }

  setLoading(true);
  try {
    // Upload attachments first
    const ticketFolder = ticketId === 'new' ? crypto.randomUUID() : ticketId;
    const uploadedAttachments = await Promise.all(
      attachments.map(async file => {
        const filePath = `support-attachments/${ticketFolder}/${Date.now()}-${file.name}`;
        
        const { error: uploadError } = await supabase.storage
          .from('support-attachments')
          .upload(filePath, file);

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from('support-attachments')
          .getPublicUrl(filePath);

        return {
          name: file.name,
          url: publicUrl,
          type: file.type
        };
      })
    );

    if (ticketId === 'new') {
      // Generate email message ID
      const messageId = crypto.randomUUID();
      const emailMessageId = `<${messageId}@keslercpareview.com>`;

      // First make sure we have a support user
      let supportUser = userLookupResult;
      
      if (!supportUser) {
        // Create support user if they don't exist
        const { data: newSupportUser, error: supportUserError } = await supabase
          .from('support_users')
          .insert({
            email: recipientEmail,
            full_name: '', // Can be updated later if needed
          })
          .select()
          .single();

        if (supportUserError) {
          if (supportUserError.code === '23505') { // Unique violation
            // User already exists, fetch them
            const { data: existingUser, error: fetchError } = await supabase
              .from('support_users')
              .select()
              .eq('email', recipientEmail)
              .single();
            
            if (fetchError) throw fetchError;
            supportUser = existingUser;
          } else {
            throw supportUserError;
          }
        } else {
          supportUser = newSupportUser;
        }
      }

      // Create the ticket with the initial message content
      const ticketData = {
        support_user_id: supportUser?.id,
        admin_id: user?.id,
        subject: replaceVariables(subject),
        body: replaceVariables(content), // Initial message content goes in ticket body
        status: 'new',
        priority: 'medium',
        source: 'web',
        has_unread: true,
        attachments: uploadedAttachments,
        original_email_id: emailMessageId,
        email_thread_id: emailMessageId,
        thread_topic: replaceVariables(subject),
        original_from_email: 'support@keslercpareview.com', // Since agent is sending
        original_subject: replaceVariables(subject)
      };

      const { data: ticket, error: ticketError } = await supabase
        .from('support_tickets')
        .insert(ticketData)
        .select('*, support_users!inner(*)')
        .single();

      if (ticketError) {
        console.error('Ticket creation error:', ticketError);
        throw new Error(`Failed to create ticket: ${ticketError.message}`);
      }

      // Send through Sendgrid
      const { data: sendgridResponse, error: sendError } = await supabase.functions
        .invoke('sendgrid', {
          body: {
            to: recipientEmail,
            subject: replaceVariables(subject),
            content: replaceVariables(content),
            attachments: uploadedAttachments,
            ticketId: ticket.id,
            messageId,
            fromName: user?.full_name || 'Support Team',
            fromEmail: 'support@keslercpareview.com'
          }
        });

      if (sendError) throw sendError;

      // Reset form and notify parent
      setSubject('');
      setContent('');
      setAttachments([]);
      setSelectedTemplate(null);
      setVariables({});
      setUserLookupResult(null);
      onTicketCreated();

    } else {
      // This is a reply to an existing ticket - create a message
      const messageId = crypto.randomUUID();
      const emailMessageId = `<${messageId}@keslercpareview.com>`;

      // Get the original ticket for thread information
      const { data: ticket, error: ticketError } = await supabase
        .from('support_tickets')
        .select('*, support_users!inner(*)')
        .eq('id', ticketId)
        .single();

      if (ticketError) throw ticketError;

      // Create new message for the reply
      const messageData = {
        ticket_id: ticketId,
        support_user_id: ticket.support_user_id,
        content: replaceVariables(content),
        is_internal: false,
        message_type: 'outbound',
        attachments: uploadedAttachments,
        email_message_id: emailMessageId,
        from_email: 'support@keslercpareview.com',
        to_emails: [recipientEmail],
        email_subject: replaceVariables(subject),
        email_sent: false,
        sendgrid_message_id: messageId
      };

      const { error: messageError } = await supabase
        .from('support_messages')
        .insert(messageData);

      if (messageError) throw messageError;

      // Update ticket status and last activity
      const { error: updateError } = await supabase
        .from('support_tickets')
        .update({
          status: 'pending',
          updated_at: new Date().toISOString(),
          last_activity_at: new Date().toISOString()
        })
        .eq('id', ticketId);

      if (updateError) throw updateError;

      // Send through Sendgrid
      const { data: sendgridResponse, error: sendError } = await supabase.functions
        .invoke('sendgrid', {
          body: {
            to: recipientEmail,
            subject: replaceVariables(subject),
            content: replaceVariables(content),
            attachments: uploadedAttachments,
            ticketId,
            messageId,
            fromName: user?.full_name || 'Support Team',
            fromEmail: 'support@keslercpareview.com'
          }
        });

      if (sendError) throw sendError;

      // Update message with Sendgrid status if successful
      if (sendgridResponse?.success) {
        await supabase
          .from('support_messages')
          .update({
            email_sent: true,
            email_sent_at: new Date().toISOString()
          })
          .eq('email_message_id', emailMessageId);
      }
    }

  } catch (error) {
    console.error('Error sending email:', error);
    setError(error instanceof Error ? error.message : 'Failed to send email');
  } finally {
    setLoading(false);
  }
};

  // Search Result Component
  const SearchResult = () => {
    switch (searchState.status) {
      case 'not_found':
        return (
          <Alert className="mb-4">
            <AlertTitle>User Not Found</AlertTitle>
            <AlertDescription className="flex items-center justify-between">
              <span>No user found with this email address.</span>
              <Button
                onClick={createSupportUser}
                className="bg-blue-600 hover:bg-blue-700 text-white inline-flex items-center justify-center"
                size="sm"
              >
                <UserPlus className="w-4 h-4 mr-2" />
                <span>Create Contact</span>
              </Button>
            </AlertDescription>
          </Alert>
        );
      
      case 'found':
        return (
          <Alert className="mb-4">
            <AlertTitle>User Found</AlertTitle>
            <AlertDescription className="flex items-center justify-between">
              <div>
                <p className="font-medium">{searchState.user?.full_name || searchState.user?.email}</p>
                {searchState.user?.auth_user_id && (
                  <span className="text-sm text-blue-600">Registered User</span>
                )}
              </div>
              <Button
                onClick={selectUser}
                className="bg-blue-600 hover:bg-blue-700 text-white inline-flex items-center justify-center"
                size="sm"
              >
                <Check className="w-4 h-4 mr-2" />
                <span>Select User</span>
              </Button>
            </AlertDescription>
          </Alert>
        );

      case 'selected':
        return (
          <Alert className="mb-4">
            <AlertTitle>Selected User</AlertTitle>
            <AlertDescription>
              <p className="font-medium">{searchState.user?.full_name || searchState.user?.email}</p>
              {searchState.user?.auth_user_id && (
                <span className="text-sm text-blue-600">Registered User</span>
              )}
            </AlertDescription>
          </Alert>
        );

      case 'error':
        return (
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{searchState.error}</AlertDescription>
          </Alert>
        );

      default:
        return null;
    }
  };

  return (
    <Card className="bg-white dark:bg-gray-900 border dark:border-gray-800">
      <div className="flex flex-col h-full">
        <div className="flex-none bg-white dark:bg-gray-800 p-4 border-b dark:border-gray-700">
          <h2 className="text-lg font-semibold mb-4">New Support Ticket</h2>
          
          {/* Email Search Section */}
          <div className="space-y-4 mb-6">
            <div>
              <label className="text-sm font-medium mb-1 block">Customer Email</label>
              <div className="flex items-center gap-2">
                <div className="flex-1 relative">
                  <Input 
                    value={recipientEmail}
                    onChange={(e) => handleRecipientChange(e.target.value)}
                    placeholder="Search and enter recipient email..."
                    className="w-full bg-gray-50 dark:bg-gray-800 border-gray-200 dark:border-gray-700 dark:text-gray-100"
                    disabled={searchState.status === 'selected'}
                  />
                  {searchState.status === 'selected' && (
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={clearSelectedUser}
                      className="absolute right-2 top-1/2 -translate-y-1/2 h-6 w-6 p-0"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  )}
                </div>
                <Button
                  variant="primary"
                  onClick={lookupUser}
                  disabled={lookupLoading || !recipientEmail || searchState.status === 'selected'}
                  className="shrink-0 bg-blue-600 hover:bg-blue-700 text-white dark:bg-blue-600 dark:hover:bg-blue-700"
                >
                  {lookupLoading ? (
                    <Loader2 className="w-4 h-4 animate-spin" />
                  ) : (
                    <Search className="w-4 h-4" />
                  )}
                </Button>
              </div>
            </div>

            <SearchResult />
          </div>

          {/* General Error Alert */}
          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {/* Attachment Errors */}
          {attachmentErrors.length > 0 && (
            <div className="space-y-2 mb-6">
              {attachmentErrors.map((error, index) => (
                <Alert 
                  key={index}
                  variant="destructive"
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center gap-2">
                    <AlertCircle className="h-4 w-4 flex-shrink-0" />
                    <span className="text-sm font-medium">
                      {error.fileName}:
                    </span>
                    <span className="text-sm">
                      {error.error}
                    </span>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => removeError(error.fileName)}
                    className="h-6 w-6 p-0 hover:bg-red-200 dark:hover:bg-red-900/50"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </Alert>
              ))}
            </div>
          )}
  
          {/* Email Header */}
          <div className="space-y-4">
            <Input
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              disabled={searchState.status !== 'selected'}
              placeholder="Subject..."
              className="text-lg font-medium bg-gray-50 dark:bg-gray-800 border-gray-200 dark:border-gray-700 dark:text-gray-100 dark:placeholder-gray-400"
            />
          </div>
  
          {/* Attachments Display */}
          {attachments.length > 0 && (
            <div className="space-y-2">
              {attachments.map((file, index) => (
                <div key={index} className="flex items-center gap-2 text-xs md:text-sm bg-gray-50 dark:bg-gray-700 p-2 rounded">
                  <Paperclip className="w-4 h-4" />
                  <span className="flex-1 truncate">{file.name}</span>
                  <span className="text-gray-500 dark:text-gray-400">
                    {(file.size / 1024 / 1024).toFixed(2)} MB
                  </span>
                  <Button
                    variant="ghost"
                    onClick={() => removeAttachment(index)}
                    className="h-6 w-6 p-0"
                  >
                    <X className="h-4" />
                  </Button>
                </div>
              ))}
            </div>
          )}
  
          {/* Main Content */}
          <Textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            disabled={searchState.status !== 'selected'}
            placeholder="Write your email here..."
            rows={12}
            className="font-mono bg-gray-50 dark:bg-gray-800 border-gray-200 dark:border-gray-700 dark:text-gray-100 dark:placeholder-gray-400 resize-none mt-6"
          />
        </div>
  
        {/* Bottom Toolbar */}
        <div className="border-t dark:border-gray-800 bg-gray-50 dark:bg-gray-800/50 p-4">
          <div className="flex items-center justify-between gap-4">
            {/* Left Side - Template & Attachments */}
            <div className="flex items-center gap-2">
            <Select 
              onValueChange={handleTemplateSelect}
              disabled={searchState.status !== 'selected'}
              className="relative"
            >
              <SelectTrigger className="w-[200px] bg-white dark:bg-gray-900">
                <LayoutTemplate className="w-4 h-4 mr-2" />
                <SelectValue placeholder="Select template..." />
              </SelectTrigger>
              <SelectContent>
                {templates.map((template) => (
                  <SelectItem key={template.id} value={template.id}>
                    {template.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
  
              {/* Attachment Button */}
              <Button
                variant="outline"
                onClick={() => fileInputRef.current?.click()}
                className="bg-white dark:bg-gray-900 inline-flex items-center justify-center"
                disabled={uploading || searchState.status !== 'selected'}
              >
                {uploading ? (
                  <Loader2 className="w-4 h-4 animate-spin" />
                ) : (
                  <Paperclip className="w-4 h-4" />
                )}
                {attachments.length > 0 && (
                  <span className="ml-2 bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-400 px-2 py-0.5 rounded-full text-xs">
                    {attachments.length}
                  </span>
                )}
              </Button>
              <input
                ref={fileInputRef}
                type="file"
                multiple
                className="hidden"
                onChange={handleFileChange}
                accept=".pdf,.jpg,.jpeg,.png,.gif"
              />
            </div>
  
            {/* Right Side - Actions */}
            <div className="flex items-center gap-2 shrink-0">
              <Button
                variant="outline"
                onClick={saveAsTemplate}
                disabled={savingTemplate || !subject || !content || searchState.status !== 'selected'}
                className="bg-white dark:bg-gray-900 flex items-center"
              >
                {savingTemplate ? (
                  <Loader2 className="w-4 h-4 mr-2" />
                ) : (
                  <Save className="w-4 h-4 mr-2" />
                )}
                <span>Save Template</span>
              </Button>
  
              <Button
                onClick={sendEmail}
                disabled={!canSend || loading}
                className="bg-blue-600 hover:bg-blue-700 text-white flex items-center"
              >
                {loading ? (
                  <Loader2 className="w-4 h-4 mr-2" />
                ) : (
                  <Send className="w-4 h-4 mr-2" />
                )}
                <span>Send Email</span>
              </Button>
            </div>
          </div>
  
          {/* Template Variables */}
          {selectedTemplate && selectedTemplate.variables.length > 0 && searchState.status === 'selected' && (
            <div className="mt-4 p-4 bg-white dark:bg-gray-900 rounded border dark:border-gray-700">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 block">
                Template Variables
              </label>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                {selectedTemplate.variables.map((variable) => (
                  <Input
                    key={variable}
                    placeholder={variable}
                    value={variables[variable] || ''}
                    onChange={(e) => handleVariableChange(variable, e.target.value)}
                    className="bg-gray-50 dark:bg-gray-800"
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default EmailComposer;
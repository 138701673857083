import React, { useState } from 'react';
import { auth } from '../../services/auth';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await auth.resetPassword(email);
      setMessage('Password reset email sent. Check your inbox.');
      setError('');
    } catch (err) {
      setError('Failed to send reset email. Please try again.');
      setMessage('');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4 text-light-text dark:text-dark-text">Forgot Password</h2>
      {message && <p className="text-green-500 dark:text-green-400 mb-4">{message}</p>}
      {error && <p className="text-red-500 dark:text-red-400 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block mb-1 text-light-text dark:text-dark-text">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
          />
        </div>
        <button type="submit" className="w-full bg-light-primary dark:bg-dark-primary text-white py-2 rounded hover:bg-light-accent dark:hover:bg-dark-accent">
          Send Reset Link
        </button>
      </form>
    </div>
  );
}

export default ForgotPassword;
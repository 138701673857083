import { supabase } from './supabase';
import {
  User,
  Product,
  UserCourseAccess,
  UserCoursePurchase,
  CACHE_KEYS,
  CACHE_DURATION,
  COURSE_IDS
} from './api';
import { getCachedData, setCachedData } from '../utils/accessUtils';

export interface UserDataResponse {
  user: User;
  products: Product[];
  courseAccesses: UserCourseAccess[];
  coursePurchases: UserCoursePurchase[];
  mentorshipAccess: UserCourseAccess | null;
}

export class UserDataService {
  private static instance: UserDataService;
  private fetchPromises: Map<string, Promise<any>> = new Map();

  private constructor() {}

  static getInstance(): UserDataService {
    if (!UserDataService.instance) {
      UserDataService.instance = new UserDataService();
    }
    return UserDataService.instance;
  }

  async getAllUserData(userId: string): Promise<UserDataResponse> {
    try {
      // Verify active session first
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('No active session');
      }

      // Check cache first
      const cachedData = getCachedData<UserDataResponse>(CACHE_KEYS.USER_DATA(userId));
      if (cachedData) {
        return cachedData;
      }

      // Prevent duplicate fetches
      const promiseKey = `getAllUserData_${userId}`;
      if (this.fetchPromises.has(promiseKey)) {
        return this.fetchPromises.get(promiseKey)!;
      }

      const fetchPromise = this.fetchAllUserData(userId);
      this.fetchPromises.set(promiseKey, fetchPromise);

      try {
        const data = await fetchPromise;
        return data;
      } finally {
        this.fetchPromises.delete(promiseKey);
      }
    } catch (error) {
      console.error('Error in getAllUserData:', error);
      throw error;
    }
  }

  private async fetchAllUserData(userId: string): Promise<UserDataResponse> {
    try {
      const [
        { data: userData, error: userError },
        { data: productsData, error: productsError },
        { data: accessesData, error: accessesError },
        { data: purchasesData, error: purchasesError }
      ] = await Promise.all([
        supabase
          .from('users')
          .select('*')
          .eq('id', userId)
          .single(),
        supabase
          .from('products')
          .select('*'),
        supabase
          .from('user_course_access')
          .select('*')
          .eq('user_id', userId),
        supabase
          .from('user_course_purchases')
          .select('*')
          .eq('user_id', userId)
      ]);

      if (userError) throw userError;
      if (productsError) throw productsError;
      if (accessesError) throw accessesError;
      if (purchasesError) throw purchasesError;

      const mentorshipAccess = accessesData?.find(
        access => access.course_id === COURSE_IDS.MENTORSHIP
      ) || null;

      const response: UserDataResponse = {
        user: userData as User,
        products: productsData as Product[],
        courseAccesses: accessesData as UserCourseAccess[],
        coursePurchases: purchasesData as UserCoursePurchase[],
        mentorshipAccess
      };

      setCachedData(
        CACHE_KEYS.USER_DATA(userId),
        response,
        CACHE_DURATION.USER_DATA
      );

      return response;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  }

  async refreshUserData(userId: string): Promise<UserDataResponse> {
    // Verify active session first
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      throw new Error('No active session');
    }

    // Clear user-specific cache
    Object.keys(localStorage).forEach(key => {
      if (key.includes(userId)) {
        localStorage.removeItem(key);
      }
    });

    return this.fetchAllUserData(userId);
  }

  async updateUserCourseAccess(
    userId: string,
    courseId: string,
    tierId: string
  ): Promise<void> {
    try {
      // Verify active session first
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('No active session');
      }

      const { error } = await supabase
        .from('user_course_access')
        .upsert(
          {
            user_id: userId,
            course_id: courseId,
            tier_id: tierId,
            updated_at: new Date().toISOString()
          },
          {
            onConflict: 'user_id,course_id'
          }
        );

      if (error) throw error;

      await this.refreshUserData(userId);
    } catch (error) {
      console.error('Error updating user course access:', error);
      throw error;
    }
  }
}

export const userDataService = UserDataService.getInstance();
// src/components/support/TicketDetail.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import { supabase } from '../../../services/supabase';
import { api, Ticket, Message, SupportUser } from '../../../services/api';
import { format } from 'date-fns';
import { 
  AlertCircle,
  ArrowLeft,
  Clock,
  Download,
  Eye,
  EyeOff,
  Paperclip,
  Send,
  UserCircle,
  Users,
  Loader2,
  ChevronDown,
  X
} from 'lucide-react';
import { Button } from '../../ui/Button';
import { Textarea } from '../../ui/Textarea';
import { Avatar } from '../../ui/Avatar';
import { Badge } from '../../ui/Badge';
import { ScrollArea } from '../../ui/ScrollArea';
import { Alert } from '../../ui/Alert';

interface AttachmentError {
  fileName: string;
  error: string;
}

interface AdminUser {
  id: string;
  email: string;
  full_name: string | null;
}

type AdminInfo = {
  email: string;
  full_name: string; // non-null as per Ticket interface
}

type TicketWithRelations = Omit<Ticket, 'user' | 'admin'> & {
  user: SupportUser;
  admin?: AdminInfo;
}

interface TicketDetailProps {
  ticketId: string;
  onBack: () => void;
  onStatusChange: (status: string) => void;
}

const TicketDetail: React.FC<TicketDetailProps> = ({ 
  ticketId, 
  onBack,
  onStatusChange
}) => {
  const { user } = useAuth();
  const [ticket, setTicket] = useState<TicketWithRelations | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [isInternal, setIsInternal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sending, setSending] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [errorDialog, setErrorDialog] = useState({ open: false, message: '' });
  const [successDialog, setSuccessDialog] = useState({ open: false, message: '' });
  const [assignDropdownOpen, setAssignDropdownOpen] = useState(false);
  const [adminUsers, setAdminUsers] = useState<Array<{
    id: string;
    email: string;
    full_name: string | null;
    role: string;
  }>>([]);
  const [loadingAdmins, setLoadingAdmins] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [attachmentErrors, setAttachmentErrors] = useState<AttachmentError[]>([]);
  const [uploading, setUploading] = useState(false);
  

  useEffect(() => {
    loadTicketData();
  }, [ticketId]);

  const loadTicketDetails = async () => {
    try {
      setLoading(true);
      setError(null);
  
      // First get the ticket with support user info
      const { data, error: ticketError } = await supabase
        .from('support_tickets')
        .select(`
          *,
          support_users (
            id,
            email,
            full_name,
            auth_user_id,
            email_domain,
            created_at,
            last_activity,
            metadata
          )
        `)
        .eq('id', ticketId)
        .single();
  
      if (ticketError) throw ticketError;
      if (!data) {
        throw new Error('Ticket not found');
      }
  
      // If there's an admin_id, get the admin info from auth.users
      let adminData = null;
      if (data.admin_id) {
        const { data: adminUser, error: adminError } = await supabase
          .from('users')  // Getting from public.users table for role info
          .select('id, email, full_name, role')
          .eq('id', data.admin_id)
          .single();
  
        if (!adminError && adminUser) {
          adminData = adminUser;
        }
      }
  
      // Transform the data to match our TicketWithRelations type
      const transformedTicket: TicketWithRelations = {
        ...data,
        user: data.support_users || {
          id: 'unknown',
          email: 'unknown',
          full_name: 'Unknown User',
          auth_user_id: null,
          email_domain: '',
          created_at: new Date().toISOString(),
          last_activity: new Date().toISOString(),
          metadata: {}
        },
        admin: adminData ? {
          email: adminData.email,
          full_name: adminData.full_name || 'Unknown Admin'
        } : undefined,
        _count: {
          messages: 0
        }
      };
  
      setTicket(transformedTicket);
  
    } catch (error) {
      console.error('Error loading ticket details:', error);
      setError(error instanceof Error ? error.message : 'Failed to load ticket details');
    } finally {
      setLoading(false);
    }
  };
  
  // Update loadAdminUsers to use public.users table
  const loadAdminUsers = async () => {
    setLoadingAdmins(true);
    try {
      // Check if current user has staff role in public.users
      const { data: currentUser, error: roleError } = await supabase
        .from('users')
        .select('role')
        .eq('id', user?.id)
        .single();
  
      if (roleError) throw roleError;
  
      if (!currentUser?.role || !['admin', 'moderator'].includes(currentUser.role)) {
        throw new Error('Unauthorized - Staff access required');
      }
  
      // Get all staff users from public.users table
      const { data: staffUsers, error } = await supabase
        .from('users')
        .select('id, email, full_name, role')
        .in('role', ['admin', 'moderator'])
        .neq('id', user?.id)
        .order('role', { ascending: false })
        .order('full_name');
  
      if (error) {
        console.error('Query error:', error);
        throw error;
      }
  
      setAdminUsers(staffUsers || []);
  
    } catch (error) {
      console.error('Error loading staff users:', error);
      setErrorDialog({
        open: true,
        message: error instanceof Error ? error.message : 'Failed to load staff users'
      });
    } finally {
      setLoadingAdmins(false);
    }
  };
  
  // Update the handleAssignTicket function to use public.users
  const handleAssignTicket = async (adminId: string) => {
    try {
      if (!user?.id) {
        throw new Error('No active session');
      }
  
      // Check if user has staff privileges in public.users
      const { data: currentUser, error: roleError } = await supabase
        .from('users')
        .select('role')
        .eq('id', user.id)
        .single();
  
      if (roleError) throw roleError;
  
      if (!currentUser?.role || !['admin', 'moderator'].includes(currentUser.role)) {
        throw new Error('Unauthorized - Staff access required');
      }
  
      // Get support user for admin
      const { data: supportUser, error: supportError } = await supabase
        .from('support_users')
        .select('id')
        .eq('auth_user_id', user.id)
        .single();
  
      if (supportError) throw supportError;
  
      // Update ticket assignment
      const { error: updateError } = await supabase
        .from('support_tickets')
        .update({
          admin_id: adminId,
          updated_at: new Date().toISOString(),
          last_activity_at: new Date().toISOString()
        })
        .eq('id', ticketId);
  
      if (updateError) throw updateError;
  
      // Find assigned admin details
      const assignedAdmin = adminUsers.find(admin => admin.id === adminId);
      const assignmentMessage = `Ticket assigned to ${assignedAdmin?.full_name || assignedAdmin?.email}`;
      
      // Create internal message for assignment
      const { error: messageError } = await supabase
        .from('support_messages')
        .insert({
          ticket_id: ticketId,
          support_user_id: supportUser.id,
          content: assignmentMessage,
          is_internal: true,
          message_type: 'system'
        });
  
      if (messageError) throw messageError;
  
      // Reload ticket data and update UI
      await loadTicketData();
      setAssignDropdownOpen(false);
      
      setSuccessDialog({
        open: true,
        message: `Ticket assigned to ${assignedAdmin?.full_name || assignedAdmin?.email}`
      });
    } catch (error) {
      console.error('Error assigning ticket:', error);
      setErrorDialog({
        open: true,
        message: error instanceof Error ? error.message : 'Failed to assign ticket'
      });
    }
  };

  useEffect(() => {
    loadTicketDetails();
  }, [ticketId]);
  
  const loadMessages = async () => {
    try {
      // Get the ticket for initial message
      const { data: ticketData } = await supabase
        .from('support_tickets')
        .select(`
          *,
          user:support_users!support_tickets_support_user_id_fkey(
            id,
            email,
            full_name
          )
        `)
        .eq('id', ticketId)
        .single();
  
      // Get subsequent messages
      const { data: messagesData, error: messagesError } = await supabase
        .from('support_messages')
        .select(`
          *,
          user:support_users!support_messages_support_user_id_fkey(
            id,
            email,
            full_name
          )
        `)
        .eq('ticket_id', ticketId)
        .order('created_at', { ascending: true });
  
      if (messagesError) throw messagesError;
  
      // Create initial message
      const initialMessage = ticketData ? {
        id: ticketData.id,
        ticket_id: ticketData.id,
        support_user_id: ticketData.support_user_id,
        content: ticketData.body,
        is_internal: false,
        created_at: ticketData.created_at,
        attachments: ticketData.attachments || [],
        user: ticketData.user || {
          id: 'unknown',
          email: 'Unknown',
          full_name: 'Unknown User'
        },
        message_type: 'inbound',
        email_sent: false,
        is_read: true,
        email_message_id: ticketData.original_email_id,
        from_email: ticketData.original_from_email,
        to_emails: null,
        email_subject: ticketData.original_subject,
        email_references: []
      } : null;
  
      // Combine messages
      const allMessages = [
        ...(initialMessage ? [initialMessage] : []),
        ...(messagesData || [])
      ];
  
      setMessages(allMessages);
  
    } catch (error) {
      console.error('Error in loadMessages:', error);
      setErrorDialog({
        open: true,
        message: 'Failed to load messages'
      });
    }
  };
  
  const loadTicketData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        loadTicketDetails(),
        loadMessages()
      ]);
    } catch (error) {
      console.error('Error loading ticket data:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Update useEffect to check database role
  useEffect(() => {
    const checkAndLoadAdmins = async () => {
      if (!user?.id) return;
  
      // Check user's role from database
      const { data: currentUser } = await supabase
        .from('users')
        .select('role')
        .eq('id', user.id)
        .single();
  
      if (currentUser?.role && ['admin', 'moderator'].includes(currentUser.role)) {
        loadAdminUsers();
      }
    };
    
    checkAndLoadAdmins();
  }, [user?.id]);

  const formatEmailContent = (content: string): string => {
    // Convert newlines to <br> tags
    let formatted = content.replace(/\n/g, '<br>');
    
    // Convert URLs to clickable links
    formatted = formatted.replace(
      /(https?:\/\/[^\s]+)/g, 
      '<a href="$1" target="_blank" style="color: #0066cc; text-decoration: underline;">$1</a>'
    );
  
    // Wrap the content in a styled div
    return `
      <div style="font-family: Arial, sans-serif; color: #333333; line-height: 1.6; padding: 20px;">
        ${formatted}
        <br><br>
        <div style="color: #666666; font-size: 12px; border-top: 1px solid #eeeeee; margin-top: 20px; padding-top: 20px;">
          <p>Kesler CPA Review Support Team</p>
          <p>Need help? Reply to this email or visit our <a href="https://app.keslercpareview.com/user/support/tickets" style="color: #0066cc; text-decoration: underline;">Support Center</a></p>
        </div>
      </div>
    `;
  };
  
  // Then update the handleStatusClick function to use the formatted content:
  const handleStatusClick = async (status: 'new' | 'open' | 'pending' | 'closed') => {
    if (!user || (!newMessage.trim() && attachments.length === 0)) return;
    
    setSending(true);
    try {
      // First get or ensure support user exists for the authenticated user
      const { data: supportUser, error: supportError } = await supabase
        .from('support_users')
        .select('id')
        .eq('auth_user_id', user.id)
        .single();

      if (supportError) {
        throw new Error('Failed to get support user');
      }

      // Format the content before sending
      const formattedContent = formatEmailContent(newMessage);
      
      // Use support_user_id instead of user.id
      const message = await api.sendTicketResponse(
        ticketId,
        supportUser.id, // Changed from user.id to support_user_id
        formattedContent,
        isInternal,
        attachments,
        `Re: [Ticket #${ticket?.short_id}] ${ticket?.subject.replace(/^Re: \[#[^\]]+\]\s*/, '')}`
      );
  
      // Update UI with the new message
      setMessages(prev => [...prev, message]);
      
      // Update ticket status
      if (status !== 'pending') {
        await api.updateTicket(ticketId, {
          status,
          updated_at: new Date().toISOString(),
          last_activity_at: new Date().toISOString(),
          ...(status === 'closed' ? { closed_at: new Date().toISOString() } : {}),
          ...(status !== 'closed' ? { closed_at: null } : {})
        });
      }
  
      // Reset form state
      setNewMessage('');
      setAttachments([]);
      setIsInternal(false);
      setDropdownOpen(false);
      
      onStatusChange(status);
  
      setSuccessDialog({
        open: true,
        message: `Message sent and ticket marked as ${status}`
      });
  
      await new Promise(resolve => setTimeout(resolve, 500));
      onBack();
  
    } catch (error) {
      console.error('Error in handleStatusClick:', error);
      setErrorDialog({
        open: true,
        message: 'Failed to send message. Please try again.'
      });
    } finally {
      setSending(false);
    }
  };

   // Updated message thread check
   const isAdminMessage = (message: Message) => {
    if (!ticket) return false;
    
    // First check if message is from a support user with auth_user_id
    if (message.user.auth_user_id) {
      // Check if the auth user is an admin
      return adminUsers.some(admin => admin.id === message.user.auth_user_id);
    }
    
    // If no auth_user_id, it's a regular support user (customer)
    return false;
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    
    setUploading(true);
    const newErrors: AttachmentError[] = [];
    const validFiles: File[] = [];

    for (const file of Array.from(event.target.files)) {
      try {
        // Check file type
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif'];
        if (!allowedTypes.includes(file.type)) {
          newErrors.push({
            fileName: file.name,
            error: `Only PDF and images are permitted`
          });
          continue;
        }

        // Check file size (10MB limit)
        if (file.size > 10 * 1024 * 1024) {
          newErrors.push({
            fileName: file.name,
            error: `File exceeds 10MB limit`
          });
          continue;
        }

        validFiles.push(file);
      } catch (error) {
        newErrors.push({
          fileName: file.name,
          error: 'Failed to process file'
        });
      }
    }

    // Add valid files to attachments
    if (validFiles.length) {
      setAttachments(prev => [...prev, ...validFiles]);
    }

    // Set errors if any
    if (newErrors.length) {
      setAttachmentErrors(newErrors);
    }

    setUploading(false);
    if (event.target.value) {
      event.target.value = '';
    }
  };

  // Add function to remove specific error
  const removeError = (fileName: string) => {
    setAttachmentErrors(errors => errors.filter(e => e.fileName !== fileName));
  };

  // Update removeAttachment to also remove any associated errors
  const removeAttachment = (index: number) => {
    setAttachments(attachments.filter((_, i) => i !== index));
    setAttachmentErrors(errors => errors.filter(e => e.fileName !== attachments[index].name));
  };

  const getPriorityColor = (priority: string) => {
    const colors = {
      urgent: 'text-red-500 bg-red-100 dark:bg-red-900/20',
      high: 'text-orange-500 bg-orange-100 dark:bg-orange-900/20',
      medium: 'text-yellow-500 bg-yellow-100 dark:bg-yellow-900/20',
      low: 'text-gray-500 bg-gray-100 dark:bg-gray-900/20'
    };
    return colors[priority as keyof typeof colors] || colors.low;
  };

  const getTimeDisplay = (date: string) => {
    const messageDate = new Date(date);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (messageDate.toDateString() === today.toDateString()) {
      return `Today at ${format(messageDate, 'h:mm a')}`;
    } else if (messageDate.toDateString() === yesterday.toDateString()) {
      return `Yesterday at ${format(messageDate, 'h:mm a')}`;
    } else {
      return format(messageDate, 'MMM d, yyyy h:mm a');
    }
  };
  
  const renderTicketBody = (content: string) => {
    // Create a DOMParser to parse the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    
    // Add base styles that will override the inline styles from the email
    const styleTag = `
      <style>
        /* Base styles */
        .ticket-content {
          font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
          line-height: 1.6;
        }
        
        /* Light mode styles */
        .ticket-content {
          --text-primary: #333333;
          --text-secondary: #666666;
          --border-color: #eeeeee;
          --link-color: #0066cc;
          color: var(--text-primary);
        }
        
        /* Dark mode styles */
        :root.dark .ticket-content,
        [data-theme="dark"] .ticket-content {
          --text-primary: #e5e7eb;
          --text-secondary: #9ca3af;
          --border-color: #374151;
          --link-color: #60a5fa;
          color: var(--text-primary);
        }
        
        /* Style overrides */
        .ticket-content [style*="color: #333333"] {
          color: var(--text-primary) !important;
        }
        
        .ticket-content [style*="color: #666666"] {
          color: var(--text-secondary) !important;
        }
        
        .ticket-content [style*="border-top: 1px solid #eeeeee"],
        .ticket-content [style*="border: 1px solid #eeeeee"] {
          border-color: var(--border-color) !important;
        }
        
        .ticket-content a {
          color: var(--link-color) !important;
          text-decoration: underline;
        }
        
        .ticket-content a:hover {
          text-decoration: none;
        }
        
        /* Preserve whitespace and line breaks */
        .ticket-content br {
          display: block;
          content: "";
          margin-top: 0.5em;
        }
        
        /* Additional spacing for paragraphs */
        .ticket-content p {
          margin: 0.5em 0;
        }
  
        /* Dark mode link hover state */
        :root.dark .ticket-content a:hover,
        [data-theme="dark"] .ticket-content a:hover {
          color: #93c5fd !important;
        }
  
        /* Support footer styling */
        .ticket-content div[style*="border-top: 1px solid #eeeeee"] {
          margin-top: 1.25em;
          padding-top: 1.25em;
          border-top: 1px solid var(--border-color) !important;
        }
      </style>
    `;
  
    // Wrap the content in our styled container
    const wrappedContent = `
      <div class="ticket-content">
        ${doc.body.innerHTML}
      </div>
    `;
  
    return (
      <div 
        dangerouslySetInnerHTML={{ 
          __html: styleTag + wrappedContent 
        }}
        className="prose dark:prose-invert max-w-none"
      />
    );
  };

  return (
    <div className="h-full flex flex-col">
      {/* Header */}
      <div className="flex-none px-4 md:px-6 py-3 md:py-4 bg-white dark:bg-gray-800 border-b dark:border-gray-700 shadow-sm">
        <div className="flex items-center gap-3 md:gap-4">
          <Button 
            variant="outline"
            onClick={onBack}
            className="w-10 h-10 md:w-12 md:h-12 p-0 flex items-center justify-center dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 dark:text-gray-100"
          >
            <ArrowLeft className="w-5 h-5 md:w-6 md:h-6 dark:text-gray-100" />
          </Button>
          <div className="flex-1 min-w-0">
            <h2 className="text-lg md:text-xl font-semibold truncate">{ticket?.subject}</h2>
            <div className="flex items-center gap-2 text-xs md:text-sm text-gray-500">
              <span className="flex items-center gap-1">
                <UserCircle className="w-4 h-4" />
                {ticket?.user?.full_name}
              </span>
              <span className="text-gray-400">•</span>
              <span className="flex items-center gap-1">
                <Clock className="w-4 h-4" />
                {ticket?.created_at && getTimeDisplay(ticket.created_at)}
              </span>
            </div>
          </div>
          <Badge 
            variant={ticket?.priority === 'urgent' ? 'destructive' : 'secondary'}
            className="capitalize text-xs md:text-sm"
          >
            {ticket?.priority}
          </Badge>
        </div>
      </div>

      {/* Message Thread */}
      <div className="flex-1 min-h-0 overflow-hidden">
        <ScrollArea className="h-[calc(100vh-400px)]">
          <div className="p-4 md:p-6 space-y-4 md:space-y-6">
            {/* Initial Ticket */}
            {ticket && (
              <div className="flex gap-3 md:gap-4">
                <Avatar 
                  name={ticket.user.full_name || 'Unknown User'}
                  className="w-8 h-8 md:w-10 md:h-10 bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-200"
                />
                <div className="flex-1">
                  <div className="rounded-lg shadow-sm p-3 md:p-4 bg-green-50 dark:bg-green-900/30 border border-green-100 dark:border-green-800">
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm md:text-base font-medium">{ticket.user.full_name}</span>
                      <span className="text-xs md:text-sm text-gray-500">
                        {getTimeDisplay(ticket.created_at)}
                      </span>
                    </div>
                    <div className="prose dark:prose-invert max-w-none text-sm md:text-base">
                      {renderTicketBody(ticket.body)}
                    </div>
                    {/* Add attachment display for initial ticket */}
                    {ticket.attachments && ticket.attachments.length > 0 && (
                      <div className="mt-4 space-y-2">
                        {ticket.attachments.map((attachment, idx) => (
                          <a
                            key={idx}
                            href={attachment.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-2 text-xs md:text-sm text-blue-500 hover:text-blue-600"
                          >
                            <Paperclip className="w-4 h-4" />
                            <span className="flex-1 truncate">{attachment.name}</span>
                            <Download className="w-4 h-4 flex-shrink-0" />
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Message Thread */}
            {messages.filter(message => message.id !== ticket?.id).map((message) => {
              const isAdmin = isAdminMessage(message);
              return (
                <div key={message.id} className={`flex gap-3 md:gap-4 ${isAdmin ? 'flex-row-reverse' : ''}`}>
                  <Avatar 
                    name={message.user.full_name || 'Unknown User'}
                    className={`w-8 h-8 md:w-10 md:h-10 ${
                      isAdmin 
                        ? 'bg-purple-100 text-purple-600 dark:bg-purple-900 dark:text-purple-200'
                        : 'bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-200'
                    }`}
                  />
                  <div className="flex-1">
                    <div className={`
                      rounded-lg shadow-sm p-3 md:p-4
                      ${message.is_internal 
                        ? 'bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800' 
                        : isAdmin
                          ? 'bg-blue-50 dark:bg-blue-800/50 border border-blue-100 dark:border-blue-800'
                          : 'bg-green-50 dark:bg-green-900/30 border border-green-100 dark:border-green-800'
                      }
                    `}>
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center gap-2">
                          <span className="text-sm md:text-base font-medium">{message.user.full_name}</span>
                          {message.is_internal && (
                            <Badge variant="secondary" className="text-xs">Internal Note</Badge>
                          )}
                          {isAdmin && (
                            <Badge variant="secondary" className="text-xs bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200">Support Agent</Badge>
                          )}
                        </div>
                        <span className="text-xs md:text-sm text-gray-500">
                          {getTimeDisplay(message.created_at)}
                        </span>
                      </div>
                      <div className="prose dark:prose-invert max-w-none text-sm md:text-base">
                        {renderTicketBody(message.content)}
                      </div>
                      {message.attachments && message.attachments.length > 0 && (
                        <div className="mt-4 space-y-2">
                          {message.attachments.map((attachment, idx) => (
                            <a
                              key={idx}
                              href={attachment.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex items-center gap-2 text-xs md:text-sm text-blue-500 hover:text-blue-600"
                            >
                              <Paperclip className="w-4 h-4" />
                              <span className="flex-1 truncate">{attachment.name}</span>
                              <Download className="w-4 h-4 flex-shrink-0" />
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </ScrollArea>
      </div>

      {/* Reply Section */}
      <div className="flex-none bg-white dark:bg-gray-800 border-t dark:border-gray-700">
        {/* Attachment Errors */}
        {attachmentErrors.length > 0 && (
          <div className="px-3 md:px-4 pt-3">
            {attachmentErrors.map((error, index) => (
              <Alert 
                key={index}
                variant="destructive"
                className="mb-2 flex items-center justify-between"
              >
                <div className="flex items-center gap-2">
                  <AlertCircle className="h-4 w-4 flex-shrink-0" />
                  <span className="text-sm font-medium">
                    {error.fileName}:
                  </span>
                  <span className="text-sm">
                    {error.error}
                  </span>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeError(error.fileName)}
                  className="h-6 w-6 p-0 hover:bg-red-200 dark:hover:bg-red-900/50"
                >
                  <X className="h-4 w-4" />
                </Button>
              </Alert>
            ))}
          </div>
        )}

        {/* Attachments Display */}
        <div className="px-3 md:px-4 pt-3">
          {attachments.map((file, index) => (
            <div key={index} className="flex items-center gap-2 text-xs md:text-sm bg-gray-50 dark:bg-gray-700 p-2 rounded mb-2">
              <Paperclip className="w-4 h-4" />
              <span className="flex-1 truncate">{file.name}</span>
              <Button
                variant="ghost"
                onClick={() => removeAttachment(index)}
                className="h-6 w-6 p-0"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          ))}
        </div>

        

       {/* Text Area */}
       <div className="p-3 md:p-4 pt-2">
          <Textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder={isInternal ? "Add an internal note..." : "Type your reply..."}
            rows={4}
            className="w-full mb-4 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 border-gray-200 dark:border-gray-700 focus:ring-blue-500 dark:focus:ring-blue-400 resize-y min-h-[80px] md:min-h-[100px] max-h-[300px] text-sm md:text-base"
          />

          {/* Action Buttons */}
          <div className="flex items-center justify-between gap-3 md:gap-4">
            {/* Left Side Buttons */}
            <div className="flex items-center gap-2 relative">
              {/* Assign Ticket Button */}
              <Button
                variant="outline"
                onClick={() => setAssignDropdownOpen(!assignDropdownOpen)}
                disabled={loadingAdmins}
                className="text-xs md:text-sm flex items-center gap-1 md:gap-2 dark:border-gray-600 dark:text-gray-100 dark:hover:bg-gray-700 h-8 md:h-10"
              >
                {loadingAdmins ? (
                  <Loader2 className="w-4 h-4 animate-spin" />
                ) : (
                  <Users className="w-4 h-4" />
                )}
                <span className="hidden md:inline">Assign Ticket</span>
                <ChevronDown className="w-4 h-4" />
              </Button>

              {assignDropdownOpen && (
                <div className="absolute bottom-full mt-1 left-0 w-56 md:w-64 bg-white dark:bg-gray-800 rounded-md shadow-lg border dark:border-gray-700 py-1 z-50">
                  {adminUsers.length === 0 ? (
                    <div className="px-3 md:px-4 py-2">
                      <div className="text-xs md:text-sm text-gray-500 dark:text-gray-400">
                        No other staff members available
                      </div>
                      <div className="text-xs mt-1 text-gray-400 dark:text-gray-500">
                        Current user: {user?.email}
                      </div>
                    </div>
                  ) : (
                    adminUsers.map((admin) => (
                      <button
                        key={admin.id}
                        onClick={() => handleAssignTicket(admin.id)}
                        className="w-full text-left px-3 md:px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center gap-1.5 md:gap-2"
                      >
                        <UserCircle className="w-4 h-4 flex-shrink-0" />
                        <div className="flex flex-col flex-1 min-w-0">
                          <span className="text-xs md:text-sm font-medium truncate">
                            {admin.full_name || admin.email}
                          </span>
                          <span className="text-xs text-gray-500 dark:text-gray-400 truncate">
                            {admin.email}
                          </span>
                        </div>
                        <Badge 
                          variant={admin.role === 'admin' ? 'default' : 'secondary'}
                          className="ml-1 md:ml-2 text-[10px] md:text-xs whitespace-nowrap"
                        >
                          {admin.role}
                        </Badge>
                      </button>
                    ))
                  )}
                </div>
              )}

              {/* Attachment Button */}
              <Button
                variant="outline"
                onClick={() => fileInputRef.current?.click()}
                className="text-xs md:text-sm flex items-center gap-1 md:gap-2 dark:border-gray-600 dark:text-gray-100 dark:hover:bg-gray-700 h-8 md:h-10"
              >
                <Paperclip className="w-4 h-4" />
              </Button>

              {/* Internal Note Toggle */}
              <Button
                variant="outline"
                onClick={() => setIsInternal(!isInternal)}
                className="text-xs md:text-sm flex items-center gap-1 md:gap-2 dark:border-gray-600 dark:text-gray-100 dark:hover:bg-gray-700 h-8 md:h-10"
              >
                {isInternal ? (
                  <>
                    <EyeOff className="w-4 h-4" />
                    <span className="hidden md:inline">Internal Note</span>
                  </>
                ) : (
                  <Eye className="w-4 h-4" />
                )}
              </Button>
            </div>

            {/* Right Side - Send Message Button */}
            <div className="relative">
              <Button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                disabled={sending || (!newMessage.trim() && attachments.length === 0)}
                className="text-xs md:text-sm flex items-center gap-1 md:gap-2 dark:text-gray-100 h-8 md:h-10"
              >
                {sending ? (
                  <Loader2 className="w-4 h-4 animate-spin" />
                ) : (
                  <Send className="w-4 h-4" />
                )}
                <span>Send</span>
              </Button>

              {dropdownOpen && (
                <div className="absolute bottom-full mb-1 right-0 w-40 md:w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg border dark:border-gray-700 py-1 z-50">
                  <button
                    onClick={() => handleStatusClick('new')}
                    className="w-full text-left px-3 md:px-4 py-2 text-xs md:text-sm hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-100"
                  >
                    Send as New
                  </button>
                  <button
                    onClick={() => handleStatusClick('open')}
                    className="w-full text-left px-3 md:px-4 py-2 text-xs md:text-sm hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-100"
                  >
                    Send as Open
                  </button>
                  <button
                    onClick={() => handleStatusClick('pending')}
                    className="w-full text-left px-3 md:px-4 py-2 text-xs md:text-sm hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-100"
                  >
                    Send as Pending
                  </button>
                  <button
                    onClick={() => handleStatusClick('closed')}
                    className="w-full text-left px-3 md:px-4 py-2 text-xs md:text-sm hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-100"
                  >
                    Send as Solved
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Hidden file input */}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
            multiple
            accept=".pdf,.jpg,.jpeg,.png,.gif"
          />
        </div>
      </div>
    </div>
  );
}

export default TicketDetail;
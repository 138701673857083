import { QueryClient, QueryCache, Query, QueryObserver } from 'react-query';

// Define the QueryCacheNotifyEvent type to handle different cache events
type QueryCacheNotifyEvent =
  | { type: 'queryAdded'; query: Query }
  | { type: 'queryRemoved'; query: Query }
  | { type: 'queryUpdated'; query: Query; action: unknown }
  | { type: 'observerAdded'; query: Query; observer: QueryObserver }
  | { type: 'observerRemoved'; query: Query; observer: QueryObserver }
  | { type: 'observerResultsUpdated'; query: QueryObserver };

// Create a new QueryCache instance with proper error handling
const queryCache = new QueryCache({
  onError: (error: unknown) => {
    console.error('React Query cache error:', error);
  },
});

// Create the client with correct configuration
export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      refetchOnWindowFocus: false, // Explicitly disable refetch on window focus
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false, // Disable retries by default
    },
  },
});
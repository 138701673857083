import React, { useEffect } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  noDarkOverlay?: boolean;
  allowClose?: boolean;
}

export const Modal: React.FC<ModalProps> = ({ 
  isOpen, 
  onClose, 
  children, 
  noDarkOverlay = false, 
  allowClose = true 
}) => {
  useEffect(() => {
    if (isOpen) {
      // Only add padding and hide overflow if there's a dark overlay
      if (!noDarkOverlay) {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflow = 'hidden';
      }
    } else {
      // Always reset styles when modal is closed
      document.body.style.paddingRight = '0';
      document.body.style.overflow = 'unset';
    }
    return () => {
      // Clean up styles when component unmounts
      document.body.style.paddingRight = '0';
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, noDarkOverlay]);

  if (!isOpen) return null;

  return (
    <div 
      className={`fixed inset-0 z-50 ${
        noDarkOverlay 
          ? 'pointer-events-none' // Allow clicks to pass through when no dark overlay
          : 'flex items-center justify-center'
      }`}
      aria-modal="true"
      role="dialog"
    >
      {!noDarkOverlay && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
          aria-hidden="true"
          onClick={allowClose ? onClose : undefined}
        ></div>
      )}
      <div 
        className={`relative z-50 w-full ${
          noDarkOverlay 
            ? 'pointer-events-auto' // Re-enable pointer events for the modal content
            : 'flex items-center justify-center min-h-screen'
        }`}
      >
        {children}
      </div>
    </div>
  );
};
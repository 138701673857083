import React from 'react';

interface LoadingScreenProps {
  message: string;
  subMessage?: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ message, subMessage }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4 sm:px-0">
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg w-full max-w-[90%] sm:max-w-md text-center">
      <div className="animate-spin rounded-full h-12 w-12 sm:h-16 sm:w-16 border-t-2 border-b-2 border-blue-500 mx-auto mb-3 sm:mb-4"></div>
      <p className="text-lg sm:text-xl font-semibold break-words">{message}</p>
      {subMessage && (
        <p className="text-sm sm:text-base text-gray-600 mt-2 break-words">
          {subMessage}
        </p>
      )}
    </div>
  </div>
);

export default LoadingScreen;
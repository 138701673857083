import { useEffect } from 'react';

const useKeyPress = (targetKey: string, action: (event?: KeyboardEvent) => void, isEnabled: () => boolean) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (isEnabled() && event.key === targetKey) {
        event.preventDefault();
        action(event);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [targetKey, action, isEnabled]);
};

export default useKeyPress;
import { 
    TIER_IDS, 
    Product, 
    UserCourseAccess, 
    UserCoursePurchase,
    UserAccessState,
    CACHE_KEYS,
    COURSE_IDS
  } from '../services/api';
  import { supabase } from '../services/supabase';
  
  interface CacheData<T> {
    data: T;
    expiry: number;
  }
  
  export async function verifySession(): Promise<boolean> {
    const { data: { session } } = await supabase.auth.getSession();
    return session !== null;
  }
  
  export function getUserProductAccess(
    product: Product,
    userAccesses: UserCourseAccess[],
    userPurchases: UserCoursePurchase[],
    mentorshipAccess?: UserCourseAccess
  ): UserAccessState {
    const currentAccess = userAccesses.find(access => access.course_id === product.id);
    
    // Check both mentorship access and current course's tier for mentor access
    const hasMentorAccess = 
      mentorshipAccess?.tier_id === TIER_IDS.MENTOR_ONLY || 
      mentorshipAccess?.tier_id === TIER_IDS.FULL_ACCESS ||
      currentAccess?.tier_id === TIER_IDS.FULL_ACCESS;
  
    const defaultTier = hasMentorAccess ? TIER_IDS.MENTOR_ONLY : TIER_IDS.FREE_TRIAL;
    const currentTier = currentAccess?.tier_id || defaultTier;
  
    // Rest of the function stays the same...
    const hasActivePaidPurchase = userPurchases.some(purchase => 
      (purchase.tier_id === TIER_IDS.FULL_ACCESS || 
       purchase.tier_id === TIER_IDS.STUDY_MATERIALS_ONLY) &&
      purchase.is_active &&
      (purchase.subscription_status === 'active' || purchase.subscription_status === null)
    );
  
    const isActivatable = hasActivePaidPurchase && 
      currentTier !== TIER_IDS.FULL_ACCESS && 
      currentTier !== TIER_IDS.STUDY_MATERIALS_ONLY;
  
    const hasFullAccess = currentTier === TIER_IDS.FULL_ACCESS || 
      currentTier === TIER_IDS.STUDY_MATERIALS_ONLY;
  
    return {
      product,
      currentTier,
      isActivatable,
      hasFullAccess,
      hasMentorAccess
    };
  }
  
  export function clearAllUserCache(userId: string): void {
    try {
      // Clear course-specific caches
      Object.values(COURSE_IDS).forEach(courseId => {
        if (typeof courseId === 'string') {
          localStorage.removeItem(CACHE_KEYS.USER_COURSE_ACCESS(userId, courseId));
        }
      });
  
      // Clear user-specific caches
      localStorage.removeItem(CACHE_KEYS.USER_DATA(userId));
      localStorage.removeItem(CACHE_KEYS.USER_ACCESS(userId));
      localStorage.removeItem(CACHE_KEYS.USER_COURSE_PURCHASES(userId));
  
      // Clear any legacy cache keys
      const keys = Object.keys(localStorage);
      keys.forEach(key => {
        if (key.includes(userId)) {
          localStorage.removeItem(key);
        }
      });
    } catch (error) {
      console.error('Error clearing user cache:', error);
      // Last resort: clear everything
      localStorage.clear();
    }
  }
  
  export function getCachedData<T>(key: string): T | null {
    try {
      const cachedItem = localStorage.getItem(key);
      if (!cachedItem) return null;
  
      const { data, expiry } = JSON.parse(cachedItem) as CacheData<T>;
      if (expiry > Date.now()) {
        return data;
      }
      
      localStorage.removeItem(key);
      return null;
    } catch (error) {
      console.error('Error parsing cached data:', error);
      localStorage.removeItem(key);
      return null;
    }
  }
  
  export function setCachedData<T>(key: string, data: T, duration: number): void {
    try {
      const cacheData: CacheData<T> = {
        data,
        expiry: Date.now() + duration
      };
      localStorage.setItem(key, JSON.stringify(cacheData));
    } catch (error) {
      console.error('Error setting cached data:', error);
      // If we hit storage limits, clear all cache
      localStorage.clear();
    }
  }
  
  export function shouldShowUpgradeButton(accessState: UserAccessState): boolean {
    return !accessState.hasFullAccess && !accessState.isActivatable;
  }
  
  export function shouldShowMentorButton(accessState: UserAccessState): boolean {
    return accessState.hasMentorAccess;
  }
  
  export function shouldShowActivateButton(
    accessState: UserAccessState,
    switchesAvailable: number
  ): boolean {
    return accessState.isActivatable && switchesAvailable > 0;
  }
  
  // Helper function to handle errors consistently
  export function handleCacheError(error: unknown, userId?: string): void {
    console.error('Cache operation error:', error);
    if (userId) {
      clearAllUserCache(userId);
    } else {
      localStorage.clear();
    }
  }
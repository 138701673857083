import React, { createContext, useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Session } from '@supabase/supabase-js';
import { supabase } from '../services/supabase';
import { api, User, SupportUser } from '../services/api';
import { userDataService } from '../services/userDataService';

interface AuthContextType {
  user: User | null;
  supportUser: SupportUser | null;
  session: Session | null;
  loading: boolean;
  error: Error | null;
  refreshUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authState, setAuthState] = useState<AuthContextType>({
    user: null,
    supportUser: null,
    session: null,
    loading: true,
    error: null,
    refreshUser: async () => {},
  });

  const initializingRef = useRef(true);
  const lastAuthCheckRef = useRef<Date | null>(null);
  const sessionRef = useRef<Session | null>(null);

  const logAuthEvent = (event: string, details?: any) => {
    console.log(`[Auth Event] ${event}:`, {
      timestamp: new Date().toISOString(),
      userId: authState.user?.id,
      isInitializing: initializingRef.current,
      ...details
    });
  };

  const processSession = useCallback(async (session: Session | null) => {
    // Store the session reference
    sessionRef.current = session;

    if (!session?.user) {
      setAuthState(prev => ({
        ...prev,
        user: null,
        supportUser: null,
        session: null,
        loading: false,
        error: null
      }));
      return;
    }

    // If we already have a user and the session ID matches, skip refetch
    if (authState.user && authState.session?.user.id === session.user.id) {
      logAuthEvent('Skipping user data fetch - session unchanged');
      return;
    }

    try {
      logAuthEvent('Fetching user data', { sessionId: session.user.id });
      const userData = await userDataService.getAllUserData(session.user.id);
      
      if (!userData.user) {
        throw new Error('No user data returned');
      }

      // Get or create support user
      const supportUser = await api.getOrCreateSupportUser(
        userData.user.email || '',
        userData.user.full_name || ''
      );

      logAuthEvent('User data fetched successfully', { 
        userId: userData.user.id,
        accessesCount: userData.courseAccesses.length,
        purchasesCount: userData.coursePurchases.length
      });

      setAuthState(prev => ({
        ...prev,
        user: userData.user,
        supportUser,
        session,
        loading: false,
        error: null
      }));
    } catch (error) {
      logAuthEvent('Error processing session', { error });
      setAuthState(prev => ({
        ...prev,
        user: null,
        supportUser: null,
        session: null,
        loading: false,
        error: error as Error
      }));
    }
  }, [authState.user, authState.session]);

  // Modify visibility change handler to be more conservative
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        const now = new Date();
        const lastCheck = lastAuthCheckRef.current;
        const timeSinceLastCheck = lastCheck ? now.getTime() - lastCheck.getTime() : Infinity;

        // Only perform auth check if:
        // 1. It's been more than 15 minutes since last check
        // 2. We have a session but no user data
        // 3. The session might be expired (over 30 minutes old)
        const shouldCheck = 
          timeSinceLastCheck > 15 * 60 * 1000 || // 15 minutes
          (sessionRef.current && !authState.user) ||
          (sessionRef.current && sessionRef.current.expires_at && 
           now.getTime() - new Date(sessionRef.current.expires_at).getTime() > 30 * 60 * 1000);

        if (shouldCheck) {
          logAuthEvent('Performing auth check on visibility change', {
            timeSinceLastCheck,
            hasSession: !!sessionRef.current,
            hasUser: !!authState.user
          });

          const { data: { session } } = await supabase.auth.getSession();
          if (session) {
            await processSession(session);
          }
          lastAuthCheckRef.current = now;
        } else {
          logAuthEvent('Skipping auth check on visibility change', {
            timeSinceLastCheck,
            hasSession: !!sessionRef.current,
            hasUser: !!authState.user
          });
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [processSession, authState.user]);

  // Initialize auth state
  useEffect(() => {
    const initialize = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          await processSession(session);
        } else {
          setAuthState(prev => ({
            ...prev,
            loading: false
          }));
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
        setAuthState(prev => ({
          ...prev,
          loading: false,
          error: error as Error
        }));
      } finally {
        initializingRef.current = false;
      }
    };

    initialize();
  }, [processSession]);

  // Handle auth state changes
  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (_, session) => {
      if (!initializingRef.current) {
        await processSession(session);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [processSession]);

  const refreshUser = useCallback(async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      await processSession(session);
    }
  }, [processSession]);

  useEffect(() => {
    setAuthState(prev => ({
      ...prev,
      refreshUser
    }));
  }, [refreshUser]);

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
}

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
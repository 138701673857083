import React, { createContext, useContext, useState } from 'react';

interface TabsContextType<T extends string> {
  value: T;
  onValueChange: (value: T) => void;
}

const TabsContext = createContext<TabsContextType<any>>({
  value: '',
  onValueChange: () => {}
});

interface TabsProps<T extends string> {
  value: T;
  onValueChange: (value: T) => void;
  className?: string;
  children: React.ReactNode;
}

export function Tabs<T extends string>({
  value,
  onValueChange,
  className = '',
  children
}: TabsProps<T>) {
  return (
    <TabsContext.Provider value={{ value, onValueChange }}>
      <div className={className}>
        {children}
      </div>
    </TabsContext.Provider>
  );
}

interface TabsListProps {
  className?: string;
  children: React.ReactNode;
}

export const TabsList: React.FC<TabsListProps> = ({
  className = '',
  children
}) => {
  return (
    <div className={`inline-flex h-10 items-center justify-center rounded-md bg-gray-100 p-1 text-gray-500 dark:bg-gray-800 dark:text-gray-400 ${className}`}>
      {children}
    </div>
  );
};

interface TabsTriggerProps<T extends string> {
  value: T;
  className?: string;
  children: React.ReactNode;
}

export function TabsTrigger<T extends string>({
  value,
  className = '',
  children
}: TabsTriggerProps<T>) {
  const { value: selectedValue, onValueChange } = useContext(TabsContext);
  const isSelected = value === selectedValue;

  return (
    <button
      type="button"
      role="tab"
      aria-selected={isSelected}
      className={`
        inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium transition-all
        ${isSelected 
          ? 'bg-white text-gray-900 shadow-sm dark:bg-gray-700 dark:text-white' 
          : 'hover:bg-gray-50 dark:hover:bg-gray-750'
        }
        ${className}
      `}
      onClick={() => onValueChange(value)}
    >
      {children}
    </button>
  );
}

interface TabsContentProps<T extends string> {
  value: T;
  className?: string;
  children: React.ReactNode;
}

export function TabsContent<T extends string>({
  value,
  className = '',
  children
}: TabsContentProps<T>) {
  const { value: selectedValue } = useContext(TabsContext);

  if (value !== selectedValue) return null;

  return (
    <div 
      role="tabpanel"
      className={`mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ${className}`}
    >
      {children}
    </div>
  );
}
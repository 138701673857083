import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Modal } from '../../../components/ui/Modal';
import { Button } from '../../../components/ui/Button';
import Spreadsheet, { Matrix } from 'react-spreadsheet';
import { Resizable } from 're-resizable';
import Draggable from 'react-draggable';
import { HyperFormula } from 'hyperformula';
import { Move as MoveIcon, Trash2 as TrashIcon, ZoomIn, ZoomOut } from 'lucide-react';
import DOMPurify from 'dompurify';

interface SpreadsheetProps {
  isOpen: boolean;
  onClose: () => void;
  autoFocus?: boolean;
  onFocusChange: (isFocused: boolean) => void;
}

interface CustomCell {
  value: string | number | boolean;
}

const MAX_CELL_LENGTH = 500; // Maximum characters per cell

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

const SpreadsheetModal: React.FC<SpreadsheetProps> = ({ isOpen, onClose, onFocusChange }) => {
  const [zoom, setZoom] = useState(1);
  const draggableRef = useRef<HTMLDivElement>(null);
  const spreadsheetContainerRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const spreadsheetRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [size, setSize] = useState({ width: 800, height: 400 });

  const hfInstance = useRef<HyperFormula | null>(null);

  const generateEmptyData = useCallback((): Matrix<CustomCell> => {
    return Array(30).fill(null).map(() => 
      Array(26).fill(null).map(() => ({ value: '' }))
    );
  }, []);

  const [data, setData] = useState<Matrix<CustomCell>>(generateEmptyData());

  useEffect(() => {
    hfInstance.current = HyperFormula.buildEmpty({
      licenseKey: 'gpl-v3',
    });
    hfInstance.current.addSheet('Sheet1');
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsFocused(true);
      spreadsheetRef.current?.focus();
    } else {
      setIsFocused(false);
    }
  }, [isOpen]);

  useEffect(() => {
    onFocusChange(isFocused);
  }, [isFocused, onFocusChange]);

  useEffect(() => {
    if (!isOpen) {
      setData(generateEmptyData());
      if (hfInstance.current) {
        const sheetId = hfInstance.current.getSheetId('Sheet1');
        if (sheetId !== undefined) {
          hfInstance.current.clearSheet(sheetId);
        }
      }
    }
  }, [isOpen, generateEmptyData]);

  const sanitizeInput = (input: string | number | boolean): string => {
    if (typeof input === 'boolean') {
      return input.toString();
    }
    return DOMPurify.sanitize(input.toString(), { ALLOWED_TAGS: [] });
  };

  const handleCellsChanged = useCallback((newData: Matrix<CustomCell>) => {
    const sanitizedData = newData.map(row =>
      row.map(cell => ({
        value: cell && cell.value !== undefined
          ? sanitizeInput(cell.value).slice(0, MAX_CELL_LENGTH)
          : ''
      }))
    );
    setData(sanitizedData);
  }, []);

  const handleZoomIn = useCallback(() => {
    setZoom(prevZoom => Math.min(2, prevZoom + 0.1));
  }, []);

  const handleZoomOut = useCallback(() => {
    setZoom(prevZoom => Math.max(0.5, prevZoom - 0.1));
  }, []);

  const handleClearSpreadsheet = useCallback(() => {
    setData(generateEmptyData());
    if (hfInstance.current) {
      const sheetId = hfInstance.current.getSheetId('Sheet1');
      if (sheetId !== undefined) {
        hfInstance.current.clearSheet(sheetId);
      }
    }
  }, [generateEmptyData]);

  const isMobile = windowWidth < 768;

  return (
    <Modal isOpen={isOpen} onClose={onClose} allowClose={false} noDarkOverlay>
      <Draggable nodeRef={draggableRef} handle=".drag-handle">
        <div 
          ref={draggableRef} 
          className="absolute w-full md:w-auto" 
          style={{ 
            left: isMobile ? '0' : '50%', 
            top: isMobile ? '0' : '50%', 
            transform: isMobile ? 'none' : 'translate(-50%, -50%)',
          }}
        >
          <Resizable
            size={size}
            onResizeStop={(e, direction, ref, d) => {
              setSize({
                width: size.width + d.width,
                height: size.height + d.height,
              });
            }}
            minWidth={isMobile ? windowWidth : 400}
            minHeight={isMobile ? windowHeight * 0.5 : 300}
            maxWidth={windowWidth * 0.9}
            maxHeight={windowHeight * 0.9}
            enable={{
              top: false,
              right: !isMobile,
              bottom: !isMobile,
              left: false,
              topRight: false,
              bottomRight: !isMobile,
              bottomLeft: false,
              topLeft: false,
            }}
          >
            <div 
              ref={spreadsheetRef}
              tabIndex={-1}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className="p-2 md:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg h-full flex flex-col relative"
              style={{ width: '100%', height: '100%' }}
            >
              <div className="drag-handle cursor-move mb-2 text-gray-700 dark:text-gray-300 flex items-center justify-start">
                <MoveIcon size={24} />
              </div>

              <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
                <h2 className="text-xl md:text-2xl font-bold text-gray-800 dark:text-gray-200 mb-2 md:mb-0">Spreadsheet</h2>
                <div className="flex space-x-2">
                  <Button onClick={handleClearSpreadsheet} className="flex items-center bg-primary-orange hover:bg-primary-orange-hover text-white text-sm md:text-base">
                    <TrashIcon size={16} className="mr-1" />
                    Clear
                  </Button>
                  <Button onClick={onClose} className="bg-primary-blue hover:bg-primary-blue-hover text-white text-sm md:text-base">
                    Close
                  </Button>
                </div>
              </div>

              <div
                ref={spreadsheetContainerRef}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                tabIndex={0}
                className="flex-grow overflow-auto"
                style={{
                  height: 'calc(100% - 120px)',
                  width: '100%',
                }}
              >
                <div style={{
                  transform: `scale(${zoom})`,
                  transformOrigin: 'top left',
                  width: `${100 / zoom}%`,
                  height: `${100 / zoom}%`,
                }}>
                  <Spreadsheet
                    data={data}
                    onChange={handleCellsChanged}
                    columnLabels={Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i))}
                    rowLabels={Array.from({ length: 30 }, (_, i) => (i + 1).toString())}
                  />
                </div>
              </div>

              <div className="absolute bottom-4 right-4 flex space-x-2">
                <Button 
                  className="text-xs p-1 bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300 rounded-full transition-colors duration-200"
                  onClick={handleZoomOut}
                >
                  <ZoomOut size={16} className="text-gray-700 dark:text-gray-300" />
                </Button>
                <Button 
                  className="text-xs p-1 bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300 rounded-full transition-colors duration-200"
                  onClick={handleZoomIn}
                >
                  <ZoomIn size={16} className="text-gray-700 dark:text-gray-300" />
                </Button>
              </div>
            </div>
          </Resizable>
        </div>
      </Draggable>
    </Modal>
  );
};

export default SpreadsheetModal;
// src/services/thriveCartService.ts

import { supabase } from './supabase';

export interface ThriveCartCustomer {
  id?: string;
  email?: string;
  name?: string;
  first_name?: string;
  last_name?: string;
}

export interface ThriveCartSubscription {
  id: string;
  status: string;
  processor: string;
  amount: number;
  amount_str: string;
  customer: ThriveCartCustomer;
  created_at: string;
  next_rebill_date: string;
}

export interface ThriveCartOrder {
  id: string;
  customer: ThriveCartCustomer;
  total: number;
  total_str: string;
  date: string;
  processor: 'stripe' | 'paypal';
  subscription_id?: string;
}

export interface ThriveCartResponse<T> {
  success: boolean;
  error?: string;
  data?: T;
}

class ThriveCartService {
  private static instance: ThriveCartService;
  private readonly apiKey: string;
  private readonly baseUrl: string;

  private constructor() {
    this.apiKey = 'BOMNUKB9-OL7ZWNWV-5T7F03DG-010UAE2H';
    this.baseUrl = 'https://thrivecart.com/api/v1';
  }

  public static getInstance(): ThriveCartService {
    if (!ThriveCartService.instance) {
      ThriveCartService.instance = new ThriveCartService();
    }
    return ThriveCartService.instance;
  }

  private async makeRequest<T>(
    endpoint: string, 
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
    body?: Record<string, unknown>
  ): Promise<ThriveCartResponse<T>> {
    try {
      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey}`
        },
        body: body ? JSON.stringify(body) : undefined
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to make ThriveCart request');
      }

      return {
        success: true,
        data: data as T
      };
    } catch (error) {
      console.error('ThriveCart API error:', error);
      return {
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error occurred'
      };
    }
  }

  // Customer Management
  async getCustomer(email: string): Promise<ThriveCartResponse<ThriveCartCustomer>> {
    return this.makeRequest<ThriveCartCustomer>(`/customer?email=${encodeURIComponent(email)}`);
  }

  // Subscription Management
  async getSubscription(subscriptionId: string): Promise<ThriveCartResponse<ThriveCartSubscription>> {
    return this.makeRequest<ThriveCartSubscription>(`/subscriptions/${subscriptionId}`);
  }

  async cancelSubscription(
    subscriptionId: string, 
    reason?: string
  ): Promise<ThriveCartResponse<{ success: boolean }>> {
    return this.makeRequest<{ success: boolean }>(
      `/subscriptions/${subscriptionId}/cancel`,
      'POST',
      { reason }
    );
  }

  async pauseSubscription(
    subscriptionId: string,
    resumeDate: Date
  ): Promise<ThriveCartResponse<{ success: boolean }>> {
    return this.makeRequest<{ success: boolean }>(
      `/subscriptions/${subscriptionId}/pause`,
      'POST',
      { resume_date: resumeDate.toISOString() }
    );
  }

  async resumeSubscription(
    subscriptionId: string
  ): Promise<ThriveCartResponse<{ success: boolean }>> {
    return this.makeRequest<{ success: boolean }>(
      `/subscriptions/${subscriptionId}/resume`,
      'POST'
    );
  }

  // Order Management
  async getOrder(orderId: string): Promise<ThriveCartResponse<ThriveCartOrder>> {
    return this.makeRequest<ThriveCartOrder>(`/orders/${orderId}`);
  }

  async refundOrder(
    orderId: string,
    amount?: number,
    reason?: string
  ): Promise<ThriveCartResponse<{ success: boolean }>> {
    return this.makeRequest<{ success: boolean }>(
      `/orders/${orderId}/refund`,
      'POST',
      { 
        amount,
        reason
      }
    );
  }

  // Sync order status with Supabase
  async syncOrderStatus(orderId: string): Promise<void> {
    try {
      const orderResponse = await this.getOrder(orderId);
      
      if (!orderResponse.success || !orderResponse.data) {
        throw new Error('Failed to fetch order from ThriveCart');
      }

      const { data: order } = orderResponse;

      const { error } = await supabase
        .from('thrivecart_orders')
        .update({
          last_updated: new Date().toISOString(),
          order_status: order.subscription_id ? 'subscription' : 'completed',
          payment_processor: order.processor,
          customer_email: order.customer.email,
          customer_name: order.customer.name,
          order_total: order.total
        })
        .eq('order_id', orderId);

      if (error) throw error;
    } catch (error) {
      console.error('Error syncing order status:', error);
      throw error;
    }
  }
}

export const thriveCartService = ThriveCartService.getInstance();
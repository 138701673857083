import { createClient, SupabaseClient } from '@supabase/supabase-js'
import config from '../config'

const supabaseUrl = config.REACT_APP_SUPABASE_URL
const supabaseAnonKey = config.REACT_APP_SUPABASE_ANON_KEY
const supabaseServiceKey = config.REACT_APP_SUPABASE_SERVICE_ROLE_KEY

if (!supabaseUrl || !supabaseAnonKey || !supabaseServiceKey) {
  throw new Error('Missing Supabase environment variables')
}

// Regular client for normal user operations
export const supabase: SupabaseClient = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true
  }
})

// Admin client for privileged operations
export const supabaseAdmin: SupabaseClient = createClient(supabaseUrl, supabaseServiceKey, {
  auth: {
    persistSession: false,
    autoRefreshToken: false
  }
})

// Helper to check if current user is staff (admin or moderator)
export const isStaffUser = async (): Promise<boolean> => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return false;

    const { data: userData } = await supabase
      .from('users')
      .select('role')
      .eq('id', user.id)
      .single();

    return userData?.role === 'admin' || userData?.role === 'moderator';
  } catch (error) {
    console.error('Error checking staff status:', error);
    return false;
  }
}

// Helper to get appropriate client based on role
export const getClientByRole = async (): Promise<SupabaseClient> => {
  if (await isStaffUser()) {
    return supabase;  // Staff users use RLS policies
  }
  return supabaseAdmin;  // Fallback to admin client if needed
}

// Moderator-specific operations
export const moderatorOperations = {
  // User management
  async getUserProfile(userId: string): Promise<any> {
    const isStaff = await isStaffUser();
    if (!isStaff) throw new Error('Unauthorized');

    const { data, error } = await supabase
      .from('users')
      .select('*')
      .eq('id', userId)
      .single();
    
    if (error) throw error;
    return data;
  },

  // Course access management
  async updateUserAccess(userId: string, courseId: string, tierId: string): Promise<void> {
    const isStaff = await isStaffUser();
    if (!isStaff) throw new Error('Unauthorized');

    const { error } = await supabase
      .from('user_course_access')
      .upsert({
        user_id: userId,
        course_id: courseId,
        tier_id: tierId,
        updated_at: new Date().toISOString()
      });

    if (error) throw error;
  },

  // Switch management
  async updateUserSwitches(userId: string, amount: number): Promise<void> {
    const isStaff = await isStaffUser();
    if (!isStaff) throw new Error('Unauthorized');

    const { error } = await supabase
      .from('users')
      .update({
        switches_available: amount,
        last_switch_replenish_date: new Date().toISOString(),
        updated_at: new Date().toISOString()
      })
      .eq('id', userId);

    if (error) throw error;
  },

  // Support user management
  async updateSupportUser(userId: string, updates: any): Promise<void> {
    const isStaff = await isStaffUser();
    if (!isStaff) throw new Error('Unauthorized');

    const { error } = await supabase
      .from('support_users')
      .update({
        ...updates,
        last_activity: new Date().toISOString()
      })
      .eq('id', userId);

    if (error) throw error;
  }
}

// Types
export interface UserQuestionResponse {
  user_id: string;
  question_id: string;
  course_id: string;
  selected_answer: string;
  is_correct: boolean;
}

export interface UserActivity {
  user_id: string;
  course_id: string;
  activity_type: string;
  activity_id: string;
  result?: string;
}

// Regular user operations
export const insertUserQuestionResponse = async (data: UserQuestionResponse): Promise<void> => {
  const { error } = await supabase.from('user_question_responses').insert(data)
  if (error) throw error
}

export const insertUserActivity = async (data: UserActivity): Promise<void> => {
  const { error } = await supabase.from('user_activity').insert(data)
  if (error) throw error
}
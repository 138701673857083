import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import { supabase } from '../../../services/supabase';
import { 
  AlertCircle,
  Inbox,
  List,
  Plus,
  User,
} from 'lucide-react';
import { Button } from '../../ui/Button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/Tabs';
import { Card } from '../../ui/Card';
import TicketList from './TicketList';
import TicketDetail from './TicketDetail';
import CustomerSidebar from './CustomerSidebar';
import EmailComposer from './EmailComposer';

interface SupportStats {
  open: number;
  pending: number;
  closed: number;
  total: number;
  urgentCount: number;
  unassignedCount: number;
  avgResponseTime: number;
}
// Updated to match TransformedTicket from TicketList
interface SupportTicket {
  id: string;
  support_user_id: string;
  admin_id: string | null;
  status: 'new' | 'open' | 'pending' | 'closed';
  subject: string;
  priority: 'low' | 'medium' | 'high' | 'urgent';
  created_at: string;
  updated_at: string;
  last_activity_at: string;
  closed_at: string | null;
  tags: string[];
  content_id?: string | null;
  user: {
    id: string;
    email: string;
    full_name: string | null;
    auth_user_id: string | null;
    email_domain: string;
  };
  admin?: {
    email: string;
    full_name: string;
  };
  _count: {
    messages: number;
  };
}

const SupportDashboard: React.FC = () => {
  const { user } = useAuth();
  const [selectedTicket, setSelectedTicket] = useState<SupportTicket | null>(null);
  const [showCustomerSidebar, setShowCustomerSidebar] = useState(true);
  const [stats, setStats] = useState<SupportStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('all');
  const [isCreatingTicket, setIsCreatingTicket] = useState(false);
  const [newTicketEmail, setNewTicketEmail] = useState('');
  const [listRefreshKey, setListRefreshKey] = useState(0);

  const ticketSubscriptionRef = useRef<ReturnType<typeof supabase.channel> | null>(null);
  const messageSubscriptionRef = useRef<ReturnType<typeof supabase.channel> | null>(null);

  // Memoize loadDashboardStats to prevent unnecessary recreations
  const loadDashboardStats = useCallback(async () => {
    try {
      setLoading(true);
  
      const { data: statsData, error: statsError } = await supabase
        .from('support_tickets')
        .select(`
          id,
          status,
          priority,
          admin_id,
          created_at,
          support_messages (
            created_at
          )
        `)
        .order('created_at', { ascending: false });
  
      if (statsError) throw statsError;
  
      if (statsData) {
        const openCount = statsData.filter(t => t.status === 'open').length;
        const pendingCount = statsData.filter(t => t.status === 'pending').length;
        const closedCount = statsData.filter(t => t.status === 'closed').length;
        const urgentCount = statsData.filter(t => t.priority === 'urgent').length;
        const unassignedCount = statsData.filter(t => !t.admin_id).length;
  
        let totalResponseTime = 0;
        let ticketsWithResponse = 0;
  
        statsData.forEach(ticket => {
          if (ticket.support_messages && ticket.support_messages.length > 1) {
            const firstResponse = new Date(ticket.support_messages[1].created_at);
            const created = new Date(ticket.created_at);
            totalResponseTime += firstResponse.getTime() - created.getTime();
            ticketsWithResponse++;
          }
        });
  
        setStats({
          open: openCount,
          pending: pendingCount,
          closed: closedCount,
          total: statsData.length,
          urgentCount,
          unassignedCount,
          avgResponseTime: ticketsWithResponse ? totalResponseTime / ticketsWithResponse : 0
        });
      }
    } catch (error) {
      console.error('Error loading dashboard stats:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Setup subscriptions once on mount
  useEffect(() => {
    if (!user?.id) return;

    loadDashboardStats();

    const ticketChannel = supabase.channel('dashboard-tickets');
    const messageChannel = supabase.channel('dashboard-messages');

    ticketSubscriptionRef.current = ticketChannel
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'support_tickets'
      }, (payload) => {
        if (!selectedTicket && !isCreatingTicket) {
          loadDashboardStats();
          setListRefreshKey(prev => prev + 1);
        }
      })
      .subscribe();

    messageSubscriptionRef.current = messageChannel
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'support_messages'
      }, (payload) => {
        if (!selectedTicket && !isCreatingTicket) {
          loadDashboardStats();
          setListRefreshKey(prev => prev + 1);
        }
      })
      .subscribe();

    return () => {
      if (ticketSubscriptionRef.current) {
        ticketSubscriptionRef.current.unsubscribe();
      }
      if (messageSubscriptionRef.current) {
        messageSubscriptionRef.current.unsubscribe();
      }
    };
  }, [user?.id]);

  const handleTicketCreated = useCallback(() => {
    setIsCreatingTicket(false);
    setNewTicketEmail('');
    loadDashboardStats();
    setListRefreshKey(prev => prev + 1);
  }, [loadDashboardStats]);

  // Type-safe handlers
  const handleTicketSelect = useCallback((ticket: SupportTicket) => {
    setSelectedTicket(ticket);
    setIsCreatingTicket(false);
    setShowCustomerSidebar(true);
  }, []);

  const handleTicketUpdate = useCallback(async (status: string) => {
    try {
      setSelectedTicket(null);
      await loadDashboardStats();
      setListRefreshKey(prev => prev + 1);
      setShowCustomerSidebar(true);
      setIsCreatingTicket(false);
    } catch (error) {
      console.error('Error updating ticket status:', error);
    }
  }, [loadDashboardStats]);

  const handleCreateNewTicket = useCallback(() => {
    setSelectedTicket(null);
    setIsCreatingTicket(true);
    setNewTicketEmail('');
  }, []);

  const handleRefreshData = useCallback((status?: string) => {
    loadDashboardStats();
    setListRefreshKey(prev => prev + 1);
  }, [loadDashboardStats]);

  const renderStats = () => {
    if (!stats) return null;

    return (
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <Card className="bg-blue-50 dark:bg-blue-900/20 p-4">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-semibold text-blue-600 dark:text-blue-400">Open</h3>
              <p className="text-2xl font-bold">{stats.open}</p>
            </div>
            <Inbox className="w-6 h-6 text-blue-500" />
          </div>
        </Card>

        <Card className="bg-yellow-50 dark:bg-yellow-900/20 p-4">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-semibold text-yellow-600 dark:text-yellow-400">Pending</h3>
              <p className="text-2xl font-bold">{stats.pending}</p>
            </div>
            <List className="w-6 h-6 text-yellow-500" />
          </div>
        </Card>

        <Card className="bg-red-50 dark:bg-red-900/20 p-4">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-semibold text-red-600 dark:text-red-400">Urgent</h3>
              <p className="text-2xl font-bold">{stats.urgentCount}</p>
            </div>
            <AlertCircle className="w-6 h-6 text-red-500" />
          </div>
        </Card>

        <Card className="bg-purple-50 dark:bg-purple-900/20 p-4">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-semibold text-purple-600 dark:text-purple-400">Unassigned</h3>
              <p className="text-2xl font-bold">{stats.unassignedCount}</p>
            </div>
            <User className="w-6 h-6 text-purple-500" />
          </div>
        </Card>
      </div>
    );
  };

  return (
    // Adjust top to account for global header height (assuming 64px - adjust this value as needed)
    <div className="fixed inset-x-0 bottom-0 top-16 flex flex-col bg-gray-50 dark:bg-gray-900">
      {/* Header - only show when no ticket is selected */}
      {!selectedTicket && !isCreatingTicket && (
        <div className="flex-none">
          <div className="flex justify-between items-center h-16 px-6 border-b dark:border-gray-700 bg-white dark:bg-gray-800">
            <h1 className="text-2xl font-bold">Support Dashboard</h1>
            <Button
              onClick={handleCreateNewTicket}
              className="flex items-center"
            >
              <Plus className="w-4 h-4 mr-2" />
              <span>New Ticket</span>
            </Button>
          </div>

          <div className="px-6 py-4 bg-white dark:bg-gray-800 border-b dark:border-gray-700">
            {renderStats()}
          </div>
        </div>
      )}

      {/* Main Content Area */}
      <div className="flex-1 min-h-0 flex gap-6 p-6">
        {/* Ticket List Section */}
        <div className={`transition-all duration-300 ease-in-out flex flex-col ${
          selectedTicket || isCreatingTicket ? 'w-1/4 min-w-[300px]' : 'w-full'
        }`}>
          <Tabs value={currentTab} onValueChange={setCurrentTab} className="h-full flex flex-col">
            <TabsList className="w-full flex-none">
              <TabsTrigger value="all" className="flex-1">All</TabsTrigger>
              <TabsTrigger value="mine" className="flex-1">My Tickets</TabsTrigger>
              <TabsTrigger value="urgent" className="flex-1">Urgent</TabsTrigger>
            </TabsList>

            <div className="flex-1 min-h-0 overflow-hidden">
              <TabsContent value="all" className="h-full overflow-auto">
                <TicketList
                  key={`all-${listRefreshKey}`}
                  filter="all"
                  onTicketSelect={handleTicketSelect}
                  selectedTicketId={selectedTicket?.id}
                />
              </TabsContent>
              <TabsContent value="mine" className="h-full overflow-auto">
                <TicketList
                  key={`mine-${listRefreshKey}`}
                  filter="mine"
                  onTicketSelect={handleTicketSelect}
                  selectedTicketId={selectedTicket?.id}
                />
              </TabsContent>
              <TabsContent value="urgent" className="h-full overflow-auto">
                <TicketList
                  key={`urgent-${listRefreshKey}`}
                  filter="urgent"
                  onTicketSelect={handleTicketSelect}
                  selectedTicketId={selectedTicket?.id}
                />
              </TabsContent>
            </div>
          </Tabs>
        </div>

        {/* Ticket Detail and Sidebar Section */}
        {(selectedTicket || isCreatingTicket) && (
          <div className="flex-1 min-h-0 flex">
            <div className="w-2/3 flex flex-col min-w-0 bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden">
              {isCreatingTicket ? (
                <div className="flex-1 flex flex-col min-h-0">
                  <div className="flex-1 min-h-0 overflow-auto">
                    <EmailComposer
                      ticketId="new"
                      recipientEmail={newTicketEmail}
                      onTicketCreated={handleTicketCreated}
                      onRecipientChange={setNewTicketEmail}
                      requireUserConfirmation={true}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex-1 min-h-0">
                  <TicketDetail
                    ticketId={selectedTicket!.id}
                    onBack={() => {
                      setSelectedTicket(null);
                      loadDashboardStats();
                    }}
                    onStatusChange={handleTicketUpdate}
                  />
                </div>
              )}
            </div>

            {/* Customer Sidebar */}
            {showCustomerSidebar && selectedTicket && (
              <div className="w-1/3 min-h-0 overflow-hidden flex flex-col ml-6 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
                <div className="flex-1 overflow-auto">
                  <CustomerSidebar
                    userId={selectedTicket.support_user_id} // Changed from user_id
                    ticketQuestionId={selectedTicket.content_id}
                    onRefreshData={handleRefreshData}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportDashboard;
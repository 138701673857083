import React, { useState, useEffect, useMemo, useRef } from 'react';
import { api, Simulation, UserTBSResponse, TBSq, FeedbackItem, AnswerFeedback, HTMLContent } from '../../services/api';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../AuthProvider';
import SimulationsRender from './SimulationsRender';
import { ChevronDown, ChevronUp } from 'lucide-react'; // Added import for icons

interface SimulationProps {
  simulation: Simulation;
  onSubmit: () => void;
  onNext: () => void; // Add this line
}

type ConfidenceLevel = 'guessing' | 'maybe' | 'confident';

// Type guard function to check if a question is TBSq
function isTBSq(question: TBSq | HTMLContent): question is TBSq {
  return (question as TBSq).id !== undefined;
}

const SimulationQuiz: React.FC<SimulationProps> = ({ simulation, onSubmit, onNext }) => {
  const [showSolution, setShowSolution] = useState(false);
  const [userAnswers, setUserAnswers] = useState<Record<string, any>>({});
  const [gradingResults, setGradingResults] = useState<{
    score: number | null;
    feedback: FeedbackItem[];
  } | null>(null);
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const [userResponse, setUserResponse] = useState<UserTBSResponse | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const quizRef = useRef<HTMLDivElement>(null);

  const [confidenceLevel, setConfidenceLevel] = useState<ConfidenceLevel | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const loadUserAnswers = async () => {
      if (user) {
        const response = await api.getUserTBSResponse(user.id, simulation.id);
        if (response) {
          setUserAnswers(response.answers);
          setUserResponse(response);
          if (response.completed) {
            setGradingResults({
              score: response.score,
              feedback: response.feedback || []
            });
            setShowSolution(true);
            setIsSubmitted(true);
          } else {
            setIsSubmitted(false);
            setShowSolution(false);
          }
        } else {
          // Initialize with empty answers if no response exists
          const initialAnswers: Record<string, any> = {};
          if (simulation.question_content && typeof simulation.question_content === 'object') {
            simulation.question_content.questions.forEach((item) => {
              if (isTBSq(item)) {
                initialAnswers[item.id] = {};
                item.cells.forEach((cell) => {
                  if (cell.type !== 'text') {
                    initialAnswers[item.id][cell.ref] = cell.type === 'input' ? '0' : '';
                  }
                });
              }
            });
          }
          setUserAnswers(initialAnswers);
          setUserResponse(null);
          setGradingResults(null);
          setShowSolution(false);
          setIsSubmitted(false);
        }
      }
    };
  
    loadUserAnswers();
  }, [user, simulation]);
  
  const getRequiredFieldCount = (question: TBSq): number => {
    if (question.type === 'mixed_table' && question.content?.table) {
      return question.content.table.rows.reduce((total, row) => {
        return total + (row.cells?.filter(cell => cell.type !== 'text').length || 0);
      }, 0);
    }
    return question.cells?.filter(cell => cell.type !== 'text').length || 0;
  };
  
  // Updated isAllAnswered to handle the correct question structure including multiple rows
  const isAllAnswered = useMemo(() => {
    if (!simulation.question_content || !simulation.question_content.questions) {
      console.log('No question content or questions found');
      return false;
    }
  
    const allAnswered = simulation.question_content.questions.every((item) => {
      if (!isTBSq(item)) {
        return true; // HTML content is always considered answered
      }
  
      const question = item as TBSq;
      const answer = userAnswers[question.id];
      const requiredFields = getRequiredFieldCount(question);
  
      console.log(`Question ${question.id}:`);
      console.log(`Required fields: ${requiredFields}`);
      console.log(`User answer:`, answer);
  
      if (!answer) {
        console.log(`No answer for question ${question.id}`);
        return false;
      }
  
      let answeredFields = 0;

      if (question.type === 'mixed_table' && question.content?.table) {
        question.content.table.rows.forEach(row => {
          row.cells.forEach(cell => {
            if (cell.type !== 'text') {
              const value = answer[cell.ref];
              // Consider '0' as a valid answer
              const isAnswered = value !== '' && value !== undefined;
              console.log(`Field ${cell.ref}: ${isAnswered ? 'Answered' : 'Not answered'}`);
              if (isAnswered) answeredFields++;
            }
          });
        });
      } else {
        question.cells.forEach(cell => {
          if (cell.type !== 'text') {
            const value = answer[cell.ref];
            // Consider '0' as a valid answer
            const isAnswered = value !== '' && value !== undefined;
            console.log(`Field ${cell.ref}: ${isAnswered ? 'Answered' : 'Not answered'}`);
            if (isAnswered) answeredFields++;
          }
        });
      }
  
      const isQuestionFullyAnswered = answeredFields === requiredFields;
      console.log(`Question ${question.id} fully answered: ${isQuestionFullyAnswered}`);
  
      return isQuestionFullyAnswered;
    });
  
    console.log(`All questions answered: ${allAnswered}`);
    return allAnswered;
  }, [userAnswers, simulation.question_content]);



  const handleAnswerChange = (questionId: string, cellRef: string, value: string) => {
    setUserAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: {
        ...prevAnswers[questionId],
        [cellRef]: value === '' ? '0' : value // Set to '0' if value is empty
      }
    }));
  };

  const handleConfidenceSelection = (level: ConfidenceLevel) => {
    setConfidenceLevel(level);
  };
  
// Handle submission of the simulation
const handleSubmit = async () => {
  if (!user || !confidenceLevel || !isAllAnswered) return;
  setIsSubmitting(true);

  try {
    const results = await api.submitUserTBSResponse(user.id, simulation.id, userAnswers, confidenceLevel);
    if (results) {
      if (!results.feedback) {
        console.error('Feedback is null or undefined', results);
        alert('An error occurred while processing the feedback. Please try again or contact support.');
        return;
      }

      const processedFeedback = results.feedback.map((item: FeedbackItem) => ({
        ...item,
        answerFeedback: Object.keys(item.userAnswer).reduce((acc: Record<string, AnswerFeedback>, key: string) => {
          acc[key] = {
            isCorrect: item.userAnswer[key] === item.correctAnswer[key],
            userAnswer: item.userAnswer[key],
            correctAnswer: item.correctAnswer[key],
          };
          return acc;
        }, {}),
      }));

      setGradingResults({
        score: results.score,
        feedback: processedFeedback,
      });
      setShowSolution(true);
      setUserResponse(results);
        // Call the onSubmit callback to update the parent component
        onSubmit();
      } else {
        console.error('Grading results are null', { 
          simulationId: simulation.id, 
          userId: user.id,
          userAnswers: userAnswers
        });
        alert('An error occurred while grading the simulation. Please try again or contact support.');
      }
    } catch (error) {
      console.error('Error grading simulation', { error, simulationId: simulation.id, userId: user.id });
      alert('An error occurred while grading the simulation. Please try again or contact support.');
    }
    setIsSubmitted(true);
  };

  // Helper function to determine if an answer is correct
  const isAnswerCorrect = (userAnswer: string, correctAnswer: string) => {
    return userAnswer === correctAnswer;
  };

  // Helper function to get the appropriate color class
  const getColorClass = (isCorrect: boolean) => {
    if (isCorrect) {
      return isDarkMode ? 'text-green-400' : 'text-green-600';
    } else {
      return isDarkMode ? 'text-red-400' : 'text-red-600';
    }
  };

  const handleNext = () => {
    onNext();
    // Scroll to the top of the component
    if (quizRef.current) {
      quizRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Render feedback for a single item
  const renderFeedback = (feedback: FeedbackItem) => {
    return (
      <div className="grid grid-cols-1 gap-4">
        {Object.entries(feedback.answerFeedback).map(([key, value]) => {
          const isCorrect = isAnswerCorrect(value.userAnswer, value.correctAnswer);
          return (
            <div key={key} className={`p-2 rounded ${isCorrect ? 'bg-green-100 dark:bg-green-800' : 'bg-red-100 dark:bg-red-800'}`}>
              <p className={`${isDarkMode ? 'text-gray-200' : 'text-gray-800'} ${isCorrect ? 'font-bold' : ''}`}>
                Your answer: <span className={getColorClass(isCorrect)}>{value.userAnswer}</span>
              </p>
              <p className={`${isDarkMode ? 'text-gray-200' : 'text-gray-800'} font-bold`}>
                Correct answer: {value.correctAnswer}
              </p>
            </div>
          );
        })}
        <p className={`font-medium mt-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
          Score: {feedback.score} / {feedback.maxScore}
        </p>
      </div>
    );
  };

  return (
    <div className="p-2 sm:p-4 md:p-8 bg-white dark:bg-gray-800 rounded-lg shadow">
      <SimulationsRender
        simulation={simulation}
        onAnswerChange={handleAnswerChange}
        userAnswers={userAnswers}
        isSubmitted={isSubmitted}
        userResponse={userResponse}
        gradingResults={gradingResults}
      />
      
      {!isSubmitted && (
        <div className="mt-4 sm:mt-8 bg-gray-50 dark:bg-gray-700 rounded-lg shadow-md p-4">
          <p className="text-base sm:text-lg font-semibold text-gray-900 dark:text-gray-200 mb-2">
            {isAllAnswered ? "How confident are you in your answers?" : "Please answer all questions before selecting confidence level."}
          </p>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
            {['guessing', 'maybe', 'confident'].map((level) => (
              <button
                key={level}
                onClick={() => handleConfidenceSelection(level as ConfidenceLevel)}
                disabled={!isAllAnswered}
                className={`py-2 px-4 rounded-lg font-medium transition-all duration-200 ease-in-out
                  ${confidenceLevel === level
                    ? 'bg-blue-500 text-white'
                    : isAllAnswered
                    ? 'bg-gray-200 text-gray-800 hover:bg-gray-300 dark:bg-gray-600 dark:text-gray-200 dark:hover:bg-gray-500'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed dark:bg-gray-700 dark:text-gray-400'
                  }`}
              >
                {level.charAt(0).toUpperCase() + level.slice(1)}
              </button>
            ))}
          </div>
          <button
            onClick={handleSubmit}
            disabled={!confidenceLevel || !isAllAnswered || isSubmitting}
            className={`w-full mt-4 py-3 px-6 rounded-lg text-white font-semibold transition-all duration-200 ease-in-out
              ${confidenceLevel && isAllAnswered && !isSubmitting
                ? 'bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700'
                : 'bg-gray-300 dark:bg-gray-600 cursor-not-allowed'
              }`}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Answers'}
          </button>
        </div>
      )}
      
      {/* Moved solution above feedback and made feedback collapsible */}
      {showSolution && (
        <div className="solution mt-6">
          <h3 className="text-xl font-bold mb-2">Solution:</h3>
          <div 
            dangerouslySetInnerHTML={{ 
              __html: `<div class="${isDarkMode ? 'text-gray-300' : 'text-gray-700'}">${simulation.answer_content}</div>` 
            }} 
          />
        </div>
      )}

{gradingResults && (
        <div className="results mt-6">
          <div 
            className={`score-box p-4 rounded text-center text-xl font-bold ${
              (gradingResults.score ?? 0) >= 70 
                ? 'bg-[var(--color-primary-blue)] text-white'
                : 'bg-[var(--color-primary-orange)] text-white'
            }`}
          >
            Your Score: {gradingResults.score !== null ? Math.round(gradingResults.score) : 'N/A'}%
          </div>
          <div className="mt-4">
            <button
              onClick={() => setShowFeedback(!showFeedback)}
              className="flex items-center justify-between w-full p-2 bg-gray-200 dark:bg-gray-700 rounded"
            >
              <span className="text-lg font-bold">Feedback</span>
              {showFeedback ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
            </button>
            {showFeedback && (
              <div className="mt-2 space-y-6">
                {gradingResults.feedback.map((item, index) => (
                  <div key={index} className={`p-4 rounded ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
                    <h4 className="text-lg font-semibold mb-2">Question {index + 1}</h4>
                    {renderFeedback(item)}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      
      {isSubmitted && (
        <button 
          onClick={handleNext}
          className={`px-4 py-2 rounded mt-4 w-full sm:w-auto
            bg-[var(--color-primary-orange)] hover:bg-[var(--color-primary-orange-hover)] 
            text-white transition-colors duration-200`}
        >
          Next Question
        </button>
      )}
    </div>
  );
};

export default SimulationQuiz;
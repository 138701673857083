import React, { useState, useEffect, forwardRef } from 'react';
import { api } from '../../services/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface StudyPlanGeneratorProps {
  userId: string;
  courseId: string;
  onComplete: () => void;
}

interface CourseOption {
  cpa_course_id: string;
  display_name: string;
  is_blueprint_based: boolean;
}

export function StudyPlanGenerator({ userId, courseId, onComplete }: StudyPlanGeneratorProps) {
  const [step, setStep] = useState(1);
  const [studyType, setStudyType] = useState<'brand_new' | 'retake'>('brand_new');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [studyDays, setStudyDays] = useState({
    monday: true, tuesday: true, wednesday: true, thursday: true,
    friday: true, saturday: true, sunday: true
  });
  const [studyDayWarning, setStudyDayWarning] = useState<string | null>(null);
  const [isKeslerPrimary, setIsKeslerPrimary] = useState<boolean>(true);
  const [primaryCourse, setPrimaryCourse] = useState<string>('');
  const [secondaryCourse, setSecondaryCourse] = useState<string>('');
  const [courses, setCourses] = useState<CourseOption[]>([]);
  const [keslerCourseId, setKeslerCourseId] = useState<string>('');
  const [recommendedWeeklyHours, setRecommendedWeeklyHours] = useState< '15' | '20' | '25' | '30'>('20');
  const [alias, setAlias] = useState<string | null>(null);
  const [selectedCourseId, setSelectedCourseId] = useState<string>('');
  const [blueprintCourseId, setBlueprintCourseId] = useState<string>('');
  const [calculatedExamDate, setCalculatedExamDate] = useState<Date | null>(null);
  const [totalWeeks, setTotalWeeks] = useState<number>(0);
  const [totalStudyHours, setTotalStudyHours] = useState<number>(0);

  useEffect(() => {
    async function fetchCourses() {
      try {
        const cpaCourses = await api.getCPACourses(courseId);
        const courseOptions: CourseOption[] = cpaCourses.map(course => ({
          cpa_course_id: course.cpa_course_id,
          display_name: course.display_name,
          is_blueprint_based: course.is_blueprint_based
        }));
        setCourses(courseOptions);

        const keslerCourse = courseOptions.find(course => course.display_name.toLowerCase().includes('kesler'));
        if (keslerCourse) {
          setKeslerCourseId(keslerCourse.cpa_course_id);
          setPrimaryCourse(keslerCourse.cpa_course_id);
        }

        // Find and set the Blueprint course ID
        const blueprintCourse = courseOptions.find(course => course.is_blueprint_based);
        if (blueprintCourse) {
          setBlueprintCourseId(blueprintCourse.cpa_course_id);
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    }
    fetchCourses();
  }, [courseId]);

  const CalendarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
    </svg>
  );

  const CustomInput = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
    ({ value, onClick, onChange }, ref) => (
      <div className="relative">
        <input
          value={value}
          onChange={onChange}
          onClick={onClick}
          ref={ref}
          className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
          readOnly
        />
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
          <CalendarIcon />
        </div>
      </div>
    )
  );

// Update the handleCourseSelection function
const handleCourseSelection = (selectedValue: string) => {
  const [courseId, displayName] = selectedValue.split(',');
  const selectedCourse = courses.find(course => course.cpa_course_id === courseId);

  if (selectedValue === 'none') {
    setAlias(null);
    if (isKeslerPrimary) {
      setSecondaryCourse('');
    } else {
      setPrimaryCourse('');
    }
    setSelectedCourseId('none');
  } else if (selectedCourse) {
    if (selectedCourse.is_blueprint_based) {
      setAlias(displayName.toLowerCase());
    } else {
      setAlias(null);
    }
    
    if (isKeslerPrimary) {
      setSecondaryCourse(courseId);
    } else {
      setPrimaryCourse(courseId);
    }
    setSelectedCourseId(courseId);
  } else {
    setSelectedCourseId('');
    setAlias(null);
    if (isKeslerPrimary) {
      setSecondaryCourse('');
    } else {
      setPrimaryCourse('');
    }
  }

  console.log('Current selection:', {
    courseId,
    displayName,
    alias: selectedCourse?.is_blueprint_based ? displayName.toLowerCase() : null,
    isKeslerPrimary,
    primaryCourse: isKeslerPrimary ? primaryCourse : courseId,
    secondaryCourse: isKeslerPrimary ? courseId : secondaryCourse,
  });
};

const handleDateChange = (date: Date | null) => {
  if (date && date >= new Date()) {
    console.log('Start date selected:', date);
    setStartDate(date);
  }
};

const formatDateWithDay = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = { 
    weekday: 'long', 
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit' 
  };
  const formattedDate = date.toLocaleDateString('en-US', options);
  const [weekday, monthDayYear] = formattedDate.split(', ');
  return `${weekday} (${monthDayYear})`;
};

const getNextMonday = (date: Date): Date => {
  const result = new Date(date);
  const day = result.getDay();
  if (day === 0) { // Sunday
    result.setDate(result.getDate() + 1);
  } else if (day === 2) { // Tuesday
    result.setDate(result.getDate() - 1);
  } else if (day > 2) { // Wednesday to Saturday
    result.setDate(result.getDate() + (8 - day));
  }
  return result;
};

const handleWeeklyHoursChange = (hours: '15' | '20' | '25' | '30') => {
  console.log('Setting weekly hours to:', hours);
  setRecommendedWeeklyHours(hours);
};

const handleStudyDayChange = (day: string) => {
  setStudyDays(prev => {
    const newStudyDays = { ...prev, [day]: !prev[day as keyof typeof studyDays] };
    const selectedDaysCount = Object.values(newStudyDays).filter(Boolean).length;

    if (selectedDaysCount < 3) {
      setStudyDayWarning("You must select at least 3 study days.");
      return prev; // Don't allow fewer than 3 days
    } else if (selectedDaysCount === 3) {
      setStudyDayWarning("It's difficult to get into a rhythm if you don't study at least every other day.");
    } else {
      setStudyDayWarning(null);
    }

    return newStudyDays;
  });
};

const calculateStudyPlan = async () => {
  console.log('Calculating study plan with:', { startDate, courseId, recommendedWeeklyHours });
  if (!startDate || !courseId) {
    console.error('Start date or course selection is missing');
    return;
  }

  try {
    // Create course mix first
    console.log('Creating course mix...');
    const courseMixResult = await api.createCourseMix(
      userId, 
      courseId, 
      isKeslerPrimary ? keslerCourseId : primaryCourse,
      isKeslerPrimary ? (secondaryCourse || null) : keslerCourseId,
      alias
    );
    console.log('Course mix created:', courseMixResult);

    // Create study type
    console.log('Creating study type...');
    const studyTypeResult = await api.createStudyType(userId, courseId, studyType);
    console.log('Study type created:', studyTypeResult);

    console.log('Sending weekly hours:', recommendedWeeklyHours);
    const result = await api.calculateStudyPlanAndExamDate(
      userId,
      courseId,
      startDate.toISOString(),
      studyType,
      recommendedWeeklyHours,
      courseMixResult.mix_id,
      studyTypeResult.id
    );

    console.log('Study plan calculation result:', result);
    
    // Update state with the calculated values
    const examDate = new Date(result.calculatedExamDate);
    const adjustedExamDate = getNextMonday(examDate);
    setCalculatedExamDate(adjustedExamDate);
    setTotalStudyHours(Math.ceil(result.totalStudyHours));
    setTotalWeeks(result.totalWeeks);

    // Return the result for immediate use
    return result;
  } catch (error) {
    console.error('Error calculating study plan:', error);
    throw error;
  }
};

const isNextButtonDisabled = () => {
  switch (step) {
    case 1:
      return studyType === null;
    case 2:
      return isKeslerPrimary === null;
    case 3:
      return isKeslerPrimary ? (secondaryCourse === '' && selectedCourseId !== 'none') : primaryCourse === '';
    case 4:
      return startDate === null;
    case 5:
      return recommendedWeeklyHours === null;
    case 6:
      return Object.values(studyDays).filter(Boolean).length < 3;
    default:
      return false;
  }
};


const handleSubmit = async () => {
  console.log('Submitting study plan...');
  console.log('Current state before submission:', {
    studyType,
    startDate,
    calculatedExamDate,
    studyDays,
    primaryCourse,
    secondaryCourse,
    alias,
    recommendedWeeklyHours
  });

  if (!startDate || !calculatedExamDate || !primaryCourse) {
    console.error('Missing required fields');
    return;
  }

  try {
    console.log('Creating study type...');
    const studyTypeResult = await api.createStudyType(userId, courseId, studyType);
    console.log('Study type created:', studyTypeResult);

    console.log('Creating course mix...');
    const courseMixResult = await api.createCourseMix(
      userId, 
      courseId, 
      isKeslerPrimary ? keslerCourseId : primaryCourse,
      isKeslerPrimary ? (secondaryCourse || null) : keslerCourseId,
      alias
    );
    console.log('Course mix created:', courseMixResult);

    console.log('Setting exam date...');
    const examDateId = await api.setExamDate(userId, courseId, startDate.toISOString(), calculatedExamDate.toISOString());
    console.log('Exam date set, ID:', examDateId);

    console.log('Setting study days...');
    await api.setStudyDays(userId, courseId, studyDays);
    console.log('Study days set');

    console.log('Creating user study plan...');
    const userStudyPlan = await api.createUserStudyPlan(
      userId, 
      courseId, 
      courseMixResult.mix_id, 
      studyTypeResult.id,
      recommendedWeeklyHours
    );
    console.log('User study plan created:', userStudyPlan);

    console.log('Creating study schedule...');
    await api.createStudySchedule(userStudyPlan.plan_id, examDateId, startDate.toISOString(), calculatedExamDate.toISOString());
    console.log('Study schedule created');

    console.log('Creating study plan items...');
    await api.createStudyPlanItems(userStudyPlan.plan_id, courseId, userId);
    console.log('Study plan items created');

    console.log('Study plan creation completed successfully');
    onComplete();
  } catch (error) {
    console.error('Error creating study plan:', error);
  }
};

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">Is this your first time sitting for this section or is this a retake?</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">By <strong>first time</strong> we typically mean, you have never sat for this section OR it has been longer than 9-12 months since you last sat.</p>
            <p className="text-sm text-gray-600 dark:text-gray-400">By <strong>retake</strong>, we mean, did you recently fail this section with a score between 50-74 within the past 6 months. If any lower than that, we recommend you select first time.</p>
            <div className="flex space-x-4">
              <button
                className={`px-6 py-3 rounded-lg transition-colors ${
                  studyType === 'brand_new' 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'
                }`}
                onClick={() => setStudyType('brand_new')}
              >
                First Time
              </button>
              <button
                className={`px-6 py-3 rounded-lg transition-colors ${
                  studyType === 'retake' 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'
                }`}
                onClick={() => setStudyType('retake')}
              >
                Retake
              </button>
            </div>
            <button 
              className={`w-full mt-6 px-6 py-3 rounded-lg transition-colors ${
                isNextButtonDisabled()
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-orange-500 text-white hover:bg-orange-600'
              }`}
              onClick={() => setStep(2)}
              disabled={isNextButtonDisabled()}
            >
              Next
            </button>
          </div>
        );
        case 2:
          return (
            <div className="space-y-6">
              <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">Are you using Kesler CPA Review as a supplement or primary learning tool?</h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">Meaning, do you want to use Kesler CPA Review's course outline or a different outline from a Big Box CPA Course?</p>
              <div className="flex space-x-4">
                <button
                  className={`px-6 py-3 rounded-lg transition-colors ${
                    isKeslerPrimary
                      ? 'bg-blue-600 text-white' 
                      : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'
                  }`}
                  onClick={() => {
                    setIsKeslerPrimary(true);
                    setPrimaryCourse(keslerCourseId);
                    setSecondaryCourse('');
                    setAlias(null);
                  }}
                >
                  Primary
                </button>
                <button
                  className={`px-6 py-3 rounded-lg transition-colors ${
                    !isKeslerPrimary
                      ? 'bg-blue-600 text-white' 
                      : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'
                  }`}
                  onClick={() => {
                    setIsKeslerPrimary(false);
                    setPrimaryCourse('');
                    setSecondaryCourse(keslerCourseId);
                    setAlias(null);
                  }}
                >
                  Supplement
                </button>
              </div>
            <div className="flex space-x-4">
              <button 
                className="w-1/2 mt-6 px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors" 
                onClick={() => setStep(1)}
                disabled={isNextButtonDisabled()}
              >
                Back
              </button>
              <button 
                className="w-1/2 mt-6 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors" 
                onClick={() => setStep(3)}
              >
                Next
              </button>
            </div>
          </div>
        );
        case 3:
          const currentCourseId = isKeslerPrimary ? secondaryCourse : primaryCourse;
          const currentCourse = courses.find(course => course.cpa_course_id === currentCourseId);
          let selectValue = "";
          
          if (selectedCourseId === 'none') {
            selectValue = "none";
          } else if (currentCourse) {
            if (currentCourse.is_blueprint_based && alias) {
              if (alias.toLowerCase() === 'uworld') {
                selectValue = `${currentCourseId},UWorld`;
              } else {
                selectValue = `${currentCourseId},${alias.charAt(0).toUpperCase() + alias.slice(1)}`;
              }
            } else {
              selectValue = `${currentCourseId},${currentCourse.display_name}`;
            }
          }
        
          return (
            <div className="space-y-6">
              <div className="space-y-2">
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  {isKeslerPrimary
                    ? "(If applicable) Select your secondary big box course that you will be using with Kesler CPA Review from the dropdown."
                    : "Select the big box CPA Course you will be using as your primary learning tool from the dropdown"}
                </h3>
                {isKeslerPrimary ? (
                  <p className="text-m text-gray-600 dark:text-gray-400">
                    PRO TIP: We recommend that you pair a big box textbook with Kesler CPA's Test Bank!
                  </p>
                ) : (
                  <p className="text-m text-gray-600 dark:text-gray-400">
                    PRO TIP: Watch the mentorship modules first to ensure you understand how to integrate Kesler with your Big Box Course before you start studying!
                  </p>
                )}
              </div>
              <select
                value={selectValue}
                onChange={(e) => {
                  handleCourseSelection(e.target.value);
                }}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
              >
                <option value="">Select a course</option>
                {isKeslerPrimary && <option value="none">None</option>}
                {courses
                  .filter(course => course.cpa_course_id !== keslerCourseId)
                  .map(course => {
                    if (course.is_blueprint_based) {
                      return [
                        <option key={`${course.cpa_course_id}_surgent`} value={`${course.cpa_course_id},Surgent`}>
                          Surgent
                        </option>,
                        <option key={`${course.cpa_course_id}_yaeger`} value={`${course.cpa_course_id},Yaeger`}>
                          Yaeger
                        </option>,
                        <option key={`${course.cpa_course_id}_uworld`} value={`${course.cpa_course_id},UWorld`}>
                          UWorld
                        </option>,
                        <option key={`${course.cpa_course_id}_other`} value={`${course.cpa_course_id},Other`}>
                          Other Blueprint Based Course
                        </option>
                      ];
                    } else {
                      return (
                        <option key={course.cpa_course_id} value={`${course.cpa_course_id},${course.display_name}`}>
                          {course.display_name}
                        </option>
                      );
                    }
                  })}
              </select>
              <div className="flex space-x-4">
                <button 
                  className="w-1/2 mt-6 px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors" 
                  onClick={() => setStep(2)}
                >
                  Back
                </button>
                <button 
                  className={`w-1/2 mt-6 px-6 py-3 rounded-lg transition-colors ${
                    isNextButtonDisabled()
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-orange-500 text-white hover:bg-orange-600'
                  }`}
                  onClick={() => setStep(4)}
                  disabled={isNextButtonDisabled()}
                >
                  Next
                </button>
              </div>
            </div>
          );
          case 4:
            return (
              <div className="space-y-6">
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">When will you start studying?</h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">We recommend starting today for the best results!</p>
                <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  customInput={<CustomInput />}
                />
                <div className="flex space-x-4">
                  <button 
                    className="w-1/2 mt-6 px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors" 
                    onClick={() => setStep(3)}
                  >
                    Back
                  </button>
                  <button 
                    className="w-1/2 mt-6 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors" 
                    onClick={() => {
                      console.log('Moving to next step, current start date:', startDate);
                      setStep(5);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            );

          case 5:
            return (
              <div className="space-y-6">
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">How many hours per week can you study?</h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">We recommend a minimum of 15 hours of dedicated, task-based, study time per week. 20 hours is ideal for most candidates.</p>
                <div className="grid grid-cols-3 gap-4">
                  {[ '15', '20', '25', '30'].map((hours) => (
                    <button
                      key={hours}
                      className={`px-6 py-3 rounded-lg transition-colors ${
                        recommendedWeeklyHours === hours 
                          ? 'bg-blue-600 text-white' 
                          : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'
                      }`}
                      onClick={() => handleWeeklyHoursChange(hours as '15' | '20' | '25' | '30')}
                    >
                      {hours} hours
                    </button>
                  ))}
                </div>
                <div className="flex space-x-4">
                  <button 
                    className="w-1/2 mt-6 px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors" 
                    onClick={() => setStep(4)}
                  >
                    Back
                  </button>
                  <button 
                    className="w-1/2 mt-6 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors" 
                    onClick={() => setStep(6)}
                    disabled={isNextButtonDisabled()}
                  >
                    Next
                  </button>
                </div>
              </div>
            );

            case 6:
              return (
                <div className="space-y-6">
                  <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">What days of the week will you dedicate to studying?</h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">We recommend taking scheduled breaks from studying so you can have guilt-free fun with family and friends, but strongly encourage getting into DAILY study routines</p>
                  {studyDayWarning && (
                    <p className="text-sm text-yellow-600 dark:text-yellow-400">{studyDayWarning}</p>
                  )}
                  <div className="grid grid-cols-2 gap-4">
                    {Object.entries(studyDays).map(([day, isSelected]) => (
                      <label key={day} className="flex items-center space-x-3 cursor-pointer">
                        <input
                          type="checkbox"
                          checked={isSelected}
                          onChange={() => handleStudyDayChange(day)}
                          className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                        />
                        <span className="text-gray-700 dark:text-gray-300">{day.charAt(0).toUpperCase() + day.slice(1)}</span>
                      </label>
                    ))}
                  </div>
                  <div className="flex space-x-4">
                    <button 
                      className="w-1/2 mt-6 px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors" 
                      onClick={() => setStep(5)}
                    >
                      Back
                    </button>
                    <button 
                      className="w-1/2 mt-6 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors" 
                      onClick={async () => {
                        try {
                          const result = await calculateStudyPlan();
                          if (result) {
                            setStep(7);
                          } else {
                            console.error('Failed to calculate study plan');
                          }
                        } catch (error) {
                          console.error('Error calculating study plan:', error);
                          // Here you might want to show an error message to the user
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              );

              case 7:
                return (
                  <div className="space-y-6">
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-white">Confirm Your Details</h3>
                    <p className="text-m text-gray-600 dark:text-gray-200">
                      Based on what you shared, we recommend:
                    </p>
                    <ul className="list-disc list-inside space-y-2 text-gray-600 dark:text-gray-200">
                      <li><strong>If You Can Study:</strong> {recommendedWeeklyHours} hrs/week on average</li>
                      <li><strong>For A Total Study Time Of:</strong> {totalStudyHours} hours</li>
                      <li><strong>We Recommend Studying For Approximately:</strong> {totalWeeks}+ weeks</li>
                      <li><strong>If You Start Studying:</strong> {formatDateWithDay(startDate)}</li>
                      <li><strong>Schedule Exam Around:</strong> {calculatedExamDate && formatDateWithDay(calculatedExamDate)}</li>
                    </ul>
                    <div className="mt-6 mb-8 p-4 bg-blue-100 dark:bg-blue-900 border-l-4 border-blue-500 rounded-r-lg">
                      <p className="text-blue-700 dark:text-blue-200 font-medium">
                        <span className="font-bold">PRO TIP:</span> Schedule your exams on Monday so you can take Friday off and have a long weekend cramming before your exam.
                      </p>
                    </div>
                    <div className="flex space-x-4">
                      <button 
                        className="w-1/2 mt-6 px-6 py-3 bg-gray-300 text-gray-700 dark:bg-gray-600 dark:text-white rounded-lg hover:bg-gray-400 dark:hover:bg-gray-500 transition-colors" 
                        onClick={() => setStep(6)}
                      >
                        Change Plan
                      </button>
                      <button 
                        className="w-1/2 mt-6 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors" 
                        onClick={handleSubmit}
                      >
                        Finalize Study Plan
                      </button>
                    </div>
                  </div>
                );
            
            default:
              return null;
        }
      };

  return (
    <div className="study-plan-generator max-w-xl mx-auto p-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg">
      <h2 className="text-3xl font-bold text-center mb-8 text-gray-900 dark:text-gray-100">Create New Study Plan</h2>
      {renderStep()}
    </div>
  );
}

export default StudyPlanGenerator;
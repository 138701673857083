import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, Note } from '../../services/api';
import { auth } from '../../services/auth';
import QuestionPreview from './QuestionPreview';
import Pagination from './common/Pagination';
import LoadingScreen from './common/LoadingScreen';
import { Edit, Trash2, ChevronDown, ChevronUp, Book, HelpCircle, Box, User, File, ExternalLink } from 'lucide-react';
import DOMPurify from 'dompurify';

interface FocusNotesProps {
  courseId: string;
  userId: string;
}

interface ExtendedNote extends Note {
  question_category?: string;
  sub_question_category?: string;
  lesson_name?: string;
  lesson_category?: string;
  lesson_subcategory?: string;
}

const noteTypes = ['All', 'Quiz', 'Lesson', 'Simulation', 'Mentor', 'General'] as const;
type NoteType = typeof noteTypes[number];

const noteTypeColors: Record<NoteType, string> = {
  All: 'bg-gray-100 dark:bg-gray-900 border-gray-300 dark:border-gray-800',
  Quiz: 'bg-blue-100 dark:bg-blue-900 border-blue-300 dark:border-blue-800',
  Lesson: 'bg-green-100 dark:bg-green-900 border-green-300 dark:border-green-800',
  Simulation: 'bg-purple-100 dark:bg-purple-900 border-purple-300 dark:border-purple-800',
  Mentor: 'bg-yellow-100 dark:bg-yellow-900 border-yellow-300 dark:border-yellow-800',
  General: 'bg-red-100 dark:bg-gray-700 border-red-300 dark:border-gray-800',
};

const getNoteIcon = (noteType: NoteType) => {
  switch (noteType) {
    case 'Quiz': return <HelpCircle size={18} className="text-blue-600 dark:text-blue-400" />;
    case 'Lesson': return <Book size={18} className="text-green-600 dark:text-green-400" />;
    case 'Simulation': return <Box size={18} className="text-purple-600 dark:text-purple-400" />;
    case 'Mentor': return <User size={18} className="text-yellow-600 dark:text-yellow-400" />;
    default: return <File size={18} className="text-gray-600 dark:text-gray-400" />;
  }
};

const MAX_NOTE_LENGTH = 2000;
const TRUNCATE_LENGTH = 500;

function FocusNotes({ courseId }: FocusNotesProps) {
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNote, setNewNote] = useState('');
  const [editingNote, setEditingNote] = useState<Note | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [expandedNoteId, setExpandedNoteId] = useState<string | null>(null);
  const [selectedNoteType, setSelectedNoteType] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNotes, setTotalNotes] = useState(0);
  const [expandedQuestionId, setExpandedQuestionId] = useState<string | null>(null);
  const notesPerPage = 25;
  const navigate = useNavigate(); // ADD: useNavigate hook

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = await auth.getCurrentUser();
      setCurrentUser(user);
    };
    fetchCurrentUser();
  }, []);

  const fetchNotes = useCallback(async () => {
    try {
      if (!currentUser) throw new Error('User not authenticated');
      
      const { notes, total } = await api.getNotes(
        currentUser.id,
        courseId,
        selectedNoteType === 'All' ? null : selectedNoteType,
        currentPage,
        notesPerPage
      );
      setNotes(notes);
      setTotalNotes(total);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch notes. Please try again later.');
      setLoading(false);
    }
  }, [courseId, currentUser, selectedNoteType, currentPage]);

  useEffect(() => {
    if (currentUser) {
      fetchNotes();
    }
  }, [fetchNotes, currentUser]);

  // ADD: Function to handle navigation to lesson
  const handleGoToLesson = (lessonId: string) => {
    navigate(`/course/${courseId}/lessons/${lessonId}`);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const sanitizedContent = DOMPurify.sanitize(e.target.value);
    setNewNote(sanitizedContent.slice(0, MAX_NOTE_LENGTH));
  };

  const handleEditingNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (editingNote) {
      const sanitizedContent = DOMPurify.sanitize(e.target.value);
      setEditingNote({ 
        ...editingNote, 
        content: sanitizedContent.slice(0, MAX_NOTE_LENGTH) 
      });
    }
  };

  async function handleCreateNote() {
    try {
      const user = await auth.getCurrentUser();
      if (!user) throw new Error('User not authenticated');
      
      await api.createNote({
        user_id: user.id,
        course_id: courseId,
        content: DOMPurify.sanitize(newNote),
        lesson_id: null,
        question_id: null,
        simulation_id: null,
        module_id:null
      });
      setNewNote('');
      fetchNotes();
  
      await api.logUserActivity({
        user_id: user.id,
        course_id: courseId,
        activity_type: 'create_note',
        activity_id: user.id,
        result: 'success'
      });
    } catch (err) {
      setError('Failed to create note. Please try again.');
    }
  }

  async function handleUpdateNote() {
    try {
      const user = await auth.getCurrentUser();
      if (!user || !editingNote) throw new Error('User not authenticated or no note selected');
      
      await api.updateNote(
        editingNote.id,
        { content: DOMPurify.sanitize(editingNote.content) },
        user.id,
        courseId,
        editingNote.question_id,
        editingNote.lesson_id,
        editingNote.module_id
      );
      setEditingNote(null);
      fetchNotes();
  
      await api.logUserActivity({
        user_id: user.id,
        course_id: courseId,
        activity_type: 'update_note',
        activity_id: editingNote.id,
        result: 'success'
      });
    } catch (err) {
      setError('Failed to update note. Please try again.');
    }
  }

  async function handleClearNote(noteId: string) {
    try {
      const user = await auth.getCurrentUser();
      if (!user) throw new Error('User not authenticated');
      
      await api.deleteNote(noteId);
      fetchNotes();
  
      await api.logUserActivity({
        user_id: user.id,
        course_id: courseId,
        activity_type: 'delete_note',
        activity_id: noteId,
        result: 'success'
      });
    } catch (err) {
      setError('Failed to clear note. Please try again.');
    }
  }

  const getNoteType = (note: ExtendedNote): NoteType => {
    if (note.question_id) return 'Quiz';
    if (note.lesson_id) return 'Lesson';
    if (note.simulation_id) return 'Simulation';
    if (note.module_id) return 'Mentor';
    return 'General';
  };

  const handleToggleQuestionPreview = (noteId: string) => {
    setExpandedQuestionId(expandedQuestionId === noteId ? null : noteId);
  };

  const handleNoteTypeChange = (noteType: string) => {
    setSelectedNoteType(noteType);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if (isLoading) {
    return <LoadingScreen message="Loading your Focus Notes..." subMessage="This may take a few moments" />;
  }
  if (error) return <div className="text-red-500 dark:text-red-400">{error}</div>;

  const renderNoteCard = (note: ExtendedNote) => {
    const noteType = getNoteType(note);
    const isExpanded = expandedNoteId === note.id;
    const isQuestionExpanded = expandedQuestionId === note.id;

    const truncatedContent = note.content.length > TRUNCATE_LENGTH 
      ? `${note.content.slice(0, TRUNCATE_LENGTH)}...` 
      : note.content;

    const buttonBaseClasses = "w-8 h-8 flex items-center justify-center rounded-full transition duration-300";

    return (
      <div key={note.id} className="rounded-lg shadow-sm border overflow-hidden transition-all duration-300 ease-in-out">
        <div className={`${noteTypeColors[noteType]}`}>
          <div className="p-4 flex flex-col">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center space-x-2">
                {getNoteIcon(noteType)}
                <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100">{noteType} Note</h4>
              </div>
              <button
                onClick={() => setExpandedNoteId(isExpanded ? null : note.id)}
                className={`${buttonBaseClasses} text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200`}
              >
                {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </button>
            </div>
            
            <div className="text-sm text-gray-600 dark:text-gray-400 mb-2">
              {note.lesson_name && <p>{note.lesson_name}</p>}
              {note.question_category && <p>{note.question_category}</p>}
              {note.module_title && <p>{note.module_title}</p>}
            </div>

            <p className="text-gray-700 dark:text-gray-300 mt-2 flex-grow">
              {isExpanded ? note.content : truncatedContent}
            </p>

            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setEditingNote(note)}
                className={`${buttonBaseClasses} text-yellow-600 hover:bg-yellow-100 dark:hover:bg-yellow-800`}
              >
                <Edit size={18} />
              </button>
              <button
                onClick={() => handleClearNote(note.id)}
                className={`${buttonBaseClasses} text-red-600 hover:bg-red-100 dark:hover:bg-red-800`}
              >
                <Trash2 size={18} />
              </button>
              {note.question_id && (
                <button
                  onClick={() => handleToggleQuestionPreview(note.id)}
                  className={`${buttonBaseClasses} text-blue-600 hover:bg-blue-100 dark:hover:bg-blue-800`}
                >
                  <HelpCircle size={18} />
                </button>
              )}
              {note.lesson_id && (
                <button
                  onClick={() => handleGoToLesson(note.lesson_id!)}
                  className={`${buttonBaseClasses} text-green-600 hover:bg-green-100 dark:hover:bg-green-800`}
                >
                  <ExternalLink size={18} />
                </button>
              )}
            </div>
          </div>
        </div>
        {isQuestionExpanded && note.question_id && currentUser && (
          <div className="border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
            <QuestionPreview
              questionId={note.question_id}
              courseId={courseId}
              userId={currentUser.id}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="space-y-6 p-0 md:p-6">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">Focus Notes</h2>
      
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
        <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Create New Note</h3>
        <textarea
          placeholder="Note Content"
          value={newNote}
          onChange={handleNoteChange}
          className="w-full p-2 mb-2 border rounded-md bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          rows={3}
          maxLength={MAX_NOTE_LENGTH}
        ></textarea>
        <p className="text-sm text-gray-500 mb-2">
          {newNote.length}/{MAX_NOTE_LENGTH} characters
        </p>
        <button
          onClick={handleCreateNote}
          disabled={newNote.trim().length === 0}
          className={`px-4 py-2 rounded-md transition duration-300 ${
            newNote.trim().length === 0
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600 text-white'
          }`}
        >
          Create Note
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
        <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Filter Notes</h3>
        <div className="flex flex-wrap gap-2">
          {noteTypes.map((type) => {
            const buttonColor = type !== 'All' ? noteTypeColors[type] : 'bg-gray-200 dark:bg-gray-700';
            return (
              <button
                key={type}
                onClick={() => handleNoteTypeChange(type)}
                className={`px-4 py-2 rounded-full text-sm md:text-base transition duration-300 ${
                  selectedNoteType === type
                    ? `${buttonColor} text-gray-900 dark:text-gray-100 font-semibold`
                    : `${buttonColor} text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100`
                }`}
              >
                {type}
              </button>
            );
          })}
        </div>
      </div>
  
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {notes.map(renderNoteCard)}
      </div>

      <Pagination
        currentPage={currentPage}
        totalItems={totalNotes}
        itemsPerPage={notesPerPage}
        onPageChange={handlePageChange}
      />

      {editingNote && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full">
                  <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Edit Note</h3>
                  <div className="space-y-4">
                    <div>
                      <textarea
                        value={editingNote.content}
                        onChange={handleEditingNoteChange}
                        className="w-full p-2 border rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base"
                        rows={5}
                        placeholder="Note content"
                        maxLength={MAX_NOTE_LENGTH}
                      />
                      <p className="text-sm text-gray-500 mt-1">
                        {editingNote.content.length}/{MAX_NOTE_LENGTH} characters
                      </p>
                    </div>
                    <div className="flex justify-end space-x-2">
                      <button
                        onClick={() => setEditingNote(null)}
                        className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 dark:bg-gray-600 dark:text-gray-200 dark:hover:bg-gray-500 transition duration-300"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleUpdateNote}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 transition duration-300"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }

export default FocusNotes;
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../services/auth';
import { api } from '../../services/api';
import { useAuth } from '../AuthProvider';
import { getBrowserInstanceId } from '../../utils/browserInstance';

function VerifyEmail() {
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [email, setEmail] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [browserInstanceId, setBrowserInstanceId] = useState<string | null>(null);

  useEffect(() => {
    const initializeComponent = async () => {
      const emailFromState = (location.state as { email?: string })?.email;
      const browserInstanceIdFromState = (location.state as { browserInstanceId?: string })?.browserInstanceId;
      setEmail(emailFromState || user?.email || null);
      
      const instanceId = browserInstanceIdFromState || await getBrowserInstanceId();
      setBrowserInstanceId(instanceId);

      const verifyEmail = async () => {
        const token = new URLSearchParams(location.search).get('token');
        if (token) {
          try {
            const { data, error } = await auth.verifyEmail(token);
            if (error) throw error;
            setVerificationStatus('success');
            // Create user instance after successful verification
            if (instanceId) {
              await api.createUserInstance(instanceId);
            }
            setTimeout(() => navigate('/login'), 3000);
          } catch (error) {
            console.error('Verification error:', error);
            setVerificationStatus('error');
            setError((error as Error).message || 'An error occurred during verification');
          }
        }
      };

      verifyEmail();
    };

    initializeComponent();
  }, [location, navigate, user]);

  const handleResendVerification = async () => {
    if (email) {
      try {
        await auth.sendVerificationEmail(email);
        alert('Verification email sent. Please check your inbox.');
      } catch (error) {
        alert('Failed to send verification email. Please try again.');
      }
    } else {
      alert('Unable to resend verification email. Email address is missing.');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4 text-light-text dark:text-dark-text">Email Verification</h2>
      {verificationStatus === 'pending' && (
        <p className="text-light-text dark:text-dark-text">Please check your email for a verification link.</p>
      )}
      {verificationStatus === 'success' && (
        <p className="text-green-500 dark:text-green-400">Your email has been successfully verified! Redirecting to login...</p>
      )}
      {verificationStatus === 'error' && (
        <p className="text-red-500 dark:text-red-400">
          Failed to verify email. {error || 'The link may have expired.'}
        </p>
      )}
      {email && (
        <button
          onClick={handleResendVerification}
          className="mt-4 bg-light-primary dark:bg-dark-primary text-white py-2 px-4 rounded hover:bg-light-accent dark:hover:bg-dark-accent"
        >
          Resend Verification Email
        </button>
      )}
      {!email && (
        <p className="text-yellow-500 dark:text-yellow-400 mt-4">
          Unable to resend verification email. Please try logging in again to set your email address.
        </p>
      )}
    </div>
  );
}

export default VerifyEmail;
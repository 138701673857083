import React, { useState, useRef, useEffect, useCallback, KeyboardEvent, TouchEvent, MouseEvent } from 'react';
import { Modal } from '../../../components/ui/Modal';
import { Button } from '../../../components/ui/Button';
import useKeyPress from '../../../hooks/useKeyPress';
import { Move as MoveIcon } from 'lucide-react';

interface CalculatorProps {
  isOpen: boolean;
  onClose: () => void;
  autoFocus?: boolean;
}

const Calculator: React.FC<CalculatorProps> = ({ isOpen, onClose }) => {
  const [display, setDisplay] = useState('0');
  const [history, setHistory] = useState<string>('');
  const [currentValue, setCurrentValue] = useState<number | null>(null);
  const [operator, setOperator] = useState<string | null>(null);
  const [waitingForOperand, setWaitingForOperand] = useState(false);
  const [lastResult, setLastResult] = useState<number | null>(null);
  const dragRef = useRef<HTMLDivElement>(null);  
  const [isFocused, setIsFocused] = useState(false);
  const calculatorRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const dragOffsetRef = useRef({ x: 0, y: 0 });
  const [calculatorPosition, setCalculatorPosition] = useState({ x: 0, y: 0 });
  

  useEffect(() => {
    if (!isOpen) {
      // Reset calculator state when it's closed
      setDisplay('0');
      setHistory('');
      setCurrentValue(null);
      setOperator(null);
      setWaitingForOperand(false);
      setLastResult(null);
    }
  }, [isOpen]);

  // Add a new effect to handle text selection prevention
  useEffect(() => {
    const preventDefault = (e: Event) => e.preventDefault();

    if (isDragging) {
      // Prevent text selection while dragging
      document.addEventListener('selectstart', preventDefault);
    }

    return () => {
      document.removeEventListener('selectstart', preventDefault);
    };
  }, [isDragging]);

  useEffect(() => {
    if (isOpen) {
      setIsFocused(true);
      calculatorRef.current?.focus();
      // Center the calculator on open
      if (dragRef.current) {
        const rect = dragRef.current.getBoundingClientRect();
        const centerX = window.innerWidth / 2 - rect.width / 2;
        const centerY = window.innerHeight / 2 - rect.height / 2;
        setCalculatorPosition({ x: centerX, y: centerY });
      }
    } else {
      setIsFocused(false);
    }
  }, [isOpen]);

  const inputDigit = useCallback((digit: string) => {
    if (waitingForOperand) {
      setDisplay(digit);
      setHistory(prev => prev + digit);
      setWaitingForOperand(false);
    } else {
      setDisplay(display === '0' ? digit : display + digit);
      setHistory(prev => prev === '0' ? digit : prev + digit);
    }
    setLastResult(null);
  }, [display, waitingForOperand]);

  const inputDecimal = useCallback(() => {
    if (waitingForOperand) {
      setDisplay('0.');
      setHistory(prev => prev + '0.');
      setWaitingForOperand(false);
    } else if (display.indexOf('.') === -1) {
      setDisplay(display + '.');
      setHistory(prev => prev + '.');
    }
    setLastResult(null);
  }, [display, waitingForOperand]);

  const clear = useCallback(() => {
    setDisplay('0');
    setHistory('');
    setCurrentValue(null);
    setOperator(null);
    setWaitingForOperand(false);
    setLastResult(null);
  }, []);

  const performOperation = useCallback((nextOperator: string) => {
    const inputValue = parseFloat(display);
    let newValue: number | null = null;

    if (currentValue === null) {
      newValue = inputValue;
    } else if (operator) {
      const currentValueNum = currentValue || 0;
      switch (operator) {
        case '+':
          newValue = currentValueNum + inputValue;
          break;
        case '-':
          newValue = currentValueNum - inputValue;
          break;
        case '*':
          newValue = currentValueNum * inputValue;
          break;
        case '/':
          if (inputValue === 0) {
            setDisplay('Error');
            setHistory(prev => prev + ' = Error');
            setCurrentValue(null);
            setOperator(null);
            setWaitingForOperand(true);
            return;
          }
          newValue = currentValueNum / inputValue;
          break;
        default:
          return;
      }
    }

    if (newValue !== null) {
      setCurrentValue(newValue);
      setDisplay(String(newValue));
      setLastResult(newValue);
      if (nextOperator === '=') {
        setHistory(prev => `${prev} ${nextOperator} ${newValue}`);
      } else {
        setHistory(prev => `${prev} ${nextOperator} `);
      }
    }

    setWaitingForOperand(true);
    setOperator(nextOperator);
  }, [currentValue, display, operator]);

  const handleEquals = useCallback(() => {
    performOperation('=');
    setOperator(null);
  }, [performOperation]);

  const handleOperatorInput = useCallback((op: string) => {
    if (operator && !waitingForOperand) {
      // If there's already an operator and we're not waiting for an operand,
      // perform the operation with the current operator before setting the new one
      performOperation(operator);
    } else if (!operator && currentValue === null) {
      // If there's no operator and no current value, set the current value to the display value
      setCurrentValue(parseFloat(display));
    }

    setHistory(prev => {
      const trimmedPrev = prev.trim();
      const lastChar = trimmedPrev[trimmedPrev.length - 1];
      if (['+', '-', '*', '/'].includes(lastChar)) {
        return `${trimmedPrev.slice(0, -1)}${op} `;
      }
      return `${trimmedPrev} ${op} `;
    });

    setOperator(op);
    setWaitingForOperand(true);
  }, [operator, waitingForOperand, currentValue, display, performOperation]);

  const handleBackspace = useCallback(() => {
    if (waitingForOperand) {
      return;
    }
    if (display.length > 1) {
      setDisplay(display.slice(0, -1));
      setHistory(prev => prev.slice(0, -1));
    } else {
      setDisplay('0');
      setHistory(prev => prev.slice(0, -1));
    }
  }, [display, waitingForOperand]);

// Keyboard input
useKeyPress('0', () => inputDigit('0'), () => isOpen && isFocused);
useKeyPress('1', () => inputDigit('1'), () => isOpen && isFocused);
useKeyPress('2', () => inputDigit('2'), () => isOpen && isFocused);
useKeyPress('3', () => inputDigit('3'), () => isOpen && isFocused);
useKeyPress('4', () => inputDigit('4'), () => isOpen && isFocused);
useKeyPress('5', () => inputDigit('5'), () => isOpen && isFocused);
useKeyPress('6', () => inputDigit('6'), () => isOpen && isFocused);
useKeyPress('7', () => inputDigit('7'), () => isOpen && isFocused);
useKeyPress('8', () => inputDigit('8'), () => isOpen && isFocused);
useKeyPress('9', () => inputDigit('9'), () => isOpen && isFocused);
useKeyPress('.', inputDecimal, () => isOpen && isFocused);
useKeyPress('+', () => handleOperatorInput('+'), () => isOpen && isFocused);
useKeyPress('-', () => handleOperatorInput('-'), () => isOpen && isFocused);
useKeyPress('*', () => handleOperatorInput('*'), () => isOpen && isFocused);
useKeyPress('/', () => handleOperatorInput('/'), () => isOpen && isFocused);
useKeyPress('Enter', handleEquals, () => isOpen && isFocused);
useKeyPress('Backspace', handleBackspace, () => isOpen && isFocused);
useKeyPress('Escape', onClose, () => isOpen);

  // Update mouse event handlers
  const onMouseDown = (e: MouseEvent) => {
    if (dragRef.current) {
      const rect = dragRef.current.getBoundingClientRect();
      dragOffsetRef.current = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      };
      setIsDragging(true);
      
      // Bring calculator into focus
      dragRef.current.focus();
      
      // Prevent default behavior to stop text selection
      e.preventDefault();
    }
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging && dragRef.current) {
        const newX = e.clientX - dragOffsetRef.current.x;
        const newY = e.clientY - dragOffsetRef.current.y;
        dragRef.current.style.left = `${newX}px`;
        dragRef.current.style.top = `${newY}px`;
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove as any);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove as any);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  // Separate touch event handlers
  // Update touch event handlers
  const onTouchStart = (e: TouchEvent) => {
    if (dragRef.current) {
      const touch = e.touches[0];
      const rect = dragRef.current.getBoundingClientRect();
      dragOffsetRef.current = {
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top
      };
      setIsDragging(true);
      
      // Bring calculator into focus
      dragRef.current.focus();
    }
  };

  const onTouchMove = (e: TouchEvent) => {
    if (isDragging && dragRef.current) {
      const touch = e.touches[0];
      const newX = touch.clientX - dragOffsetRef.current.x;
      const newY = touch.clientY - dragOffsetRef.current.y;
      dragRef.current.style.left = `${newX}px`;
      dragRef.current.style.top = `${newY}px`;
    }
  };

  const onTouchEnd = () => {
    setIsDragging(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleEquals();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} noDarkOverlay>
      <div
        ref={dragRef}
        tabIndex={0}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={() => setIsDragging(false)}
        onKeyDown={handleKeyDown}
        className="bg-light-background dark:bg-dark-background shadow-lg rounded-lg absolute cursor-move"
        style={{
          touchAction: 'none',
          width: '90%',
          maxWidth: '320px',
          left: `${calculatorPosition.x}px`,
          top: `${calculatorPosition.y}px`,
          userSelect: 'none', // Prevent text selection within the calculator
        }}
      >
        <div className="flex items-center justify-between mb-2 text-gray-700 dark:text-gray-300">
          <div className="flex items-center cursor-move p-2">
            <MoveIcon size={24} />
          </div>
          <div className="flex items-center p-1">
            <button
              onClick={onClose}
              className="w-2 h-2 flex items-center justify-center rounded-full focus:outline-none transition-colors duration-200"
            >
              <span className="text-xs" style={{ marginTop: '-1px' }}>close</span>
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="mb-2 p-2 bg-gray-100 dark:bg-gray-700 rounded text-right text-sm text-light-text dark:text-dark-text overflow-x-auto whitespace-nowrap">
            {history}
          </div>
          <div className="mb-4 p-2 bg-gray-100 dark:bg-gray-700 rounded text-right text-2xl text-light-text dark:text-dark-text">
            {display}
          </div>
          <div className="grid grid-cols-4 gap-2">
            {['7', '8', '9', '/', '4', '5', '6', '*', '1', '2', '3', '-', '0', '.', '=', '+'].map((key) => (
              <Button
                key={key}
                onClick={() => {
                  switch (key) {
                    case '=':
                      handleEquals();
                      break;
                    case '+':
                    case '-':
                    case '*':
                    case '/':
                      handleOperatorInput(key);
                      break;
                    case '.':
                      inputDecimal();
                      break;
                    default:
                      inputDigit(key);
                  }
                }}
                className={`p-2 text-lg bg-primary-blue hover:bg-primary-blue-hover text-white ${['+', '-', '*', '/'].includes(key) && key === operator ? 'btn-primary' : ''}`}
              >
                {key}
              </Button>
            ))}
          </div>
          <div className="grid grid-cols-2 gap-2 mt-4">
            <Button onClick={clear} className="bg-primary-orange hover:bg-primary-orange-hover text-white">
              Clear
            </Button>
            <Button onClick={handleBackspace} className="w-full bg-primary-blue hover:bg-primary-blue-hover text-white">
              Backspace
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Calculator;
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, Simulation, SimulationCategory } from '../../services/api';
import SimulationQuiz from './SimulationsQuiz';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../AuthProvider';
import { RotateCcw, ChevronRight } from 'lucide-react';
import LoadingScreen from './common/LoadingScreen';

interface SimulationsProps {
  courseId: string;
  userId: string;
}

interface SimulationCategoryWithStatus extends SimulationCategory {
  total: number;
  completed: number;
}

const Simulations: React.FC<SimulationsProps> = ({ courseId, userId }) => {
  const [simulationCategories, setSimulationCategories] = useState<SimulationCategoryWithStatus[]>([]);
  const [selectedSimulation, setSelectedSimulation] = useState<Simulation | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());
  const [showExplanation, setShowExplanation] = useState(false);
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();

  const fetchSimulationCategories = useCallback(async () => {
    if (!courseId || !userId) return;

    try {
      setLoading(true);
      const categoriesData = await api.getSimulationCategories(courseId);
      const simulationsData = await api.getSimulations(courseId);

      // Fetch user responses for all simulations
      const userResponses = await Promise.all(
        simulationsData.map(sim => api.getUserTBSResponse(userId, sim.id))
      );

      const categoriesWithStatus = categoriesData.map(category => {
        const categorySimulations = simulationsData.filter(sim => sim.question_category_id === category.id);
        const completed = userResponses.filter(
          (response, index) => 
            response && 
            response.completed && 
            simulationsData[index].question_category_id === category.id
        ).length;
        return {
          ...category,
          total: categorySimulations.length,
          completed
        };
      });

      setSimulationCategories(categoriesWithStatus);
      setLoading(false);
    } catch (err) {
      console.error('Failed to fetch simulation categories:', err);
      setError('Failed to load simulation categories. Please try again.');
      setLoading(false);
    }
  }, [courseId, userId]);

  useEffect(() => {
    fetchSimulationCategories();
  }, [fetchSimulationCategories]);

  const handleCategoryClick = (categoryId: string) => {
    setExpandedCategories(prev => {
      const newSet = new Set(prev);
      if (newSet.has(categoryId)) {
        newSet.delete(categoryId);
      } else {
        newSet.add(categoryId);
      }
      return newSet;
    });
  };

  const handlePracticeSimulations = async (categoryId: string) => {
    if (!user) return;
  
    try {
      const simulations = await api.getSimulations(courseId);
      const categorySimulations = simulations.filter(sim => sim.question_category_id === categoryId);
  
      if (categorySimulations.length === 0) {
        alert('No simulations available in this category.');
        return;
      }
  
      // Fetch user responses for all simulations in this category
      const userResponses = await Promise.all(
        categorySimulations.map(sim => api.getUserTBSResponse(user.id, sim.id))
      );
  
      // Find the first unanswered simulation
      const firstUnansweredIndex = userResponses.findIndex(response => !response || !response.completed);
  
      if (firstUnansweredIndex !== -1) {
        // If there's an unanswered simulation, set it as selected
        setSelectedSimulation(categorySimulations[firstUnansweredIndex]);
        setShowExplanation(false);
      } else {
        // If all simulations are answered, set the first simulation
        setSelectedSimulation(categorySimulations[0]);
        setShowExplanation(true);
      }
  
    } catch (error) {
      console.error('Error fetching simulations:', error);
      setError('Failed to start practice simulations. Please try again.');
    }
  };

  const handleNextSimulation = async () => {
    if (!selectedSimulation) return;

    try {
      const simulations = await api.getSimulations(courseId);
      const currentIndex = simulations.findIndex(sim => sim.id === selectedSimulation.id);
      const nextSimulation = simulations[currentIndex + 1];

      if (nextSimulation) {
        setSelectedSimulation(nextSimulation);
        setShowExplanation(false);
      } else {
        setSelectedSimulation(null);
        setShowExplanation(false);
      }
    } catch (error) {
      console.error('Error fetching next simulation:', error);
      setError('Failed to load the next simulation. Please try again.');
    }
  };

  // Updated handleSimulationSubmit function
  const handleSimulationSubmit = useCallback(async () => {
    setShowExplanation(true);
    if (selectedSimulation) {
      // Update the completion status
      setSimulationCategories(prevCategories =>
        prevCategories.map(category =>
          category.id === selectedSimulation.question_category_id
            ? { ...category, completed: category.completed + 1 }
            : category
        )
      );
    }
    // Fetch updated categories to ensure accuracy
    await fetchSimulationCategories();
  }, [selectedSimulation, fetchSimulationCategories]);

  const handleResetSimulations = async () => {
    if (!user) return;
  
    const confirm = window.confirm("Are you sure you want to reset all simulations? This will erase all your previous answers and progress.");
    
    if (confirm) {
      try {
        await api.resetSimulations(user.id, courseId);
        alert("All simulations have been reset successfully.");
        fetchSimulationCategories(); // Refresh the simulation categories
      } catch (error) {
        console.error('Error resetting simulations:', error);
        setError('Failed to reset simulations. Please try again.');
      }
    }
  };

  if (isLoading) {
    return <LoadingScreen message="Loading your Simulations..." subMessage="This may take a few moments" />;
  }
  if (error) return <div className="text-red-500">{error}</div>;

  if (selectedSimulation) {
    return (
      <>
        <SimulationQuiz 
          simulation={selectedSimulation} 
          onSubmit={handleSimulationSubmit}
          onNext={handleNextSimulation}
        />
      </>
    );
  }

  return (
    <div className={`p-4 sm:p-6 md:p-8 ${isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-50 text-gray-900'}`}>
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
          <h2 className="text-2xl font-bold">Task-Based Simulations</h2>
          <button
            onClick={handleResetSimulations}
            className={`px-4 py-2 rounded-full text-sm font-medium
              ${isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600 text-gray-200' 
                : 'bg-gray-200 hover:bg-gray-300 text-gray-700'} 
              transition-colors duration-200 flex items-center shadow-sm`}
          >
            <RotateCcw size={16} className="mr-2" />
            Reset Simulations
          </button>
        </div>
        <div className="space-y-4">
          {simulationCategories.map(category => (
            <div
              key={category.id}
              className={`bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden
                transition-all duration-200 hover:shadow-lg`}
            >
              <div 
                className="p-4 sm:p-6 cursor-pointer"
                onClick={() => handleCategoryClick(category.id)}
              >
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-3 sm:space-y-0">
                  <div className="flex items-center space-x-3">
                    <span className="font-semibold text-lg">
                      {category.parent_question_category_name}
                    </span>
                    <span className="bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-100 
                      text-xs px-2.5 py-0.5 rounded-full font-medium">
                      {category.completed} / {category.total}
                    </span>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePracticeSimulations(category.id);
                    }}
                    className="bg-[var(--color-primary-orange)] hover:bg-[var(--color-primary-orange-hover)] 
                      text-white text-sm px-4 py-2 rounded-full transition-colors duration-200 
                      flex items-center justify-center shadow-sm"
                  >
                    Practice
                    <ChevronRight size={16} className="ml-1" />
                  </button>
                </div>
              </div>
              {expandedCategories.has(category.id) && (
                <div className="px-4 pb-4 sm:px-6 sm:pb-6">
                  {/* Add expanded content here if needed */}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Simulations;
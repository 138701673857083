import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import { supabase } from '../../../services/supabase';
import { Ticket } from '../../../services/api';
import { format } from 'date-fns';
import { 
  AlertCircle, 
  Clock, 
  Filter, 
  Inbox, 
  Loader2, 
  MessageCircle, 
  Search,
  Tag,
  UserCircle,
  RefreshCcw,
  Trash2,
  Mail,
  X,
  CheckSquare,
  Square  // Add this import
} from 'lucide-react';
import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';
import { Card } from '../../ui/Card';
import { Checkbox } from '../../ui/Checkbox';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue 
} from '../../ui/Select';
import { Tooltip } from '../../ui/Tooltip';
import Pagination from '../../course/common/Pagination';


// Constants
const ITEMS_PER_PAGE = 25;
const MAX_PREVIEW_LENGTH = 150;
const MAX_TOOLTIP_LENGTH = 500;

// Also add a type for status to make it more maintainable
type TicketStatus = 'new' | 'open' | 'pending' | 'closed';

interface AdminData {
  email: string;
  full_name: string;
}

interface TransformedTicket extends Omit<Ticket, '_count'> {
  _count: {
    messages: number;
  };
  admin?: AdminData;
}

interface TicketListProps {
  filter: 'all' | 'mine' | 'urgent';
  onTicketSelect: (ticket: TransformedTicket) => void;
  selectedTicketId?: string;
}

const TicketList: React.FC<TicketListProps> = ({ filter, onTicketSelect, selectedTicketId }) => {
  const { user } = useAuth();
  const [tickets, setTickets] = useState<TransformedTicket[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeStatuses, setActiveStatuses] = useState<Set<string>>(new Set(['new', 'open', 'pending']));
  const [priorityFilter, setPriorityFilter] = useState<string>('all');
  const [assignmentFilter, setAssignmentFilter] = useState<string>('all');
  const [selectedTickets, setSelectedTickets] = useState<Set<string>>(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [editingStatus, setEditingStatus] = useState<string>('');

  // Add these new helper functions at the top
  const stripHtmlTags = (html: string): string => {
    try {
      // Create a DOMParser instance
      const parser = new DOMParser();
      // Parse the HTML string
      const doc = parser.parseFromString(html, 'text/html');
      // Get the text content and normalize whitespace
      return (doc.body.textContent || '')
        .replace(/\s+/g, ' ')
        .trim();
    } catch (error) {
      // Fallback to basic string replacement if DOMParser fails
      return html
        .replace(/<[^>]*>/g, '') // Remove HTML tags
        .replace(/&nbsp;/g, ' ') // Replace &nbsp; with space
        .replace(/\s+/g, ' ') // Normalize whitespace
        .trim();
    }
  };

  const truncateText = (text: string, maxLength: number): string => {
    if (!text) return '';
    const cleanText = stripHtmlTags(text);
    if (cleanText.length <= maxLength) return cleanText;
    return `${cleanText.substring(0, maxLength).trim()}...`;
  };

  useEffect(() => {
    loadTickets();
  }, [activeStatuses, priorityFilter, assignmentFilter, filter, currentPage]);

  // Updated loadTickets function with proper joins and logging
  const loadTickets = async () => {
    try {
      setLoading(true);
      
      // First get count for pagination
      let countQuery = supabase
        .from('support_tickets')
        .select('id', { count: 'exact' })
        .not('support_user_id', 'is', null);
  
      if (filter === 'urgent') {
        countQuery = countQuery.eq('priority', 'urgent');
      } else if (filter === 'mine') {
        countQuery = countQuery.eq('admin_id', user?.id);
      }
  
      if (activeStatuses.size > 0) {
        countQuery = countQuery.in('status', Array.from(activeStatuses));
      }
  
      const { count, error: countError } = await countQuery;
      
      if (countError) throw countError;
  
      setTotalItems(count || 0);
      
      // Main query with correct table references
      let query = supabase
        .from('support_tickets')
        .select(`
          *,
          user:support_users (
            id,
            email,
            full_name,
            auth_user_id,
            email_domain,
            metadata,
            created_at,
            last_activity
          ),
          support_messages (count)
        `)
        .not('support_user_id', 'is', null)
        .order('last_activity_at', { ascending: false })
        .range((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE - 1);
  
      // Apply filters
      if (filter === 'urgent') {
        query = query.eq('priority', 'urgent');
      } else if (filter === 'mine') {
        query = query.eq('admin_id', user?.id);
      }
  
      if (activeStatuses.size > 0) {
        query = query.in('status', Array.from(activeStatuses));
      }
  
      if (priorityFilter !== 'all') {
        query = query.eq('priority', priorityFilter);
      }
  
      switch (assignmentFilter) {
        case 'unassigned':
          query = query.is('admin_id', null);
          break;
        case 'assigned':
          query = query.not('admin_id', 'is', null);
          break;
        case 'mine':
          query = query.eq('admin_id', user?.id);
          break;
      }
  
      const { data: ticketsData, error: ticketsError } = await query;
  
      if (ticketsError) throw ticketsError;
  
      if (!ticketsData) {
        setTickets([]);
        return;
      }
    
      // Transform the data with guaranteed _count
      // Transform the data with guaranteed _count
      const transformedTickets: TransformedTicket[] = ticketsData.map(ticket => ({
        ...ticket,
        _count: {
          messages: ticket.support_messages?.[0]?.count || 0
        },
        admin: ticket.admin ? {
          email: ticket.admin.email,
          full_name: ticket.admin.full_name
        } : undefined
      }));
  
      setTickets(transformedTickets);
  
    } catch (error) {
      console.error('Error in loadTickets:', error);
    } finally {
      setLoading(false);
    }
  };

  // Update the message count display in the JSX to handle undefined safely
  const renderMessageCount = (ticket: TransformedTicket) => {
    return (
      <div className="flex items-center">
        <MessageCircle className="w-3 h-3 mr-1" />
        {ticket._count.messages}
      </div>
    );
  };

  const toggleStatus = (status: TicketStatus) => {
    const newStatuses = new Set(activeStatuses);
    if (newStatuses.has(status)) {
      newStatuses.delete(status);
    } else {
      newStatuses.add(status);
    }
    setActiveStatuses(newStatuses);
  };

  // Helper function to get status button styles
  const getStatusButtonStyle = (status: string) => {
    const isActive = activeStatuses.has(status);
    return {
      base: `h-8 text-xs px-2.5 transition-colors flex items-center gap-1.5 border`,
      active: isActive ? 
        'bg-blue-100 text-blue-800 border-blue-200 hover:bg-blue-200 dark:bg-blue-900/30 dark:text-blue-300 dark:border-blue-800' :
        'bg-gray-50 text-gray-600 border-gray-200 hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-700 dark:hover:bg-gray-700'
    };
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'urgent': return 'bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-300 border border-red-200 dark:border-red-800';
      case 'high': return 'bg-orange-100 text-orange-800 dark:bg-orange-900/20 dark:text-orange-300 border border-orange-200 dark:border-orange-800';
      case 'medium': return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-300 border border-yellow-200 dark:border-yellow-800';
      default: return 'bg-gray-100 text-gray-800 dark:bg-gray-900/20 dark:text-gray-300 border border-gray-200 dark:border-gray-800';
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'new': return 'bg-yellow-400 text-black border border-yellow-500 dark:border-yellow-600';
      case 'open': return 'bg-red-600 text-white border border-red-700 dark:border-red-800';
      case 'pending': return 'bg-blue-600 text-yellow-200 border border-blue-700 dark:border-blue-800';
      case 'closed': return 'bg-gray-600 text-white border border-gray-700 dark:border-gray-800';
      default: return 'bg-gray-100 text-gray-800 dark:bg-gray-900/20 dark:text-gray-300 border border-gray-200 dark:border-gray-800';
    }
  };

  const StatusChangeDropdown = () => (
    <div className="relative">
      <Select value={editingStatus} onValueChange={handleUpdateStatus}>
        <SelectTrigger className="h-8 text-sm min-w-[80px] bg-white dark:bg-gray-800">
          <SelectValue placeholder="Edit" />
        </SelectTrigger>
        <SelectContent className="w-auto min-w-[120px] absolute left-0 right-auto">
          <SelectItem 
            value="new" 
            className="text-sm whitespace-nowrap px-2 py-1.5 w-auto"
          >
            New
          </SelectItem>
          <SelectItem 
            value="open" 
            className="text-sm whitespace-nowrap px-2 py-1.5 w-auto"
          >
            Open
          </SelectItem>
          <SelectItem 
            value="pending" 
            className="text-sm whitespace-nowrap px-2 py-1.5 w-auto"
          >
            Pending
          </SelectItem>
          <SelectItem 
            value="closed" 
            className="text-sm whitespace-nowrap px-2 py-1.5 w-auto"
          >
            Closed
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  );

  // Updated search filter to use support_user fields
const filteredTickets = tickets.filter(ticket => {
  if (!searchQuery) return true;
  const searchLower = searchQuery.toLowerCase();
  return (
    ticket.subject.toLowerCase().includes(searchLower) ||
    ticket.user.email.toLowerCase().includes(searchLower) ||
    (ticket.user.full_name || '').toLowerCase().includes(searchLower) ||
    ticket.tags?.some(tag => tag.toLowerCase().includes(searchLower)) ||
    (ticket.user.email_domain || '').toLowerCase().includes(searchLower)
  );
});

  const handleSelectAllButtonClick = () => {
    // Get all visible ticket IDs
    const visibleTicketIds = filteredTickets.map(ticket => ticket.id);
    
    // Check if all visible tickets are currently selected
    const allVisible = visibleTicketIds.every(id => selectedTickets.has(id));
    
    if (allVisible) {
      // If all visible tickets are selected, deselect only the visible ones
      const newSelected = new Set(selectedTickets);
      visibleTicketIds.forEach(id => newSelected.delete(id));
      setSelectedTickets(newSelected);
    } else {
      // If not all visible tickets are selected, select all visible ones
      const newSelected = new Set([...Array.from(selectedTickets), ...visibleTicketIds]);
      setSelectedTickets(newSelected);
    }
  };

  // Add helper to check if all visible tickets are selected
  const areAllVisibleSelected = () => {
    return filteredTickets.length > 0 && 
           filteredTickets.every(ticket => selectedTickets.has(ticket.id));
  };

  const handleSelectTicket = (ticketId: string) => {
    const newSelected = new Set(selectedTickets);
    if (newSelected.has(ticketId)) {
      newSelected.delete(ticketId);
    } else {
      newSelected.add(ticketId);
    }
    setSelectedTickets(newSelected);
  };

  const handleDeleteSelected = async () => {
    if (!window.confirm('Are you sure you want to delete the selected tickets?')) return;

    try {
      const { error } = await supabase
        .from('support_tickets')
        .delete()
        .in('id', Array.from(selectedTickets));

      if (error) throw error;
      
      setSelectedTickets(new Set());
      loadTickets();
    } catch (error) {
      console.error('Error deleting tickets:', error);
    }
  };

  const handleUpdateStatus = async (newStatus: string) => {
    try {
      const ticketsToUpdate = Array.from(selectedTickets);
      
      const { error } = await supabase
        .from('support_tickets')
        .update({ 
          status: newStatus,
          updated_at: new Date().toISOString()
        })
        .in('id', ticketsToUpdate);

      if (error) throw error;
      
      setEditingStatus('');
      setSelectedTickets(new Set());
      loadTickets();
    } catch (error) {
      console.error('Error updating ticket status:', error);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <Card className="flex-1 flex flex-col rounded-none border-0 shadow-none">
        {/* Header Section */}
        <div className="p-2 border-b dark:border-gray-800 bg-white dark:bg-gray-900">
          <div className="flex flex-col gap-2">
            {/* Search and Actions Row */}
            <div className="flex gap-2">
              <div className="relative flex-1 min-w-0">
                <Input
                  placeholder="Search tickets..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="pl-8 h-9 bg-gray-50 dark:bg-gray-800 text-sm"
                />
                <Search className="w-4 h-4 text-gray-500 absolute left-2.5 top-2.5" />
              </div>
            </div>
  
            {/* Filters */}
            <div className="flex flex-wrap gap-2">
              {/* Status Toggle Buttons */}
              <div className="flex gap-1">
                <Button
                  variant="ghost"
                  onClick={() => toggleStatus('new')}
                  className={`${getStatusButtonStyle('new').base} ${getStatusButtonStyle('new').active}`}
                >
                  New
                  {activeStatuses.has('new') && (
                    <X className="w-3 h-3" />
                  )}
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => toggleStatus('open')}
                  className={`${getStatusButtonStyle('open').base} ${getStatusButtonStyle('open').active}`}
                >
                  Open
                  {activeStatuses.has('open') && (
                    <X className="w-3 h-3" />
                  )}
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => toggleStatus('pending')}
                  className={`${getStatusButtonStyle('pending').base} ${getStatusButtonStyle('pending').active}`}
                >
                  Pending
                  {activeStatuses.has('pending') && (
                    <X className="w-3 h-3" />
                  )}
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => toggleStatus('closed')}
                  className={`${getStatusButtonStyle('closed').base} ${getStatusButtonStyle('closed').active}`}
                >
                  Closed
                  {activeStatuses.has('closed') && (
                    <X className="w-3 h-3" />
                  )}
                </Button>
              </div>
  
              <Select value={priorityFilter} onValueChange={setPriorityFilter}>
                <SelectTrigger className="h-8 text-sm min-w-[100px] max-w-[120px] bg-gray-50 dark:bg-gray-800">
                  <AlertCircle className="w-3 h-3 mr-1" />
                  <SelectValue placeholder="Priority" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Priority</SelectItem>
                  <SelectItem value="urgent">Urgent</SelectItem>
                  <SelectItem value="high">High</SelectItem>
                  <SelectItem value="medium">Medium</SelectItem>
                  <SelectItem value="low">Low</SelectItem>
                </SelectContent>
              </Select>
  
              <Select value={assignmentFilter} onValueChange={setAssignmentFilter}>
                <SelectTrigger className="h-8 text-sm min-w-[100px] max-w-[120px] bg-gray-50 dark:bg-gray-800">
                  <UserCircle className="w-3 h-3 mr-1" />
                  <SelectValue placeholder="Assignment" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Tickets</SelectItem>
                  <SelectItem value="mine">My Tickets</SelectItem>
                  <SelectItem value="unassigned">Unassigned</SelectItem>
                  <SelectItem value="assigned">Assigned</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
  
        {/* Main Content Area */}
        <div className="flex-1 flex flex-col">
          {/* Selection Header */}
          <div className="px-4 py-2 bg-gray-50/80 dark:bg-gray-800/80 border-b dark:border-gray-700 flex items-center justify-between sticky top-0 backdrop-blur-sm z-10">
            <div className="flex items-center space-x-4">
              
              {/* Select All Button */}
              <Button 
                variant="outline" 
                onClick={handleSelectAllButtonClick}
                className="
                  h-6 px-0 
                  flex items-center justify-center gap-1
                  border border-gray-200 dark:border-gray-700
                  bg-white dark:bg-gray-900 
                  hover:bg-gray-50 dark:hover:bg-gray-800
                  text-gray-700 dark:text-gray-300
                  transition-colors
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-offset-gray-900
                "
              >
                <span className="flex items-center justify-center">
                  {areAllVisibleSelected() ? (
                    <CheckSquare className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                  ) : (
                    <Square className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                  )}
                </span>
                <span className="text-sm font-medium">
                  {areAllVisibleSelected()}
                </span>
              </Button>

              {/* Selection Count */}
              <div className="flex items-center">
                <span className="text-xs font-medium text-gray-600 dark:text-gray-300">
                  {selectedTickets.size} selected
                </span>
              </div>

              {/* Action Buttons - Only show when items are selected */}
              {selectedTickets.size > 0 && (
                <div className="flex items-center space-x-3">
                  <StatusChangeDropdown />
                  <Button
                    variant="destructive"
                    onClick={handleDeleteSelected}
                    className="h-8 px-3 text-sm flex items-center gap-1.5"
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </div>
              )}
            </div>

            {/* Refresh Button - aligned to the far right */}
            <div className="ml-auto">
              <Button 
                variant="outline" 
                onClick={() => loadTickets()}
                className="h-9 w-9 p-1 flex items-center justify-center text-gray-700 dark:text-white bg-blue-200 hover:bg-blue-100 dark:bg-blue-800 dark:hover:bg-blue-700"
              >
                <RefreshCcw className="w-8 h-8" /> {/* Increased icon size */}
              </Button>
            </div>
          </div>

  
          {/* Tickets List */}
          <div className="flex-1 overflow-auto">
            {loading ? (
              <div className="flex items-center justify-center h-full">
                <Loader2 className="w-8 h-8 animate-spin text-gray-500" />
              </div>
            ) : filteredTickets.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-500">
                <Inbox className="w-12 h-12 mb-2" />
                <p>No tickets found</p>
              </div>
            ) : (
              <div className="divide-y divide-gray-100 dark:divide-gray-800">
                {filteredTickets.map((ticket) => (
                  <div
                    key={ticket.id}
                    className={`
                      group flex items-start p-2 cursor-pointer border-l-4 transition-all
                      hover:bg-gray-50 dark:hover:bg-gray-800/50 
                      ${selectedTicketId === ticket.id ? 
                        'bg-blue-50/80 dark:bg-blue-900/20 border-l-4 border-blue-500 font-bold' : 
                        'border-l-transparent bg-white dark:bg-gray-900'}
                      ${selectedTickets.has(ticket.id) ? 
                        'bg-gray-50/80 dark:bg-gray-800/50' : ''}
                    `}
                    onClick={() => onTicketSelect(ticket)}
                  >
                    <div 
                      className="mr-2 mt-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectTicket(ticket.id);
                      }}
                    >
                      <Checkbox
                        checked={selectedTickets.has(ticket.id)}
                        className="cursor-pointer"
                      />
                    </div>
  
                    <div className="flex-1 min-w-0">
                      {/* Ticket Header */}
                      <div className="flex items-start justify-between gap-2 mb-1">
                        <div className="flex-1 min-w-0">
                          <div className="flex items-center gap-2">
                            <Mail className="w-4 h-4 text-gray-400 flex-shrink-0" />
                            <h3 className="font-medium text-sm text-gray-900 dark:text-gray-100 truncate">
                              {ticket.subject}
                            </h3>
                          </div>
                          
                          {/* Body preview with tooltip */}
                          {ticket.body && (
                            <Tooltip
                              content={
                                <div className="max-w-md">
                                  <p className="whitespace-pre-wrap text-xs">
                                    {truncateText(ticket.body, MAX_TOOLTIP_LENGTH)}
                                  </p>
                                </div>
                              }
                              side="right"
                              align="start"
                              delayMS={300}
                            >
                              <div className="text-xs text-gray-600 dark:text-gray-400 line-clamp-1">
                                {truncateText(ticket.body, MAX_PREVIEW_LENGTH)}
                              </div>
                            </Tooltip>
                          )}
                        </div>
  
                        {/* Status and Priority Badges */}
                        <div className="flex items-center gap-1 flex-shrink-0">
                          <span className={`text-xs px-1.5 py-0.5 rounded-full ${getPriorityColor(ticket.priority)}`}>
                            {ticket.priority}
                          </span>
                          <span className={`text-xs px-1.5 py-0.5 rounded-full ${getStatusColor(ticket.status)}`}>
                            {ticket.status}
                          </span>
                        </div>
                      </div>
  
                      {/* Ticket Metadata */}
                      <div className="flex flex-wrap items-center text-xs text-gray-500 gap-x-3 gap-y-1">
                        <div className="flex items-center">
                          <UserCircle className="w-3 h-3 mr-1" />
                          <span className="truncate max-w-[150px]">{ticket.user.full_name}</span>
                        </div>
                        <div className="flex items-center">
                          <Clock className="w-3 h-3 mr-1" />
                          {format(new Date(ticket.last_activity_at), 'MMM d, h:mm a')}
                        </div>
                        {renderMessageCount(ticket)}
                        {ticket.admin && (
                          <div className="flex items-center">
                            <UserCircle className="w-3 h-3 mr-1" />
                            <span className="truncate max-w-[150px]">{ticket.admin.full_name}</span>
                          </div>
                        )}
                        {ticket.tags && ticket.tags.length > 0 && (
                          <div className="flex items-center gap-1 flex-wrap">
                            <Tag className="w-3 h-3" />
                            {ticket.tags.map((tag: string, index: number) => (
                              <span
                                key={index}
                                className="text-xs px-1.5 py-0.5 rounded-full bg-gray-100 dark:bg-gray-800"
                              >
                                {tag}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
  
          {/* Pagination */}
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            onPageChange={setCurrentPage}
          />
        </div>
      </Card>
    </div>
  );
};

export default TicketList;
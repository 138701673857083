import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import { supabase } from '../../../services/supabase';
import { Ticket, api } from '../../../services/api';
import { 
  Mail,
  Plus
} from 'lucide-react';
import { Button } from '../../ui/Button';
import UserTicketList from './UserTicketList';
import UserTicketDetail from './UserTicketDetail';
import UserSidebar from './UserSidebar';
import QuestionPreview from '../../course/QuestionPreview';
import TicketComposer from './TicketComposer';

interface Question {
  id: string;
  question_content: string;
  answer_content: string;
  question_category_name: string | null;
  sub_question_category_name: string | null;
  question_type: string | null;
  correct_answer: string | null;
  choices_count: number | null;
}

const UserTicketDashboard: React.FC = () => {
  const { user, refreshUser } = useAuth();
  const [userData, setUserData] = useState<{ email: string; full_name: string | null } | null>(null);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [showSidebar, setShowSidebar] = useState(true);
  const [isCreatingTicket, setIsCreatingTicket] = useState(false);
  const [questionData, setQuestionData] = useState<Question | null>(null);
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(false);
  const [expandedQuestionId, setExpandedQuestionId] = useState<string | null>(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  // Add refs to store subscription channels
  const ticketSubscriptionRef = useRef<ReturnType<typeof supabase.channel> | null>(null);
  const messageSubscriptionRef = useRef<ReturnType<typeof supabase.channel> | null>(null);


  const loadUserTickets = useCallback(async () => {
    if (!user?.id) return;
    
    try {
      // Get support user first
      const supportUser = await api.getOrCreateSupportUser(user.email, user.id);
      
      // Get latest tickets for this user
      const { data: ticketsData, error } = await supabase
        .from('support_tickets')
        .select(`
          *,
          support_users!support_tickets_support_user_id_fkey(*)
        `)
        .eq('support_user_id', supportUser.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Update any relevant state based on the tickets
      // Note: We don't need to set the tickets here since UserTicketList 
      // manages its own ticket state and will refresh via the subscription
    } catch (error) {
      console.error('Error loading tickets:', error);
    }
  }, [user?.id, user?.email]);

  const refreshData = useCallback(async () => {
    setShouldRefresh(true);
    try {
      // Refresh user data if needed
      await refreshUser();
      // Force reload tickets
      await loadUserTickets();
    } catch (error) {
      console.error('Error refreshing data:', error);
    } finally {
      setShouldRefresh(false);
    }
  }, [refreshUser]);

  // Memoize loadQuestionData to prevent unnecessary re-renders
  const loadQuestionData = useCallback(async (questionId: string) => {
      setIsLoadingQuestion(true);
      try {
          const { data, error } = await supabase
              .from('questions')
              .select('*')
              .eq('id', questionId)
              .single();

          if (error) throw error;
          setQuestionData(data);
      } catch (error) {
          console.error('Error loading question:', error);
      } finally {
          setIsLoadingQuestion(false);
      }
  }, []);

  // Memoize loadUserData to prevent unnecessary re-renders
  const loadUserData = useCallback(async () => {
      if (!user?.id) return;
      
      try {
          const { data, error } = await supabase
              .from('users')
              .select('email, full_name')
              .eq('id', user.id)
              .single();

          if (error) throw error;
          setUserData(data);
      } catch (error) {
          console.error('Error loading user data:', error);
      }
  }, [user?.id]);

  // Setup subscriptions only once when component mounts
  useEffect(() => {
      if (user?.id) {
          // Load initial user data
          loadUserData();

          // Setup realtime subscriptions
          const ticketChannel = supabase.channel(`tickets-${user.id}`);
          const messageChannel = supabase.channel(`messages-${user.id}`);

          ticketSubscriptionRef.current = ticketChannel
              .on('postgres_changes', {
                  event: '*',
                  schema: 'public',
                  table: 'support_tickets',
                  filter: `user_id=eq.${user.id}`
              }, () => {
                  refreshData();
              })
              .subscribe();

          messageSubscriptionRef.current = messageChannel
              .on('postgres_changes', {
                  event: 'INSERT',
                  schema: 'public',
                  table: 'support_messages'
              }, () => {
                  refreshData();
              })
              .subscribe();

          // Cleanup subscriptions
          return () => {
              if (ticketSubscriptionRef.current) {
                  ticketSubscriptionRef.current.unsubscribe();
              }
              if (messageSubscriptionRef.current) {
                  messageSubscriptionRef.current.unsubscribe();
              }
          };
      }
  }, [user?.id, refreshData, loadUserData]);

    // Load question data only when content_id changes
    useEffect(() => {
        if (selectedTicket?.content_id) {
            loadQuestionData(selectedTicket.content_id);
        } else {
            setQuestionData(null);
        }
    }, [selectedTicket?.content_id, loadQuestionData]);

    const handleTicketSelect = useCallback((ticket: Ticket) => {
        setSelectedTicket(ticket);
        setIsCreatingTicket(false);
        setShowSidebar(true);
        setExpandedQuestionId(null);
    }, []);

    const handleCreateNewTicket = useCallback(() => {
        setIsCreatingTicket(true);
        setSelectedTicket(null);
        setShowSidebar(false);
    }, []);

    const handleBackToList = useCallback(() => {
        setIsCreatingTicket(false);
        setSelectedTicket(null);
        setShowSidebar(true);
    }, []);

    const toggleQuestionPreview = useCallback(() => {
        if (selectedTicket?.content_id) {
            setExpandedQuestionId(prevId => 
                prevId === selectedTicket.content_id ? null : selectedTicket.content_id
            );
        }
    }, [selectedTicket?.content_id]);

    return (
        <div className="fixed inset-x-0 bottom-0 top-16 flex flex-col bg-gray-50 dark:bg-gray-900">
          {shouldRefresh && (
            <div className="absolute inset-0 bg-black/10 dark:bg-black/20 flex items-center justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
            </div>
          )}
    
          {/* Header section */}
          {!selectedTicket && !isCreatingTicket && (
            <div className="flex-none">
              <div className="flex justify-between items-center h-16 px-6 border-b dark:border-gray-700 bg-white dark:bg-gray-800">
                <h1 className="text-2xl font-bold">Support Tickets</h1>
                <Button onClick={handleCreateNewTicket} className="flex items-center">
                  <Plus className="w-4 h-4 mr-2" />
                  <span>New Ticket</span>
                </Button>
              </div>
            </div>
          )}
    
          {/* Main Content Area - Updated with better overflow handling */}
          <div className="flex-1 min-h-0"> {/* Added min-h-0 to allow child flex items to scroll */}
            <div className="h-full p-6 flex gap-6">
              {/* Ticket List Section - Updated with responsive width */}
              <div className={`h-full transition-all duration-300 ease-in-out ${
                selectedTicket || isCreatingTicket ? 'w-1/3 min-w-[300px]' : 'w-full'
              }`}>
                <div className="h-full flex flex-col bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden">
                  <UserTicketList
                    onTicketSelect={handleTicketSelect}
                    selectedTicketId={selectedTicket?.id}
                    isCompressed={Boolean(selectedTicket || isCreatingTicket)}
                  />
                </div>
              </div>
    
              {/* Detail Section */}
              {(selectedTicket || isCreatingTicket) && (
                <div className="flex-1 min-w-0 flex gap-6">
                  <div className="flex-1 flex flex-col min-w-0 bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden">
                  {isCreatingTicket ? (
                        <div className="flex-1">
                        <TicketComposer 
                            onBack={handleBackToList} 
                            onRefresh={refreshData}  // Add refresh callback
                        />
                        </div>
                    ) : (
                      <>
                        <UserTicketDetail
                          ticketId={selectedTicket!.id}
                          onBack={handleBackToList}
                          onToggleQuestion={selectedTicket?.content_id ? toggleQuestionPreview : undefined}
                        />
                        {expandedQuestionId && selectedTicket?.content_id && (
                          <div className="border-t border-gray-200 dark:border-gray-700">
                            <QuestionPreview
                              questionId={selectedTicket.content_id}
                              courseId={selectedTicket.content_id.split('-')[0]}
                              userId={user?.id || ''}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
    
                  {showSidebar && !isCreatingTicket && (
                    <div className="w-1/3 min-w-[250px] flex flex-col bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden">
                      <UserSidebar
                        userId={user?.id || ''}
                        ticketQuestionId={questionData ? selectedTicket?.content_id : null}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
    
          {/* Mobile Create Button */}
          {!selectedTicket && !isCreatingTicket && (
            <div className="lg:hidden fixed bottom-4 right-4">
              <Button
                onClick={handleCreateNewTicket}
                size="lg"
                className="rounded-full w-12 h-12 shadow-lg"
              >
                <Mail className="w-6 h-6" />
              </Button>
            </div>
          )}
        </div>
      );
    };

export default UserTicketDashboard;
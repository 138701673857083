import { useQuery, UseQueryResult, QueryClient } from 'react-query';
import { api, SPUserStudyPlan, SPStudyPlanItem, SPCourse, SPCourseMix, SPStudySchedule } from '../services/api';

interface StudyPlanData {
  userStudyPlan: SPUserStudyPlan | null;
  studyPlanItems: SPStudyPlanItem[];
  examDate: string | null;
  startDate: string | null;
  endDate: string | null;
  courseProviders: { [key: string]: string };
  primaryCourse: SPCourse | null;
  secondaryCourse: SPCourse | null;
  courseMix: SPCourseMix | null;
  studySchedule: SPStudySchedule | null;
}

interface StudyPlanError {
  message: string;
}

const fetchStudyPlanData = async (userId: string, courseId: string): Promise<StudyPlanData> => {
  const plan = await api.getUserStudyPlan(userId, courseId);
  
  if (!plan) {
    return {
      userStudyPlan: null,
      studyPlanItems: [],
      examDate: null,
      startDate: null,
      endDate: null,
      courseProviders: {},
      primaryCourse: null,
      secondaryCourse: null,
      courseMix: null,
      studySchedule: null
    };
  }

  const [items, dateData, cpaCourses, courseMix, studySchedule] = await Promise.all([
    api.getStudyPlanItems(plan.plan_id),
    api.getExamDate(userId, courseId),
    api.getCPACourses(courseId),
    api.getCourseMix(userId, courseId),
    api.getStudySchedule(plan.plan_id)
  ]);

  const courseProviders = cpaCourses.reduce((acc: { [key: string]: string }, course: SPCourse) => {
    acc[course.cpa_course_id] = course.name.toLowerCase().includes('kesler') ? 'kesler' :
                                course.name.toLowerCase().includes('becker') ? 'becker' :
                                course.name.toLowerCase().includes('gleim') ? 'gleim' : 'blueprint';
    return acc;
  }, {});

  let primaryCourse: SPCourse | null = null;
  let secondaryCourse: SPCourse | null = null;

  if (courseMix) {
    primaryCourse = cpaCourses.find((course: SPCourse) => course.cpa_course_id === courseMix.primary_cpa_course_id) || null;
    secondaryCourse = courseMix.secondary_cpa_course_id 
      ? cpaCourses.find((course: SPCourse) => course.cpa_course_id === courseMix.secondary_cpa_course_id) || null
      : null;
  }

  return {
    userStudyPlan: plan,
    studyPlanItems: items,
    examDate: dateData?.exam_date || null,
    startDate: studySchedule?.start_date || null,
    endDate: studySchedule?.end_date || null,
    courseProviders,
    primaryCourse,
    secondaryCourse,
    courseMix,
    studySchedule
  };
};

export const useStudyPlan = (
  userId: string,
  courseId: string,
  queryClient: QueryClient
): UseQueryResult<StudyPlanData, StudyPlanError> => {
  return useQuery<StudyPlanData, StudyPlanError>(
    ['studyPlan', userId, courseId],
    () => fetchStudyPlanData(userId, courseId),
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // Update the cache with the new data
        queryClient.setQueryData(['studyPlan', userId, courseId], data);
      },
    }
  );
};
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { api, Flashcard, FlashcardCategory, UserFlashcardProgress, CustomFlashcard } from '../../services/api';
import { getCardsCompleted, updateCardsCompletedInStorage, getSpacedRepetitionUnlocked, setSpacedRepetitionUnlocked } from '../../utils/LocalStorageUtility';
import { auth } from '../../services/auth';
import DOMPurify from 'dompurify';
import { ChevronUp, ChevronDown, ChevronLeft, ChevronRight, RefreshCcw, Edit, Trash2, X } from 'react-feather';
import LoadingScreen from './common/LoadingScreen';
import useKeyPress from '../../hooks/useKeyPress';
import Pagination from './common/Pagination';
import { Switch } from '../ui/Switch';
//iDB
import { flashcardIDB, syncService } from '../../services/flashcardapi';
import { getBrowserInstanceId } from '../../utils/browserInstance';
import { debounce } from 'lodash';

interface KeslerCardsProps {
  courseId: string;
  userId: string;
}

interface LocationState {
  selectedCategory?: string;
  autoStart?: boolean;
}

interface FilterCounts {
  all: number;
  unanswered: number;
  guessing: number;
  maybe: number;
  confident: number;
  createcards: number;
}

const AlertMessage: React.FC<{ message: string }> = ({ message }) => (
  <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded shadow-md z-50 animate-fade-in-out">
    <p>{message}</p>
  </div>
);

export type ConfidenceLevel = 'guessing' | 'maybe' | 'confident' | null;
export type FilterType = 'All' | 'Unanswered' | 'Guessing' | 'Maybe' | 'Confident' | 'CreateCards';

// Tag Caching
const TAG_CACHE_KEY = 'keslerCardsTags';
const TAG_CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

function KeslerCards({ courseId, userId }: KeslerCardsProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const initialCategory = searchParams.get('category');
  const initialFlashcardId = searchParams.get('flashcardId');
  const returnUrl = searchParams.get('returnUrl');
  const [focusedConfidenceLevel, setFocusedConfidenceLevel] = useState<number>(-1);
  const [cards, setCards] = useState<Flashcard[]>([]);
  const [customCards, setCustomCards] = useState<CustomFlashcard[]>([]);
  const [categories, setCategories] = useState<FlashcardCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(initialCategory);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showingFront, setShowingFront] = useState(true);
  const [hasSeenBack, setHasSeenBack] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userProgress, setUserProgress] = useState<UserFlashcardProgress[]>([]);
  const [confidenceLevel, setConfidenceLevel] = useState<ConfidenceLevel>(null);
  const [displayedConfidenceLevel, setDisplayedConfidenceLevel] = useState<ConfidenceLevel>(null);
  const confidenceLevelRef = useRef<ConfidenceLevel>(null);  
  const [isFlipping, setIsFlipping] = useState(false);
  const [filteredCards, setFilteredCards] = useState<(Flashcard | CustomFlashcard)[]>([]);
  const [confidenceFilter, setConfidenceFilter] = useState<FilterType>('All');
  const [categoryCompleted, setCategoryCompleted] = useState(false);
  const [isYourCardsSelected, setIsYourCardsSelected] = useState(false);
  const [isCreateCardsActive, setIsCreateCardsActive] = useState(false);
  const [showEditTagDropdown, setShowEditTagDropdown] = useState(false);
  const [filteredEditTags, setFilteredEditTags] = useState<string[]>([]);
  const editTagInputRef = useRef<HTMLTextAreaElement | null>(null);

    // Add new state variables for tags and color
  const [newCardFront, setNewCardFront] = useState('');
  const [newCardBack, setNewCardBack] = useState('');
  const [newCardTags, setNewCardTags] = useState<string[]>([]);
  const [editingCard, setEditingCard] = useState<CustomFlashcard | null>(null);
  const [tagInput, setTagInput] = useState('');
  const tagInputRef = useRef<HTMLTextAreaElement | null>(null);
  const showCreateForm = searchParams.get('showCreateForm') === 'true';
  const [showCreateCardForm, setShowCreateCardForm] = useState(showCreateForm);
  const [expandedCards, setExpandedCards] = useState<{ [key: string]: boolean }>({});
  const [isInputActive, setIsInputActive] = useState(false);
  const inputRefs = useRef<(HTMLTextAreaElement | HTMLInputElement | null)[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredCustomCards, setFilteredCustomCards] = useState<CustomFlashcard[]>([]);
  const [showAllTags, setShowAllTags] = useState(false);
  const [newCardTagInput, setNewCardTagInput] = useState('');
  const newCardTagInputRef = useRef<HTMLTextAreaElement | null>(null);
  const [isCreatingCard, setIsCreatingCard] = useState(false);

  const cardsPerPage = 25;

  //IDB Fetching
  const isFetchingRef = useRef(false);
  const fetchAttempts = useRef(0);
  const maxFetchAttempts = 3;
  const [browserInstanceId, setBrowserInstanceId] = useState<string | null>(null);

  // Tag Caching
  const [allTags, setAllTags] = useState<string[]>([]);
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [showTagDropdown, setShowTagDropdown] = useState(false);
  const [selectedFilterTags, setSelectedFilterTags] = useState<string[]>([]);
  const [showYourCards, setShowYourCards] = useState(false);
  const [visibleTags, setVisibleTags] = useState<string[]>([]);
  
  //Randomizer
  const [isRandomMode, setIsRandomMode] = useState<boolean>(() => {
    const savedMode = localStorage.getItem('keslerCardsRandomMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  //Spaced Repetition aka Looping Mode
  const [isSpacedRepetitionUnlocked, setIsSpacedRepetitionUnlocked] = useState(() => 
    getSpacedRepetitionUnlocked(userId, courseId)
  );
  const [cardsCompleted, setCardsCompleted] = useState(() => 
    getCardsCompleted(userId, courseId)
  );
  // Update Spaced Repetition aka Looping Mode state
  const [isSpacedRepetitionMode, setIsSpacedRepetitionMode] = useState<boolean>(() => {
    const savedMode = localStorage.getItem('keslerCardsSpacedRepetitionMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const [dueCards, setDueCards] = useState<(Flashcard | CustomFlashcard)[]>([]);
  const [spacedRepetitionCompleted, setSpacedRepetitionCompleted] = useState(false);
  
  const [isFilterMinimized, setIsFilterMinimized] = useState(false);
  const [isCardMinimized, setIsCardMinimized] = useState(false);
  const [hasBeenFlipped, setHasBeenFlipped] = useState(false);
  const [isPreviouslyAnswered, setIsPreviouslyAnswered] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const confidenceLevels: ConfidenceLevel[] = ['guessing', 'maybe', 'confident'];
  const [showCompletionAlert, setShowCompletionAlert] = useState(false);
  const [filterCounts, setFilterCounts] = useState<FilterCounts>({
    all: 0,
    unanswered: 0,
    guessing: 0,
    maybe: 0,
    confident: 0,
    createcards: 0
  });

  // Safely get the current card
  const currentCard = useMemo(() => {
    if (filteredCards.length > 0 && currentCardIndex < filteredCards.length) {
      return filteredCards[currentCardIndex];
    }
    return null;
  }, [filteredCards, currentCardIndex]);
  
  // Update getStoredConfidenceLevel function
  const getStoredConfidenceLevel = useCallback((card: Flashcard | CustomFlashcard | undefined, progress: UserFlashcardProgress[]): ConfidenceLevel => {
    if (!card) {
      return null; // Return null if the card is undefined
    }
    
    if ('side_1_content' in card) {
      // Pre-made flashcard
      const cardProgress = progress.find(p => p.flashcard_id === card.id);
      return cardProgress?.confidence_level as ConfidenceLevel || null;
    } else {
      // Custom flashcard
      return card.confidence_level as ConfidenceLevel || null;
    }
  }, []);

  const updateConfidenceLevelLocally = useCallback((cardId: string, newLevel: ConfidenceLevel) => {
    setUserProgress(prevProgress => 
      prevProgress.map(p => 
        p.flashcard_id === cardId && newLevel !== null
          ? { ...p, confidence_level: newLevel, last_reviewed: new Date().toISOString() } 
          : p
      )
    );

    setCustomCards(prevCards => 
      prevCards.map(card => 
        card.id === cardId && newLevel !== null
          ? { ...card, confidence_level: newLevel, last_reviewed: new Date().toISOString() } 
          : card
      )
    );
  }, []);

  const updateFilterCountsAfterConfidenceChange = useCallback((oldLevel: ConfidenceLevel, newLevel: ConfidenceLevel) => {
    setFilterCounts(prevCounts => {
      const newCounts = { ...prevCounts };
      if (oldLevel === null) {
        newCounts.unanswered = Math.max(0, newCounts.unanswered - 1);
      } else {
        newCounts[oldLevel] = Math.max(0, newCounts[oldLevel] - 1);
      }
      if (newLevel !== null) {
        newCounts[newLevel]++;
      }
      return newCounts;
    });
  }, []);

  const clearUrlParams = useCallback(() => {
    navigate(`/course/${courseId}/kesler-cards`, { replace: true });
  }, [courseId, navigate]);
  
  
  // TAG CACHING
  const fetchAndCacheTags = useCallback(async () => {
    try {
      const tags = await flashcardIDB.getAllUserTags(userId, courseId);
      setAllTags(tags);
    } catch (error) {
      console.error('Failed to fetch tags:', error);
    }
  }, [userId, courseId]);

  const filterTags = useCallback((input: string) => {
    if (input.length === 0) {
      setFilteredTags([]);
      setShowTagDropdown(false);
      return;
    }
    const filtered = allTags
      .filter(tag => tag.toLowerCase().includes(input.toLowerCase()))
      .slice(0, 5);  // Limit to top 5 matches
    setFilteredTags(filtered);
    setShowTagDropdown(filtered.length > 0);
  }, [allTags]);

  // Filter Create Cards For Decks

  const handleTagFilter = useCallback((tag: string) => {
    setSelectedTags(prevTags => {
      let newTags: string[];
      if (tag === 'All') {
        newTags = [];
      } else if (prevTags.includes(tag)) {
        newTags = prevTags.filter(t => t !== tag);
      } else {
        newTags = [...prevTags, tag];
      }
      
      // Update filtered cards based on new tags
      const filtered = customCards.filter(card => 
        newTags.length === 0 || newTags.some(t => card.tags.includes(t))
      );
      setFilteredCustomCards(filtered);
      
      return newTags;
    });
  }, [customCards]);
  
  const clearTagFilters = () => {
    setSelectedFilterTags([]);
  };

  const renderTagFilters = () => {
    return (
      <div>
        <div className="mb-4 flex flex-wrap gap-2 items-center">
          <button
            onClick={() => handleTagFilter('All')}
            className={`px-3 py-1 rounded-full text-xs md:text-sm ${
              selectedTags.length === 0
                ? 'bg-primary-blue text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
            }`}
          >
            All
          </button>
          {visibleTags.map((tag) => {
            const cardCount = customCards.filter(card => card.tags.includes(tag)).length;
            return (
              <button
                key={tag}
                onClick={() => handleTagFilter(tag)}
                className={`px-3 py-1 rounded-full text-xs md:text-sm ${
                  selectedTags.includes(tag)
                    ? 'bg-primary-blue text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
                }`}
              >
                {tag} ({cardCount})
              </button>
            );
          })}
          {allTags.length > 10 && (
            <button
              onClick={() => setShowAllTags(!showAllTags)}
              className="px-3 py-1 rounded-full text-xs md:text-sm bg-blue-200 text-gray-700 hover:bg-gray-300 dark:bg-green-700 dark:text-gray-300 dark:hover:bg-green-800"
            >
              {showAllTags ? 'Show Less' : 'Show More Tags'}
            </button>
          )}
          {selectedTags.length > 0 && (
            <button
              onClick={clearTagFilters}
              className="px-3 py-1 rounded-full text-xs md:text-sm bg-red-500 text-white hover:bg-red-600"
            >
              Clear Tags
            </button>
          )}
        </div>
      </div>
    );
  };
  
  //edit tags
  const addTag = (newTag: string) => {
    const sanitizedTag = sanitizeInput(newTag);
    if (editingCard && sanitizedTag && editingCard.tags.length < MAX_TAGS && !editingCard.tags.includes(sanitizedTag)) {
      setEditingCard({
        ...editingCard,
        tags: [...editingCard.tags, sanitizedTag.slice(0, MAX_TAG_LENGTH)]
      });
      setTagInput('');
    }
  };
  
  const filterEditTags = useCallback((input: string) => {
    if (input.length === 0) {
      setFilteredEditTags([]);
      setShowEditTagDropdown(false);
      return;
    }
    const filtered = allTags
      .filter(tag => tag.toLowerCase().includes(input.toLowerCase()))
      .slice(0, 5);  // Limit to top 5 matches
    setFilteredEditTags(filtered);
    setShowEditTagDropdown(filtered.length > 0);
  }, [allTags]);
  
  const handleEditTagInput = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = sanitizeInput(e.target.value.slice(0, MAX_TAG_LENGTH));
    setTagInput(value);
    filterEditTags(value);
  }, [filterEditTags]);


  const handleEditTagSelection = useCallback((tag: string) => {
    if (editingCard) {
      addTag(tag);
    }
    setShowEditTagDropdown(false);
  }, [editingCard, addTag]);

  useEffect(() => {
    if (editTagInputRef.current) {
      editTagInputRef.current.style.height = 'auto';
      editTagInputRef.current.style.height = `${editTagInputRef.current.scrollHeight}px`;
    }
  }, [tagInput]);

    // New function to update filter counts locally
    const updateFilterCountsLocally = useCallback((cardsToCount: (Flashcard | CustomFlashcard)[], progress: UserFlashcardProgress[]) => {
      const counts: FilterCounts = {
        all: cardsToCount.length,
        unanswered: 0,
        guessing: 0,
        maybe: 0,
        confident: 0,
        createcards: cardsToCount.filter(card => !('side_1_content' in card)).length
      };
  
      cardsToCount.forEach(card => {
        let confidenceLevel: ConfidenceLevel | undefined;
        
        if ('side_1_content' in card) {
          // Pre-made flashcard
          const cardProgress = progress.find(p => p.flashcard_id === card.id);
          confidenceLevel = cardProgress?.confidence_level as ConfidenceLevel | undefined;
        } else {
          // Custom flashcard
          confidenceLevel = card.confidence_level as ConfidenceLevel | undefined;
        }
  
        if (!confidenceLevel) {
          counts.unanswered++;
        } else {
          counts[confidenceLevel]++;
        }
      });
  
      setFilterCounts(counts);
    }, []);
  
       
    const filterCardsByCategory = (flashcards: Flashcard[], customCards: CustomFlashcard[], category: string) => {
      const [parentCategory, subCategory] = category.split(' - ');
      return [...flashcards, ...customCards].filter(card => 
        'flash_card_category_name' in card &&
        card.flash_card_category_name === parentCategory &&
        card.sub_flash_card_category_name === subCategory
      );
    };

  // Modify fetchData function
  const fetchData = useCallback(async () => {
    if (isFetchingRef.current) {
      console.log('Fetch already in progress, skipping');
      return;
    }

    isFetchingRef.current = true;
    setLoading(true);

    try {
      console.log('Starting fetchData');
      const user = await auth.getCurrentUser();
      if (!user) throw new Error('User not authenticated');

      console.log('Initializing IDB');
      await flashcardIDB.init(courseId);

      console.log('Fetching data from IDB');
      let [flashcardsData, categoriesData, progressData, customCardsData] = await Promise.all([
        flashcardIDB.getAllFlashcards(),
        flashcardIDB.getAllCategories(),
        flashcardIDB.getAllUserProgress(courseId),
        flashcardIDB.getCustomFlashcards(user.id, courseId)
      ]);
  
      let shouldFetchFromApi = !flashcardsData.length || !categoriesData.length;
      let shouldFetchUserData = !progressData.length || !customCardsData.length;
  
      if (shouldFetchFromApi) {
        console.log('Fetching flashcards and categories from API');
        const [apiFlashcards, apiCategories] = await Promise.all([
          api.getFlashcards(courseId),
          api.getFlashcardCategories(courseId)
        ]);
  
        await Promise.all([
          ...apiFlashcards.map(flashcard => flashcardIDB.addOrUpdateFlashcard(flashcard)),
          ...apiCategories.map(category => flashcardIDB.addOrUpdateCategory(category))
        ]);
  
        flashcardsData = apiFlashcards;
        categoriesData = apiCategories;
      }
  
      if (shouldFetchUserData) {
        console.log('Fetching user-specific data from API');
        const [apiProgressData, apiCustomCardsData, userstudyStats] = await Promise.all([
            syncService.getUserFlashcardProgress(user.id, courseId),
            syncService.getCustomFlashcards(user.id, courseId),
            syncService.getUserStudyStats(user.id, courseId)
        ]);
    
        await Promise.all([
          ...apiProgressData.map(progress => {
            const localId = `${progress.user_id}-${progress.course_id}-${progress.flashcard_id}-${progress.browser_instance_id}`;
            return flashcardIDB.addOrUpdateUserProgress(localId, progress);
          }),
          ...apiCustomCardsData.map(card => flashcardIDB.addOrUpdateCustomFlashcard(card))
        ]);
  
        progressData = apiProgressData;
        customCardsData = apiCustomCardsData;
  
        // Update cards completed count
        const completedCount = userstudyStats.cardsCompleted;
        setCardsCompleted(completedCount);
        updateCardsCompletedInStorage(userId, courseId, completedCount);
  
        // Check if spaced repetition aka Looping Mode should be unlocked
        if (completedCount >= 10 && !isSpacedRepetitionUnlocked) {
          setIsSpacedRepetitionUnlocked(true);
          setSpacedRepetitionUnlocked(userId, courseId, true);
          showAlert("Spaced Repetition mode unlocked!");
        }
      }
  
      // Update state with fetched data
      setCards(flashcardsData);
      setCustomCards(customCardsData);
      setCategories(categoriesData);
      setUserProgress(progressData);
  
      let initialFilteredCards = [...flashcardsData, ...customCardsData];
  
      if (initialCategory) {
        initialFilteredCards = filterCardsByCategory(flashcardsData, customCardsData, initialCategory);
        setSelectedCategory(initialCategory);
      }
  
      if (initialFlashcardId) {
        const flashcardIndex = initialFilteredCards.findIndex(card => card.id === initialFlashcardId);
        if (flashcardIndex !== -1) {
          setCurrentCardIndex(flashcardIndex);
          setShowingFront(true);
          const initialConfidence = getStoredConfidenceLevel(initialFilteredCards[flashcardIndex], progressData);
          setConfidenceLevel(initialConfidence);
          setIsPreviouslyAnswered(initialConfidence !== null);
          setHasBeenFlipped(false);
          initialFilteredCards = [initialFilteredCards[flashcardIndex]];
        }
      }
  
      setFilteredCards(initialFilteredCards);
      updateFilterCountsLocally(initialFilteredCards, progressData);
      setCategoryCompleted(false);
      setShowCompletionAlert(false);
  
      setLoading(false);
  
    } catch (err) {
      console.error('Error in fetchData:', err);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      isFetchingRef.current = false;
      setLoading(false);
    }
  }, [courseId, userId, auth, flashcardIDB, api, setError, setLoading, isSpacedRepetitionUnlocked, updateCardsCompletedInStorage]);

  useEffect(() => {
    let isMounted = true;

    const fetchDataAndTags = async () => {
      if (isMounted && !isFetchingRef.current) {
        await fetchData();

        // Fetch and set tags
        await fetchAndCacheTags();
      }
    };

    fetchDataAndTags();

    return () => {
      isMounted = false;
    };
  }, [fetchData, fetchAndCacheTags]);

    useEffect(() => {
      const fetchBrowserInstanceId = async () => {
        const id = await getBrowserInstanceId();
        setBrowserInstanceId(id);
      };
      fetchBrowserInstanceId();
    }, []);

    // Add a new function to truncate and format category names
    const formatCategoryName = (category: FlashcardCategory): string => {
      const maxLength = 100; // Adjust as needed
      let formattedName = `${category.sub_flashcard_category_name}`;
      
      if (formattedName.length > maxLength) {
        formattedName = formattedName.substring(0, maxLength - 3) + '...';
      }
      
      return formattedName;
    };
  
  const resetCardState = useCallback(() => {
    setShowingFront(true);
    setHasSeenBack(false);
    setConfidenceLevel(null);
    setIsPreviouslyAnswered(false);
  }, []);

  //RANDOMIZATION LOGIC

  const shuffleArray = useCallback((array: any[]) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }, []);


  //SPACED REPETITION aka Looping Mode LOGIC

  // Add new function to calculate next review date
  const calculateNextReviewDate = (confidence: ConfidenceLevel, lastReviewed: Date): Date => {
    const now = new Date();
    let interval: number;
  
    switch (confidence) {
      case 'guessing':
        interval = 1; // 1 day
        break;
      case 'maybe':
        interval = 3; // 3 days
        break;
      case 'confident':
        interval = 7; // 7 days
        break;
      default:
        interval = 1;
    }
  
    const nextReview = new Date(lastReviewed);
    nextReview.setDate(nextReview.getDate() + interval);
    return nextReview;
  };

  // Update the isCardDueForReview function
  const isCardDueForReview = (card: Flashcard | CustomFlashcard, progress?: UserFlashcardProgress): boolean => {
    const now = new Date();
    let lastReviewed: Date;
    let confidenceLevel: ConfidenceLevel;

    if ('side_1_content' in card) {
      // Pre-made flashcard
      if (!progress) return false; // If no progress, it's not due for review (unanswered)
      lastReviewed = new Date(progress.last_reviewed);
      confidenceLevel = progress.confidence_level as ConfidenceLevel;
    } else {
      // Custom flashcard
      lastReviewed = new Date(card.last_reviewed);
      confidenceLevel = card.confidence_level as ConfidenceLevel;
    }

    if (!confidenceLevel) return false; // If no confidence level, it's not due for review (unanswered)

    const nextReviewDate = calculateNextReviewDate(confidenceLevel, lastReviewed);
    return now >= nextReviewDate;
  };

  // Update the calculateDueCards logic
  const calculateDueCards = useMemo(() => {
    return [...cards, ...customCards].filter(card => {
      if ('side_1_content' in card) {
        // Pre-made flashcard
        const progress = userProgress.find(p => p.flashcard_id === card.id);
        return progress && progress.confidence_level && isCardDueForReview(card, progress);
      } else {
        // Custom flashcard
        return card.confidence_level && isCardDueForReview(card);
      }
    });
  }, [cards, customCards, userProgress]);

  // Update dueCards when calculateDueCards changes
  useEffect(() => {
    setDueCards(calculateDueCards);
  }, [calculateDueCards]);
  

  // END SPACED REPETITION  aka Looping Mode

  //SORTING CATEGORIES

  const sortCategories = (a: FlashcardCategory, b: FlashcardCategory) => {
    const categoryOrder = ['GEM', 'SIM', 'CALC'];
    
    // Function to parse the text title
    const parseTitle = (title: string) => {
      const match = title.match(/\[(\w+)\]\s*(\d+)\.(\d+)/);
      if (match) {
        return {
          category: match[1],
          chapterNum: parseInt(match[2]),
          sectionNum: parseInt(match[3])
        };
      }
      return null;
    };
  
    const parseA = parseTitle(a.sub_flashcard_category_name);
    const parseB = parseTitle(b.sub_flashcard_category_name);
  
    if (!parseA || !parseB) return 0; // If parsing fails, don't change order
  
    // Compare chapter numbers
    if (parseA.chapterNum !== parseB.chapterNum) {
      return parseA.chapterNum - parseB.chapterNum;
    }
  
    // If chapter numbers are the same, compare section numbers
    if (parseA.sectionNum !== parseB.sectionNum) {
      return parseA.sectionNum - parseB.sectionNum;
    }
  
    // If chapter and section numbers are the same, sort by category order
    return categoryOrder.indexOf(parseA.category) - categoryOrder.indexOf(parseB.category);
  };
  
  // Sort the categories
  const sortedCategories = useMemo(() => {
    return [...categories].sort(sortCategories);
  }, [categories]);

//END SORTING CATEGORIES

  //CARD MODES

  const getLinearModeCards = useCallback(() => {
    let cardsToFilter = [...cards, ...customCards];
    
    if (selectedCategory) {
      const [parentCategory, subCategory] = selectedCategory.split(' - ');
      cardsToFilter = cardsToFilter.filter(card => 
        'flash_card_category_name' in card &&
        card.flash_card_category_name === parentCategory &&
        card.sub_flash_card_category_name === subCategory
      );
    }
  
    if (confidenceFilter === 'CreateCards') {
      cardsToFilter = customCards;
    } else if (confidenceFilter !== 'All') {
      cardsToFilter = cardsToFilter.filter(card => {
        const storedConfidence = getStoredConfidenceLevel(card, userProgress);
        if (confidenceFilter === 'Unanswered') {
          return storedConfidence === null;
        }
        return storedConfidence?.toLowerCase() === confidenceFilter.toLowerCase();
      });
    }
  
    return cardsToFilter;
  }, [cards, customCards, selectedCategory, confidenceFilter, getStoredConfidenceLevel, userProgress]);
  
  const getRandomModeCards = useCallback(() => {
    let cardsToRandomize = getLinearModeCards();
    
    const storedOrder = localStorage.getItem('keslerCardsRandomOrder');
    let randomOrder: number[];
  
    if (storedOrder) {
      randomOrder = JSON.parse(storedOrder);
      // If the stored order length doesn't match the current cards length, generate a new order
      if (randomOrder.length !== cardsToRandomize.length) {
        randomOrder = shuffleArray(cardsToRandomize.map((_, index) => index));
        localStorage.setItem('keslerCardsRandomOrder', JSON.stringify(randomOrder));
      }
    } else {
      randomOrder = shuffleArray(cardsToRandomize.map((_, index) => index));
      localStorage.setItem('keslerCardsRandomOrder', JSON.stringify(randomOrder));
    }
  
    return randomOrder.map(index => cardsToRandomize[index]);
  }, [getLinearModeCards, shuffleArray]);
  
  const getSpacedRepetitionCards = useCallback(() => {
    return calculateDueCards;
  }, [calculateDueCards]);

  
  // Update updateFilteredCards to use local state for filtering
  const updateFilteredCards = useCallback(() => {
    let cardsToFilter: (Flashcard | CustomFlashcard)[];

    if (isSpacedRepetitionMode) {
      cardsToFilter = getSpacedRepetitionCards();
    } else if (isRandomMode) {
      cardsToFilter = getRandomModeCards();
    } else {
      cardsToFilter = getLinearModeCards();
    }
    
    // Apply CreateCards filter if active
    if (isCreateCardsActive) {
      cardsToFilter = cardsToFilter.filter(card => !('side_1_content' in card));
    }

    // Apply confidence filter
    if (confidenceFilter !== 'All' && confidenceFilter !== 'CreateCards') {
      cardsToFilter = cardsToFilter.filter(card => {
        const storedConfidence = getStoredConfidenceLevel(card, userProgress);
        if (confidenceFilter === 'Unanswered') {
          return storedConfidence === null;
        }
        return storedConfidence?.toLowerCase() === confidenceFilter.toLowerCase();
      });
    }

    // Apply tag filtering
    if (selectedFilterTags.length > 0) {
      cardsToFilter = cardsToFilter.filter(card => 
        'tags' in card && selectedFilterTags.some(tag => card.tags.includes(tag))
      );
    }

    setFilteredCards(cardsToFilter);
    updateFilterCountsLocally(cardsToFilter, userProgress);

    // Adjust currentCardIndex if it's out of bounds
    if (currentCardIndex >= cardsToFilter.length) {
      setCurrentCardIndex(Math.max(0, cardsToFilter.length - 1));
    }

    if (cardsToFilter.length > 0) {
      const safeIndex = Math.min(currentCardIndex, cardsToFilter.length - 1);
      const currentCard = cardsToFilter[safeIndex];
      if (currentCard) {
        const initialConfidence = getStoredConfidenceLevel(currentCard, userProgress);
        setConfidenceLevel(initialConfidence);
        setDisplayedConfidenceLevel(initialConfidence);
        setIsPreviouslyAnswered(initialConfidence !== null);
        setHasSeenBack(initialConfidence !== null);
      }
    } else {
      resetCardState();
    }

    setCategoryCompleted(cardsToFilter.length === 0);
    setSpacedRepetitionCompleted(isSpacedRepetitionMode && cardsToFilter.length === 0);

    return cardsToFilter;
  }, [
    confidenceFilter,
    selectedCategory,
    isSpacedRepetitionMode,
    isRandomMode,
    isCreateCardsActive,
    getSpacedRepetitionCards,
    getRandomModeCards,
    getLinearModeCards,
    updateFilterCountsLocally,
    currentCardIndex,
    getStoredConfidenceLevel,
    userProgress,
    resetCardState,
    selectedFilterTags,
  ]);

  // Use useMemo to memoize filteredCards
  const memoizedFilteredCards = useMemo(() => updateFilteredCards(), [updateFilteredCards]);

  // Update useEffect to use memoizedFilteredCards
  useEffect(() => {
    setFilteredCards(memoizedFilteredCards);
    if (currentCardIndex >= memoizedFilteredCards.length) {
      setCurrentCardIndex(Math.max(0, memoizedFilteredCards.length - 1));
    }
  }, [memoizedFilteredCards, currentCardIndex]);

  // Add new function to handle turning off spaced repetition aka Looping Mode
  const handleTurnOffSpacedRepetition = useCallback(() => {
    setIsSpacedRepetitionMode(false);
    setConfidenceFilter('Unanswered');
    setSpacedRepetitionCompleted(false);
    updateFilteredCards();
  }, [setIsSpacedRepetitionMode, setConfidenceFilter, updateFilteredCards]);

  const handleFilterChange = useCallback((filter: FilterType) => {
    if (filter === 'CreateCards') {
      setIsCreateCardsActive(prev => !prev);
      setShowYourCards(prev => !prev);
      if (!isCreateCardsActive) {
        setConfidenceFilter('All');
      }
    } else if (filter === 'All') {
      setIsCreateCardsActive(false);
      setShowYourCards(false);
      setConfidenceFilter('All');
      clearTagFilters();
    } else {
      setConfidenceFilter(filter);
    }
    
    setCurrentCardIndex(0);
    clearUrlParams();
    
    if (filter === 'Unanswered') {
      resetCardState();
    }
    
    // Only clear tags when switching from CreateCards to a non-CreateCards filter
    if (isCreateCardsActive && filter !== 'CreateCards') {
      clearTagFilters();
    }
  }, [resetCardState, clearUrlParams, clearTagFilters, isCreateCardsActive]);

  function handleFlipCard() {
    setIsFlipping(true);
    setTimeout(() => {
      const newShowingFront = !showingFront;
      setShowingFront(newShowingFront);
      setIsFlipping(false);
      if (!newShowingFront) {
        setHasSeenBack(true);
      }
    }, 300);
  }

  const navigateToNextCard = useCallback((forceMoveNext = false) => {
    setFilteredCards(prevCards => {
      let nextIndex = currentCardIndex + 1;
      if (nextIndex >= prevCards.length) {
        if (isRandomMode) {
          // Reshuffle cards for the next round
          const newRandomOrder = shuffleArray(prevCards.map((_, index) => index));
          localStorage.setItem('keslerCardsRandomOrder', JSON.stringify(newRandomOrder));
          nextIndex = 0;
          return newRandomOrder.map(index => prevCards[index]);
        } else if (isSpacedRepetitionMode) {
          // In spaced repetition mode, we don't end the deck
          // Instead, we use the current dueCards
          if (dueCards.length > 0) {
            nextIndex = 0;
            return dueCards;
          }
        } else {
          // Cycle back to the beginning for filtered cards
          nextIndex = 0;
        }
      }
      
      if (forceMoveNext || !isPreviouslyAnswered || isSpacedRepetitionMode) {
        setCurrentCardIndex(nextIndex);
        setShowingFront(true);
        setHasSeenBack(false);
        
        const nextCard = prevCards[nextIndex];
        if (nextCard) {
          const nextCardConfidence = getStoredConfidenceLevel(nextCard, userProgress);
          setDisplayedConfidenceLevel(nextCardConfidence);
          setConfidenceLevel(nextCardConfidence);
          setIsPreviouslyAnswered(nextCardConfidence !== null);
        }
      }
      
      return prevCards;
    });
  }, [currentCardIndex, isRandomMode, isSpacedRepetitionMode, shuffleArray, dueCards, getStoredConfidenceLevel, userProgress]);
  
  useEffect(() => {
    if (isSpacedRepetitionMode) {
      setFilteredCards(dueCards);
      setCurrentCardIndex(0);
    }
  }, [isSpacedRepetitionMode, dueCards]);

  // Update handleNextCard to use the new function
  function handleNextCard() {
    navigateToNextCard(true);
  }

    // Update handlePreviousCard for consistency
    function handlePreviousCard() {
      if (currentCardIndex > 0) {
        setCurrentCardIndex(prevIndex => {
          const newIndex = prevIndex - 1;
          const prevCard = filteredCards[newIndex];
          setShowingFront(true);
          setHasBeenFlipped(false);
          const prevCardConfidence = getStoredConfidenceLevel(prevCard, userProgress);
          setConfidenceLevel(prevCardConfidence);
          setIsPreviouslyAnswered(prevCardConfidence !== null);
          return newIndex;
        });
      } else if (filteredCards.length > 0) {
        // Cycle to the last card if at the beginning
        setCurrentCardIndex(filteredCards.length - 1);
      }
    }

// Modify handleConfidenceLevel function
const handleConfidenceLevel = useCallback(async (level: ConfidenceLevel) => {
  if (level === null || !browserInstanceId) return;

  const currentCard = filteredCards[currentCardIndex];
  if (!currentCard) {
    console.error('Current card is undefined', { currentCardIndex, filteredCardsLength: filteredCards.length });
    return;
  }

  const oldLevel = getStoredConfidenceLevel(currentCard, userProgress);

  // Start the flip animation immediately
  setIsFlipping(true);
  setShowingFront(true);

  // Update local state
  setDisplayedConfidenceLevel(level);
  setConfidenceLevel(level);
  setIsPreviouslyAnswered(true);
  setFocusedConfidenceLevel(-1);
  updateConfidenceLevelLocally(currentCard.id, level);
  updateFilterCountsAfterConfidenceChange(oldLevel, level);

  let shouldUpdateCard = true;
  let shouldMoveToNextCard = confidenceFilter === 'All' || confidenceFilter === 'Unanswered';

  try {
    const user = await auth.getCurrentUser();
    if (!user) throw new Error('User not authenticated');

    const now = new Date().toISOString();
    const browserInstanceId = await getBrowserInstanceId();

    // Define a type that can be either a Flashcard or CustomFlashcard with confidence_level and last_reviewed
    type UpdatedCardType = (Flashcard | CustomFlashcard) & {
      confidence_level: ConfidenceLevel;
      last_reviewed: string;
    };

    let updatedCard: UpdatedCardType;

    if ('side_1_content' in currentCard) {
      // This is a pre-made flashcard
      const updateData: Omit<UserFlashcardProgress, 'id'> = {
        user_id: user.id,
        course_id: courseId,
        flashcard_id: currentCard.id,
        confidence_level: level,
        last_reviewed: now,
        browser_instance_id: browserInstanceId,
        version: 1,
        synced: 0,
      };

      await syncService.addToSyncQueue('userProgress', updateData);
      updatedCard = { ...currentCard, confidence_level: level, last_reviewed: now };
      
      // Update IndexedDB
      await flashcardIDB.addOrUpdateUserProgress(`${user.id}-${courseId}-${currentCard.id}-${browserInstanceId}`, updateData);
    } else {
      // This is a custom flashcard
      updatedCard = {
        ...currentCard,
        confidence_level: level,
        last_reviewed: now,
        browser_instance_id: browserInstanceId,
        version: (currentCard.version || 0) + 1,
        course_id: courseId,
      };

      await syncService.addToSyncQueue('customFlashcard', updatedCard);
      
      // Update IndexedDB
      await flashcardIDB.addOrUpdateCustomFlashcard(updatedCard);
    }

    // Update filteredCards state
    setFilteredCards(prevCards => {
      let newCards = prevCards.map(card => card.id === currentCard.id ? updatedCard : card);
      if (confidenceFilter !== 'All' && confidenceFilter !== 'Unanswered' && level.toLowerCase() !== confidenceFilter.toLowerCase()) {
        // Remove the card if it no longer matches the filter
        newCards = newCards.filter(card => card.id !== currentCard.id);
        shouldMoveToNextCard = true;
      }
      return newCards;
    });

    // Update userProgress state
    setUserProgress(prevProgress => {
      const newProgress = [...prevProgress];
      const progressIndex = newProgress.findIndex(p => p.flashcard_id === currentCard.id);
      if (progressIndex !== -1) {
        newProgress[progressIndex] = { ...newProgress[progressIndex], confidence_level: level, last_reviewed: now };
      } else {
        newProgress.push({
          id: `${user.id}-${courseId}-${currentCard.id}-${browserInstanceId}`,
          user_id: user.id,
          course_id: courseId,
          flashcard_id: currentCard.id,
          confidence_level: level,
          last_reviewed: now,
          browser_instance_id: browserInstanceId,
          version: 1,
          synced: 0,
        });
      }
      return newProgress;
    });

    // Update filteredCards state
    setFilteredCards(prevCards => {
      let newCards = prevCards.map(card => card.id === currentCard.id ? updatedCard : card);
      if (confidenceFilter !== 'All' && confidenceFilter !== 'CreateCards' && level.toLowerCase() !== confidenceFilter.toLowerCase()) {
        // Remove the card if it no longer matches the filter
        newCards = newCards.filter(card => card.id !== currentCard.id);
      }
      return newCards;
    });

    // Adjust currentCardIndex if necessary
    setCurrentCardIndex(prevIndex => {
      if (prevIndex >= filteredCards.length) {
        return Math.max(0, filteredCards.length - 1);
      }
      return prevIndex;
    });

    // Increment cards completed count
    const newCount = cardsCompleted + 1;
    setCardsCompleted(newCount);
    updateCardsCompletedInStorage(userId, courseId, newCount);

    if (newCount === 10 && !isSpacedRepetitionUnlocked) {
      setIsSpacedRepetitionUnlocked(true);
      setSpacedRepetitionUnlocked(userId, courseId, true);
      showAlert("Looping Mode unlocked!");
    }

    // Update filter counts
    updateFilterCountsLocally(filteredCards, userProgress);

    // Update user study stats in IndexedDB
    await flashcardIDB.updateUserStudyStats(userId, courseId, newCount);

  } catch (err) {
    console.error('Failed to update card progress:', err);
    setError('Failed to update card progress. Please try again.');
    // Revert local state changes
    updateConfidenceLevelLocally(currentCard.id, oldLevel);
    updateFilterCountsAfterConfidenceChange(level, oldLevel);
    setDisplayedConfidenceLevel(oldLevel);
    setConfidenceLevel(oldLevel);
    shouldUpdateCard = false;
  } finally {
    // Wait for the flip animation to complete before updating the card
    setTimeout(() => {
      setIsFlipping(false);

      if (shouldUpdateCard) {
        if (shouldMoveToNextCard) {
          // Move to the next card
          setCurrentCardIndex(prevIndex => (prevIndex + 1) % filteredCards.length);
        } else {
          // Ensure the current index is within bounds
          setCurrentCardIndex(prevIndex => Math.min(prevIndex, filteredCards.length - 1));
        }

        setHasSeenBack(false);

        // Check if all cards are completed
        if (filteredCards.length === 0) {
          setCategoryCompleted(true);
          setShowCompletionAlert(true);
        }
      }
    }, 300); // Adjust this timing to match your flip animation duration
  }
}, [
  filteredCards,
  currentCardIndex,
  getStoredConfidenceLevel,
  userProgress,
  updateConfidenceLevelLocally,
  updateFilterCountsAfterConfidenceChange,
  courseId,
  userId,
  cardsCompleted,
  isSpacedRepetitionUnlocked,
  setError,
  getBrowserInstanceId,
  browserInstanceId,
  syncService,
  updateFilterCountsLocally,
  confidenceFilter,
]);

  // Add this useEffect to handle filtering based on confidence levels
  useEffect(() => {
    const applyConfidenceFilter = (cards: (Flashcard | CustomFlashcard)[]) => {
      if (confidenceFilter !== 'All') {
        return cards.filter(card => {
          const cardConfidence = getStoredConfidenceLevel(card, userProgress);
          if (confidenceFilter === 'Unanswered') {
            return cardConfidence === null;
          }
          return cardConfidence === confidenceFilter.toLowerCase();
        });
      }
      return cards;
    };

    const updateFilteredCardsAndCounts = (newFilteredCards: (Flashcard | CustomFlashcard)[]) => {
      setFilteredCards(newFilteredCards);
      setFilterCounts(prevCounts => ({
        ...prevCounts,
        all: newFilteredCards.length,
        unanswered: newFilteredCards.filter(card => getStoredConfidenceLevel(card, userProgress) === null).length,
        guessing: newFilteredCards.filter(card => getStoredConfidenceLevel(card, userProgress) === 'guessing').length,
        maybe: newFilteredCards.filter(card => getStoredConfidenceLevel(card, userProgress) === 'maybe').length,
        confident: newFilteredCards.filter(card => getStoredConfidenceLevel(card, userProgress) === 'confident').length,
      }));
    };

    // Apply filters
    const newFilteredCards = applyConfidenceFilter([...cards, ...customCards]);
    updateFilteredCardsAndCounts(newFilteredCards);

  }, [confidenceFilter, cards, customCards, userProgress, getStoredConfidenceLevel]);

// KEYBOARD SHORTCUTS
const handleSpacebar = useCallback(() => {
  if (!isInputActive) {
    handleFlipCard();
  }
}, [handleFlipCard, isInputActive]);

const handleTabPress = useCallback((event?: KeyboardEvent) => {
  if (!isInputActive) {
    if (event) {
      event.preventDefault();
    }
    if (hasSeenBack) {
      setFocusedConfidenceLevel((prev: number) => (prev + 1) % confidenceLevels.length);
    }
  }
}, [hasSeenBack, confidenceLevels.length, isInputActive]);

const handleShiftPress = useCallback(() => {
  if (!isInputActive && hasSeenBack && focusedConfidenceLevel !== -1) {
    const selectedConfidence = confidenceLevels[focusedConfidenceLevel];
    handleConfidenceLevel(selectedConfidence);
  }
}, [hasSeenBack, focusedConfidenceLevel, confidenceLevels, handleConfidenceLevel, isInputActive]);

const isKeyPressEnabled = useCallback(() => {
  return !showCreateCardForm && !isInputActive;
}, [showCreateCardForm, isInputActive]);

// Update the useKeyPress calls
useKeyPress(' ', handleSpacebar, isKeyPressEnabled);
useKeyPress('Tab', handleTabPress, isKeyPressEnabled);
useKeyPress('Shift', handleShiftPress, isKeyPressEnabled);

const handleInputFocus = () => setIsInputActive(true);
const handleInputBlur = () => setIsInputActive(false);

// Use this function to add refs to your input elements
const addInputRef = (el: HTMLTextAreaElement | HTMLInputElement | null) => {
  if (el && !inputRefs.current.includes(el)) {
    inputRefs.current.push(el);
  }
};
  
//CREATE CARD FUNCTIONS

  const FRONT_MAX_LENGTH = 500;
  const BACK_MAX_LENGTH = 2000;
  const MAX_TAG_LENGTH = 30;
  const MAX_TAGS = 3;

  const sanitizeInput = (input: string) => {
    return DOMPurify.sanitize(input);
  };

  // Add a function to truncate text
  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  // Add state for character limit warning
  const [showCharLimitWarning, setShowCharLimitWarning] = useState(false);

  // Update handleCardContentChange function
  const handleCardContentChange = (setter: React.Dispatch<React.SetStateAction<string>>, maxLength: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = sanitizeInput(e.target.value);
    setter(newValue.slice(0, maxLength));
    setShowCharLimitWarning(newValue.length >= maxLength);
    if (newValue.length >= maxLength) {
      setTimeout(() => setShowCharLimitWarning(false), 3000);
    }
  };

  const getFilterButtonText = (filter: FilterType) => {
    if (filter === 'CreateCards') return 'Your Cards';
    return filter;
  };

  // Updated color options
  const colorOptions = [
    { name: 'Light Gray', value: '#E2E8F0', darkMode: '#2D3748' },
    { name: 'Light Blue', value: '#BFDBFE', darkMode: '#2C5282' },
    { name: 'Light Green', value: '#BBF7D0', darkMode: '#276749' },
    { name: 'Light Yellow', value: '#FEF08A', darkMode: '#723B13' },
    { name: 'Light Pink', value: '#FBCFE8', darkMode: '#702459' },
    { name: 'Light Purple', value: '#DDD6FE', darkMode: '#553C9A' },
  ];
  
  const [newCardColor, setNewCardColor] = useState<string>(colorOptions[0].value);

  const getAllTags = useCallback(async () => {
    try {
      // First, try to get tags from IDB
      let tags = await flashcardIDB.getAllUserTags(userId, courseId);
      
      // If no tags in IDB, fallback to the previous method
      if (!tags || tags.length === 0) {
        const customCards = await flashcardIDB.getCustomFlashcards(userId, courseId);
        const tagCounts = customCards.reduce((acc, card) => {
          card.tags.forEach(tag => {
            acc[tag] = (acc[tag] || 0) + 1;
          });
          return acc;
        }, {} as Record<string, number>);
      
        tags = Object.entries(tagCounts)
          .sort((a, b) => b[1] - a[1])
          .map(([tag]) => tag);
        
        // Update IDB with the computed tags
        await flashcardIDB.addUserTags(userId, courseId, tags);
      }
      
      return tags;
    } catch (error) {
      console.error('Error fetching tags:', error);
      return [];
    }
  }, [userId, courseId]);

  useEffect(() => {
    const loadTags = async () => {
      const tags = await getAllTags();
      setAllTags(tags);
      setVisibleTags(showAllTags ? tags : tags.slice(0, 10));
    };
    loadTags();
  }, [getAllTags, showAllTags]);
  
  // Separate effect to update visible tags when showAllTags changes
  useEffect(() => {
    setVisibleTags(showAllTags ? allTags : allTags.slice(0, 10));
  }, [showAllTags, allTags]);

  const addNewCardTag = useCallback(async (newTag: string) => {
    const sanitizedTag = sanitizeInput(newTag);
    if (sanitizedTag && newCardTags.length < MAX_TAGS && !newCardTags.includes(sanitizedTag)) {
      setNewCardTags(prev => [...prev, sanitizedTag.slice(0, MAX_TAG_LENGTH)]);
      setNewCardTagInput('');
      
      // Remove the immediate update of allTags
      // We'll update allTags only after the card is created
    }
  }, [newCardTags, setNewCardTags, setNewCardTagInput]);


  const handleTagSelection = useCallback((tag: string, isNewCard: boolean = false) => {
    if (isNewCard) {
      addNewCardTag(tag);
    } else {
      setSelectedTags(prev => {
        if (prev.includes(tag)) {
          return prev.filter(t => t !== tag);
        } else {
          return [...prev, tag];
        }
      });
      setCurrentPage(1);
    }
    setShowTagDropdown(false);
  }, [addNewCardTag, setSelectedTags, setCurrentPage, setShowTagDropdown]);
  
  useEffect(() => {
    const filtered = customCards.filter(card => 
      selectedTags.length === 0 || selectedTags.some(tag => card.tags.includes(tag))
    );
    setFilteredCustomCards(filtered);
  }, [customCards, selectedTags]);

  useEffect(() => {
    const filterCards = async () => {
      const allCustomCards = await flashcardIDB.getCustomFlashcards(userId, courseId);
      const filtered = allCustomCards.filter(card => 
        selectedTags.length === 0 || selectedTags.some(tag => card.tags.includes(tag))
      );
      setFilteredCustomCards(filtered);
    };
    filterCards();
  }, [userId, courseId, selectedTags]);

  const isCreateCardDisabled = !newCardFront.trim() || !newCardBack.trim();

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredCustomCards.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const setTagInputRef = useCallback((el: HTMLTextAreaElement | null) => {
    tagInputRef.current = el;
    if (el) {
      addInputRef(el);
    }
  }, [addInputRef]);

  //CREATE CARD FORM //

  const handleBackToStudy = useCallback(() => {
    setShowCreateCardForm(false);
    setConfidenceFilter('CreateCards');
    updateFilteredCards();
    clearUrlParams();
  }, [setShowCreateCardForm, setConfidenceFilter, updateFilteredCards, clearUrlParams]);

  const setNewCardTagInputRef = useCallback((el: HTMLTextAreaElement | null) => {
    newCardTagInputRef.current = el;
    if (el) {
      addInputRef(el);
    }
  }, [addInputRef]);

  const handleNewCardTagKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Backspace' && newCardTagInput === '' && newCardTags.length > 0) {
      e.preventDefault();
      const lastTag = newCardTags[newCardTags.length - 1];
      removeNewCardTag(lastTag);
      setNewCardTagInput(lastTag);
    } else if (e.key === ',' || e.key === ' ') {
      e.preventDefault();
      const newTag = newCardTagInput.trim();
      addNewCardTag(newTag);
    }
  };

  const removeNewCardTag = (tagToRemove: string) => {
    setNewCardTags(newCardTags.filter(tag => tag !== tagToRemove));
  };

  const handleTagInput = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>, isNewCard: boolean = false) => {
    const value = sanitizeInput(e.target.value.slice(0, MAX_TAG_LENGTH));
    if (isNewCard) {
      setNewCardTagInput(value);
    } else {
      setTagInput(value);
    }
    filterTags(value);
  }, [filterTags, setNewCardTagInput, setTagInput]);


  const handleTagKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Backspace' && tagInput === '' && editingCard && editingCard.tags.length > 0) {
      e.preventDefault();
      const lastTag = editingCard.tags[editingCard.tags.length - 1];
      removeTag(lastTag);
      setTagInput(lastTag);
    } else if (e.key === ',' || e.key === ' ') {
      e.preventDefault();
      const newTag = tagInput.trim();
      addTag(newTag);
    }
  };

  const removeTag = (tagToRemove: string) => {
    if (editingCard) {
      setEditingCard({
        ...editingCard,
        tags: editingCard.tags.filter(tag => tag !== tagToRemove)
      });
    }
  };

  useEffect(() => {
    if (tagInputRef.current) {
      tagInputRef.current.style.height = 'auto';
      tagInputRef.current.style.height = `${tagInputRef.current.scrollHeight}px`;
    }
  }, [tagInput, newCardTags]);

  const toggleCardExpansion = (cardId: string) => {
    setExpandedCards(prev => ({ ...prev, [cardId]: !prev[cardId] }));
  };

  
  const renderTagInput = useCallback((isNewCard: boolean = false) => (
    <div className="relative">
      <div className="flex flex-wrap items-center gap-2 p-1 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 min-h-[50px]">
        {(isNewCard ? newCardTags : editingCard?.tags || []).map((tag, index) => (
          <span key={index} className="ml-1 inline-flex items-center bg-blue-100 dark:bg-primary-blue text-blue-800 dark:text-blue-200 px-2 py-1 rounded-full text-sm max-h-[40px]">
            {tag}
            <button 
              onClick={() => isNewCard ? removeNewCardTag(tag) : removeTag(tag)}
              className="ml-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 flex items-center justify-center"
            >
              <X size={18} />
            </button>
          </span>
        ))}
        <textarea
          ref={isNewCard ? setNewCardTagInputRef : setTagInputRef}
          id={isNewCard ? "newCardTags" : "editTags"}
          value={isNewCard ? newCardTagInput : tagInput}
          onChange={(e) => isNewCard ? handleTagInput(e, true) : handleEditTagInput(e)}
          onKeyDown={isNewCard ? handleNewCardTagKeyDown : handleTagKeyDown}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          className="flex-grow p-0.5 bg-transparent border-none outline-none text-gray-900 dark:text-gray-100 text-sm md:text-base resize-none h-6 min-h-[24px] overflow-hidden"
          placeholder={isNewCard 
            ? (newCardTags.length < MAX_TAGS ? "Add a comma or space to add tag" : "") 
            : ((editingCard?.tags?.length ?? 0) < MAX_TAGS ? "Add comma or space to add tag" : "")}
          rows={1}
          disabled={isNewCard ? newCardTags.length >= MAX_TAGS : (editingCard?.tags?.length ?? 0) >= MAX_TAGS}
          maxLength={MAX_TAG_LENGTH}
        />
      </div>
      <p className="text-xs text-gray-500 mt-1 text-right">
        {(isNewCard ? newCardTagInput : tagInput).length}/{MAX_TAG_LENGTH}
      </p>
      {isNewCard ? (
        showTagDropdown && (
          <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
            {filteredTags.map((tag, index) => (
              <div
                key={index}
                className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                onClick={() => handleTagSelection(tag, true)}
              >
                {tag}
              </div>
            ))}
          </div>
        )
      ) : (
        showEditTagDropdown && (
          <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
            {filteredEditTags.map((tag, index) => (
              <div
                key={index}
                className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                onClick={() => handleEditTagSelection(tag)}
              >
                {tag}
              </div>
            ))}
          </div>
        )
      )}
    </div>
  ), [newCardTags, editingCard, newCardTagInput, tagInput, filteredTags, filteredEditTags, handleTagInput, handleEditTagInput, handleNewCardTagKeyDown, handleTagKeyDown, handleInputFocus, handleInputBlur, setNewCardTagInputRef, setTagInputRef, removeNewCardTag, removeTag, handleTagSelection, handleEditTagSelection, showTagDropdown, showEditTagDropdown, MAX_TAGS]);

  async function handleCreateCard() {
    if (isCreatingCard) return; // Prevent double submission
    setIsCreatingCard(true);
    try {
      const user = await auth.getCurrentUser();
      if (!user) throw new Error('User not authenticated');
  
      const tags = newCardTags.length > 0 ? newCardTags : ['general'];
      const browserInstanceId = await getBrowserInstanceId();
  
      const newCard: Omit<CustomFlashcard, 'id' | 'created_at' | 'updated_at' | 'last_reviewed' | 'version' | 'synced' | 'card_identifier' | 'local_id'> = {
        user_id: user.id,
        course_id: courseId,
        front_content: sanitizeInput(newCardFront),
        back_content: sanitizeInput(newCardBack),
        confidence_level: null,
        tags: tags,
        color: newCardColor,
        browser_instance_id: browserInstanceId,
      };
  
      const createdCard = await syncService.createCustomFlashcard(newCard);
  
      setCustomCards(prevCards => [...prevCards, createdCard]);
      setNewCardFront('');
      setNewCardBack('');
      setNewCardTags([]);
      setTagInput('');
      setNewCardColor(colorOptions[0].value);
  
      // Update local tag state only after card is created
      setAllTags(prevTags => Array.from(new Set([...prevTags, ...createdCard.tags])));
  
      // Update local storage cache
      const updatedTags = Array.from(new Set([...allTags, ...createdCard.tags]));
      localStorage.setItem(`${TAG_CACHE_KEY}_${courseId}`, JSON.stringify({
        tags: updatedTags,
        timestamp: Date.now()
      }));
  
      updateFilteredCards();
      
      // Show success message
      showAlert('New card created successfully');
    } catch (err) {
      console.error('Error creating custom card:', err);
      setError('Failed to create custom card. Please try again.');
      showAlert('Failed to create new card. Please try again.');
    } finally {
      setIsCreatingCard(false); // Reset the creating state regardless of success or failure
    }
  }
  
  const handleUpdateCard = async () => {
    if (!editingCard) return;
    try {
      const user = await auth.getCurrentUser();
      if (!user) throw new Error('User not authenticated');
  
      const updatedCard = await syncService.updateCustomFlashcard(editingCard.card_identifier, {
        user_id: user.id,
        course_id: courseId,
        front_content: sanitizeInput(editingCard.front_content),
        back_content: sanitizeInput(editingCard.back_content),
        tags: editingCard.tags,
        color: editingCard.color
      });
      setCustomCards(prevCards => 
        prevCards.map(card => 
          card.card_identifier === updatedCard.card_identifier ? updatedCard : card
        )
      );
      setEditingCard(null);
      updateFilteredCards();
  
      // Update IDB tags
      await flashcardIDB.addUserTags(user.id, courseId, updatedCard.tags);
  
      // Update local storage cache
      const updatedTags = Array.from(new Set([...allTags, ...updatedCard.tags]));
      localStorage.setItem(`${TAG_CACHE_KEY}_${courseId}`, JSON.stringify({
        tags: updatedTags,
        timestamp: Date.now()
      }));
    } catch (err) {
      console.error('Failed to update custom card:', err);
    }
  };


  const updateUIAfterDeletion = useCallback((deletedCardId: string, updatedTags: string[]) => {
    console.log(`Updating UI after deletion. Deleted card ID: "${deletedCardId}"`);
    setCustomCards(prevCards => prevCards.filter(card => card.card_identifier !== deletedCardId));
    setAllTags(updatedTags);
    setSelectedTags(prevSelectedTags => prevSelectedTags.filter(tag => updatedTags.includes(tag)));
    updateFilteredCards();
  }, [updateFilteredCards]);

  const debouncedDeleteCard = useCallback(debounce(async (cardIdentifier: string) => {
    console.log(`Attempting to delete card with identifier: "${cardIdentifier}"`);
    if (!cardIdentifier) {
      console.error('Attempted to delete card with empty identifier');
      showAlert('Cannot delete card: Invalid card identifier');
      return;
    }
    try {
      await syncService.deleteCustomFlashcard(
        cardIdentifier,
        courseId,
        userId,
        updateUIAfterDeletion
      );
      console.log(`Successfully deleted card with identifier: "${cardIdentifier}"`);
      showAlert('Card successfully marked for deletion');
    } catch (err) {
      console.error('Failed to mark custom card for deletion:', err);
      setError('Failed to mark custom card for deletion. Please try again.');
      showAlert('Failed to mark card for deletion. Please try again.');
    }
  }, 300), [courseId, userId, updateUIAfterDeletion]);

  const handleDeleteCard = useCallback(async (cardIdentifier: string) => {
    console.log(`handleDeleteCard called with identifier: "${cardIdentifier}"`);
    if (!cardIdentifier) {
      console.error('handleDeleteCard called with empty identifier');
      showAlert('Cannot delete card: Invalid card identifier');
      return;
    }
    try {
      await debouncedDeleteCard(cardIdentifier);
    } catch (error) {
      console.error('Error in handleDeleteCard:', error);
      showAlert('An unexpected error occurred. Please try again.');
    }
  }, [debouncedDeleteCard]);
  
  // Update filtered cards when relevant state changes
  useEffect(() => {
    updateFilteredCards();
  }, [cards, customCards, selectedCategory, confidenceFilter, userProgress, updateFilteredCards, isRandomMode, selectedTags, selectedFilterTags]);

  const showAlert = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => setAlertMessage(null), 2500); // Hide alert after 3 seconds
  };

  const handleLockedConfidenceClick = () => {
    showAlert("Please flip card before updating confidence level.");
  };

  if (isLoading) {
    return <LoadingScreen message="Loading your KeslerCards..." subMessage="This may take a few moments" />;
  }
  if (error) return <div className="text-red-500 dark:text-red-400">{error}</div>;

  const canMoveToNextCard = isPreviouslyAnswered || (hasSeenBack && confidenceLevel !== null);

  // Use this check before accessing currentCard properties
  const renderCard = () => {
    if (!currentCard) {
      return <div>No cards available</div>;
    }
  
    const frontContent = 'side_1_content' in currentCard
      ? currentCard.side_1_content 
      : currentCard.front_content;
    const backContent = 'side_2_content' in currentCard 
      ? currentCard.side_2_content 
      : currentCard.back_content;
  
    return (
      <div 
        className={`flashcard-container w-full max-w-sm md:max-w-3xl h-[30rem] md:h-96 ${isFlipping ? 'flipping' : ''} relative`}
        onClick={handleFlipCard}
      >
        <div className={`flashcard ${showingFront ? 'front' : 'back'}`}>
          <div className="flashcard-side-indicator text-xs md:text-sm">
            {showingFront ? 'FRONT' : 'BACK'}
          </div>
          {showingFront && 'tags' in currentCard && (
            <div className="absolute top-2 right-2 flex flex-wrap justify-end">
              {currentCard.tags.map((tag, index) => (
                <span key={index} className="m-1 px-2 py-1 bg-gray-200 text-gray-700 text-xs rounded-full">
                  {tag}
                </span>
              ))}
            </div>
          )}
          <div className={`flashcard-content p-6 md:p-6 h-full text-gray-900 dark:text-gray-100 bg-white dark:bg-gray-800 overflow-y-auto`}>
            <div className="flashcard-html-content pt-3 text-xs md:text-base">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(showingFront ? frontContent : backContent)
                }} 
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

    // Update the filter buttons rendering
    const renderFilterButtons = () => {
      const isFilterActive = (filter: FilterType): boolean => {
        if (filter === 'CreateCards') return isCreateCardsActive;
        if (filter === 'All') return confidenceFilter === 'All' && !isCreateCardsActive;
        return confidenceFilter === filter;
      };
    
      const handleXClick = (filter: FilterType) => (e: React.MouseEvent) => {
        e.stopPropagation();
        if (filter === 'CreateCards') {
          setIsCreateCardsActive(false);
        } else {
          // For confidence levels, reset to 'All' without affecting CreateCards
          setConfidenceFilter('All');
        }
      };
    
      return (
        <div className="flex flex-wrap gap-2">
          {(['All', 'CreateCards', 'Unanswered', 'Guessing', 'Maybe', 'Confident'] as const).map((filter: FilterType) => {
            const isActive = isFilterActive(filter);
            
            return (
              <button
                key={filter}
                onClick={() => handleFilterChange(filter)}
                className={`relative rounded text-xs md:text-sm flex items-center overflow-hidden ${
                  isActive
                    ? 'bg-primary-blue text-white'
                    : 'bg-gray-300 text-gray-700 hover:bg-gray-400 dark:bg-gray-600 dark:text-gray-200 dark:hover:bg-gray-500'
                }`}
              >
                <span className="px-2 py-1">
                  {getFilterButtonText(filter)} ({filterCounts[filter.toLowerCase() as keyof typeof filterCounts]})
                </span>
                {isActive && filter !== 'All' && (
                  <span className={`flex items-center justify-center h-full px-2 ml-1 ${filter === 'CreateCards' ? 'bg-red-500' : 'bg-red-400'}`}>
                    <X
                      size={14}
                      className="cursor-pointer text-white"
                      onClick={handleXClick(filter)}
                    />
                  </span>
                )}
              </button>
            );
          })}
        </div>
      );
    };
    
  const SpacedRepetitionCompletionScreen: React.FC<{
    onTurnOffSpacedRepetition: () => void;
    onCreateNewCard: () => void;
  }> = ({ onTurnOffSpacedRepetition, onCreateNewCard }) => {
    return (
      <div className="mt-4 md:mt-8 text-center bg-green-100 dark:bg-green-800 p-4 rounded-lg shadow">
        <h3 className="text-lg md:text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">
          All Looping Mode Cards Completed!
        </h3>
        <p className="mb-4 text-sm md:text-base text-gray-700 dark:text-gray-300">
          Great job! You've reviewed all the cards due for today in Looping mode.
        </p>
        <p className="mb-4 text-sm md:text-base text-gray-700 dark:text-gray-300">
          <strong>REMINDER:</strong> Cards marked CONFIDENT will cycle in every 7 days, MAYBE every 4 days and GUESSING every 2 days until you have mastered
        </p>
        <div className="flex flex-wrap justify-center gap-2">
          <button
            onClick={onTurnOffSpacedRepetition}
            className="bg-primary-blue text-white px-3 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-800 transition duration-300"
          >
            Review Unanswered Cards
          </button>
          <button
            onClick={onCreateNewCard}
            className="bg-green-500 text-white px-3 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-800 transition duration-300"
          >
            Create New Card
          </button>
        </div>
      </div>
    );
  };
  
  return (
    <div className="space-y-4 relative md:p-6">
      {alertMessage && <AlertMessage message={alertMessage} />}
  
      {/* Filter section */}
      <div className={`bg-white dark:bg-gray-800 p-4 rounded shadow transition-all duration-300 ${isFilterMinimized ? 'h-20 overflow-hidden' : ''}`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl md:text-2xl font-bold text-light-text dark:text-dark-text">KeslerCards</h2>
          <div className="flex items-center space-x-4 md:space-x-6">
            <button
              onClick={() => {
                setShowCreateCardForm(!showCreateCardForm);
                clearUrlParams();
              }}
              className="bg-primary-blue text-white px-2 py-1 md:px-4 md:py-2 text-sm md:text-base rounded hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-600"
            >
              {showCreateCardForm ? 'Back' : 'Create Cards'}
            </button>
            <button
              onClick={() => setIsFilterMinimized(!isFilterMinimized)}
              className="p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 flex items-center justify-center w-8 h-8 md:w-10 md:h-10"
            >
              {isFilterMinimized ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
            </button>
          </div>
        </div>
  
        {!showCreateCardForm && (
          <div className="flex flex-col space-y-4">
            {/* Category select */}
            <div>
              <label htmlFor="category-select" className="block text-sm font-medium text-light-text dark:text-dark-text mb-2">
                Select Category
              </label>
              <select
                  id="category-select"
                  value={selectedCategory || ''}
                  onChange={(e) => {
                    const newCategory = e.target.value || null;
                    setSelectedCategory(newCategory);
                    clearUrlParams();
                    if (newCategory) {
                      const filteredCards = filterCardsByCategory(cards, customCards, newCategory);
                      setFilteredCards(filteredCards);
                      setCurrentCardIndex(0);
                      setShowingFront(true);
                      setCategoryCompleted(false);
                      if (filteredCards.length > 0) {
                        setConfidenceLevel(getStoredConfidenceLevel(filteredCards[0], userProgress));
                      }
                    } else {
                      setFilteredCards([...cards, ...customCards]);
                    }
                  }}
                  className="w-full p-2 border rounded bg-light-background dark:bg-gray-700 text-light-text dark:text-dark-text"
                  >
                  <option value="">All Categories</option>
                  {sortedCategories.map((category) => (
                    <option key={category.id} value={`${category.parent_flashcard_category_name} - ${category.sub_flashcard_category_name}`}>
                      {formatCategoryName(category)}
                    </option>
                  ))}
                </select>
            </div>
  
            {/* Filter buttons */}
            <div>
              <h3 className="text-sm font-medium text-light-text dark:text-dark-text mb-2">Filter KeslerCards</h3>
              {renderFilterButtons()}
            </div>
  
            {/* Random and Looping Modes */}
            <div className="flex">
              <div className="pl-2 flex items-center space-x-2">
                <span className="text-sm text-gray-600 dark:text-gray-300">Random</span>
                <Switch
                  checked={isRandomMode}
                  onChange={(checked) => {
                    setIsRandomMode(checked);
                    if (checked) {
                      setIsSpacedRepetitionMode(false);
                    }
                    localStorage.setItem('keslerCardsRandomMode', JSON.stringify(checked));
                  }}
                />
              </div>
              <div className="pl-2 flex items-center space-x-2">
                <span className="text-sm text-gray-600 dark:text-gray-300">Looping Mode</span>
                <Switch
                  checked={isSpacedRepetitionMode}
                  onChange={(checked) => {
                    if (isSpacedRepetitionUnlocked) {
                      setIsSpacedRepetitionMode(checked);
                      if (checked) {
                        setIsRandomMode(false);
                        localStorage.setItem('keslerCardsRandomMode', JSON.stringify(false));
                      }
                      localStorage.setItem('keslerCardsSpacedRepetitionMode', JSON.stringify(checked));
                    } else {
                      showAlert(`Complete ${Math.max(10 - cardsCompleted, 0)} more cards to unlock Looping Mode`);
                    }
                  }}
                  disabled={!isSpacedRepetitionUnlocked}
                />
              </div>
            </div>
  
            {/* Tag Filters */}
            {isCreateCardsActive && (
              <div className="mt-4">
                <h3 className="text-sm font-medium text-light-text dark:text-dark-text mb-2">Filter by Tags</h3>
                {renderTagFilters()}
              </div>
            )}
          </div>
        )}
      </div>
  
      {!showCreateCardForm && (
        <>
          {spacedRepetitionCompleted ? (
            <SpacedRepetitionCompletionScreen
              onTurnOffSpacedRepetition={handleTurnOffSpacedRepetition}
              onCreateNewCard={() => setShowCreateCardForm(true)}
            />
          ) : filteredCards.length > 0 && !showCompletionAlert ? (
            <div className={`mt-4 md:mt-8 transition-all duration-300 ${isCardMinimized ? 'h-16 overflow-hidden' : ''}`}>
              <div className="flex flex-col items-center relative">
              <div className="text-gray-700 dark:text-gray-300 mb-2">
                Card {currentCardIndex + 1} / {filteredCards.length}
                {isRandomMode && <span className="ml-2 text-blue-500">(Random Mode)</span>}
                {isSpacedRepetitionMode && <span className="ml-2 text-purple-500">(Looping Mode)</span>}
              </div>
                {/* Flashcard */}
                {renderCard()}
  
                {/* Navigation and confidence buttons */}
                <div className="mt-4 flex flex-wrap items-center justify-center gap-1 md:gap-4 px-1">
                  <button
                    onClick={handlePreviousCard}
                    className="flex items-center justify-center p-1 md:p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
                    disabled={currentCardIndex === 0}
                  >
                    <ChevronLeft size={20} />
                  </button>
                  {confidenceLevels.map((level, index) => (
                    <button
                      key={level}
                      onClick={hasSeenBack ? () => handleConfidenceLevel(level) : handleLockedConfidenceClick}
                      className={`px-2 py-1 md:px-3 md:py-2 rounded-full text-xs md:text-sm transition-colors duration-200 
                        ${hasSeenBack ? 'border border-gray-600 dark:border-gray-400' : ''}
                        ${confidenceLevel === level
                          ? level === 'guessing'
                            ? 'bg-red-500 text-white'
                            : level === 'maybe'
                            ? 'bg-yellow-500 text-white'
                            : 'bg-green-500 text-white'
                          : hasSeenBack
                          ? 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
                          : 'bg-gray-200 text-gray-500 dark:bg-gray-700 dark:text-gray-500 cursor-not-allowed'}
                        ${focusedConfidenceLevel === index ? 'ring-2 ring-blue-500' : ''}
                      `}
                    >
                      {level ? level.charAt(0).toUpperCase() + level.slice(1) : 'Unknown'}
                    </button>
                  ))}
                  <button
                    onClick={handleFlipCard}
                    className="p-1 md:p-2 flex items-center justify-center rounded-full bg-primary-blue text-white hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-600 transition-colors duration-200"
                  >
                    <RefreshCcw size={20} />
                  </button>
                  <button
                    onClick={handleNextCard}
                    className={`flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full transition-colors duration-200 ${
                      canMoveToNextCard
                        ? 'bg-primary-blue text-white hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-600'
                        : 'bg-gray-300 text-gray-500 cursor-not-allowed dark:bg-gray-600 dark:text-gray-400'
                    }`}
                    disabled={!canMoveToNextCard}
                  >
                    <ChevronRight size={20} />
                  </button>
                </div>
              </div>
            </div>
          ) : showCompletionAlert ? (
            <div className="mt-4 md:mt-8 text-center bg-green-100 dark:bg-green-800 p-4 rounded-lg shadow">
              <h3 className="text-lg md:text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">
                {confidenceFilter === 'CreateCards'
                  ? "You've reviewed all your custom cards!"
                  : isSpacedRepetitionMode
                  ? "All due cards completed!"
                  : "All available cards completed!"}
              </h3>
              <p className="mb-4 text-sm md:text-base text-gray-700 dark:text-gray-300">
                {confidenceFilter === 'CreateCards'
                  ? "Great job! You've gone through all your custom cards."
                  : isSpacedRepetitionMode
                  ? "Great job! You've reviewed all the cards due for today in Looping Mode."
                  : "Great job! You've gone through all the cards in this category."}
              </p>
              <div className="flex flex-wrap justify-center gap-2">
                {isSpacedRepetitionMode ? (
                  <>
                    <button
                      onClick={handleTurnOffSpacedRepetition}
                      className="bg-primary-blue text-white px-3 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-800 transition duration-300"
                    >
                      Review Unanswered Cards
                    </button>
                    <button
                      onClick={() => setShowCreateCardForm(true)}
                      className="bg-green-500 text-white px-3 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-800 transition duration-300"
                    >
                      Create New Card
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setSelectedCategory(null);
                        setConfidenceFilter('All');
                        setShowCompletionAlert(false);
                        clearUrlParams();
                        updateFilteredCards();
                      }}
                      className="bg-primary-blue text-white px-3 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-800 transition duration-300"
                    >
                      Study Different Category
                    </button>
                    <button
                      onClick={() => setShowCreateCardForm(true)}
                      className="bg-green-500 text-white px-3 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-800 transition duration-300"
                    >
                      Create New Card
                    </button>
                  </>
                )}
                {returnUrl && (
                  <button
                    onClick={() => navigate(decodeURIComponent(returnUrl))}
                    className="bg-yellow-500 text-white px-3 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md hover:bg-yellow-600 dark:bg-yellow-700 dark:hover:bg-yellow-800 transition duration-300"
                  >
                    Return to Study Task
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="mt-4 md:mt-8 text-center bg-yellow-100 dark:bg-yellow-800 p-4 rounded-lg shadow">
              <h3 className="text-lg md:text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">No cards match the current filter</h3>
              <p className="mb-4 text-sm md:text-base text-gray-700 dark:text-gray-300">
                {confidenceFilter === 'CreateCards' 
                  ? "You haven't created any custom cards yet." 
                  : "Try adjusting your filter settings or create new cards."}
              </p>
              <div className="flex flex-wrap justify-center gap-2">
                <button
                  onClick={() => {
                    setSelectedCategory(null);
                    setConfidenceFilter('All');
                    updateFilteredCards();
                  }}
                  className="bg-primary-blue text-white px-3 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-800 transition duration-300"
                >
                  Reset Filters
                </button>
                <button
                  onClick={() => setShowCreateCardForm(true)}
                  className="bg-green-500 text-white px-3 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-800 transition duration-300"
                >
                  Create New Card
                </button>
              </div>
            </div>
          )}
        </>
      )}
  


    <div className="space-y-4 relative md:p-6">
      {/* Create card form */}
      {showCreateCardForm && (
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold mb-6 text-gray-900 dark:text-gray-100">Create New KeslerCard</h3>
          <div className="space-y-6">
            {/* Front of card */}
            <div>
              <label htmlFor="front-content" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Front of Card
              </label>
              <textarea
                id="front-content"
                placeholder="Enter the question or prompt"
                value={newCardFront}
                onChange={handleCardContentChange(setNewCardFront, FRONT_MAX_LENGTH)}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                ref={(el) => addInputRef(el)}
                className="w-full p-3 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                rows={3}
                maxLength={FRONT_MAX_LENGTH}
              />
              <p className="text-xs text-gray-500 mt-1 text-right">{newCardFront.length}/{FRONT_MAX_LENGTH}</p>
            </div>

            {/* Back of card */}
            <div>
              <label htmlFor="back-content" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Back of Card
              </label>
              <textarea
                id="back-content"
                placeholder="Enter the answer or explanation"
                value={newCardBack}
                onChange={handleCardContentChange(setNewCardBack, BACK_MAX_LENGTH)}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                ref={(el) => addInputRef(el)}
                className="w-full p-3 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                style={{ maxHeight: '400px' }}
                rows={3}
                maxLength={BACK_MAX_LENGTH}
              />
              <p className="text-xs text-gray-500 mt-1 text-right">{newCardBack.length}/{BACK_MAX_LENGTH}</p>
            </div>

            {/* Tags and Color in one row */}
            <div className="flex flex-col md:flex-row md:space-x-4">
              {/* Tags */}
              <div className="flex-grow mb-4 md:mb-0">
                <label htmlFor="newCardTags" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Optional: Add Tags (max 3)
                </label>
                {renderTagInput(true)}
              </div>

              {/* Card color */}
              <div className="flex-shrink-0 w-full md:w-auto">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Pick Color
                </label>
                <div className="flex space-x-2 flex-wrap">
                  {colorOptions.map((color) => (
                    <button
                      key={color.value}
                      onClick={() => setNewCardColor(color.value)}
                      className={`w-8 h-8 rounded-full border-2 transition-all duration-200 ${
                        newCardColor === color.value ? 'border-blue-500 scale-110' : 'border-gray-300 dark:border-gray-600 hover:scale-105'
                      }`}
                      style={{ backgroundColor: color.value }}
                      title={color.name}
                    />
                  ))}
                </div>
              </div>
            </div>

            {/* Create card button */}
            <button
              onClick={handleCreateCard}
              disabled={isCreateCardDisabled || isCreatingCard}
              className={`w-full px-6 py-3 rounded-md text-lg font-semibold transition duration-300 shadow-md hover:shadow-lg ${
                isCreateCardDisabled || isCreatingCard
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-primary-blue text-white hover:bg-blue-600 dark:bg-orange-600 dark:hover:bg-orange-700'
              }`}
              >
              {isCreatingCard ? 'Creating...' : 'Create KeslerCard'}
            </button>
          </div>
        </div>
      )}


      {/* Custom cards display */}
  {showCreateCardForm && (
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow relative">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">Your Custom Cards</h3>
        <button
          onClick={handleBackToStudy}
          className="bg-primary-blue text-white px-2 py-1 md:px-4 md:py-2 text-sm md:text-base rounded hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-600"
        >
          Study
        </button>
      </div>
      
      {/* Tag filter */}
      <div className="mb-4 flex flex-wrap gap-2">
        <button
          onClick={() => setSelectedTags([])}
          className={`px-3 py-1 rounded-full text-sm ${
            selectedTags.length === 0
              ? 'bg-primary-blue text-white'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
          }`}
        >
          All
        </button>
        {visibleTags.map((tag) => (
          <button
            key={tag}
            onClick={() => handleTagSelection(tag)}
            className={`px-3 py-1 rounded-full text-sm ${
              selectedTags.includes(tag)
                ? 'bg-primary-blue text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
            }`}
          >
            {tag}
          </button>
        ))}
        {allTags.length > 10 && (
            <button
              onClick={() => setShowAllTags(!showAllTags)}
              className="px-3 py-1 rounded-full text-sm bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
            >
              {showAllTags ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 auto-rows-auto">
            {currentCards.map((card) => (
              <div 
                key={card.id} 
                className="rounded-lg shadow-md border border-gray-200 dark:border-gray-700 overflow-hidden flex flex-col"
                style={{ backgroundColor: card.color }}
              >
                <div className="p-4 relative flex-grow">
                  <div className="flex justify-between items-start mb-2">
                    <div className="flex flex-wrap"><span className="mr-1 mb-1 pr-1.5 py-0.5 text-gray-800 text-xs rounded">Front</span> 
                      {card.tags.map((tag, tagIndex) => (
                        <span
                          key={tagIndex}
                          className="mr-1 mb-1 px-1.5 py-0.5 bg-gray-50 dark:bg-gray-600 text-gray-800 dark:text-gray-200 text-xs rounded"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                    <button
                      onClick={() => toggleCardExpansion(card.id)}
                      className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200 ml-2"
                    >
                      {expandedCards[card.id] ? (
                        <ChevronDown size={28} />
                      ) : (
                        <ChevronUp size={28} />
                      )}
                    </button>
                  </div>
                  <div className="font-bold text-sm md:text-base text-gray-800 dark:text-gray-700 mt-2 mb-10 overflow-hidden" style={{ maxHeight: '225px' }}>
                    {expandedCards[card.id] ? card.front_content : truncateText(card.front_content, 500)}
                  </div>
                  <div className="absolute bottom-2 right-4 flex space-x-2 z-10">
                    <button
                      onClick={() => setEditingCard(card)}
                      className="p-1 bg-gray-50 dark:bg-gray-600 text-yellow-500 hover:text-yellow-600 dark:text-yellow-400 dark:hover:text-yellow-300 transition duration-300 shadow-md rounded-full flex items-center justify-center w-8 h-8"
                    >
                      <Edit size={14} />
                    </button>
                    <button
                      onClick={() => {
                        console.log('Delete button clicked. Card identifier:', card.card_identifier);
                        handleDeleteCard(card.card_identifier);
                      }}
                      className="p-1 bg-gray-50 dark:bg-gray-600 text-red-500 hover:text-red-600 dark:text-red-400 dark:hover:text-red-300 transition duration-300 shadow-md rounded-full flex items-center justify-center w-8 h-8"
                      >
                      <Trash2 size={14} />
                    </button>
                  </div>
                </div>
                {expandedCards[card.id] && (
                  <div className="p-4 bg-white dark:bg-gray-700 relative rounded-lg mt-2">
                    <div className="flex flex-wrap"><span className="mr-1 mb-1 pr-1.5 py-0.5 text-gray-800 dark:text-gray-200 text-xs rounded">Back</span> 
                    </div>
                    <p className="text-sm md:text-base text-gray-700 dark:text-gray-300 mb-8 max-h-[400px] overflow-y-auto">
                      {card.back_content}
                    </p>
                    <span className="absolute bottom-4 right-4 text-xs text-gray-600 dark:text-gray-400">
                      #{card.card_identifier}
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalItems={filteredCustomCards.length}
            itemsPerPage={cardsPerPage}
            onPageChange={paginate}
          />
        </div>
      )}

        {editingCard && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full">
              <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Edit Card</h3>
              <div className="space-y-4">
                <div>
                  <textarea
                    value={editingCard.front_content}
                    onChange={(e) => setEditingCard({ ...editingCard, front_content: sanitizeInput(e.target.value.slice(0, FRONT_MAX_LENGTH)) })}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={(el) => addInputRef(el)}
                    className="w-full p-2 border rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base"
                    rows={3}
                    placeholder="Front of card"
                    maxLength={FRONT_MAX_LENGTH}
                  />
                  <p className="text-xs text-gray-500 mt-1 text-right">{editingCard.front_content.length}/{FRONT_MAX_LENGTH}</p>
                </div>
                <div>
                  <textarea
                    value={editingCard.back_content}
                    onChange={(e) => setEditingCard({ ...editingCard, back_content: sanitizeInput(e.target.value.slice(0, BACK_MAX_LENGTH)) })}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={(el) => addInputRef(el)}
                    className="w-full p-2 border rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base"
                    rows={3}
                    placeholder="Back of card"
                    maxLength={BACK_MAX_LENGTH}
                  />
                  <p className="text-xs text-gray-500 mt-1 text-right">{editingCard.back_content.length}/{BACK_MAX_LENGTH}</p>
                </div>
                <div>
                  <label htmlFor="editTags" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Tags (max 3, press comma or space to add)
                  </label>
                  <div className="relative">
                    <div className="flex flex-wrap items-center gap-2 p-1 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 min-h-[50px]">
                      {editingCard.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="ml-1 inline-flex items-center bg-blue-100 dark:bg-primary-blue text-blue-800 dark:text-blue-200 px-2 py-1 rounded-full text-sm max-h-[40px]"
                        >
                          {tag}
                          <button
                            onClick={() => removeTag(tag)}
                            className="ml-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 flex items-center justify-center"
                          >
                            <X size={18} />
                          </button>
                        </span>
                      ))}
                      <textarea
                        ref={editTagInputRef}
                        id="editTags"
                        value={tagInput}
                        onChange={handleEditTagInput}
                        onKeyDown={handleTagKeyDown}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        className="flex-grow p-0.5 bg-transparent border-none outline-none text-gray-900 dark:text-gray-100 text-sm md:text-base resize-none overflow-hidden"
                        placeholder={editingCard && editingCard.tags.length < MAX_TAGS ? "Add comma or space to add tag" : ""}
                        rows={1}
                        style={{ minHeight: '24px', height: 'auto' }}
                        maxLength={MAX_TAG_LENGTH}
                        disabled={editingCard && editingCard.tags.length >= MAX_TAGS}
                      />
                    </div>
                    {showEditTagDropdown && (
                      <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                        {filteredEditTags.map((tag, index) => (
                          <div
                            key={index}
                            className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                            onClick={() => handleEditTagSelection(tag)}
                          >
                            {tag}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <p className="text-xs text-gray-500 mt-1 text-right">{tagInput.length}/{MAX_TAG_LENGTH}</p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Card Color
                  </label>
                  <div className="flex space-x-2">
                    {colorOptions.map((color) => (
                      <button
                        key={color.value}
                        onClick={() => setEditingCard({ ...editingCard, color: color.value })}
                        className={`w-8 h-8 rounded-full border-2 ${
                          editingCard.color === color.value ? 'border-blue-500' : 'border-transparent'
                        }`}
                        style={{ backgroundColor: color.value }}
                        title={color.name}
                      />
                    ))}
                  </div>
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => setEditingCard(null)}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 dark:bg-gray-600 dark:text-gray-200 dark:hover:bg-gray-500 transition duration-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleUpdateCard}
                    className="px-4 py-2 bg-primary-blue text-white rounded-md hover:bg-blue-600 dark:bg-primary-blue dark:hover:bg-blue-800 transition duration-300"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      
      {/* Add character limit warning */}
      {showCharLimitWarning && (
        <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded-md shadow-lg">
          Character limit reached!
        </div>
      )}

      {/* Add a button to return to the study task */}
      {returnUrl && (
        <div className="flex justify-center mt-6">
          <button
            onClick={() => navigate(decodeURIComponent(returnUrl))}
            className="px-6 py-3 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75 transition-transform transform hover:scale-105 active:scale-95"
          >
            Return to Study Task
          </button>
        </div>
      )}
    </div>
  );
}

export default KeslerCards;
// src/components/ui/Accordion.tsx

import React, { createContext, useContext, useState } from 'react';

interface AccordionContextType {
  value: string | null;
  onChange: (value: string) => void;
  type: 'single' | 'multiple';
  collapsible?: boolean;
}

const AccordionContext = createContext<AccordionContextType>({
  value: null,
  onChange: () => {},
  type: 'single'
});

interface AccordionItemContextType {
  value: string;
}

const AccordionItemContext = createContext<AccordionItemContextType>({ value: '' });

interface AccordionProps {
  type?: 'single' | 'multiple';
  collapsible?: boolean;
  value?: string | null;
  onValueChange?: (value: string) => void;
  className?: string;
  children: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({
  type = 'single',
  collapsible = true,
  value: controlledValue,
  onValueChange,
  className = '',
  children
}) => {
  const [internalValue, setInternalValue] = useState<string | null>(null);
  const value = controlledValue ?? internalValue;
  const onChange = onValueChange ?? setInternalValue;

  return (
    <AccordionContext.Provider value={{ value, onChange, type, collapsible }}>
      <div className={`space-y-1 ${className}`}>{children}</div>
    </AccordionContext.Provider>
  );
};

interface AccordionItemProps {
  value: string;
  className?: string;
  children: React.ReactNode;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({ value, className = '', children }) => {
  return (
    <AccordionItemContext.Provider value={{ value }}>
      <div className={`border-b dark:border-gray-700 ${className}`}>
        {children}
      </div>
    </AccordionItemContext.Provider>
  );
};

interface AccordionTriggerProps {
  className?: string;
  children: React.ReactNode;
}

export const AccordionTrigger: React.FC<AccordionTriggerProps> = ({ className = '', children }) => {
  const { value, onChange, collapsible } = useContext(AccordionContext);
  const { value: itemValue } = useContext(AccordionItemContext);
  const isExpanded = value === itemValue;

  const handleClick = () => {
    if (collapsible && isExpanded) {
      onChange('');
    } else {
      onChange(itemValue);
    }
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={`
        flex w-full items-center justify-between py-4 text-sm font-medium text-left hover:underline
        ${className}
      `}
      aria-expanded={isExpanded}
    >
      {children}
      <svg
        className={`w-4 h-4 transform transition-transform duration-200 ${
          isExpanded ? 'rotate-180' : ''
        }`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    </button>
  );
};

interface AccordionContentProps {
  className?: string;
  children: React.ReactNode;
}

export const AccordionContent: React.FC<AccordionContentProps> = ({ className = '', children }) => {
  const { value } = useContext(AccordionContext);
  const { value: itemValue } = useContext(AccordionItemContext);
  const isExpanded = value === itemValue;

  if (!isExpanded) return null;

  return (
    <div
      className={`overflow-hidden transition-all pb-4 text-sm ${className}`}
    >
      {children}
    </div>
  );
};
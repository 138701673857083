import React, { useState, useEffect, useCallback, useRef, useMemo, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { api, LessonCategory, Lesson, UserLessonResponse } from '../../services/api';
import DOMPurify from 'dompurify';
import { Menu, ArrowLeft, ChevronDown, ChevronUp, ChevronRight, CheckCircle, Edit, ChevronLeft, ArrowRight, Filter, Lock, Crown } from 'lucide-react';
import LessonNotes from './LessonNotes';
import LoadingScreen from './common/LoadingScreen';
import UpgradeModal from './common/UpgradeModal';
import { useAuth } from '../AuthProvider';
import { useUserCourseAccess } from '../UserCourseAccessProvider';
import { Button } from '../ui/Button';

interface LessonData extends Lesson {
  is_completed: boolean;
  confidence_level: string | null;
  content: string | null;
}

interface LessonsProps {
  courseId: string;
  userId: string;
  item_id?: string;
  lessonId?: string;
}

const LOCAL_STORAGE_KEY = 'lessonNotes';

const Lessons: React.FC<LessonsProps> = React.memo(({ courseId, userId, item_id, lessonId: propLessonId }) => {
  const { lessonId: urlLessonId } = useParams<{ lessonId?: string }>();
  const navigate = useNavigate();
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [specialOfferProductId, setSpecialOfferProductId] = useState<string | undefined>(undefined);
  
  // Replace useExtendedUser with useAuth
  const { user, loading: authLoading } = useAuth();
  const { userCourseAccesses, isLoading: accessLoading } = useUserCourseAccess();
  const queryClient = useQueryClient();
  const notesRef = useRef<HTMLDivElement>(null);
  const MemoizedLessonNotes = React.memo(LessonNotes);

  // State management
  const [selectedLessonId, setSelectedLessonId] = useState<string | null>(null);
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());
  const [showCategoryMenu, setShowCategoryMenu] = useState(false);
  const [selectedConfidenceLevel, setSelectedConfidenceLevel] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [unsavedNotes, setUnsavedNotes] = useState<Record<string, string>>({});
  const [activeFilters, setActiveFilters] = useState<Set<string>>(new Set(['guessing', 'maybe', 'confident', 'not_rated']));

  // Update the lessons query key to include userId
  const lessonsQueryKey = ['lessons', courseId, userId, 'completionStatus'];

  // Fetch lesson categories and lessons using React Query
  const { data: lessonCategories = [], isLoading: categoriesLoading } = useQuery<LessonCategory[]>(
    ['lessonCategories', courseId],
    () => api.getLessonCategories(courseId),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  const { data: lessons = [], isLoading: lessonsLoading } = useQuery<LessonData[]>(
    lessonsQueryKey,
    () => api.getLessons(courseId, userId),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );

  // Combined loading state
  const isLoading = authLoading || accessLoading || categoriesLoading || lessonsLoading;

  console.log('Hook states:', {
    authLoading,
    accessLoading,
    categoriesLoading,
    lessonsLoading,
    hasUser: !!user,
    userId: user?.id
  });

  // Load unsaved notes from local storage
  useEffect(() => {
    const storedNotes = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedNotes) {
      setUnsavedNotes(JSON.parse(storedNotes));
    }
  }, []);

  // Save unsaved notes to local storage
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(unsavedNotes));
  }, [unsavedNotes]);

  // New function to process URLs
  const processUrls = useCallback((content: string) => {
    return content.replace(/https:\/\/app\.keslercpareview\.com\/course\/{{courseId}}/g, `https://app.keslercpareview.com/course/${courseId}`);
  }, [courseId]);
  
  // Memoize selected lesson
  const selectedLesson = useMemo(() => 
    lessons.find(l => l.id === selectedLessonId) || null,
    [lessons, selectedLessonId]
  );

  // Effects for setting initial selected lesson and confidence level
  useEffect(() => {
    const lessonIdToUse = urlLessonId || propLessonId;
    if (lessonIdToUse && lessonIdToUse !== selectedLessonId) {
      setSelectedLessonId(lessonIdToUse);
      const lesson = lessons.find(l => l.id === lessonIdToUse);
      if (lesson) {
        setSelectedConfidenceLevel(lesson.confidence_level);
      }
    }
  }, [urlLessonId, propLessonId, lessons, selectedLessonId]);

  useEffect(() => {
    if (selectedLesson) {
      setSelectedConfidenceLevel(selectedLesson.confidence_level);
    }
  }, [selectedLesson]);

  // Memoize category completion status
  const categoryCompletionStatus = useMemo(() => {
    const status: Record<string, { completed: number, total: number }> = {};
    lessons.forEach(lesson => {
      if (lesson.lesson_category_id) {
        if (!status[lesson.lesson_category_id]) {
          status[lesson.lesson_category_id] = { completed: 0, total: 0 };
        }
        status[lesson.lesson_category_id].total++;
        if (lesson.is_completed) {
          status[lesson.lesson_category_id].completed++;
        }
      }
    });
    return status;
  }, [lessons]);

    // Memoize the sorted lessons array
  // This array will be sorted first by category_number, then by sequence_number
  const sortedLessons = useMemo(() => {
    return [...lessons].sort((a, b) => {
      // First, sort by category_number
      const categoryA = lessonCategories.find(cat => cat.id === a.lesson_category_id);
      const categoryB = lessonCategories.find(cat => cat.id === b.lesson_category_id);
      if (categoryA && categoryB) {
        if (categoryA.category_number !== categoryB.category_number) {
          return categoryA.category_number - categoryB.category_number;
        }
      }
      // If categories are the same or not found, sort by sequence_number
      return (a.sequence_number || 0) - (b.sequence_number || 0);
    });
  }, [lessons, lessonCategories]);

  const handleNoteChange = useCallback((lessonId: string, content: string) => {
    setUnsavedNotes(prev => ({
      ...prev,
      [lessonId]: content
    }));
  }, []);

  const handleNoteSave = useCallback(async (lessonId: string, content: string) => {
    if (!userId || !courseId) return;
  
    try {
      await api.updateNote(null, { content }, userId, courseId, null, lessonId, null);
      setUnsavedNotes(prev => {
        const newUnsavedNotes = { ...prev };
        delete newUnsavedNotes[lessonId];
        return newUnsavedNotes;
      });
      queryClient.invalidateQueries(['lessonNote', userId, courseId, lessonId]);
    } catch (err) {
      console.error('Failed to save note:', err);
      setError('Failed to save note. Please try again.');
    }
  }, [userId, courseId, queryClient]);


  // Handlers
  const handleLessonClick = useCallback((lessonId: string) => {
    setSelectedLessonId(lessonId);
    setShowCategoryMenu(false);
    navigate(`/course/${courseId}/lessons/${lessonId}`, { replace: true });
  }, [courseId, navigate]);

  const handleBackToLessons = useCallback(() => {
    setSelectedLessonId(null);
    setShowCategoryMenu(false);
    setSelectedConfidenceLevel(null);
    navigate(`/course/${courseId}/lessons`, { replace: true });
  }, [courseId, navigate]);

  const handleCategoryClick = useCallback((categoryId: string) => {
    setExpandedCategories(prev => {
      const newSet = new Set(prev);
      if (newSet.has(categoryId)) {
        newSet.delete(categoryId);
      } else {
        newSet.add(categoryId);
      }
      return newSet;
    });
  }, []);

  // Update handleConfidenceLevelChange to immediately update the local cache
  const handleConfidenceLevelChange = useCallback(async (level: string) => {
    if (!selectedLesson || !userId || !courseId) return;

    // Optimistically update the cache
    queryClient.setQueryData<LessonData[]>(lessonsQueryKey, (oldData) => {
      if (!oldData) return [];
      return oldData.map(lesson =>
        lesson.id === selectedLesson.id ? { ...lesson, confidence_level: level } : lesson
      );
    });

    setSelectedConfidenceLevel(level);

    try {
      await api.updateLessonStatus(userId, courseId, selectedLesson.id, selectedLesson.is_completed, level);
      // If the API call is successful, we don't need to do anything else
      // as we've already updated the cache
    } catch (err) {
      console.error('Failed to update confidence level:', err);
      setError('Failed to update confidence level. Please try again.');
      
      // Revert the optimistic update in case of an error
      queryClient.setQueryData<LessonData[]>(lessonsQueryKey, (oldData) => {
        if (!oldData) return [];
        return oldData.map(lesson =>
          lesson.id === selectedLesson.id ? { ...lesson, confidence_level: selectedConfidenceLevel } : lesson
        );
      });
    }
  }, [selectedLesson, userId, courseId, queryClient, lessonsQueryKey, selectedConfidenceLevel]);

  // Update handleLessonCompletion to also update confidence_level in the cache
  const handleLessonCompletion = useCallback(async (lessonId: string, isCompleted: boolean) => {
    if (!userId || !courseId || !selectedConfidenceLevel) return;

    // Optimistically update the cache
    queryClient.setQueryData<LessonData[]>(lessonsQueryKey, (oldData) => {
      if (!oldData) return [];
      return oldData.map(lesson =>
        lesson.id === lessonId ? { ...lesson, is_completed: isCompleted, confidence_level: selectedConfidenceLevel } : lesson
      );
    });

    try {
      await api.updateLessonStatus(userId, courseId, lessonId, isCompleted, selectedConfidenceLevel);
      // If the API call is successful, we don't need to do anything else
      // as we've already updated the cache
    } catch (err) {
      console.error('Failed to update lesson status:', err);
      setError('Failed to update lesson status. Please try again.');
      
      // Revert the optimistic update in case of an error
      queryClient.setQueryData<LessonData[]>(lessonsQueryKey, (oldData) => {
        if (!oldData) return [];
        return oldData.map(lesson =>
          lesson.id === lessonId ? { ...lesson, is_completed: !isCompleted } : lesson
        );
      });
    }
  }, [userId, courseId, selectedConfidenceLevel, queryClient, lessonsQueryKey]);

  const scrollToNotes = useCallback(() => {
    notesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);



  // Update getNextLesson to use sortedLessons
  const getNextLesson = useCallback((): LessonData | null => {
    if (!selectedLesson) return null;
    const currentIndex = sortedLessons.findIndex(lesson => lesson.id === selectedLesson.id);
    return sortedLessons[currentIndex + 1] || null;
  }, [selectedLesson, sortedLessons]);

  const handleNextLesson = useCallback(() => {
    const nextLesson = getNextLesson();
    if (nextLesson) {
      handleLessonClick(nextLesson.id);
    }
  }, [getNextLesson, handleLessonClick]);

  // Update getPreviousLesson to use sortedLessons
  const getPreviousLesson = useCallback((): LessonData | null => {
    if (!selectedLesson) return null;
    const currentIndex = sortedLessons.findIndex(lesson => lesson.id === selectedLesson.id);
    return sortedLessons[currentIndex - 1] || null;
  }, [selectedLesson, sortedLessons]);

  const handlePreviousLesson = useCallback(() => {
    const previousLesson = getPreviousLesson();
    if (previousLesson) {
      handleLessonClick(previousLesson.id);
    }
  }, [getPreviousLesson, handleLessonClick]);

  const toggleAllCategories = useCallback(() => {
    if (expandedCategories.size === lessonCategories.length) {
      setExpandedCategories(new Set());
    } else {
      setExpandedCategories(new Set(lessonCategories.map(cat => cat.id)));
    }
  }, [expandedCategories, lessonCategories]);

  const handleBackToStudyTask = useCallback(() => {
    if (item_id) {
      navigate(`/course/${courseId}/study-task/${item_id}`);
    }
  }, [item_id, navigate, courseId]);

 

    // Fetch user lesson responses
    const { data: userLessonResponses = [] } = useQuery<UserLessonResponse[]>(
      ['userLessonResponses', courseId, userId],
      () => api.getUserLessonResponse(userId, courseId),
      {
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 10 * 60 * 1000, // 10 minutes
      }
    );
  
    // Update lessonConfidenceLevels to use the latest data from the cache
    const lessonConfidenceLevels = useMemo(() => {
      const cachedLessons = queryClient.getQueryData<LessonData[]>(lessonsQueryKey) || [];
      const map = new Map<string, string>();
      cachedLessons.forEach(lesson => {
        map.set(lesson.id, lesson.confidence_level || 'not_rated');
      });
      return map;
    }, [queryClient, lessonsQueryKey]);
  
    // Function to toggle filters
    const toggleFilter = useCallback((filter: string) => {
      setActiveFilters(prev => {
        const newFilters = new Set(prev);
        if (newFilters.has(filter)) {
          newFilters.delete(filter);
        } else {
          newFilters.add(filter);
        }
        return newFilters;
      });
    }, []);
  
        // Helper function to check if a category has visible lessons
    const categoryHasVisibleLessons = useCallback((categoryId: string) => {
      return lessons.some(lesson => 
        lesson.lesson_category_id === categoryId && 
        activeFilters.has(lessonConfidenceLevels.get(lesson.id) || 'not_rated')
      );
    }, [lessons, activeFilters, lessonConfidenceLevels]);

    // Placeholder message component
    const NoLessonsPlaceholder = () => (
      <div className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg text-center">
        <p className="text-gray-600 dark:text-gray-400">
          No lessons available with current filtering settings
        </p>
      </div>
    );

  // Update getColor function to use the latest data from the cache
  const getColor = useCallback((lessonId: string) => {
    const lesson = queryClient.getQueryData<LessonData[]>(lessonsQueryKey)?.find(l => l.id === lessonId);
    if (!lesson) return 'bg-blue-100 dark:bg-gray-700';
    
    if (!lesson.is_completed) return 'bg-blue-100 dark:bg-gray-700';
    switch (lesson.confidence_level) {
      case 'confident': return 'bg-green-500';
      case 'maybe': return 'bg-orange-500';
      case 'guessing': return 'bg-yellow-500';
      default: return 'bg-blue-100 dark:bg-gray-700';
    }
  }, [queryClient, lessonsQueryKey]);
  
    const getFilterButtonColor = (level: string, isActive: boolean) => {
      const baseClasses = 'px-4 py-2 rounded-full transition-colors duration-200 ';
      if (isActive) {
        switch (level) {
          case 'confident': return `${baseClasses} bg-green-500 text-white`;
          case 'maybe': return `${baseClasses} bg-orange-500 text-white`;
          case 'guessing': return `${baseClasses} bg-yellow-500 text-white`;
          case 'not_rated': return `${baseClasses} bg-blue-500 text-white`;
          default: return `${baseClasses} bg-gray-500 text-white`;
        }
      } else {
        switch (level) {
          case 'confident': return `${baseClasses} bg-transparent text-green-500 border border-green-500`;
          case 'maybe': return `${baseClasses} bg-transparent text-orange-500 border border-orange-500`;
          case 'guessing': return `${baseClasses} bg-transparent text-yellow-500 border border-yellow-500`;
          case 'not_rated': return `${baseClasses} bg-transparent text-blue-500 border border-blue-500`;
          default: return `${baseClasses} bg-transparent text-gray-500 border border-gray-500`;
        }
      }
    };


    // Getting User Tier Access
    // Update the currentCourseAccess calculation
    const currentCourseAccess = useMemo(() => {
      if (!userCourseAccesses || !Array.isArray(userCourseAccesses)) return null;
      return userCourseAccesses.find(access => access.course_id === courseId) || null;
    }, [userCourseAccesses, courseId]);
    
    // Lesson Content Lock
    const isContentLocked = useCallback((lesson: LessonData) => {
      console.log('Checking if content is locked:', { userCourseAccesses, courseId, lesson });
      if (!userCourseAccesses || userCourseAccesses.length === 0) return true;
      
      const currentAccess = userCourseAccesses.find(access => access.course_id === courseId);
      console.log('Current access:', currentAccess);
      
      if (!currentAccess) {
        console.warn(`No access found for course ID: ${courseId}`);
        return true;
      }
      
      const freeTrialTierId = '337eeb06-1040-43d5-ad6d-5316e058aec9';
      const mentorOnlyTierId = '25f83b83-0a8a-4599-b387-c8870d79b214';
      const isLimitedTier = currentAccess.tier_id === freeTrialTierId || currentAccess.tier_id === mentorOnlyTierId;
      
      console.log('Is limited tier:', isLimitedTier);
      return isLimitedTier && !lesson.free_trial_content;
    }, [userCourseAccesses, courseId]);

    //UPGRADE POPUP MODAL

  // Update showUpgradeButton to use currentCourseAccess
  const showUpgradeButton = useMemo(() => {
    console.log('Checking if upgrade button should show:', { userCourseAccesses, courseId });
    if (!userCourseAccesses || userCourseAccesses.length === 0) return false;
    
    const currentAccess = userCourseAccesses.find(access => access.course_id === courseId);
    console.log('Current access:', currentAccess);
    
    if (!currentAccess) {
      console.warn(`No access found for course ID: ${courseId}`);
      return false;
    }
    
    const freeTrialTierId = '337eeb06-1040-43d5-ad6d-5316e058aec9';
    const mentorOnlyTierId = '25f83b83-0a8a-4599-b387-c8870d79b214';
    const shouldShow = currentAccess.tier_id === freeTrialTierId || currentAccess.tier_id === mentorOnlyTierId;
    
    console.log('Should show upgrade button:', shouldShow);
    return shouldShow;
  }, [userCourseAccesses, courseId]);

    useEffect(() => {
      const checkSpecialOffer = async () => {
        if (user && user.last_sign_in_at) {
          const lastSignIn = new Date(user.last_sign_in_at);
          const thirtyDaysAgo = new Date();
          thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  
          if (lastSignIn < thirtyDaysAgo) {
            setSpecialOfferProductId("special_offer_product_id");
            setIsUpgradeModalOpen(true);
          }
        }
      };
  
      checkSpecialOffer();
    }, [user]);
  
    const handleUpgradeClick = useCallback(() => {
      console.log('Upgrade button clicked');
      setIsUpgradeModalOpen(prev => {
        console.log('Setting isUpgradeModalOpen to:', !prev);
        return !prev;
      });
    }, []);
  
    const handleCloseUpgradeModal = useCallback(() => {
      console.log('Closing upgrade modal');
      setIsUpgradeModalOpen(false);
    }, []);
  
    // Debug effect
    useEffect(() => {
      console.log('isUpgradeModalOpen changed:', isUpgradeModalOpen);
    }, [isUpgradeModalOpen]);

  // Memoize the renderCategories function
  const renderCategories = useMemo(() => {
    const render = (parentCategoryId: string | null = null, depth: number = 0) => {
      const categories = lessonCategories.filter(cat => cat.parent_lesson_category_id === parentCategoryId);

      return categories.map(category => (
        <div key={category.id} className="mb-4">
          <div
            className={`
              ${depth === 0 ? 'bg-primary-blue dark:bg-dark-secondary' : 'bg-gray-100 dark:bg-gray-800'}
              cursor-pointer transition-colors duration-200 rounded-lg shadow-md
            `}
            onClick={() => handleCategoryClick(category.id)}
          >
            <div className="flex justify-between items-center p-4">
              <span className={`font-medium ${depth === 0 ? 'text-white' : 'text-gray-800 dark:text-gray-200'} flex items-center`}>
                {expandedCategories.has(category.id) ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                <span className="ml-2">{category.name}</span>
              </span>
              {depth === 0 && category.id && categoryCompletionStatus[category.id] && (
                <span className="text-sm bg-primary-yellow text-black font-medium px-3 py-1 rounded-full ml-2 whitespace-nowrap">
                  {categoryCompletionStatus[category.id].completed}/{categoryCompletionStatus[category.id].total}
                </span>
              )}
            </div>
          </div>
          {expandedCategories.has(category.id) && (
            <div className="ml-4 mt-2 space-y-2">
              {render(category.id, depth + 1)}
              {categoryHasVisibleLessons(category.id) ? (
                lessons
                  .filter(lesson => lesson.lesson_category_id === category.id)
                  .map((lesson) => {
                    const confidenceLevel = lessonConfidenceLevels.get(lesson.id) || 'not_rated';
                    if (!activeFilters.has(confidenceLevel)) return null;
                    const isLocked = isContentLocked(lesson);
                    return (
                      <div
                        key={lesson.id}
                        className={`
                          p-4 rounded-lg cursor-pointer transition-colors duration-200
                          ${getColor(lesson.id)}
                          ${lesson.is_completed ? 'text-white' : 'text-gray-700 dark:text-gray-300 hover:bg-blue-200 dark:hover:bg-gray-600'}
                        `}
                        onClick={() => handleLessonClick(lesson.id)}
                      >
                        <div className="flex justify-between items-center">
                          <span>{lesson.name}</span>
                          <div className="flex items-center">
                            {isLocked && (
                              <Lock size={16} className="mr-2 text-gray-500" />
                            )}
                            {lesson.is_completed && (
                              <CheckCircle size={20} className="text-white" />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <NoLessonsPlaceholder />
              )}
            </div>
          )}
        </div>
      ));
    };

    return render;
  }, [lessonCategories, expandedCategories, lessons, handleLessonClick, lessonConfidenceLevels, activeFilters, getColor, handleCategoryClick, categoryHasVisibleLessons, isContentLocked]);
  
  // Update renderFilterButtons to use the new color scheme
  const renderFilterButtons = useCallback(() => (
    <div className="flex flex-wrap gap-2 mb-4">
      {['Guessing', 'Maybe', 'Confident', 'Not Rated'].map((level) => {
        const filterKey = level.toLowerCase().replace(' ', '_');
        return (
          <button
            key={filterKey}
            onClick={() => toggleFilter(filterKey)}
            className={`${getFilterButtonColor(filterKey, activeFilters.has(filterKey))} flex items-center`}
            title={`Filter ${level} lessons`}
          >
            <Filter size={16} className="mr-2" />
            {level}
          </button>
        );
      })}
    </div>
  ), [activeFilters, toggleFilter, getFilterButtonColor]);

  const renderCategoryMenu = useCallback(() => (
    <div className="mb-6 p-6 bg-grey-50 dark:bg-dark-secondary rounded-lg">

      <h3 className="text-xl font-bold mb-4 text-light-text dark:text-dark-text">Lesson Categories</h3>
      <ul className="w-full space-y-2">
        {renderCategories()}
      </ul>
    </div>
  ), [handleBackToLessons, renderCategories]);

  const renderLessonContent = useCallback((lesson: LessonData) => {
    const isLocked = isContentLocked(lesson);
  
    return (
      <div className="bg-white dark:bg-gray-900 p-4 rounded-lg shadow-md">
        <div className="flex flex-col space-y-2 mb-8">
          {/* Navigation buttons row */}
          <div className="flex items-center justify-between">
            {/* Back to Lessons Button - Left Aligned */}
            <button
              onClick={handleBackToLessons}
              className="flex items-center text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-200"
            >
              <ChevronLeft size={24} />
              <span className="ml-2 text-lg">Back</span>
            </button>
    
            {/* Other Buttons - Right Aligned */}
            <div className="flex items-center space-x-4">
              <button
                onClick={scrollToNotes}
                className="w-12 h-12 flex items-center justify-center rounded-full bg-yellow-100 dark:bg-yellow-900 text-yellow-700 dark:text-yellow-200 hover:bg-yellow-200 dark:hover:bg-yellow-800 transition-colors duration-200"
                title="Go to Notes"
              >
                <Edit size={24} />
              </button>
              <button
                onClick={() => setShowCategoryMenu(!showCategoryMenu)}
                className="w-12 h-12 flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200"
                title="Toggle Category Menu"
              >
                <Menu size={24} />
              </button>
              <button
                onClick={handlePreviousLesson}
                disabled={!getPreviousLesson()}
                className="w-12 h-12 sm:w-auto sm:h-auto sm:px-6 sm:py-3 flex items-center justify-center rounded-full sm:rounded-md bg-blue-500 dark:bg-blue-700 text-white hover:bg-blue-600 dark:hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                title="Previous Lesson"
              >
                <ArrowLeft size={24} />
              </button>
              <button
                onClick={handleNextLesson}
                disabled={!getNextLesson()}
                className="w-12 h-12 sm:w-auto sm:h-auto sm:px-6 sm:py-3 flex items-center justify-center rounded-full sm:rounded-md bg-blue-500 dark:bg-blue-700 text-white hover:bg-blue-600 dark:hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                title="Next Lesson"
              >
                <ArrowRight size={24} />
              </button>
            </div>
          </div>
  
          {/* Category menu */}
          {showCategoryMenu && (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
              {renderCategoryMenu()}
            </div>
          )}
  
          {/* Lesson title row */}
          <h3 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 dark:text-white text-center py-4 sm:py-6 px-4 sm:px-6 bg-gray-50 dark:bg-gray-700">
            {lesson.name}
          </h3>
  
           {/* Lesson content */}
            <div className="p-2 sm:p-2 md:p-8 lg:p-12 relative min-h-[500px]">
              <div className={`prose dark:prose-invert prose-sm sm:prose-base lg:prose-lg max-w-none ${isLocked ? 'blur-sm' : ''}`}>
                {isLocked ? (
                  <div className="text-center">
                    <h3>This content is hidden</h3>
                    <p>You need to upgrade to see this content</p>
                    <p>Click the upgrade button now to add a full membership to Kesler CPA Review</p>
                  </div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(processUrls(lesson.content || '')) }} />
                )}
              </div>
              {isLocked && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                  <div className="text-center text-white p-6 rounded-lg">
                    <Lock size={48} className="mx-auto mb-4" />
                    <p className="text-xl font-semibold mb-4">Content is locked</p>
                    <p className="mb-6">Upgrade your account to access this lesson.</p>
                    <div className="flex justify-center">
                      <Button
                        onClick={handleUpgradeClick}
                        className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center"
                      >
                        <Crown size={16} className="mr-2" /> {/* Add Crown icon */}
                        Upgrade Now
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
        </div>
      {/* Confidence rating and completion buttons */}
      {!isLocked && (
        <div className="mt-6 sm:mt-8 p-4 sm:p-6 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-md">
          <h4 className="text-lg sm:text-xl font-semibold mb-4 sm:mb-6 text-gray-800 dark:text-white text-center">
            Rate your confidence on this topic
          </h4>
          <div className="flex flex-col sm:flex-row justify-center gap-3 sm:gap-4 mb-4 sm:mb-6">
            {['Guessing', 'Maybe', 'Confident'].map((level) => (
              <button
                key={level}
                onClick={() => handleConfidenceLevelChange(level.toLowerCase())}
                className={`px-4 sm:px-6 py-2 sm:py-3 rounded-md text-sm sm:text-base font-medium transition-colors duration-200 ${
                  selectedConfidenceLevel === level.toLowerCase()
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-400 dark:hover:bg-gray-600'
                }`}
              >
                {level}
              </button>
            ))}
          </div>
          <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 items-stretch">
            <button
              onClick={() => handleLessonCompletion(lesson.id, !lesson.is_completed)}
              disabled={!selectedConfidenceLevel}
              className={`flex-grow flex items-center justify-center px-4 sm:px-6 py-3 sm:py-4 rounded-md text-sm sm:text-base font-medium transition-colors duration-200 ${
                lesson.is_completed
                  ? 'bg-green-500 text-white hover:bg-green-600'
                  : 'bg-blue-600 text-white hover:bg-blue-700'
              } disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              {lesson.is_completed ? (
                <>
                  <CheckCircle size={20} className="mr-2" />
                  Completed
                </>
              ) : (
                'Mark as Completed'
              )}
            </button>
            {item_id && (
              <button
                onClick={handleBackToStudyTask}
                className="flex-grow sm:flex-grow-0 flex items-center justify-center px-4 sm:px-6 py-3 sm:py-4 rounded-md text-sm sm:text-base font-medium bg-yellow-500 text-black hover:bg-yellow-600 transition-colors duration-200"
              >
                <ArrowLeft className="mr-2" size={20} />
                Back To Study Task
              </button>
            )}
          </div>
        </div>
      )}
      {/* Notes section */}
      {!isLocked && (
        <div ref={notesRef} className="mt-6 sm:mt-8 bg-white dark:bg-gray-500 rounded-lg shadow-md overflow-hidden mx-4">
          <MemoizedLessonNotes
            courseId={courseId}
            userId={userId}
            lessonId={lesson.id}
            lessonName={lesson.name}
            lessonCategory={lessonCategories.find(cat => cat.id === lesson.lesson_category_id) || { 
              id: '', 
              course_id: '', 
              name: '', 
              parent_lesson_category_id: null,
              category_number: -1
            }}
            unsavedNote={unsavedNotes[lesson.id]}
            onNoteChange={(content) => handleNoteChange(lesson.id, content)}
            onNoteSave={(content) => handleNoteSave(lesson.id, content)}
          />
        </div>
      )}
    </div>
  );
}, [
  handleBackToLessons,
  scrollToNotes,
  setShowCategoryMenu,
  showCategoryMenu,
  handlePreviousLesson,
  getPreviousLesson,
  handleNextLesson,
  getNextLesson,
  renderCategoryMenu,
  isContentLocked,
  processUrls,
  handleUpgradeClick,
  handleConfidenceLevelChange,
  selectedConfidenceLevel,
  handleLessonCompletion,
  item_id,
  handleBackToStudyTask,
  courseId,
  userId,
  lessonCategories,
  unsavedNotes,
  handleNoteChange,
  handleNoteSave
]);
  
  if (isLoading || accessLoading) {
    return <LoadingScreen message="Loading your Lessons..." subMessage="This may take a few moments" />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center p-8 bg-red-100 dark:bg-red-900 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-red-700 dark:text-red-300 mb-4">Error</h2>
          <p className="text-red-600 dark:text-red-400">{error}</p>
        </div>
      </div>
    );
  }

  // Update the main return statement with enhanced UI
  return (
    <div className="max-w-7xl mx-auto space-y-8 p-2">
      {!selectedLesson && (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
          <div className="p-6">
            {/* Header section */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 mb-6">
              <div>
                <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 dark:text-gray-200 mb-2">
                  Recommended Lessons
                </h2>
              </div>
              {/* Flex container to align buttons side by side */}
            <div className="flex space-x-4">
                {showUpgradeButton && (
                  <Button
                  onClick={handleUpgradeClick}
                  className="bg-primary-orange hover:bg-primary-orange-hover text-white flex items-center px-4 py-2 text-sm font-medium"
                >
                  <Crown size={16} className="mr-2" /> {/* Add Crown icon */}
                  Upgrade
                </Button>
              )}
              <button
                onClick={toggleAllCategories}
                className="flex items-center px-4 py-2 text-sm font-medium rounded-md bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-200 hover:bg-blue-200 dark:hover:bg-blue-800 transition-colors duration-200"
                title={
                  expandedCategories.size === lessonCategories.length
                    ? 'Collapse all categories'
                    : 'Expand all categories'
                }
              >
                {expandedCategories.size === lessonCategories.length ? (
                  <>
                    <ChevronUp size={16} className="mr-2" /> Collapse All
                  </>
                ) : (
                  <>
                    <ChevronDown size={16} className="mr-2" /> Expand All
                  </>
                )}
              </button>
              </div>
              </div>
            
            {/* Filter section */}
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
                Filter Lessons
              </h3>
              {renderFilterButtons()}
            </div>

            {/* Categories section */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
                Lesson Categories
              </h3>
              {renderCategories()}
            </div>
          </div>
        </div>
      )}

      {selectedLesson && (
          <div className="bg-white dark:bg-gray-900 rounded-lg shadow-md overflow-hidden">
            {renderLessonContent(selectedLesson)}
          </div>
          )}
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={handleCloseUpgradeModal}
      />
    </div>
  );
});


export default React.memo(Lessons);
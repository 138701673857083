// src/components/course/common/switch-course/SwitchConfirmationModal.tsx

import React, { useContext, useEffect } from 'react';
import { Modal } from '../../../ui/Modal';
import { Button } from '../../../ui/Button';
import { ModalContext } from '../../../../App';
import { ArrowRight, AlertTriangle } from 'lucide-react';

interface SwitchConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  switchesAvailable: number;
  fromCourse: string;
  toCourse: string;
}

const SwitchConfirmationModal: React.FC<SwitchConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  switchesAvailable,
  fromCourse,
  toCourse
}) => {
  const { setIsModalOpen } = useContext(ModalContext);

  useEffect(() => {
    setIsModalOpen(isOpen);
    return () => setIsModalOpen(false);
  }, [isOpen, setIsModalOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  const handleConfirm = async () => {
    try {
      await onConfirm();
      handleClose();
    } catch (error) {
      console.error('Error during course switch:', error);
      // Error handling will be managed by the parent component
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl w-full max-w-md mx-auto">
        <div className="flex items-center justify-center mb-4 text-yellow-500">
          <AlertTriangle size={32} />
        </div>
        
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white text-center">
          Confirm Course Switch
        </h2>

        <div className="mb-6">
          <div className="flex items-center justify-center space-x-3 text-gray-700 dark:text-gray-300">
            <span className="font-medium">{fromCourse}</span>
            <ArrowRight className="w-5 h-5" />
            <span className="font-medium">{toCourse}</span>
          </div>
        </div>

        <p className="mb-6 text-base text-gray-600 dark:text-gray-300">
          This action will use 1 switch credit. You currently have {switchesAvailable} switch{switchesAvailable !== 1 ? 'es' : ''} available.
          {switchesAvailable === 1 && (
            <span className="block mt-2 text-yellow-500 text-sm">
              ⚠️ This is your last available switch
            </span>
          )}
        </p>

        <div className="flex flex-col space-y-3">
          <Button
            onClick={handleConfirm}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition-colors duration-200"
          >
            Confirm Switch
          </Button>
          <Button
            onClick={handleClose}
            variant="outline"
            className="w-full bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 hover:text-gray-900 dark:hover:text-white"
          >
            Cancel
          </Button>
        </div>

        <p className="mt-4 text-xs text-gray-500 dark:text-gray-400 text-center">
          Note: Switches replenish every 30 days with an active subscription
        </p>
      </div>
    </Modal>
  );
};

export default SwitchConfirmationModal;
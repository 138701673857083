import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, SPStudyPlanItem } from '../../services/api';
import { ArrowRight } from 'lucide-react';
import DOMPurify from 'dompurify';

interface StudyPlanCountdownProps {
  examDate: string | null;
  startDate: string | null;
  planId: string;
  userId: string;
  courseId: string;
  studyPlanItems: SPStudyPlanItem[];
  recommendedWeeklyHours: number;
  isPrimaryBlueprint: boolean;
}


interface WeekRange {
  start: Date;
  end: Date;
  availableMinutes: number;
}

interface TaskDueDate {
  start: Date;
  end: Date;
  weekNumber: number;
}

const StudyPlanCountdown: React.FC<StudyPlanCountdownProps> = ({
  examDate,
  startDate,
  planId,
  userId,
  courseId,
  studyPlanItems,
  recommendedWeeklyHours,
  isPrimaryBlueprint
}) => {
  const [daysLeft, setDaysLeft] = useState(0);
  const [studyTasks, setStudyTasks] = useState({ completed: 0, total: 0 });
  const [formattedExamDate, setFormattedExamDate] = useState('');
  const [studyType, setStudyType] = useState<'brand_new' | 'retake'>('brand_new');
  const [nextTask, setNextTask] = useState<SPStudyPlanItem | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const sanitizeHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const calculateWeeks = (startDate: Date, examDate: Date, recommendedWeeklyHours: number): WeekRange[] => {
    const weeks: WeekRange[] = [];
    let currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);
  
    while (currentDate <= examDate) {
      const weekStart = new Date(currentDate);
      const weekEnd = new Date(currentDate);
      weekEnd.setDate(weekEnd.getDate() + 6);
  
      const availableMinutes = recommendedWeeklyHours * 60;
  
      if (weekEnd > examDate) {
        const daysInLastWeek = Math.floor((examDate.getTime() - weekStart.getTime()) / (24 * 60 * 60 * 1000)) + 1;
        const lastWeekMinutes = Math.floor((daysInLastWeek / 7) * availableMinutes);
        weeks.push({ start: weekStart, end: new Date(examDate), availableMinutes: lastWeekMinutes });
      } else {
        weeks.push({ start: weekStart, end: weekEnd, availableMinutes });
      }
  
      currentDate.setDate(currentDate.getDate() + 7);
    }
  
    return weeks;
  };

  const getSequenceNumber = (task: SPStudyPlanItem, isPrimaryBlueprint: boolean): number => {
    return isPrimaryBlueprint ? task.blueprint_topic_sequence : task.primary_sequence;
  };

  const createTaskDueDate = (week: WeekRange, weekNumber: number): TaskDueDate => {
    return {
      start: week.start,
      end: week.end,
      weekNumber: weekNumber
    };
  };

  const calculateTaskDueDates = (
    weeks: WeekRange[],
    tasks: SPStudyPlanItem[],
    startDate: Date,
    examDate: Date,
    isPrimaryBlueprint: boolean
  ): Map<string, TaskDueDate> => {
    const taskDueDates = new Map<string, TaskDueDate>();
    let currentWeekIndex = 0;
    let remainingTimeInWeek = weeks[currentWeekIndex].availableMinutes;
  
    const sortedTasks = [...tasks].sort((a, b) => 
      getSequenceNumber(a, isPrimaryBlueprint) - getSequenceNumber(b, isPrimaryBlueprint)
    );
  
    sortedTasks.forEach(task => {
      const estimatedTime = task.estimated_minutes || 0;
  
      while (estimatedTime > remainingTimeInWeek && currentWeekIndex < weeks.length - 1) {
        currentWeekIndex++;
        remainingTimeInWeek = weeks[currentWeekIndex].availableMinutes;
      }
  
      if (currentWeekIndex < weeks.length) {
        taskDueDates.set(task.item_id, createTaskDueDate(weeks[currentWeekIndex], currentWeekIndex + 1));
      } else {
        taskDueDates.set(task.item_id, createTaskDueDate({ start: examDate, end: examDate, availableMinutes: 0 }, weeks.length));
      }
  
      remainingTimeInWeek -= estimatedTime;
    });
  
    return taskDueDates;
  };

  const { weeks, taskDueDates } = useMemo(() => {
    if (!startDate || !examDate) {
      return { weeks: [], taskDueDates: new Map() };
    }
    const startDateObj = new Date(startDate);
    const examDateObj = new Date(examDate);
    const calculatedWeeks = calculateWeeks(startDateObj, examDateObj, recommendedWeeklyHours);
    const calculatedDueDates = calculateTaskDueDates(
      calculatedWeeks,
      studyPlanItems,
      startDateObj,
      examDateObj,
      isPrimaryBlueprint
    );
    return { weeks: calculatedWeeks, taskDueDates: calculatedDueDates };
  }, [startDate, examDate, recommendedWeeklyHours, studyPlanItems, isPrimaryBlueprint]);

  useEffect(() => {
    const calculateDaysLeft = () => {
      if (!examDate) return 0;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const examDay = new Date(examDate);
      examDay.setHours(0, 0, 0, 0);
      const timeDiff = examDay.getTime() - today.getTime();
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    };

    const formatDate = (dateString: string | null) => {
      if (!dateString) return 'Not set';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.error('Invalid date:', dateString);
        return 'Invalid Date';
      }
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    const fetchStudyType = async () => {
      try {
        const type = await api.getStudyType(userId, courseId);
        setStudyType(type.study_type);
      } catch (error) {
        console.error('Error fetching study type:', error);
        setError('Failed to fetch study type');
      }
    };

    const fetchStudyTasks = async () => {
      try {
        const tasks = await api.getStudyTasksProgress(planId);
        setStudyTasks(tasks);
      } catch (error) {
        console.error('Error fetching study tasks:', error);
        setError('Failed to fetch study tasks');
      }
    };

    const fetchNextIncompleteTask = async () => {
      try {
        const incompleteTask = studyPlanItems.find(task => !task.is_completed);
        setNextTask(incompleteTask || null);
      } catch (error) {
        console.error('Error finding next incomplete task:', error);
        setError('Failed to find next task');
      }
    };

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      await Promise.all([fetchStudyType(), fetchStudyTasks(), fetchNextIncompleteTask()]);
      setIsLoading(false);
    };

    setDaysLeft(calculateDaysLeft());
    setFormattedExamDate(formatDate(examDate));
    fetchData();
  }, [examDate, planId, userId, courseId, studyPlanItems]);

  const handleNextTaskClick = () => {
    if (nextTask) {
      navigate(`/course/${courseId}/study-task/${nextTask.item_id}`);
    }
  };

  const formatDateRange = (start: Date, end: Date): string => {
    const formatDate = (date: Date) => {
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear().toString().slice(-2);
      return `${month}/${day}/${year}`;
    };
  
    return `(${formatDate(start)}-${formatDate(end)})`;
  };

  const renderTaskButton = () => {
    if (studyTasks.completed === studyTasks.total) {
      return (
        <div className="mt-3 md:mt-4 p-2 md:p-3 bg-green-100 dark:bg-green-800 rounded-lg text-xs md:text-sm text-green-800 dark:text-green-100">
          Congrats! You Are Ready To Earn Your KeslerBoost!
        </div>
      );
    }

    if (nextTask) {
      const dueDate = taskDueDates.get(nextTask.item_id);
      return (
        <div className="mt-3 md:mt-4">
          <h4 className="text-sm md:text-base font-semibold mb-1 md:mb-2 text-gray-700 dark:text-gray-300">
            <span className="font-bold">Next Study Task Due:</span>{' '}
            <span className="font-normal">
              {dueDate ? `Week ${dueDate.weekNumber} ${formatDateRange(dueDate.start, dueDate.end)}` : 'Not scheduled'}
            </span>
          </h4>
          <button
            onClick={handleNextTaskClick}
            className="w-full flex items-center justify-between px-2 md:px-3 py-1 md:py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors duration-300 dark:bg-blue-600 dark:hover:bg-blue-700"
          >
            <span className="text-left font-medium truncate text-xs md:text-sm flex-grow mr-2">
              <span dangerouslySetInnerHTML={sanitizeHTML(nextTask.task_name)} />
            </span>
            <ArrowRight size={14} className="md:w-4 md:h-4 flex-shrink-0" />
          </button>
        </div>
      );
    }

    return null;
  };
  if (isLoading) {
    return <div className="text-center text-xs md:text-sm">Loading study plan data...</div>;
  }

  if (error) {
    return <div className="text-center text-xs md:text-sm text-red-500">{error}</div>;
  }

  return (
    <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4 sm:p-6 text-center h-full">
      <h3 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4 text-gray-700 dark:text-gray-300">
        {studyType === 'brand_new' ? 'Countdown to Exam Day' : 'Countdown to Retake Day'}
      </h3>
      <div className="text-7xl sm:text-8xl font-bold mb-2 text-blue-600 dark:text-blue-400">
        {daysLeft}
      </div>
      <div className="text-lg sm:text-xl text-gray-600 dark:text-gray-400">
        {daysLeft === 1 ? 'Day' : 'Days'} Left
      </div>
      <div className="mt-3 sm:mt-4 text-base sm:text-lg text-gray-700 dark:text-gray-300">
        Exam Date: {formattedExamDate}
      </div>
      <div className="mt-2 sm:mt-4 text-base sm:text-lg text-gray-700 dark:text-gray-300">
        Completed Tasks: {studyTasks.completed}/{studyTasks.total}
      </div>
      {renderTaskButton()}
    </div>
  );
};

export default StudyPlanCountdown;
import React from 'react';
import { Switch } from '../../../components/ui/Switch';

interface TestModeToggleProps {
  isTestMode: boolean;
  onToggle: () => void;
  disabled: boolean;
}

const TestModeToggle: React.FC<TestModeToggleProps> = ({ isTestMode, onToggle, disabled }) => {
  return (
    <div className="flex items-center space-x-2">
      <Switch
        checked={isTestMode}
        onChange={onToggle}
        disabled={disabled}
      />
      <label
        htmlFor="test-mode"
        className={`text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-light-text dark:text-dark-text ${disabled ? 'opacity-50' : ''}`}
      >
        Test Mode
      </label>
    </div>
  );
};

export default TestModeToggle;
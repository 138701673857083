import React, { useEffect, useCallback } from 'react';

interface QuizSummaryFilterProps {
  filter: string;
  setFilter: (filter: string) => void;
  counts: {
    all: number;
    correct: number;
    incorrect: number;
    confident: number;
    maybe: number;
    guessing: number;
  };
  currentSummary: any[]; // Replace 'any' with your QuizSummary type
}

const QuizSummaryFilter: React.FC<QuizSummaryFilterProps> = React.memo(({ filter, setFilter, counts }) => {
  console.log('Rendering QuizSummaryFilter with counts:', counts);
  const handleFilterChange = useCallback((newFilter: string) => {
    console.log('Filter changed:', newFilter);
    setFilter(newFilter);
  }, [setFilter]);

  return (
    <div className="mb-4">
      <h3 className="font-bold mb-2 text-light-text dark:text-dark-text">Filter Results</h3>
      <div className="flex flex-wrap space-x-2">
        {['All', 'Correct', 'Incorrect', 'Confident', 'Maybe', 'Guessing'].map((option) => {
          const countKey = option.toLowerCase() as keyof typeof counts;
          const count = counts[countKey] || 0;
          const isDisabled = count === 0 && option !== 'All';
          return (
            <button
              key={option}
              onClick={() => !isDisabled && handleFilterChange(option.toLowerCase())}
              className={`px-3 py-1 rounded mb-2 ${
                filter === option.toLowerCase()
                  ? 'bg-blue-500 text-white'
                  : isDisabled
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
              }`}
              disabled={isDisabled}
            >
              {option} ({count})
            </button>
          );
        })}
      </div>
    </div>
  );
});

export default QuizSummaryFilter;
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface CourseData {
  id: string;
  title: string;
  description: string;
}

interface CourseHomepageProps {
  course: CourseData;
}

function CourseHomepage({ course }: CourseHomepageProps) {
  const navigate = useNavigate();

  const handleNavClick = (path: string) => {
    navigate(path);
  };

  return (
    <div className="space-y-6">
      <p className="text-light-text dark:text-dark-text">{course.description}</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <button onClick={() => handleNavClick('study-plan')} className="bg-light-primary text-white p-4 rounded hover:bg-light-accent dark:bg-dark-primary dark:hover:bg-dark-accent text-center">
          Start Studying
        </button>
        <button onClick={() => handleNavClick('analytics')} className="bg-light-secondary text-light-text p-4 rounded hover:bg-gray-300 dark:bg-dark-secondary dark:text-dark-text dark:hover:bg-gray-600 text-center">
          View Progress
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <button onClick={() => handleNavClick('lessons')} className="bg-purple-500 text-white p-4 rounded hover:bg-purple-600 dark:bg-purple-700 dark:hover:bg-purple-800 text-center">
          Lessons
        </button>
        <button onClick={() => handleNavClick('quiz')} className="bg-yellow-500 text-white p-4 rounded hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700 text-center">
          Quizzes
        </button>
        <button onClick={() => handleNavClick('mock-exam')} className="bg-red-500 text-white p-4 rounded hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700 text-center">
          Mock Exam
        </button>
      </div>

      <div className="bg-light-secondary dark:bg-dark-secondary p-4 rounded">
        <h3 className="font-bold mb-2 text-light-text dark:text-dark-text">Quick Links</h3>
        <ul className="list-disc list-inside">
          <li><button onClick={() => handleNavClick('simulations')} className="text-light-primary hover:underline dark:text-dark-accent">Task-Based Simulations</button></li>
          <li><button onClick={() => handleNavClick('focus-notes')} className="text-light-primary hover:underline dark:text-dark-accent">Focus Notes</button></li>
          <li><button onClick={() => handleNavClick('kesler-cards')} className="text-light-primary hover:underline dark:text-dark-accent">KeslerCards</button></li>
        </ul>
      </div>
    </div>
  );
}

export default CourseHomepage;
import { useQuery, useQueryClient, UseMutationResult, useMutation } from 'react-query';
import { api } from '../services/api';

interface Category {
  id: string;
  parent_question_category_name: string;
  sub_question_category_name: string;
  total_questions: number;
  unanswered_questions: number;
  correct_percentage: number;
}

interface CategoryStats {
  category_id: string;
  total_questions: number;
  unanswered_questions: number;
  correct_percentage: number;
}

export const useQuizData = (courseId: string, userId: string | undefined) => {
  const queryClient = useQueryClient();

  const fetchCategories = async () => {
    if (!userId) return null;

    const data = await api.getQuestionCategories(courseId);
    const allCategoryIds = data.map(category => category.id);
    const stats = await api.getCategoryStats(userId, courseId, allCategoryIds);

    const statsMap = stats.reduce((acc, stat) => {
      acc[stat.category_id] = stat;
      return acc;
    }, {} as { [key: string]: CategoryStats });

    const structuredCategories: { [key: string]: Category[] } = {};

    for (const category of data) {
      if (!structuredCategories[category.parent_question_category_name]) {
        structuredCategories[category.parent_question_category_name] = [];
      }
      const categoryStats = statsMap[category.id] || {
        total_questions: 0,
        unanswered_questions: 0,
        correct_percentage: 0
      };
      structuredCategories[category.parent_question_category_name].push({
        ...category,
        ...categoryStats
      });
    }

    // Sort categories
    Object.keys(structuredCategories).sort().forEach(key => {
      structuredCategories[key].sort((a, b) => a.sub_question_category_name.localeCompare(b.sub_question_category_name));
    });

    // Calculate parent category stats
    Object.keys(structuredCategories).forEach(key => {
      const parentStats = structuredCategories[key].reduce((acc, curr) => {
        acc.total_questions += curr.total_questions;
        acc.unanswered_questions += curr.unanswered_questions;
        acc.correct_answers += (curr.total_questions - curr.unanswered_questions) * (curr.correct_percentage / 100);
        return acc;
      }, { total_questions: 0, unanswered_questions: 0, correct_answers: 0 });

      const answeredQuestions = parentStats.total_questions - parentStats.unanswered_questions;
      const parentCorrectPercentage = answeredQuestions > 0
        ? (parentStats.correct_answers / answeredQuestions) * 100
        : 0;

      structuredCategories[key].unshift({
        id: key,
        parent_question_category_name: key,
        sub_question_category_name: 'All',
        total_questions: parentStats.total_questions,
        unanswered_questions: parentStats.unanswered_questions,
        correct_percentage: parentCorrectPercentage
      });
    });

    return { structuredCategories, statsMap };
  };

  const { data: categoriesData, isLoading: categoriesLoading, error: categoriesError } = useQuery(
    ['categories', courseId, userId],
    fetchCategories,
    {
      enabled: !!userId,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      retry: 3,
      onError: (error) => {
        console.error('Error fetching categories:', error);
      }
    }
  );

  const useAvailableQuestionCounts = (selectedCategories: string[], keslerQFilter: string[], enabled: boolean) => {
    return useQuery(
      ['availableQuestionCounts', courseId, userId, selectedCategories, keslerQFilter],
      () => api.getAvailableQuestionCounts(courseId, selectedCategories, keslerQFilter, userId!),
      {
        enabled: enabled && !!userId && selectedCategories.length > 0,
        staleTime: 1 * 60 * 1000, // 1 minute
        cacheTime: 5 * 60 * 1000, // 5 minutes
        retry: 3,
        onError: (error) => {
          console.error('Error fetching available question counts:', error);
        }
      }
    );
  };

  const resetCategories: UseMutationResult<void, Error, { categoryIds: string[] }> = useMutation(
    async ({ categoryIds }) => {
      if (!userId) throw new Error('User not authenticated');
      for (const categoryId of categoryIds) {
        await api.resetCategory(userId, courseId, categoryId);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['categories', courseId]);
        queryClient.invalidateQueries(['availableQuestionCounts', courseId]);
      },
    }
  );
  

  const useActiveQuiz = () => {
    return useQuery(
      ['activeQuiz', userId, courseId],
      async () => {
        const checkedKey = `activeQuizChecked_${userId}_${courseId}`;
        const checked = localStorage.getItem(checkedKey);
        
        if (checked) {
          return null; // Return null if already checked this session
        }
        
        const result = await api.getActiveQuiz(userId!, courseId);
        localStorage.setItem(checkedKey, 'true');
        return result;
      },
      {
        enabled: !!userId,
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );
  };

  const resetActiveQuizCheck = () => {
    localStorage.removeItem(`activeQuizChecked_${userId}_${courseId}`);
    queryClient.invalidateQueries(['activeQuiz', userId, courseId]);
  };

  const useFilteredQuizHistory = (filter: string = 'all') => {
    return useQuery(
      ['filteredQuizHistory', userId, courseId, filter],
      () => api.getFilteredQuizHistory(userId!, courseId, filter),
      {
        enabled: !!userId,
        staleTime: 1 * 60 * 1000, // 1 minute
        cacheTime: 5 * 60 * 1000, // 5 minutes
        retry: 3,
        onError: (error) => {
          console.error('Error fetching filtered quiz history:', error);
        }
      }
    );
  };

  return {
    categoriesData,
    categoriesLoading,
    categoriesError,
    useAvailableQuestionCounts,
    resetCategories,
    useActiveQuiz,
    resetActiveQuizCheck,
    useFilteredQuizHistory,
  };
};
import React from 'react';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white dark:bg-gray-800 shadow-sm mt-10 py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <div className="text-sm text-gray-500 dark:text-gray-400">
          {/* Add any additional footer content here */}
        </div>
        <div className="text-sm text-gray-500 dark:text-gray-400">
          Kesler CPA Review © {currentYear}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
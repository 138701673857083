import { useState, useEffect } from 'react';
import { api, StudyTaskDetails } from '../services/api';

export const useStudyTaskDetails = (userId: string, courseId: string, itemId: string) => {
  console.log('useStudyTaskDetails hook called with:', { userId, courseId, itemId });
  const [taskDetails, setTaskDetails] = useState<StudyTaskDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchTaskDetails = async () => {
      console.log('Fetching task details...');
      try {
        setIsLoading(true);
        const details = await api.getStudyTaskDetails(userId, courseId, itemId);
        console.log('Task details fetched successfully:', details);
        console.log('Fetched topic_type:', details.topic_type);
        
        // Fetch the plan_id separately if it's not included in the task details
        if (!details.plan_id) {
          const userStudyPlan = await api.getUserStudyPlan(userId, courseId);
          if (userStudyPlan) {
            details.plan_id = userStudyPlan.plan_id;
          }
        }
        
        // Ensure topic_type is set correctly
        if (!details.topic_type) {
          console.warn('topic_type is missing from task details, defaulting to "learn"');
          details.topic_type = 'learn';
        } else if (!['learn', 'loop', 'funnel', 'retake_funnel', 'mock_exam', 'mock_exam_retake'].includes(details.topic_type)) {
          console.warn(`Unknown topic_type: ${details.topic_type}, defaulting to "learn"`);
          details.topic_type = 'learn';
        }
        
        setTaskDetails(details);
      } catch (err) {
        console.error('Error fetching task details:', err);
        setError(err instanceof Error ? err : new Error('An error occurred while fetching task details'));
      } finally {
        setIsLoading(false);
      }
    };

    if (userId && courseId && itemId) {
      fetchTaskDetails();
    } else {
      console.warn('Missing required parameters for fetching task details');
      setIsLoading(false);
    }
  }, [userId, courseId, itemId]);

  return { taskDetails, isLoading, error };
};
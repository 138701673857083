import { supabase } from './supabase';
import { 
  TIER_IDS, 
  User, 
  UserCourseAccess, 
  UserCoursePurchase,
  CACHE_KEYS,
  CACHE_DURATION,
  COURSE_IDS
} from './api';

interface CacheItem<T> {
  data: T;
  timestamp: number;
}

interface SwitchResult {
  success: boolean;
  message: string;
  switchUsed: boolean;
  user?: User;
  access?: UserCourseAccess[];
}

interface UserSwitchInfo {
  switches_available: number;
  last_switch_date: string | null;
  last_switch_replenish_date: string | null;
}

type SwitchAvailabilityResult = {
  canSwitch: boolean;
  switchesAvailable: number;
  nextReplenishDate: Date | null;
  message: string;
};

class CourseService {
  private static instance: CourseService;

  private constructor() {
    // Initialize by clearing any potentially stale cache
    this.clearAllCache();
  }

  static getInstance(): CourseService {
    if (!CourseService.instance) {
      CourseService.instance = new CourseService();
    }
    return CourseService.instance;
  }

  async handleCourseSwitch(
    userId: string,
    newCourseId: string,
    currentCourseId: string,
    useSwitch: boolean
  ): Promise<SwitchResult> {
    try {
      console.log('handleCourseSwitch called with params:', {
        userId,
        newCourseId,
        currentCourseId,
        useSwitch,
      });

      // Clear all cache before switch
      this.clearAllUserCache(userId);

      // Verify user exists and get current data
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single();

      if (userError) throw userError;
      const user = userData as User;

      // Get current access state
      const { data: currentAccess, error: accessError } = await supabase
        .from('user_course_access')
        .select('*')
        .eq('user_id', userId)
        .eq('course_id', currentCourseId)
        .single();

      if (accessError && accessError.code !== 'PGRST116') {  // Ignore not found error
        throw accessError;
      }
      const currentUserAccess = currentAccess as UserCourseAccess | null;

      // Check active purchase status
      const { data: activePurchase, error: purchaseError } = await supabase
        .from('user_course_purchases')
        .select('*')
        .eq('user_id', userId)
        .in('tier_id', [TIER_IDS.FULL_ACCESS, TIER_IDS.STUDY_MATERIALS_ONLY])
        .eq('is_active', true)
        .or('subscription_status.eq.active,subscription_status.is.null')
        .single();

      if (purchaseError) throw purchaseError;
      const purchase = activePurchase as UserCoursePurchase;

      // Verify switch availability if needed
      if (useSwitch && user.switches_available <= 0) {
        throw new Error('No switches available');
      }

      // Call the stored procedure for course switch with updated parameter names
      const { data: switchResult, error: switchError } = await supabase.rpc(
        'access_switch_cpa_course',
        {
          target_user: userId,
          target_current_course: currentCourseId,
          target_new_course: newCourseId,
          should_use_switch: useSwitch,
        }
      );

      if (switchError) throw switchError;

      // Get updated user data after switch
      const { data: updatedUserData, error: updatedUserError } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single();

      if (updatedUserError) throw updatedUserError;
      const updatedUser = updatedUserData as User;

      // Get all updated access records
      const { data: updatedAccess, error: updatedAccessError } = await supabase
        .from('user_course_access')
        .select('*')
        .eq('user_id', userId);

      if (updatedAccessError) throw updatedAccessError;
      const userAccess = updatedAccess as UserCourseAccess[];

      // After successful switch, invalidate all cache
      this.clearAllUserCache(userId);

      return {
        success: true,
        message: this.formatSwitchMessage(updatedUser, switchResult),
        switchUsed: switchResult,
        user: updatedUser,
        access: userAccess
      };
    } catch (error) {
      // Clear cache on error too
      this.clearAllUserCache(userId);
      
      console.error('Error switching course:', error);
      return {
        success: false,
        message: error instanceof Error ? error.message : 'An error occurred while switching courses',
        switchUsed: false
      };
    }
  }

  // New method to set cache with expiry
  private setCacheWithExpiry<T>(key: string, data: T, duration: number): void {
    const item: CacheItem<T> = {
      data,
      timestamp: Date.now()
    };
    try {
      localStorage.setItem(key, JSON.stringify(item));
    } catch (error) {
      console.error('Cache set error:', error);
      this.clearAllCache(); // Clear all cache if we hit storage limits
    }
  }

  // New method to get cache with expiry check
  private getCacheWithExpiry<T>(key: string, duration: number): T | null {
    try {
      const cached = localStorage.getItem(key);
      if (!cached) return null;

      const item: CacheItem<T> = JSON.parse(cached);
      const now = Date.now();

      if (now - item.timestamp > duration) {
        localStorage.removeItem(key);
        return null;
      }

      return item.data;
    } catch (error) {
      console.error('Cache get error:', error);
      localStorage.removeItem(key);
      return null;
    }
  }

  // New method to clear all cache for a specific user
  private clearAllUserCache(userId: string): void {
    try {
      // Clear all user-specific cache items
      Object.values(COURSE_IDS).forEach(courseId => {
        if (typeof courseId === 'string') {
          localStorage.removeItem(CACHE_KEYS.USER_COURSE_ACCESS(userId, courseId));
        }
      });

      // Clear other user-specific caches
      localStorage.removeItem(CACHE_KEYS.USER_DATA(userId));
      localStorage.removeItem(CACHE_KEYS.USER_ACCESS(userId));
      localStorage.removeItem(CACHE_KEYS.USER_COURSE_PURCHASES(userId));

      // Clear general caches that might be affected
      localStorage.removeItem(CACHE_KEYS.PRODUCTS);
    } catch (error) {
      console.error('Error clearing user cache:', error);
      this.clearAllCache(); // Fallback to clearing everything
    }
  }

  // New method to clear all cache
  private clearAllCache(): void {
    try {
      // Clear all cache keys that match our patterns
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && (
          key.startsWith('userData_') ||
          key.startsWith('userAccess_') ||
          key.startsWith('userCourseAccess_') ||
          key.startsWith('userCoursePurchases_') ||
          key === CACHE_KEYS.PRODUCTS
        )) {
          localStorage.removeItem(key);
        }
      }
    } catch (error) {
      console.error('Error clearing all cache:', error);
      localStorage.clear(); // Last resort: clear everything
    }
  }

  // Modified verifyUserAccess to use new cache methods
  async verifyUserAccess(userId: string, courseId: string): Promise<{
    hasAccess: boolean;
    access?: UserCourseAccess;
    user?: User;
  }> {
    const cacheKey = CACHE_KEYS.USER_COURSE_ACCESS(userId, courseId);
    
    try {
      // Try to get from cache first
      const cachedData = this.getCacheWithExpiry<{
        access: UserCourseAccess;
        user: User;
      }>(cacheKey, CACHE_DURATION.USER_ACCESS);

      if (cachedData) {
        return {
          hasAccess: true,
          access: cachedData.access,
          user: cachedData.user
        };
      }

      // If not in cache, fetch fresh data
      const [userResponse, accessResponse] = await Promise.all([
        supabase.from('users').select('*').eq('id', userId).single(),
        supabase.from('user_course_access').select('*')
          .eq('user_id', userId)
          .eq('course_id', courseId)
          .single()
      ]);

      if (userResponse.error || accessResponse.error) {
        throw new Error('Failed to fetch user access data');
      }

      const result = {
        hasAccess: !!accessResponse.data,
        access: accessResponse.data as UserCourseAccess,
        user: userResponse.data as User
      };

      // Cache the result
      this.setCacheWithExpiry(cacheKey, {
        access: result.access,
        user: result.user
      }, CACHE_DURATION.USER_ACCESS);

      return result;
    } catch (error) {
      console.error('Error verifying user access:', error);
      this.clearAllUserCache(userId); // Clear cache on error
      return { hasAccess: false };
    }
  }

  async checkSwitchAvailability(userId: string): Promise<SwitchAvailabilityResult> {
    const { data, error } = await supabase
      .from('users')
      .select('switches_available, last_switch_date, last_switch_replenish_date')
      .eq('id', userId)
      .single();

    if (error) throw error;
    const userData = data as UserSwitchInfo;

    const nextReplenishDate = userData.last_switch_replenish_date
      ? new Date(new Date(userData.last_switch_replenish_date).getTime() + 30 * 24 * 60 * 60 * 1000)
      : null;

    return {
      canSwitch: userData.switches_available > 0,
      switchesAvailable: userData.switches_available,
      nextReplenishDate,
      message: this.formatSwitchAvailabilityMessage(userData.switches_available, nextReplenishDate),
    };
  }

  private formatSwitchMessage(user: User, switchUsed: boolean): string {
    const switchesRemaining = user.switches_available;
    const nextReplenishDate = user.last_switch_replenish_date
      ? new Date(user.last_switch_replenish_date)
      : null;

    if (switchUsed) {
      const replenishInfo = nextReplenishDate
        ? `. Next switch available ${this.formatRelativeDate(nextReplenishDate)}`
        : '';
      return `Course switched successfully. Switches remaining: ${switchesRemaining}${replenishInfo}`;
    }

    return 'Course switched to free trial mode successfully.';
  }

  private formatRelativeDate(date: Date): string {
    const now = new Date();
    const diffDays = Math.ceil((date.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    return diffDays > 0 ? `in ${diffDays} days` : 'today';
  }

  private formatSwitchAvailabilityMessage(switches: number, nextReplenish: Date | null): string {
    if (switches > 0) {
      return `You have ${switches} switch${switches !== 1 ? 'es' : ''} available.`;
    }

    if (nextReplenish) {
      return `No switches available. Next switch will be available ${this.formatRelativeDate(nextReplenish)}.`;
    }

    return 'No switches available. Please contact support for assistance.';
  }
  
  // Public method for clearing all cache
  public clearCache(): void {
    try {
      // Clear all cache keys that match our patterns
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && (
          key.startsWith('userData_') ||
          key.startsWith('userAccess_') ||
          key.startsWith('userCourseAccess_') ||
          key.startsWith('userCoursePurchases_') ||
          key === CACHE_KEYS.PRODUCTS
        )) {
          localStorage.removeItem(key);
        }
      }
    } catch (error) {
      console.error('Error clearing all cache:', error);
      localStorage.clear(); // Last resort: clear everything
    }
  }

  // Public method for clearing user-specific cache
  public clearUserCache(userId: string): void {
    try {
      console.log('Clearing cache for user:', userId);
      
      // Clear all course-specific caches
      Object.values(COURSE_IDS).forEach(courseId => {
        if (typeof courseId === 'string') {
          const key = CACHE_KEYS.USER_COURSE_ACCESS(userId, courseId);
          localStorage.removeItem(key);
          console.log('Cleared cache for course:', courseId);
        }
      });
  
      // Clear user-specific caches
      [
        CACHE_KEYS.USER_DATA(userId),
        CACHE_KEYS.USER_ACCESS(userId),
        CACHE_KEYS.USER_COURSE_PURCHASES(userId),
        `userData_${userId}`,
        `userAccess_${userId}`,
        `userCourseAccess_${userId}`,
        `userCoursePurchases_${userId}`
      ].forEach(key => {
        localStorage.removeItem(key);
        console.log('Cleared cache key:', key);
      });
  
      // Clear any legacy or general caches
      [
        CACHE_KEYS.PRODUCTS,
        'products',
        `user_${userId}_course_access`,
        `user_${userId}_purchases`
      ].forEach(key => {
        localStorage.removeItem(key);
        console.log('Cleared legacy cache key:', key);
      });
  
      console.log('Cache clearing complete for user:', userId);
    } catch (error) {
      console.error('Error clearing user cache:', error);
      // Fallback to clearing everything if specific clearing fails
      localStorage.clear();
      console.log('Cleared all localStorage due to error');
    }
  }
}

export const courseService = CourseService.getInstance();
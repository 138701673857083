import React, { useState } from 'react';
import { QuizHistory } from '../../services/api';
import QuizHistoryTableEndQuizPopup from './QuizHistoryTableEndQuizPopup';

interface QuizHistoryTableProps {
  quizzes: QuizHistory[];
  onViewResults: (id: string) => void;
  onResumeQuiz: (quiz: QuizHistory) => void;
  onEndQuiz: (id: string) => void;
}

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const formatDateTime = (date: Date): string => {
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

const QuizHistoryTable: React.FC<QuizHistoryTableProps> = React.memo(({ 
  quizzes, 
  onViewResults, 
  onResumeQuiz, 
  onEndQuiz
}) => {
  const [showEndQuizPopup, setShowEndQuizPopup] = useState(false);
  const [quizToEnd, setQuizToEnd] = useState<string | null>(null);

  const handleEndQuiz = (id: string) => {
    setQuizToEnd(id);
    setShowEndQuizPopup(true);
  };

  const handleConfirmEndQuiz = () => {
    if (quizToEnd) {
      onEndQuiz(quizToEnd);
      setQuizToEnd(null);
      setShowEndQuizPopup(false);
    }
  };

  return (
    <>
      <div className="overflow-x-auto shadow rounded-lg">
        <table className="w-full divide-y divide-gray-200 dark:divide-gray-700">
      <thead className="bg-gray-800 dark:bg-gray-700">
        <tr>
          <th className="px-2 sm:px-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Date</th>
          <th className="px-2 sm:px-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Questions</th>
          <th className="px-2 sm:px-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider hidden sm:table-cell">Correct</th>
          <th className="px-2 sm:px-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider hidden sm:table-cell">Incorrect</th>
          <th className="px-2 sm:px-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Score</th>
          <th className="px-2 sm:px-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Total Time</th>
          <th className="px-2 sm:px-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider hidden sm:table-cell">Avg Time</th>
          <th className="px-2 sm:px-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Action</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
        {quizzes.map((quiz) => (
          <tr key={quiz.id} className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-150">
            <td className="px-2 sm:px-3 py-4 text-sm text-gray-900 dark:text-gray-200 text-center">
              <div className="whitespace-normal">{formatDateTime(new Date(quiz.end_time || quiz.start_time))}</div>
            </td>
            <td className="px-2 sm:px-3 py-4 text-sm text-gray-900 dark:text-gray-200 text-center">
              {quiz.total_questions}
            </td>
            <td className="px-2 sm:px-3 py-4 text-sm text-gray-900 dark:text-gray-200 text-center hidden sm:table-cell">
              {quiz.correct_answers}
            </td>
            <td className="px-2 sm:px-3 py-4 text-sm text-gray-900 dark:text-gray-200 text-center hidden sm:table-cell">
              {quiz.incorrect_answers}
            </td>
            <td className="px-2 sm:px-3 py-4 text-sm text-gray-900 dark:text-gray-200 text-center">
              {quiz.percentage_correct?.toFixed(2) ?? '0.00'}%
            </td>
            <td className="px-2 sm:px-3 py-4 text-sm text-gray-900 dark:text-gray-200 text-center">
              {formatTime(quiz.total_time)}
            </td>
            <td className="px-2 sm:px-3 py-4 text-sm text-gray-900 dark:text-gray-200 text-center hidden sm:table-cell">
              {formatTime(quiz.avg_time_per_question)}
            </td>
            <td className="px-2 sm:px-3 py-4 text-sm text-gray-900 dark:text-gray-200 text-center">
                  {quiz.completed ? (
                    <button
                      onClick={() => onViewResults(quiz.id)}
                      className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200"
                    >
                      Results
                    </button>
                  ) : (
                    <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-1 sm:space-y-0 justify-center">
                      <button
                        onClick={() => onResumeQuiz(quiz)}
                        className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200"
                      >
                        [Resume]
                      </button>
                      <button
                        onClick={() => handleEndQuiz(quiz.id)}
                        className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-200"
                      >
                        [End]
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <QuizHistoryTableEndQuizPopup
        isOpen={showEndQuizPopup}
        onClose={() => setShowEndQuizPopup(false)}
        onConfirmEnd={handleConfirmEndQuiz}
        quizId={quizToEnd || ''}
      />
    </>
  );
});

export default QuizHistoryTable;
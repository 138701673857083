import React, { createContext, useContext, useEffect, useState } from 'react';
import { flashcardIDB, syncService } from '../services/flashcardapi';
import { useParams } from 'react-router-dom';
import { auth } from '../services/auth';
import { supabase } from '../services/supabase';

interface FlashcardDBContextType {
  isInitialized: boolean;
  courseId: string | null;
  isSyncing: boolean;
  lastSyncTime: number | null;
  syncError: string | null;
  initError: string | null;
}

const FlashcardDBContext = createContext<FlashcardDBContextType | undefined>(undefined);

export function FlashcardDBProvider({ children }: { children: React.ReactNode }) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [lastSyncTime, setLastSyncTime] = useState<number | null>(null);
  const [syncError, setSyncError] = useState<string | null>(null);
  const [initError, setInitError] = useState<string | null>(null);
  const { courseId } = useParams<{ courseId: string }>();
  const [currentCourseId, setCurrentCourseId] = useState<string | null>(null);

  // Initialize DB and start sync process
  useEffect(() => {
    let isSubscribed = true;
    
    const initializeDB = async () => {
      try {
        // Reset states when switching courses
        if (currentCourseId && currentCourseId !== courseId) {
          setIsInitialized(false);
          setLastSyncTime(null);
          setSyncError(null);
          setInitError(null);
        }

        if (courseId) {
          const user = await auth.getCurrentUser();
          if (!user) {
            throw new Error('User not authenticated');
          }

          // Verify course access before initializing
          const { data: accessData, error: accessError } = await supabase
            .from('user_course_access')
            .select('*')
            .eq('user_id', user.id)
            .eq('course_id', courseId)
            .single();

          if (accessError || !accessData) {
            throw new Error('No access to this course');
          }

          await flashcardIDB.init(courseId);
          
          if (isSubscribed) {
            setIsInitialized(true);
            setCurrentCourseId(courseId);
            setInitError(null);
          }
        } else {
          // Fallback initialization without courseId
          await flashcardIDB.init();
          if (isSubscribed) {
            setIsInitialized(true);
            setInitError(null);
          }
        }
      } catch (error) {
        console.error('Failed to initialize FlashcardDB:', error);
        if (isSubscribed) {
          setInitError(error instanceof Error ? error.message : 'Failed to initialize database');
          setIsInitialized(false);
        }
      }
    };

    initializeDB();

    // Clean up subscription
    return () => {
      isSubscribed = false;
    };
  }, [courseId, currentCourseId]);

  // Handle periodic syncing
  useEffect(() => {
    let syncInterval: NodeJS.Timeout;
    
    const performSync = async () => {
      if (!isInitialized || isSyncing || !courseId) return;

      try {
        setIsSyncing(true);
        setSyncError(null);
        
        await syncService.syncAll();
        
        setLastSyncTime(Date.now());
      } catch (error) {
        console.error('Sync failed:', error);
        setSyncError(error instanceof Error ? error.message : 'Sync failed');
      } finally {
        setIsSyncing(false);
      }
    };

    if (isInitialized && courseId) {
      // Initial sync
      performSync();
      
      // Set up periodic sync every 5 minutes
      syncInterval = setInterval(performSync, 5 * 60 * 1000);
    }

    return () => {
      if (syncInterval) {
        clearInterval(syncInterval);
      }
    };
  }, [isInitialized, courseId, isSyncing]);

  const contextValue = {
    isInitialized,
    courseId: currentCourseId,
    isSyncing,
    lastSyncTime,
    syncError,
    initError
  };

  return (
    <FlashcardDBContext.Provider value={contextValue}>
      {children}
    </FlashcardDBContext.Provider>
  );
}

export function useFlashcardDB() {
  const context = useContext(FlashcardDBContext);
  if (context === undefined) {
    throw new Error('useFlashcardDB must be used within a FlashcardDBProvider');
  }
  return context;
}
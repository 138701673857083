import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { api } from '../../services/api';
import { Question, CategoryStats, QuizHistory } from '../../services/api';
import PopupModal from './common/PopupModal';

interface QuizCustomizerProps {
  courseId: string;
  userId: string;
  show: boolean;
  availableQuestionCounts: {[key: string]: number};
  customQuestionCount: number;
  setCustomQuestionCount: (count: number) => void;
  keslerQFilter: string[];
  setKeslerQFilter: (filter: string[]) => void;
  onStartQuiz: (questions: Question[]) => void;
  onResumeQuiz: (quizToResume: QuizHistory) => void; 
  selectedCategories: string[];
}

const QuizCustomizer: React.FC<QuizCustomizerProps> = ({
  courseId,
  userId,
  show,
  availableQuestionCounts,
  customQuestionCount,
  setCustomQuestionCount,
  keslerQFilter,
  setKeslerQFilter,
  onStartQuiz,
  onResumeQuiz,
  selectedCategories,
}) => {
  const [categoryStats, setCategoryStats] = useState<CategoryStats[]>([]);
  const [maxQuestions, setMaxQuestions] = useState(0);
  const [maxUnansweredQuestions, setMaxUnansweredQuestions] = useState(0);
  const [activeQuiz, setActiveQuiz] = useState<any | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCountButton, setSelectedCountButton] = useState<number | null>(null);
  const [quizHistory, setQuizHistory] = useState<QuizHistory[]>([]);
  const [isValidInput, setIsValidInput] = useState(true);
  const [totalTime, setTotalTime] = useState(0);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const availableButtons = [10, 25, 50];
  const MAX_ALLOWED_QUESTIONS = 72; // Maximum number of questions allowed in a quiz
  const [isLoading, setIsLoading] = useState(false);

  const totalAvailableQuestions = useMemo(() => {
    return keslerQFilter.reduce((sum, filter) => sum + (availableQuestionCounts[filter] || 0), 0);
  }, [availableQuestionCounts, keslerQFilter]);

  const calculateLimitedCount = useCallback((count: number) => {
    return Math.min(count, MAX_ALLOWED_QUESTIONS, totalAvailableQuestions);
  }, [totalAvailableQuestions]);

  useEffect(() => {
    if (show) {
      const newCount = calculateLimitedCount(customQuestionCount);
      if (newCount !== customQuestionCount) {
        setCustomQuestionCount(newCount);
      }
      setSelectedCountButton(availableButtons.includes(newCount) ? newCount : null);
    }
  }, [show, customQuestionCount, calculateLimitedCount, setCustomQuestionCount]);

  const fetchCategoryStats = useCallback(async () => {
    if (selectedCategories.length === 0) return;
    try {
      const stats = await api.getCategoryStats(userId, courseId, selectedCategories);
      setCategoryStats(stats);
      const totalQuestions = stats.reduce((sum, stat) => sum + stat.total_questions, 0);
      const totalUnansweredQuestions = stats.reduce((sum, stat) => sum + stat.unanswered_questions, 0);
      // Remove the 72-question limit here
      setMaxQuestions(totalQuestions);
      setMaxUnansweredQuestions(totalUnansweredQuestions);
    } catch (error) {
      console.error('Failed to fetch category stats:', error);
    }
  }, [userId, courseId, selectedCategories]);

  useEffect(() => {
    fetchCategoryStats();
  }, [fetchCategoryStats]);

  const fetchQuizHistory = useCallback(async () => {
    try {
      const history = await api.getQuizHistory(userId, courseId);
      setQuizHistory(history);
    } catch (error) {
      console.error('Failed to fetch quiz history:', error);
    }
  }, [userId, courseId]);

  useEffect(() => {
    fetchQuizHistory();
  }, [fetchQuizHistory]);
  
  const updateQuestionCount = useCallback((newFilter: string[]) => {
    const newCount = newFilter.reduce((sum, filter) => sum + (availableQuestionCounts[filter] || 0), 0);
    const limitedCount = calculateLimitedCount(newCount);
    setCustomQuestionCount(limitedCount);
    setSelectedCountButton(null);
    setIsValidInput(true);
  }, [availableQuestionCounts, calculateLimitedCount, setCustomQuestionCount]);

  useEffect(() => {
    updateQuestionCount(keslerQFilter);
  }, [keslerQFilter, updateQuestionCount]);

    // Add this useEffect to ensure customQuestionCount always respects the limit
    useEffect(() => {
      const limitedCount = calculateLimitedCount(customQuestionCount);
      if (limitedCount !== customQuestionCount) {
        setCustomQuestionCount(limitedCount);
      }
    }, [customQuestionCount, calculateLimitedCount, setCustomQuestionCount]);
    
  // Use an effect to set the initial selected button and question count
  useEffect(() => {
    if (show && isInitialRender) {
      const initialCount = customQuestionCount === 10 ? 10 : availableButtons[0];
      setSelectedCountButton(initialCount);
      setCustomQuestionCount(initialCount);
      setIsInitialRender(false);
    }
  }, [show, customQuestionCount, setCustomQuestionCount, isInitialRender]);

  // Update the handleQuestionCountChange function
  const handleQuestionCountChange = useCallback((value: number | string) => {
    const count = typeof value === 'string' ? parseInt(value, 10) : value;
    if (isNaN(count)) {
      setIsValidInput(false);
      return;
    }
    
    const limitedCount = calculateLimitedCount(count);
    setCustomQuestionCount(limitedCount);
    setIsValidInput(limitedCount === count);
    setSelectedCountButton(availableButtons.includes(limitedCount) ? limitedCount : null);
  }, [calculateLimitedCount, setCustomQuestionCount, availableButtons]);

  const handleKeslerQFilterChange = useCallback((level: string) => {
    const filterKey = level.toLowerCase();
    const statusFilters = ['unanswered', 'incorrect', 'correct'];
    const confidenceLevels = ['confident', 'maybe', 'guessing'];
  
    let newFilter: string[] = [...keslerQFilter];
  
    if (statusFilters.includes(filterKey)) {
      if (filterKey === 'unanswered') {
        newFilter = keslerQFilter.includes(filterKey)
          ? newFilter.filter(f => !statusFilters.includes(f) && !confidenceLevels.includes(f))
          : ['unanswered'];
      } else {
        newFilter = keslerQFilter.includes(filterKey)
          ? newFilter.filter(f => f !== filterKey && f !== 'unanswered')
          : [...newFilter.filter(f => f !== 'unanswered' && f !== filterKey && !confidenceLevels.includes(f)), filterKey];
      }
    } else if (confidenceLevels.includes(filterKey)) {
      newFilter = keslerQFilter.includes(filterKey)
        ? newFilter.filter(f => !statusFilters.includes(f) && f !== filterKey)
        : [...newFilter.filter(f => !statusFilters.includes(f)), filterKey];
    }
  
    if (newFilter.length === 0) {
      newFilter = ['unanswered'];
    }
  
    if (JSON.stringify(newFilter) !== JSON.stringify(keslerQFilter)) {
      setKeslerQFilter(newFilter);
      const newCount = newFilter.reduce((sum, filter) => sum + (availableQuestionCounts[filter] || 0), 0);
      handleQuestionCountChange(newCount);
    }
  }, [keslerQFilter, setKeslerQFilter, availableQuestionCounts, handleQuestionCountChange]);

  const checkActiveQuiz = useCallback(async () => {
    try {
      const active = await api.getActiveQuiz(userId, courseId);
      setActiveQuiz(active);
    } catch (error) {
      console.error('Failed to check for active quiz:', error);
    }
  }, [userId, courseId]);

  useEffect(() => {
    checkActiveQuiz();
  }, [checkActiveQuiz]);

  const handleStartQuiz = () => {
    if (activeQuiz) {
      setShowPopup(true);
    } else {
      startNewQuiz();
    }
  };

  const resetQuizState = useCallback(() => {
    setCustomQuestionCount(0);
    setKeslerQFilter(['unanswered']);
    setSelectedCountButton(null);
    setIsValidInput(true);
    // Reset any other relevant states
  }, []);
  
  useEffect(() => {
    resetQuizState();
  }, []); // This will run when the component mounts
  


  const startNewQuiz = async () => {
    setIsLoading(true);
    try {
      if (customQuestionCount === 0) {
        console.error('Cannot start quiz with 0 questions');
        return;
      }
      
      // Limit the question count to MAX_ALLOWED_QUESTIONS
      const limitedQuestionCount = Math.min(customQuestionCount, MAX_ALLOWED_QUESTIONS);
  
      // Fetch questions and reset them in one step
      const questions = await api.getQuestionsForCustomQuiz(
        courseId,
        selectedCategories,
        keslerQFilter,
        limitedQuestionCount,
        userId
      );
      
      if (questions.length === 0) {
        console.error('No questions returned for the given criteria');
        return;
      }
  
      console.log('Fetched question IDs:', questions.map(q => q.id));
  
      // Double-check that we're not exceeding MAX_ALLOWED_QUESTIONS
      const finalQuestions = questions.slice(0, MAX_ALLOWED_QUESTIONS);
  
      // Start the quiz with the fetched and limited questions
      onStartQuiz(finalQuestions);
    } catch (error) {
      console.error('Failed to start custom quiz:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleResumeQuiz = () => {
    if (activeQuiz) {
      // Instead of calling onStartQuiz, we'll call onResumeQuiz with the entire QuizHistory object
      onResumeQuiz(activeQuiz);
    }
    setShowPopup(false);
  };

  // Updated handleEndQuiz function
  const handleEndQuiz = async () => {
    if (activeQuiz) {
      try {
        // End the active quiz session
        await api.endQuizSession(activeQuiz.id, totalTime);
        
        // Reset the active quiz state
        setActiveQuiz(null);
        
        // Close the popup modal
        setShowPopup(false);
        
        // Optionally, you can update the available question counts here
        // This ensures that the UI reflects the latest state after ending the quiz
        await checkActiveQuiz();
        
        // You might also want to refetch category stats here
        await fetchCategoryStats();
        
        console.log('Quiz ended successfully. You can now customize a new quiz.');
      } catch (error) {
        console.error('Failed to end quiz session:', error);
        // Optionally, you can show an error message to the user here
      }
    }
  };


  if (!show) return null;

  return (
    <>
      <div className="quiz-customizer fixed bottom-0 left-0 right-0 bg-white bg-opacity-75 dark:bg-dark-background dark:bg-opacity-75 backdrop-blur-sm shadow-lg z-40 transition-all duration-300 ease-in-out">
        <div className="flex flex-col space-y-2 md:flex-row md:justify-between md:items-center max-w-7xl mx-auto px-4 lg:px-8 py-2">
          <div className="flex flex-col items-start w-full md:w-auto">
            <h3 className="text-xs font-bold mb-1 text-light-text dark:text-dark-text">KeslerQ Count</h3>
            <div className="flex space-x-1 w-full">
              {availableButtons.map((count) => (
                <button
                  key={count}
                  onClick={() => handleQuestionCountChange(count)}
                  className={`px-2 py-1 text-sm md:text-xs rounded flex-grow ${
                    selectedCountButton === count
                      ? 'btn-orange dark:btn-orange text-white'
                      : 'bg-gray-200 dark:bg-gray-700 text-light-text dark:text-dark-text hover:bg-gray-300 dark:hover:bg-gray-600'
                  }`}
                  disabled={count > totalAvailableQuestions || !!activeQuiz}
                >
                  {count}
                </button>
              ))}
              <input
                type="number"
                value={customQuestionCount}
                onChange={(e) => handleQuestionCountChange(parseInt(e.target.value) || 0)}
                min={0}
                max={Math.min(MAX_ALLOWED_QUESTIONS, totalAvailableQuestions)}
                className={`w-16 px-1 py-1 text-sm md:text-xs border rounded bg-light-background dark:bg-dark-background ${
                  isValidInput 
                    ? 'text-light-text dark:text-dark-text' 
                    : 'text-red-500 dark:text-red-400 border-red-500 dark:border-red-400'
                }`}
                disabled={!!activeQuiz}
              />
            </div>
          </div>
          <div className="flex flex-col items-start w-full md:w-auto">
            <h3 className="text-xs font-bold mb-1 text-light-text dark:text-dark-text">KeslerQ Filter</h3>
            <div className="keslerq-filter-scroll w-full overflow-x-auto pb-1">
              <div className="flex space-x-1 w-max">
                {['Unanswered', 'Incorrect', 'Correct', 'Confident', 'Maybe', 'Guessing'].map(level => {
                  const filterKey = level.toLowerCase();
                  const count = availableQuestionCounts[filterKey] || 0;
                  const isDisabled = count === 0 || !!activeQuiz;
                  return (
                    <button
                      key={level}
                      onClick={() => !isDisabled && handleKeslerQFilterChange(level)}
                      className={`px-2 py-1 text-sm md:text-xs rounded whitespace-nowrap ${
                        keslerQFilter.includes(filterKey)
                          ? 'btn-orange dark:btn-orange text-white'
                          : isDisabled
                          ? 'bg-gray-300 dark:bg-gray-600 text-gray-500 dark:text-gray-400 cursor-not-allowed'
                          : 'bg-gray-200 dark:bg-gray-700 text-light-text dark:text-dark-text hover:bg-gray-300 dark:hover:bg-gray-600'
                      }`}
                      disabled={isDisabled}
                    >
                      {level} ({count})
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <button
            onClick={handleStartQuiz}
            className={`w-full md:w-auto px-4 py-2 text-sm rounded relative ${
              activeQuiz
                ? 'bg-red-500 dark:bg-red-600 text-white hover:bg-red-600 dark:hover:bg-red-700'
                : customQuestionCount > 0 && isValidInput
                ? 'btn-primary dark:btn-primary text-white hover:btn-primary dark:hover:btn-primary'
                : 'bg-gray-300 dark:bg-gray-600 text-gray-500 dark:text-gray-400 cursor-not-allowed'
            }`}
            disabled={(!activeQuiz && (customQuestionCount === 0 || !isValidInput)) || isLoading}
          >
            {isLoading ? (
              <span className="absolute inset-0 flex items-center justify-center">
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </span>
            ) : (
              <span className={isLoading ? 'invisible' : ''}>
                {activeQuiz ? 'End/Resume Quiz' : `Start Quiz (${customQuestionCount})`}
              </span>
            )}
          </button>
        </div>
      </div>
      <PopupModal
        isOpen={showPopup}
        onClose={() => setShowPopup(false)}
        onResume={handleResumeQuiz}
        onEnd={handleEndQuiz}
      />
    </>
  );
};

export default React.memo(QuizCustomizer);
import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { supabase } from '../../../services/supabase';
import { 
  Activity,
  Book,
  ChevronDown,
  Clock,
  HelpCircle,
  Loader2,
  Mail,
  UserCircle
} from 'lucide-react';
import { Card } from '../../ui/Card';
import { Badge } from '../../ui/Badge';
import { Separator } from '../../ui/Separator';
import { ScrollArea } from '../../ui/ScrollArea';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../ui/Accordion';
import { Alert, AlertDescription } from '../../ui/Alert';
import QuestionPreview from '../../course/QuestionPreview';
import { 
  User, 
  UserCourseAccess, 
  Product,
  TIER_IDS,
  COURSE_IDS
} from '../../../services/api';

interface Question {
  id: string;
  question_content: string;
  answer_content: string;
  question_category_name: string | null;
  sub_question_category_name: string | null;
  question_type: string | null;
  correct_answer: string | null;
  choices_count: number | null;
}

interface CourseAccessStatus {
  productTitle: string;
  accessType: 'Full Access' | 'Study Materials Only' | 'Mentor Only' | 'Free Trial';
  tierId: string;
}

interface UserSidebarProps {
  userId: string;
  ticketQuestionId?: string | null;
  onRefreshData?: () => void;
}

const UserSidebar: React.FC<UserSidebarProps> = ({
  userId,
  ticketQuestionId,
  onRefreshData
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [courseAccess, setCourseAccess] = useState<CourseAccessStatus[]>([]);
  const [products, setProducts] = useState<Record<string, string>>({});
  const [question, setQuestion] = useState<Question | null>(null);
  const [questionLoading, setQuestionLoading] = useState(false);

  useEffect(() => {
    loadUserData();
  }, [userId]);

  useEffect(() => {
    if (ticketQuestionId) {
      loadQuestionData();
    }
  }, [ticketQuestionId]);

  const loadUserData = async () => {
    try {
      setLoading(true);
      await Promise.all([
        loadUserDetails(),
        loadProducts(),
        loadCourseAccess()
      ]);
    } catch (error) {
      console.error('Error loading user data:', error);
      setError('Failed to load user data');
    } finally {
      setLoading(false);
    }
  };

  const loadUserDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error loading user details:', error);
      throw error;
    }
  };

  const loadProducts = async () => {
    try {
      const { data: productsData, error: productsError } = await supabase
        .from('products')
        .select('id, title')
        .in('type', ['course', 'mentorship'])
        .in('id', Object.values(COURSE_IDS));

      if (productsError) throw productsError;

      const productMap: Record<string, string> = {};
      productsData?.forEach((product: Pick<Product, 'id' | 'title'>) => {
        productMap[product.id] = product.title;
      });
      
      setProducts(productMap);
    } catch (error) {
      console.error('Error loading products:', error);
      throw error;
    }
  };

  const loadCourseAccess = async () => {
    try {
      const { data: accessData, error: accessError } = await supabase
        .from('user_course_access')
        .select('*')
        .eq('user_id', userId);

      if (accessError) throw accessError;
      
      const accessStatuses: CourseAccessStatus[] = (accessData || [])
        .filter(access => !access.end_date || new Date(access.end_date) > new Date())
        .map((access: UserCourseAccess) => {
          let accessType: CourseAccessStatus['accessType'];
          
          switch (access.tier_id) {
            case TIER_IDS.FULL_ACCESS:
              accessType = 'Full Access';
              break;
            case TIER_IDS.STUDY_MATERIALS_ONLY:
              accessType = 'Study Materials Only';
              break;
            case TIER_IDS.MENTOR_ONLY:
              accessType = 'Mentor Only';
              break;
            case TIER_IDS.FREE_TRIAL:
              accessType = 'Free Trial';
              break;
            default:
              accessType = 'Free Trial';
          }

          return {
            productTitle: access.course_id,
            accessType,
            tierId: access.tier_id
          };
        });

      setCourseAccess(accessStatuses);
    } catch (error) {
      console.error('Error loading course access:', error);
      throw error;
    }
  };

  const loadQuestionData = async () => {
    if (!ticketQuestionId) return;
    
    try {
      setQuestionLoading(true);
      const { data, error } = await supabase
        .from('questions')
        .select(`
          id,
          question_content,
          answer_content,
          question_category_name,
          sub_question_category_name,
          question_type,
          correct_answer,
          choices_count
        `)
        .eq('id', ticketQuestionId)
        .single();

      if (error) throw error;
      setQuestion(data);
    } catch (error) {
      console.error('Error loading question:', error);
      setError('Failed to load question data');
    } finally {
      setQuestionLoading(false);
    }
  };

    // Update event handlers with correct types
  const preventContextMenu = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const preventTextSelection = useCallback((e: React.SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const renderQuestionContent = () => {
    if (!question || !userId) return null;

    return (
      <Card className="mb-4">
        <Accordion type="single" collapsible>
          <AccordionItem value="question">
            <AccordionTrigger className="px-4 py-2 hover:no-underline">
              <div className="flex items-center gap-2">
                <HelpCircle className="w-4 h-4" />
                <span>Question Content</span>
              </div>
            </AccordionTrigger>
            <AccordionContent className="px-4 py-2">
              <div 
                onContextMenu={preventContextMenu}
                onMouseDown={preventTextSelection}
                onSelect={preventTextSelection}
                className="select-none pointer-events-none"
              >
                <QuestionPreview
                  questionId={question.id}
                  courseId={ticketQuestionId || ''}
                  userId={userId}
                />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    );
  };

  if (loading) {
    return (
      <div className="p-4 flex items-center justify-center">
        <Loader2 className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  if (!user) return null;

  return (
    <div className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600 scrollbar-track-transparent">
      <div className="p-4 space-y-4">
        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {/* User Info Card */}
        <Card className="p-4">
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <UserCircle className="w-4 h-4 text-gray-500" />
              <span className="font-medium">{user.full_name || 'Unknown'}</span>
            </div>
            <div className="flex items-center gap-2">
              <Mail className="w-4 h-4 text-gray-500" />
              <span className="text-sm">{user.email}</span>
            </div>
            <Separator />
            <div className="flex items-center gap-2 text-sm">
              <Clock className="w-4 h-4 text-gray-500" />
              Member since {format(new Date(user.created_at || new Date()), 'MMM d, yyyy')}
            </div>
            {user.last_sign_in_at && (
              <div className="flex items-center gap-2 text-sm">
                <Activity className="w-4 h-4 text-gray-500" />
                Last active {format(new Date(user.last_sign_in_at), 'MMM d, yyyy')}
              </div>
            )}
          </div>
        </Card>

        {/* Course Access Card */}
        <Card className="p-4">
          <div className="space-y-3">
            <div className="flex items-center gap-2 mb-2">
              <Book className="w-4 h-4 text-gray-500" />
              <span className="font-medium">Course Access</span>
            </div>
            {courseAccess.length === 0 ? (
              <div className="text-sm text-gray-500">
                No active course access
              </div>
            ) : (
              <div className="space-y-2">
                {courseAccess.map((access, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <span className="text-sm font-medium">
                      {products[access.productTitle] || 'Loading...'}
                    </span>
                    <Badge 
                      variant={access.tierId === TIER_IDS.FULL_ACCESS ? 'default' : 'secondary'}
                      className="text-xs"
                    >
                      {access.accessType}
                    </Badge>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Card>

        {/* Question Content Section */}
        {ticketQuestionId && (
          <div className="space-y-2">
            <h3 className="font-medium text-gray-900 dark:text-gray-100">
              Referenced Question
            </h3>
            {questionLoading ? (
              <div className="flex items-center justify-center p-4">
                <Loader2 className="w-4 h-4 animate-spin" />
              </div>
            ) : (
              renderQuestionContent()
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSidebar;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../services/auth';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const passwordRequirements = {
    minLength: 8,
    hasUppercase: /[A-Z]/,
    hasLowercase: /[a-z]/,
    hasNumber: /\d/,
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/
  };

  useEffect(() => {
    validatePassword(newPassword);
  }, [newPassword]);

  const validatePassword = (password: string) => {
    const isValid = 
      password.length >= passwordRequirements.minLength &&
      passwordRequirements.hasUppercase.test(password) &&
      passwordRequirements.hasLowercase.test(password) &&
      passwordRequirements.hasNumber.test(password) &&
      passwordRequirements.hasSpecialChar.test(password);

    setIsPasswordValid(isValid);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setMessage(null);

    if (!isPasswordValid) {
      setError("Password does not meet the requirements");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    try {
      const token = new URLSearchParams(location.search).get('token');
      if (!token) {
        throw new Error('Reset token not found');
      }

      await auth.updatePassword(newPassword);
      setMessage('Password reset successfully. You can now log in with your new password.');
      setTimeout(() => navigate('/login'), 3000);
    } catch (err) {
      setError('Failed to reset password. Please try again.');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4 text-light-text dark:text-dark-text">Reset Password</h2>
      {error && <p className="text-red-500 dark:text-red-400 mb-4">{error}</p>}
      {message && <p className="text-green-500 dark:text-green-400 mb-4">{message}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="newPassword" className="block mb-1 text-light-text dark:text-dark-text">New Password</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text border-light-text dark:border-dark-text"
          />
          <ul className="text-sm text-light-text dark:text-dark-text mt-2">
            <li className={newPassword.length >= passwordRequirements.minLength ? "text-green-500 dark:text-green-400" : ""}>
              At least {passwordRequirements.minLength} characters
            </li>
            <li className={passwordRequirements.hasUppercase.test(newPassword) ? "text-green-500 dark:text-green-400" : ""}>
              At least one uppercase letter
            </li>
            <li className={passwordRequirements.hasLowercase.test(newPassword) ? "text-green-500 dark:text-green-400" : ""}>
              At least one lowercase letter
            </li>
            <li className={passwordRequirements.hasNumber.test(newPassword) ? "text-green-500 dark:text-green-400" : ""}>
              At least one number
            </li>
            <li className={passwordRequirements.hasSpecialChar.test(newPassword) ? "text-green-500 dark:text-green-400" : ""}>
              At least one special character (!@#$%^&*(),.?":{}|&lt;&gt;)
            </li>
          </ul>
        </div>
        <div>
          <label htmlFor="confirmPassword" className="block mb-1 text-light-text dark:text-dark-text">Confirm New Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text border-light-text dark:border-dark-text"
          />
        </div>
        <button 
          type="submit" 
          className={`w-full py-2 rounded transition duration-200 ${
            isPasswordValid 
              ? "bg-light-primary dark:bg-dark-primary text-white hover:bg-light-accent dark:hover:bg-dark-accent"
              : "bg-gray-300 dark:bg-gray-600 text-gray-500 dark:text-gray-400 cursor-not-allowed"
          }`}
          disabled={!isPasswordValid}
        >
          Reset Password
        </button>
      </form>
      <p className="mt-4 text-center text-light-text dark:text-dark-text">
        Remember your password?{' '}
        <button 
          onClick={() => navigate('/login')} 
          className="text-light-primary dark:text-dark-accent hover:underline"
        >
          Log in
        </button>
      </p>
    </div>
  );
}

export default ResetPassword;
import { useState, useEffect } from 'react';
import { api, ConfidenceTrackerData } from '../services/api';

export const useConfidenceTracker = (userId: string, courseId: string) => {
  const [data, setData] = useState<ConfidenceTrackerData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await api.getConfidenceTrackerData(userId, courseId);
        setData(result);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('An error occurred'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, courseId]);

  return { data, loading, error };
};
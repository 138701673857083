import { useState, useEffect } from 'react';
import { useParams, useNavigate, Routes, Route, useLocation } from 'react-router-dom';
import { useAuth } from '../components/AuthProvider';
import { useProducts } from '../components/ProductProvider';
import Sidebar from './Sidebar';
import CourseHomepage from '../components/course/CourseHomepage';
import StudyPlan from '../components/course/StudyPlan';
import Lessons from '../components/course/Lessons';
import Quiz from '../components/course/Quiz';
import Simulations from '../components/course/Simulations';
import Analytics from '../components/course/Analytics';
import FocusNotes from '../components/course/FocusNotes';
import KeslerCards from '../components/course/KeslerCards';
import MockExam from '../components/course/MockExam';
import StudyTaskDetail from '../components/course/StudyTaskDetail';
import Mentor from '../components/course/Mentor'; // Import the Mentor component
import Search from '../components/course/Search'; // Import the Search component
import LoadingScreen from '../components/course/common/LoadingScreen';

interface CourseData {
  id: string;
  title: string;
  description: string;
}

function Course() {
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { getProduct, loading: productsLoading, error } = useProducts();
  const [course, setCourse] = useState<CourseData | null>(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (courseId) {
      setIsLoading(true);
      getProduct(courseId).then(fetchedCourse => {
        if (fetchedCourse) {
          setCourse(fetchedCourse as CourseData);
        }
        setIsLoading(false);
      });
    }
  }, [courseId, getProduct]);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const LessonsWrapper = () => {
    const location = useLocation();
    const { lessonId } = useParams<{ lessonId: string }>();
    const item_id = location.state?.item_id;
    return <Lessons courseId={courseId!} userId={user!.id} item_id={item_id} lessonId={lessonId} />;
  };

  if (isLoading || productsLoading) {
    return <LoadingScreen message="Loading Kesler CPA..." subMessage="This may take a few moments" />;
  }

  if (error) return <div className="text-red-500">{(error as Error).message}</div>;
  if (!course || !courseId) return <div>Course not found.</div>;
  if (!user) return <div>Please log in to access this course.</div>;

  return (
    <div className="flex">
      <Sidebar 
        courseId={courseId} 
        user={user} 
        isMenuOpen={isMenuOpen} 
        setIsMenuOpen={setIsMenuOpen}
      />
      <div className="flex-1 overflow-y-auto">
        <div className="w-full max-w-[100%] mx-auto py-0">
          <Routes>
            <Route index element={<CourseHomepage course={course} />} />
            <Route path="study-plan" element={<StudyPlan courseId={courseId} userId={user.id} />} />
            <Route path="mentor" element={<Mentor />} />
            <Route path="lessons" element={<LessonsWrapper />} />
            <Route path="lessons/:lessonId" element={<LessonsWrapper />} />
            <Route path="quiz" element={<Quiz courseId={courseId} userId={user.id} />} />
            <Route path="simulations" element={<Simulations courseId={courseId} userId={user.id} />} />
            <Route path="analytics" element={<Analytics courseId={courseId} userId={user.id} />} />
            <Route path="focus-notes" element={<FocusNotes courseId={courseId} userId={user.id} />} />
            <Route path="kesler-cards" element={<KeslerCards courseId={courseId} userId={user.id} />} />
            <Route path="mock-exam" element={<MockExam courseId={courseId} userId={user.id} />} />
            <Route path="study-task/:itemId" element={<StudyTaskDetail userId={user.id} courseId={courseId} />} />
            <Route path="study-plan/:itemId" element={<StudyTaskDetail userId={user.id} courseId={courseId} />} />            
            <Route path="search" element={<Search courseId={courseId} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Course;
import { useQuery, useQueryClient } from 'react-query';
import { api, CategoryStats } from '../services/api';

export const useCategoryStats = (courseId: string, userId: string, selectedCategories: string[]) => {
    const queryClient = useQueryClient();
  
    const fetchCategoryStats = async () => {
      const categories = await api.getQuestionCategories(courseId);
      const allCategoryIds = categories.map((category: { id: string }) => category.id);
      return api.getCategoryStats(userId, courseId, allCategoryIds);
    };
  
    const { data, isLoading, error } = useQuery<CategoryStats[], Error>(
      ['categoryStats', courseId, userId, selectedCategories],
      fetchCategoryStats,
      {
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: !!courseId && !!userId && selectedCategories.length > 0,
      }
    );
  
    const invalidateCategoryStats = () => {
      queryClient.invalidateQueries(['categoryStats', courseId, userId, selectedCategories]);
    };
  
    return { categoryStats: data, isLoading, error, invalidateCategoryStats };
  };
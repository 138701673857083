import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../services/auth';
import { getBrowserInstanceId } from '../../utils/browserInstance';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    try {
      const browserInstanceId = getBrowserInstanceId();
      const { data, error } = await auth.register(email, password, fullName);
      if (error) throw error;
      if (data.user && !data.user.confirmed_at) {
        navigate('/verify-email', { state: { email, browserInstanceId } });
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } catch (err) {
      setError((err as Error).message);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4 text-light-text dark:text-dark-text">Register</h2>
      {error && <p className="text-red-500 dark:text-red-400 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="fullName" className="block mb-1 text-light-text dark:text-dark-text">Full Name</label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
          />
        </div>
        <div>
          <label htmlFor="email" className="block mb-1 text-light-text dark:text-dark-text">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
          />
        </div>
        <div>
          <label htmlFor="password" className="block mb-1 text-light-text dark:text-dark-text">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
          />
        </div>
        <div>
          <label htmlFor="confirmPassword" className="block mb-1 text-light-text dark:text-dark-text">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text"
          />
        </div>
        <button type="submit" className="w-full bg-light-primary dark:bg-dark-primary text-white py-2 rounded hover:bg-light-accent dark:hover:bg-dark-accent">
          Register
        </button>
      </form>
    </div>
  );
}

export default Register;
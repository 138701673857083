// src/contexts/ThriveCartContext.tsx

import React, { createContext, useContext, useState } from 'react';
import { thriveCartService, ThriveCartOrder, ThriveCartSubscription } from '../services/thriveCartService';
import { useNotification } from './NotificationContext';

interface ThriveCartContextType {
  loadingOrder: boolean;
  loadingSubscription: boolean;
  currentOrder: ThriveCartOrder | null;
  currentSubscription: ThriveCartSubscription | null;
  fetchOrder: (orderId: string) => Promise<void>;
  fetchSubscription: (subscriptionId: string) => Promise<void>;
  cancelSubscription: (subscriptionId: string, reason?: string) => Promise<boolean>;
  pauseSubscription: (subscriptionId: string, resumeDate: Date) => Promise<boolean>;
  resumeSubscription: (subscriptionId: string) => Promise<boolean>;
  refundOrder: (orderId: string, amount?: number, reason?: string) => Promise<boolean>;
}

const ThriveCartContext = createContext<ThriveCartContextType | undefined>(undefined);

export const ThriveCartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<ThriveCartOrder | null>(null);
  const [currentSubscription, setCurrentSubscription] = useState<ThriveCartSubscription | null>(null);
  const { showNotification } = useNotification();

  const handleError = (error: unknown, action: string) => {
    const message = error instanceof Error ? error.message : 'An unknown error occurred';
    console.error(`Error ${action}:`, error);
    showNotification(`Failed to ${action}: ${message}`, 'error');
  };

  const fetchOrder = async (orderId: string) => {
    setLoadingOrder(true);
    try {
      const response = await thriveCartService.getOrder(orderId);
      if (!response.success || !response.data) {
        throw new Error(response.error || 'Failed to fetch order');
      }
      setCurrentOrder(response.data);
      await thriveCartService.syncOrderStatus(orderId);
    } catch (error) {
      handleError(error, 'fetch order');
    } finally {
      setLoadingOrder(false);
    }
  };

  const fetchSubscription = async (subscriptionId: string) => {
    setLoadingSubscription(true);
    try {
      const response = await thriveCartService.getSubscription(subscriptionId);
      if (!response.success || !response.data) {
        throw new Error(response.error || 'Failed to fetch subscription');
      }
      setCurrentSubscription(response.data);
    } catch (error) {
      handleError(error, 'fetch subscription');
    } finally {
      setLoadingSubscription(false);
    }
  };

  const cancelSubscription = async (subscriptionId: string, reason?: string): Promise<boolean> => {
    try {
      const response = await thriveCartService.cancelSubscription(subscriptionId, reason);
      if (!response.success) {
        throw new Error(response.error || 'Failed to cancel subscription');
      }
      showNotification('Subscription cancelled successfully', 'success');
      await fetchSubscription(subscriptionId);
      return true;
    } catch (error) {
      handleError(error, 'cancel subscription');
      return false;
    }
  };

  const pauseSubscription = async (subscriptionId: string, resumeDate: Date): Promise<boolean> => {
    try {
      const response = await thriveCartService.pauseSubscription(subscriptionId, resumeDate);
      if (!response.success) {
        throw new Error(response.error || 'Failed to pause subscription');
      }
      showNotification('Subscription paused successfully', 'success');
      await fetchSubscription(subscriptionId);
      return true;
    } catch (error) {
      handleError(error, 'pause subscription');
      return false;
    }
  };

  const resumeSubscription = async (subscriptionId: string): Promise<boolean> => {
    try {
      const response = await thriveCartService.resumeSubscription(subscriptionId);
      if (!response.success) {
        throw new Error(response.error || 'Failed to resume subscription');
      }
      showNotification('Subscription resumed successfully', 'success');
      await fetchSubscription(subscriptionId);
      return true;
    } catch (error) {
      handleError(error, 'resume subscription');
      return false;
    }
  };

  const refundOrder = async (orderId: string, amount?: number, reason?: string): Promise<boolean> => {
    try {
      const response = await thriveCartService.refundOrder(orderId, amount, reason);
      if (!response.success) {
        throw new Error(response.error || 'Failed to refund order');
      }
      showNotification('Order refunded successfully', 'success');
      await fetchOrder(orderId);
      return true;
    } catch (error) {
      handleError(error, 'refund order');
      return false;
    }
  };

  const value: ThriveCartContextType = {
    loadingOrder,
    loadingSubscription,
    currentOrder,
    currentSubscription,
    fetchOrder,
    fetchSubscription,
    cancelSubscription,
    pauseSubscription,
    resumeSubscription,
    refundOrder
  };

  return (
    <ThriveCartContext.Provider value={value}>
      {children}
    </ThriveCartContext.Provider>
  );
};

export const useThriveCart = () => {
  const context = useContext(ThriveCartContext);
  if (context === undefined) {
    throw new Error('useThriveCart must be used within a ThriveCartProvider');
  }
  return context;
};
import React, { useContext, useEffect } from 'react';
import { Modal } from '../../components/ui/Modal';
import { Button } from '../../components/ui/Button';
import { ModalContext } from '../../App'; // Import the ModalContext

interface QuizHistoryTableEndQuizPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmEnd: () => void;
  quizId: string;
}

const QuizHistoryTableEndQuizPopup: React.FC<QuizHistoryTableEndQuizPopupProps> = ({ 
  isOpen, 
  onClose, 
  onConfirmEnd, 
  quizId 
}) => {
  const { setIsModalOpen } = useContext(ModalContext);

  useEffect(() => {
    setIsModalOpen(isOpen);
    return () => setIsModalOpen(false);
  }, [isOpen, setIsModalOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  const handleConfirmEnd = () => {
    setIsModalOpen(false);
    onConfirmEnd();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg w-full max-w-[90%] sm:max-w-md">
        <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-gray-800 dark:text-white">End Quiz Session</h2>
        <p className="mb-4 text-sm sm:text-base text-gray-600 dark:text-gray-300">
          Are you sure you want to end this quiz session? This action cannot be undone.
        </p>
        <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
          <Button
            onClick={handleConfirmEnd}
            className="w-full sm:w-auto bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200"
          >
            End Session
          </Button>
          <Button
            onClick={handleClose}
            variant="outline"
            className="w-full sm:w-auto bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 hover:text-gray-900 dark:hover:text-white"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default QuizHistoryTableEndQuizPopup;
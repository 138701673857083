import React from 'react';

interface ScrollAreaProps extends React.HTMLAttributes<HTMLDivElement> {
  orientation?: 'vertical' | 'horizontal' | 'both';
  scrollHideDelay?: number;
}

export const ScrollArea = React.forwardRef<HTMLDivElement, ScrollAreaProps>(
  ({ 
    className = '', 
    orientation = 'vertical',
    scrollHideDelay = 600,
    children,
    ...props 
  }, ref) => {
    const [showScrollbar, setShowScrollbar] = React.useState(false);
    const hideScrollbarTimer = React.useRef<NodeJS.Timeout>();

    const handleScroll = () => {
      setShowScrollbar(true);
      
      if (hideScrollbarTimer.current) {
        clearTimeout(hideScrollbarTimer.current);
      }
      
      hideScrollbarTimer.current = setTimeout(() => {
        setShowScrollbar(false);
      }, scrollHideDelay);
    };

    React.useEffect(() => {
      return () => {
        if (hideScrollbarTimer.current) {
          clearTimeout(hideScrollbarTimer.current);
        }
      };
    }, []);

    return (
      <div
        ref={ref}
        className={`
          relative
          ${orientation === 'both' ? 'overflow-auto' : ''}
          ${orientation === 'vertical' ? 'overflow-y-auto overflow-x-hidden' : ''}
          ${orientation === 'horizontal' ? 'overflow-x-auto overflow-y-hidden' : ''}
          ${className}
        `}
        onScroll={handleScroll}
        {...props}
      >
        {children}
        <ScrollBar 
          orientation={orientation} 
          visible={showScrollbar}
        />
      </div>
    );
  }
);

ScrollArea.displayName = 'ScrollArea';

interface ScrollBarProps {
  orientation: 'vertical' | 'horizontal' | 'both';
  visible: boolean;
}

const ScrollBar: React.FC<ScrollBarProps> = ({ 
  orientation, 
  visible 
}) => {
  const showVertical = orientation === 'vertical' || orientation === 'both';
  const showHorizontal = orientation === 'horizontal' || orientation === 'both';

  return (
    <>
      {showVertical && (
        <div
          className={`
            absolute right-0 top-0 h-full w-2 transition-opacity duration-200
            ${visible ? 'opacity-100' : 'opacity-0'}
          `}
        >
          <div className="relative h-full w-full">
            <div className="absolute right-0 top-0 h-full w-1.5 rounded-full bg-gray-200 dark:bg-gray-700" />
          </div>
        </div>
      )}
      {showHorizontal && (
        <div
          className={`
            absolute bottom-0 left-0 w-full h-2 transition-opacity duration-200
            ${visible ? 'opacity-100' : 'opacity-0'}
          `}
        >
          <div className="relative w-full h-full">
            <div className="absolute bottom-0 left-0 w-full h-1.5 rounded-full bg-gray-200 dark:bg-gray-700" />
          </div>
        </div>
      )}
    </>
  );
};

export const ScrollAreaViewport = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className = '', ...props }, ref) => (
  <div
    ref={ref}
    className={`h-full w-full ${className}`}
    {...props}
  />
));

ScrollAreaViewport.displayName = 'ScrollAreaViewport';
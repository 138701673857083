import React, { useEffect, useState, useMemo } from 'react';
import { Simulation, TBSq, SimulationQuestionContent, UserTBSResponse, HTMLContent } from '../../services/api';
import { useTheme } from '../../contexts/ThemeContext';
import FormattedNumberInput from './common/FormattedNumberInput';

interface SimulationsRenderProps {
  simulation: Simulation;
  onAnswerChange: (questionId: string, field: string, value: string) => void;
  userAnswers: Record<string, any>;
  isSubmitted: boolean;
  userResponse?: UserTBSResponse | null;
  gradingResults: any;
}

    // Add this type guard function
    function isTBSq(question: TBSq | HTMLContent): question is TBSq {
        return (question as TBSq).id !== undefined;
      }

    const SimulationsRender: React.FC<SimulationsRenderProps> = ({
      simulation,
      onAnswerChange,
      userAnswers,
      isSubmitted,
      userResponse,
      gradingResults
    }) => {
      const { isDarkMode } = useTheme();
      const [questionContent, setQuestionContent] = useState<SimulationQuestionContent | null>(null);
    
      useEffect(() => {
          console.log('Simulation data:', simulation);
          if (simulation.question_content) {
            console.log('Question content:', simulation.question_content);
            if (typeof simulation.question_content === 'string') {
              try {
                const parsed = JSON.parse(simulation.question_content);
                console.log('Parsed question content:', parsed);
                setQuestionContent(parsed);
              } catch (error) {
                console.error('Error parsing question_content:', error);
                setQuestionContent(null);
              }
            } else {
              console.log('Question content is already an object:', simulation.question_content);
              setQuestionContent(simulation.question_content as SimulationQuestionContent);
            }
          } else {
            console.error('No question_content found in simulation data');
            setQuestionContent(null);
          }
        }, [simulation]);

        useEffect(() => {
            if (userResponse && userResponse.answers) {
              Object.entries(userResponse.answers).forEach(([questionId, answer]) => {
                Object.entries(answer).forEach(([cellRef, value]) => {
                  console.log(`Question ${questionId} ${cellRef} value:`, value);
                });
              });
            }
          }, [userResponse]);
  
    const renderHTMLContent = useMemo(() => {
      return simulation.html ? (
        <div dangerouslySetInnerHTML={{ __html: simulation.html }} className="mb-4" />
      ) : null;
    }, [simulation.html]);

    const formatNumberWithCommas = (value: string): string => {
        const parts = value.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
      };
    
      const handleAnswerChange = (questionId: string, cellRef: string, value: string) => {
        let formattedValue = value;
        const question = questionContent?.questions.find(q => isTBSq(q) && q.id === questionId) as TBSq | undefined;
        const cell = question?.cells.find(c => c.ref === cellRef);
        
        if (cell && cell.type === 'input') {
          formattedValue = value === '' ? '0' : formatNumberWithCommas(value);
        }
        onAnswerChange(questionId, cellRef, formattedValue);
      };

    const getAnswerStatus = (questionId: string, cellRef: string) => {
        if (!isSubmitted || !gradingResults || !gradingResults.feedback) return 'unanswered';
        const feedback = gradingResults.feedback.find((f: any) => f.questionId === questionId);
        if (!feedback) return 'unanswered';
        return feedback.answerFeedback[cellRef]?.isCorrect ? 'correct' : 'incorrect';
      };
    
      const getAnswerClass = (status: string) => {
        switch (status) {
          case 'correct':
            return isDarkMode ? 'bg-green-700 text-white' : 'bg-green-100 text-green-800';
          case 'incorrect':
            return isDarkMode ? 'bg-red-700 text-white' : 'bg-red-100 text-red-800';
          default:
            return isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800';
        }
      };
    
      const renderCell = (question: TBSq, cell: TBSq['cells'][0]) => {
        const questionId = question.id;
        const cellRef = cell.ref;
        const answerStatus = getAnswerStatus(questionId, cellRef);
        const answerClass = getAnswerClass(answerStatus);
        const value = userAnswers[questionId]?.[cellRef] || '';
                
        switch (cell.type) {
          case 'text':
            return <span className="dark:text-gray-300">{cell.value || value}</span>;
          case 'dropdown':
            return renderDropdown(questionId, cellRef, value, cell.options || [], answerClass);
          case 'input':
            return renderTextInput(questionId, cellRef, value, answerClass);
          default:
            return <span className="dark:text-gray-300">{value || cell.value || ''}</span>;
        }
      };
    
      const renderDropdown = (questionId: string, cellRef: string, value: string, options: string[], answerClass: string) => (
        <select
          value={value}
          onChange={(e) => handleAnswerChange(questionId, cellRef, e.target.value)}
          disabled={isSubmitted}
          className={`w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${answerClass}`}
        >
          <option value="">Select...</option>
          {options.map((choice: string) => (
            <option key={choice} value={choice}>{choice}</option>
          ))}
        </select>
      );
      
      const renderTextInput = (questionId: string, cellRef: string, value: string, answerClass: string) => (
        <FormattedNumberInput
          value={value || '0'}
          onChange={(newValue) => handleAnswerChange(questionId, cellRef, newValue || '0')}
          disabled={isSubmitted}
          className={`w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${answerClass}`}
        />
      );



  // Updated renderTable function to handle different structures
// Update the renderTable function
const renderTable = () => {
  if (!questionContent || !questionContent.questions) return null;

  return (
    <>
      {questionContent.introduction && (
        <div dangerouslySetInnerHTML={{ __html: questionContent.introduction }} className="mb-4" />
      )}
      <table className="w-full border-collapse border border-gray-300 dark:border-gray-600 mt-4">
        <thead>
          <tr className="bg-gray-100 dark:bg-gray-700">
            {questionContent.tableHeaders?.map((header, index) => (
              <th key={index} className="border border-gray-300 dark:border-gray-600 p-3 text-left dark:text-white">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {questionContent.questions.map((item, index) => {
            if (!isTBSq(item)) {
              return (
                <tr key={`html-${index}`}>
                  <td colSpan={questionContent.tableHeaders.length} dangerouslySetInnerHTML={{ __html: item.content }} />
                </tr>
              );
            } else {
              return (
                <tr key={item.id}>
                  {item.cells.map((cell) => (
                    <td key={cell.ref} className="border border-gray-300 dark:border-gray-600 p-3">
                      {renderCell(item, cell)}
                    </td>
                  ))}
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </>
  );
};

  if (!questionContent) {
    return <div>Loading...</div>;
  }

  return (
    <div className="simulation-render max-w-4xl mx-auto p-8 bg-white dark:bg-gray-900 rounded-lg">
      {renderHTMLContent}
      {renderTable()}
    </div>
  );
};

export default React.memo(SimulationsRender);
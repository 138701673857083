import React from 'react';
import { Modal } from '../../../components/ui/Modal';
import { Button } from '../../../components/ui/Button';
import { useAuth } from '../../AuthProvider';
import { Crown, Check, X } from 'lucide-react';

interface UpgradeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FeatureRow {
  feature: string;
  freeTrial: string | React.ReactNode;
  fullAccess: string | React.ReactNode;
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({ isOpen, onClose }) => {
  // Use the user from AuthContext which now contains our consolidated User type
  const { user } = useAuth();

  const getThrivecartUrl = () => {
    const baseUrl = 'https://sales.cpaexamguide.com/kesler-cpa-review-membership/';
    const params = new URLSearchParams();

    // Use the user data directly from AuthContext
    if (user) {
      const { email, full_name } = user;
      if (email) params.append('passthrough[customer_email]', email);
      if (full_name) params.append('passthrough[customer_name]', full_name);
    }

    return `${baseUrl}?${params.toString()}`;
  };

  const handleUpgradeClick = () => {
    // If user is not authenticated, you might want to handle that case
    if (!user) {
      console.warn('User not authenticated');
      return;
    }
    
    const url = getThrivecartUrl();
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // Rest of your features array...
  const features: FeatureRow[] = [
    { feature: "Mentorship Course", freeTrial: <X size={16} className="mx-auto text-red-500" />, fullAccess: <Check size={18} className="mx-auto text-green-500 font-bold" /> },
    { feature: "2300+ MCQs", freeTrial: "Only 175", fullAccess: <Check size={18} className="mx-auto text-green-500 font-bold" /> },
    { feature: "2,250+ Flashcards", freeTrial: "Only 160", fullAccess: <Check size={18} className="mx-auto text-green-500 font-bold" /> },
    { feature: "25+ TBS", freeTrial: "Only 4", fullAccess: <Check size={18} className="mx-auto text-green-500 font-bold" /> },
    { feature: "Custom Flashcards", freeTrial: "50", fullAccess: "Unlimited" },
    { feature: "Custom Notes", freeTrial: "50", fullAccess: "Unlimited" },
    { feature: "Private Study Group", freeTrial: <X size={16} className="mx-auto text-red-500" />, fullAccess: <Check size={18} className="mx-auto text-green-500 font-bold" /> },
    { feature: "Access To CPA Exam Experts", freeTrial: <X size={16} className="mx-auto text-red-500" />, fullAccess: <Check size={18} className="mx-auto text-green-500 font-bold" /> },
    { feature: "10-15 Point KeslerBoost*", freeTrial: <X size={16} className="mx-auto text-red-500" />, fullAccess: <Check size={18} className="mx-auto text-green-500 font-bold" /> },
    { feature: "Triple The Fun While Studying", freeTrial: <X size={16} className="mx-auto text-red-500" />, fullAccess: <Check size={18} className="mx-auto text-green-500 font-bold" /> },
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-[95%] sm:max-w-3xl h-auto flex flex-col p-4 sm:p-6">
        <div className="flex justify-between items-start">
          <h2 className="text-base sm:text-lg font-bold text-gray-800 dark:text-white">
            {user?.full_name ? `Upgrade For ${user.full_name}` : 'Upgrade To Kesler Unlimited'}
          </h2>
          <button
            onClick={onClose}
            className="bg-red-500 rounded-full p-1 text-white hover:bg-red-600 focus:outline-none flex items-center justify-center"
            aria-label="Close"
          >
            <X size={16} />
          </button>
        </div>
        
        <p className="mb-4 text-xs sm:text-sm text-gray-600 dark:text-gray-300">
          Unlock all features and accelerate your CPA exam preparation today!
        </p>
        
        <div className="w-full">
          <table className="w-full text-[10px] sm:text-xs md:text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-[10px] sm:text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-1 py-2 sm:px-2 sm:py-3 w-1/2">Feature</th>
                <th scope="col" className="px-1 py-2 sm:px-2 sm:py-3 text-center w-1/4">Free Trial</th>
                <th scope="col" className="px-1 py-2 sm:px-2 sm:py-3 text-center w-1/4 bg-gradient-to-r dark:text-white from-orange-100 to-orange-200 dark:from-blue-700 dark:to-blue-800">Full Access</th>
              </tr>
            </thead>
            <tbody className="text-[10px] sm:text-xs md:text-sm">
              {features.map((row, index) => (
                <tr key={index} className="border-b dark:border-gray-700">
                  <th scope="row" className="px-1 py-1 sm:px-2 sm:py-2 font-medium text-gray-900 break-words whitespace-normal dark:text-white">
                    {row.feature}
                  </th>
                  <td className="px-1 py-1 sm:px-2 sm:py-2 text-center">{row.freeTrial}</td>
                  <td className="px-1 py-1 sm:px-2 sm:py-2 text-center bg-gradient-to-r font-bold dark:text-white from-orange-100 to-orange-200 dark:from-blue-700 dark:to-blue-800">{row.fullAccess}</td>
                </tr>
              ))}
              <tr className="border-b dark:border-gray-700">
                <th scope="row" className="px-1 py-1 sm:px-2 sm:py-2 font-medium text-gray-900 break-words whitespace-normal dark:text-white">
                  Upgrade Now
                </th>
                <td className="px-1 py-1 sm:px-2 sm:py-2 text-center">-</td>
                <td className="px-1 py-1 sm:px-2 sm:py-2 text-center bg-gradient-to-r dark:text-white from-orange-100 to-orange-200 dark:from-blue-700 dark:to-blue-800">
                  <Button
                    onClick={handleUpgradeClick}
                    disabled={!user}
                    className="bg-primary-orange hover:bg-primary-orange-hover text-white px-2 py-1 sm:px-4 sm:py-2 rounded-lg font-semibold text-[10px] sm:text-xs md:text-sm flex items-center justify-center mx-auto"
                  >
                    <Crown size={12} className="mr-1 sm:mr-2" />
                    Upgrade
                  </Button>
                  <p className="text-[8px] sm:text-[10px] text-gray-500 dark:text-gray-200 mt-1">
                    Redirects to secure checkout
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className="text-[8px] sm:text-[10px] text-gray-500 dark:text-gray-400 mt-2">
          *Results may vary based on individual effort and adherence to the study system
        </p>
      </div>
    </Modal>
  );
};

export default React.memo(UpgradeModal);
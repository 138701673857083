import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../services/auth';
import { api } from '../../services/api';
import { queryClient } from '../../services/queryClient';  // Assuming you have a centralized queryClient

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        await auth.logout();
        
        // Clear React Query cache
        queryClient.clear();
        
        // Clear localStorage
        localStorage.clear();
        
        // Clear API-specific caches
        api.clearAllCache();
        
        // Redirect to home page
        navigate('/');
      } catch (error) {
        console.error('Error during logout:', error);
        // You might want to handle the error, perhaps by showing a message to the user
      }
    };
    performLogout();
  }, [navigate]);

  return <div className="text-light-text dark:text-dark-text">Logging out...</div>;
}

export default Logout;
import { useQuery, useQueryClient } from 'react-query';
import { api } from '../services/api';

export const useQuizHistory = (
  userId: string | undefined, 
  courseId: string, 
  filter: string = 'all', 
  isFilteringLocally: boolean = false,
  refetchOnMount: boolean = false
) => {
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery(
    ['quizHistory', userId, courseId],
    () => api.getFilteredQuizHistory(userId!, courseId, 'all'),
    {
      enabled: !!userId && !isFilteringLocally,
      staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
      cacheTime: 10 * 60 * 1000, // Keep unused data in cache for 10 minutes
      refetchOnWindowFocus: false,
      refetchOnMount,
      retry: 3,
      onError: (error) => {
        console.error('Error fetching quiz history:', error);
      }
    }
  );

  const refetchQuizHistory = () => {
    return queryClient.invalidateQueries(['quizHistory', userId, courseId]);
  };

  return { data, isLoading, error, refetchQuizHistory };
};
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../services/supabase';
import { User } from '@supabase/supabase-js';

function Home() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    async function fetchUser() {
      const { data: { user } } = await supabase.auth.getUser();
      setCurrentUser(user);
    }
    fetchUser();
  }, []);


  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-4xl font-bold mb-6">Welcome to Kesler CPA Review</h1>
      
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-2xl font-semibold mb-4">Your Path to CPA Success</h2>
        <p className="mb-4">
          Our comprehensive Kesler CPA Review is designed to help you prepare effectively for your CPA exam. 
          With expert-led courses, adaptive study plans, and a variety of practice materials, 
          you'll be well-equipped to tackle the exam with confidence.
        </p>
        {currentUser ? (
          <Link to="/products" className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Explore Courses
          </Link>
        ) : (
          <div className="space-x-4">
            <Link to="/login" className="inline-block bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
              Log In
            </Link>
            <Link to="/register" className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
              Register
            </Link>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-2">Key Features</h3>
          <ul className="list-disc list-inside">
            <li>Comprehensive study materials</li>
            <li>Adaptive learning technology</li>
            <li>Practice quizzes and mock exams</li>
            <li>Performance analytics</li>
          </ul>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-2">Available Courses</h3>
          <ul className="list-disc list-inside">
            <li>AUD - Auditing and Attestation</li>
            <li>BEC - Business Environment and Concepts</li>
            <li>FAR - Financial Accounting and Reporting</li>
            <li>REG - Regulation</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Home;
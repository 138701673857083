//SPACED REPEITION 50 CARD COUNT CHECKds
const CARDS_COMPLETED_KEY = 'keslerCardsCompleted';
const SPACED_REPETITION_UNLOCKED_KEY = 'spacedRepetitionUnlocked';

export const getCardsCompleted = (userId: string, courseId: string): number => {
  const key = `${CARDS_COMPLETED_KEY}_${userId}_${courseId}`;
  const stored = localStorage.getItem(key);
  return stored ? parseInt(stored, 10) : 0;
};

export const updateCardsCompletedInStorage = (userId: string, courseId: string, count: number): void => {
  const key = `${CARDS_COMPLETED_KEY}_${userId}_${courseId}`;
  localStorage.setItem(key, count.toString());
};

export const getSpacedRepetitionUnlocked = (userId: string, courseId: string): boolean => {
  const key = `${SPACED_REPETITION_UNLOCKED_KEY}_${userId}_${courseId}`;
  return localStorage.getItem(key) === 'true';
};

export const setSpacedRepetitionUnlocked = (userId: string, courseId: string, unlocked: boolean): void => {
  const key = `${SPACED_REPETITION_UNLOCKED_KEY}_${userId}_${courseId}`;
  localStorage.setItem(key, unlocked.toString());
};
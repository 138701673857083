import React, { useState } from 'react';

interface TooltipProps {
  children: React.ReactElement;
  content: React.ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
  delayMS?: number;
  className?: string;
  variant?: 'default' | 'preview';
}

export const TooltipContent: React.FC<{
  children: React.ReactNode;
  className?: string;
  side?: 'top' | 'right' | 'bottom' | 'left';
  variant?: 'default' | 'preview';
}> = ({ children, className = '', side = 'top', variant = 'default' }) => (
  <div className={`
    z-50 bg-gray-900 dark:bg-gray-100 
    text-gray-50 dark:text-gray-900 rounded-md shadow-md
    ${variant === 'preview' ? 
      'p-3 max-w-lg text-xs leading-relaxed whitespace-pre-wrap' : 
      'px-3 py-2 text-sm'
    }
    ${className}
  `}>
    {children}
  </div>
);

export const TooltipTrigger: React.FC<{ 
  asChild?: boolean;
  children: React.ReactElement;
}> = ({ asChild, children }) => {
  if (asChild) {
    return children;
  }
  return <div className="inline-block">{children}</div>;
};

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  side = 'top',
  align = 'center',
  delayMS = 200,
  className = '',
  variant = 'default'
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, delayMS);
    setTimeoutId(timeout);
  };

  const handleMouseLeave = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsVisible(false);
  };

  const getPositionClasses = () => {
    const baseClasses = 'absolute pointer-events-none transition-opacity duration-200';
    const positions = {
      top: 'bottom-full mb-2',
      right: 'left-full ml-2',
      bottom: 'top-full mt-2',
      left: 'right-full mr-2'
    };
    
    const alignments = {
      start: side === 'top' || side === 'bottom' ? 'left-0' : 'top-0',
      center: side === 'top' || side === 'bottom' ? 'left-1/2 -translate-x-1/2' : 'top-1/2 -translate-y-1/2',
      end: side === 'top' || side === 'bottom' ? 'right-0' : 'bottom-0'
    };

    return `${baseClasses} ${positions[side]} ${alignments[align]}`;
  };

  return (
    <div 
      className="relative inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isVisible && (
        <div className={`${getPositionClasses()} ${isVisible ? 'opacity-100' : 'opacity-0'} ${className}`}>
          <TooltipContent side={side} variant={variant}>
            {content}
          </TooltipContent>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
import React, { useState, useRef } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import { useNotification } from '../../../contexts/NotificationContext';
import { api } from '../../../services/api';
import { supabase } from '../../../services/supabase';
import { 
  AlertCircle,
  ArrowLeft,
  Loader2,
  Send,
  HelpCircle,
  User,
  GraduationCap,
  Paperclip,
  X,
} from 'lucide-react';
import { Input } from '../../ui/Input';
import { Button } from '../../ui/Button';
import { Textarea } from '../../ui/Textarea';
import { Alert, AlertDescription } from '../../ui/Alert';
import { ScrollArea } from '../../ui/ScrollArea';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue 
} from '../../ui/Select';

type InquiryType = 'general' | 'mentor-q' | 'account-management';

interface InquiryOption {
  value: InquiryType;
  label: string;
  icon: React.ReactNode;
  description: string;
}

interface AttachmentError {
  fileName: string;
  error: string;
}

interface TicketComposerProps {
  onBack: () => void;
  onRefresh?: () => Promise<void>;
}

const INQUIRY_OPTIONS: InquiryOption[] = [
  {
    value: 'general',
    label: 'General Question',
    icon: <HelpCircle className="w-4 h-4" />,
    description: 'Ask about study materials, website features, or other general inquiries'
  },
  {
    value: 'mentor-q',
    label: 'CPA Exam Mentorship Question',
    icon: <GraduationCap className="w-4 h-4" />,
    description: 'Questions about exam strategy, study planning, or mentorship support'
  },
  {
    value: 'account-management',
    label: 'Account Management',
    icon: <User className="w-4 h-4" />,
    description: 'Help with account settings, billing, or access issues'
  }
];

const getInquiryLabel = (value: InquiryType): string => {
  return INQUIRY_OPTIONS.find(option => option.value === value)?.label || '';
};

const MAX_CHARS = 4000;
const MAX_SUBJECT_CHARS = 125;

const convertToHtml = (text: string): string => {
    if (!text) return '';
  
    // First sanitize the input to prevent XSS
    const sanitizedText = text
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  
    // Convert URLs to clickable links
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const textWithLinks = sanitizedText.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-600 underline">${url}</a>`;
    });
  
    // Convert line breaks to <br> and preserve paragraphs
    return textWithLinks
      .split(/\n\n+/) // Split on multiple line breaks (paragraphs)
      .map(paragraph => 
        `<p>${
          paragraph
            .replace(/\n/g, '<br>') // Convert single line breaks
            .replace(/\s{2,}/g, ' ') // Normalize multiple spaces
        }</p>`
      )
      .join('');
  };
  
interface PreviewProps {
  content: string;
}

const Preview: React.FC<PreviewProps> = ({ content }) => {
  return (
    <div 
      className="p-4 border rounded-md bg-white dark:bg-gray-900 min-h-[200px] overflow-auto"
    >
      <div 
        className="prose dark:prose-invert max-w-none"
        dangerouslySetInnerHTML={{ __html: convertToHtml(content) }}
      />
    </div>
  );
};

const TicketComposer: React.FC<TicketComposerProps> = ({ onBack, onRefresh }) => {
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [inquiryType, setInquiryType] = useState<InquiryType>('general');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [attachmentErrors, setAttachmentErrors] = useState<AttachmentError[]>([]);
  const [uploading, setUploading] = useState(false);

  const sanitizeInput = (input: string) => {
    return input
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    
    setUploading(true);
    const newErrors: AttachmentError[] = [];
    const validFiles: File[] = [];

    for (const file of Array.from(event.target.files)) {
      try {
        // Check file type
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif'];
        if (!allowedTypes.includes(file.type)) {
          newErrors.push({
            fileName: file.name,
            error: `Only PDF and images are permitted`
          });
          continue;
        }

        // Check file size (10MB limit)
        if (file.size > 10 * 1024 * 1024) {
          newErrors.push({
            fileName: file.name,
            error: `File exceeds 10MB limit`
          });
          continue;
        }

        validFiles.push(file);
      } catch (error) {
        newErrors.push({
          fileName: file.name,
          error: 'Failed to process file'
        });
      }
    }

    // Add valid files to attachments
    if (validFiles.length) {
      setAttachments(prev => [...prev, ...validFiles]);
    }

    // Set errors if any
    if (newErrors.length) {
      setAttachmentErrors(newErrors);
    }

    setUploading(false);
    if (event.target.value) {
      event.target.value = '';
    }
  };

  const removeError = (fileName: string) => {
    setAttachmentErrors(errors => errors.filter(e => e.fileName !== fileName));
  };

  const removeAttachment = (index: number) => {
    setAttachments(attachments.filter((_, i) => i !== index));
    setAttachmentErrors(errors => errors.filter(e => e.fileName !== attachments[index].name));
  };

  const validateForm = () => {
    if (!subject.trim()) {
      setError('Please enter a subject');
      return false;
    }
    if (!body.trim()) {
      setError('Please enter your message');
      return false;
    }
    if (body.length > MAX_CHARS) {
      setError(`Message is too long. Maximum ${MAX_CHARS} characters allowed`);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!user?.id) return;
    if (!validateForm()) return;
  
    setLoading(true);
    setError(null);
  
    try {
      // Handle attachments first
      const uploadedAttachments = [];
      for (const file of attachments) {
        // Use consistent path format with UUID folder
        const ticketFolder = crypto.randomUUID();
        const filePath = `support-attachments/${ticketFolder}/${Date.now()}-${file.name}`;
        
        const { error: uploadError } = await supabase.storage
          .from('support-attachments')
          .upload(filePath, file);
  
        if (uploadError) throw uploadError;
  
        const { data: { publicUrl } } = supabase.storage
          .from('support-attachments')
          .getPublicUrl(filePath);
  
        uploadedAttachments.push({
          name: file.name,
          url: publicUrl,
          type: file.type
        });
      }
  
      // Convert the body text to HTML
      const htmlBody = convertToHtml(body);
      
      // Create ticket with attachments included
      const { ticket } = await api.createTicket(
        user.id,
        sanitizeInput(subject.trim()),
        htmlBody,
        htmlBody, // Initial message same as body for web tickets
        'medium',
        undefined, // No content_id for regular tickets
        'web',     // Source type
        uploadedAttachments // Pass attachments to createTicket
      );
  
      if (ticket) {
        // Single update call including both tags and attachments
        await api.updateTicket(ticket.id, {
          tags: [inquiryType],
          updated_at: new Date().toISOString(),
          last_activity_at: new Date().toISOString(),
          attachments: uploadedAttachments // Ensure attachments are set in update as well
        });
  
        // Send confirmation email with attachments
        await supabase.functions.invoke('sendgrid', {
          body: {
            to: user.email,
            subject: `[Ticket #${ticket.short_id}] ${subject}`,
            content: `Thank you for contacting support. We have received your request and will respond shortly.
  
  Your message:
  ${htmlBody}`,
            ticketId: ticket.id,
            messageId: ticket.original_email_id?.replace(/[<>]/g, ''),
            fromName: 'Team Kesler CPA Review',
            fromEmail: 'support@keslercpareview.com',
            attachments: uploadedAttachments
          }
        });
  
        showNotification?.('Ticket created successfully', 'success');
        
        // Trigger refresh before navigating back
        if (onRefresh) {
          await onRefresh();
        }
        
        onBack();
      }
    } catch (error) {
      console.error('Error creating ticket:', error);
      setError('Failed to create ticket. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const remainingChars = MAX_CHARS - body.length;

  return (
    <div className="h-full flex flex-col bg-white dark:bg-gray-900">
      {/* Header */}
      <div className="flex-none px-6 py-4 border-b dark:border-gray-700">
        <div className="flex items-center gap-4">
          <Button 
            variant="outline"
            onClick={onBack}
            className="w-10 h-10 p-0 flex items-center justify-center dark:text-gray-100 dark:border-gray-600 dark:hover:bg-gray-800"
          >
            <ArrowLeft className="w-4 h-4" />
          </Button>
          <h1 className="text-xl font-bold text-gray-900 dark:text-gray-200">
            Create Support Ticket
          </h1>
        </div>
      </div>

      {/* Scrollable Content */}
      <ScrollArea>
        <div className="p-6 space-y-6">
          {error && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {/* Form Fields */}
        <div className="space-y-6 flex-none">
          <div>
            <label className="block text-sm font-medium mb-2 text-gray-900 dark:text-gray-100">
              Type of Inquiry
            </label>
            <Select value={inquiryType} onValueChange={(value) => setInquiryType(value as InquiryType)}>
              <SelectTrigger className="w-full bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-700">
                <SelectValue>
                  {getInquiryLabel(inquiryType)}
                </SelectValue>
              </SelectTrigger>
              <SelectContent className="bg-white dark:bg-gray-900">
                {INQUIRY_OPTIONS.map((option) => (
                  <SelectItem 
                    key={option.value} 
                    value={option.value}
                    className="py-3"
                  >
                    <div className="flex items-start gap-2">
                      <div className="flex-shrink-0 mt-1">
                        {option.icon}
                      </div>
                      <div>
                        <div className="font-medium">{option.label}</div>
                        <div className="text-xs text-gray-500 dark:text-gray-400">
                          {option.description}
                        </div>
                      </div>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2 text-gray-900 dark:text-gray-100">
              Subject
            </label>
            <Input
              placeholder="Enter the subject of your inquiry"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              maxLength={MAX_SUBJECT_CHARS}
              className="w-full bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-100"
            />
          </div>
        </div>

          {/* Attachment Errors */}
          {attachmentErrors.length > 0 && (
            <div className="space-y-2">
              {attachmentErrors.map((error, index) => (
                <Alert 
                  key={index}
                  variant="destructive"
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center gap-2">
                    <AlertCircle className="h-4 w-4 flex-shrink-0" />
                    <span className="text-sm font-medium">
                      {error.fileName}:
                    </span>
                    <span className="text-sm">
                      {error.error}
                    </span>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => removeError(error.fileName)}
                    className="h-6 w-6 p-0 hover:bg-red-200 dark:hover:bg-red-900/50"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </Alert>
              ))}
            </div>
          )}

          {/* Attachments Display */}
          {attachments.length > 0 && (
            <div className="space-y-2">
              {attachments.map((file, index) => (
                <div key={index} className="flex items-center gap-2 text-xs md:text-sm bg-gray-50 dark:bg-gray-700 p-2 rounded">
                  <Paperclip className="w-4 h-4" />
                  <span className="flex-1 truncate">{file.name}</span>
                  <Button
                    variant="ghost"
                    onClick={() => removeAttachment(index)}
                    className="h-6 w-6 p-0"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      </ScrollArea>

      {/* Fixed Bottom Section - Matches UserTicketDetail */}
      <div className="flex-none max-h-[40rem] min-h-[25rem] border-t dark:border-gray-700 p-3 md:p-4">
        {/* Message Input Section */}
        <div className="flex-1 h-full flex gap-4 min-h-0 mt-6">
          <div className="flex-1 flex flex-col min-h-0">
            <Textarea
              value={body}
              onChange={(e) => setBody(e.target.value)}
              placeholder="Type your message..."
              className="flex-1 min-h-0 resize-none text-sm md:text-base dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-400 dark:border-gray-700"
            />
            <div className="mt-2 text-xs text-gray-500 dark:text-gray-400">
              {remainingChars} characters remaining
            </div>
          </div>
          
          <div className="flex flex-col gap-2">
            <Button
              variant="outline"
              onClick={() => fileInputRef.current?.click()}
              className="h-8 md:h-10 px-3 md:px-4 dark:text-gray-100 dark:border-gray-600 dark:hover:bg-gray-800"
              disabled={uploading}
            >
              {uploading ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <Paperclip className="w-4 h-4" />
              )}
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={loading || !subject.trim() || !body.trim()}
              className="h-8 md:h-10 px-3 md:px-4 dark:text-gray-100"
            >
              {loading ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <Send className="w-4 h-4" />
              )}
            </Button>
          </div>
        </div>
      </div>

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
        multiple
        accept=".pdf,.jpg,.jpeg,.png,.gif"
      />
    </div>
  );
};

export default TicketComposer;